import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Material, MATERIAL_GROUP_OPTIONS, MATERIAL_TYPE_OPTIONS } from '../../models/material.model';
import { CatalogueListColumn } from '../readonly-list.component/readonly-list.component';
import { MaterialDetailComponent } from '../material-detail/material-detail.component';
import { ModelService } from './../../services/model.service';
import { CommonTools } from '../../shared/helpers/common-tools';
import { EntityType } from '../../models/enums/entityType';
import { WindowPropertiesModalComponent } from '../windows-properties-list/window-properties-modal/window-properties-modal.component';
import { MDBModalService } from 'angular-bootstrap-md';


@Component({
    selector: 'app-materials-list',
    templateUrl: './materials-list.component.html',
    styleUrls: ['./materials-list.component.scss']
})
export class MaterialsListComponent implements OnInit {


    headElements: string[] = ['Jméno', 'Popis', 'Skupina', 'SVT kód'];
    columnElements: string[] = ['name', 'description', 'group', 'svtCode'];
    dataSource: Material[] = [];

    constructor(private model: ModelService, private mbdModalService: MDBModalService) { }

    ngOnInit() {
        this.dataSource = this.model.Materials;
    }
    defineColumns(): CatalogueListColumn[] {

        const columns: CatalogueListColumn[] = []; 

        let column = new CatalogueListColumn();
        column.heading = 'Jméno';
        column.dataSourceColumnName = 'name';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Popis';
        column.dataSourceColumnName = 'description';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Skupina';
        column.dataSourceColumnName = 'group';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = MATERIAL_GROUP_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Typ';
        column.dataSourceColumnName = 'type';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = MATERIAL_TYPE_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        column.allColumnOptions = MATERIAL_TYPE_OPTIONS;
        columns.push(column);


        column = new CatalogueListColumn();
        column.heading = 'SVT kód';
        column.dataSourceColumnName = 'svtCode';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Výpočtová hodnota';
        column.dataSourceColumnName = 'calculatingValue';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Objemová hmotnost';
        column.dataSourceColumnName = 'volumeWeight';
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onCopyOne(externalId: string) {
        var material: Material = this.model.Materials.find(m => m.externalId == externalId);
        var clone = Object.assign(new Material(), material);
        clone.externalId = CommonTools.GetUUID();
        this.model.AddEntity(clone);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.Material, externalId);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    private openModal(externalId?: string) {
        const modalRef = this.mbdModalService.show(MaterialDetailComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: externalId
            }
        });
    }
}