import { CommonTools } from './../shared/helpers/common-tools';
import { Point } from "./point.model";

export class Room {
    centerPoint: Point;
    vertecies: Point[];
    spacePropertiesExternalId: string;
    storeyExternalId: string;
    text: string;
    zoneExternalId: string;
    stroke = '#353ef0';
    fill = '#353ef0';

    constructor(config: any) {
        this.centerPoint = config.centerPoint;
        this.fill = config.color;
        this.vertecies = config.points;
        this.spacePropertiesExternalId = config.spacePropertiesExternalId;
        this.storeyExternalId = config.storeyExternalId;
        this.text = config.text;
        this.zoneExternalId = config.zoneExternalId;
    }

    sceneFunc(context, shape) {
        shape.strokeWidth(1 / shape.parent.scale().x);

        context.beginPath();
        context.moveTo(shape.attrs.vertecies[0].x, shape.attrs.vertecies[0].y, 10 / shape.parent.scale().x);
        for (let i = 1; i < shape.attrs.vertecies.length; i++) {
            context.lineTo(shape.attrs.vertecies[i].x, shape.attrs.vertecies[i].y);
        }
        context.closePath();
        context.fillStrokeShape(shape);

        CommonTools.RenderText(context, shape.attrs.text, shape.attrs.centerPoint, (15 / shape.parent.scale().x), "black", "middle", "center");
    }
}