// tslint:disable-next-line:max-line-length
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MdbTableDirective, MdbTablePaginationComponent, MdbTableService } from 'angular-bootstrap-md';
import { isObservable, Observable, Subscription } from 'rxjs';
import { DefaultDataService } from '../../services/defaultdata.service';

/**
 * This component represents list of catalogue entities. It can trigger deleting, adding or showing of an entity.
 */
@Component({
    selector: 'app-readonly-list',
    templateUrl: './readonly-list.component.html',
    styleUrls: ['./readonly-list.component.scss'],
})

export class ReadOnlyListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(MdbTableDirective) mdbTable: MdbTableDirective;
    @ViewChild(MdbTablePaginationComponent) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild('row') row: ElementRef;

    @Input() dataSource$: Observable<any>;
    @Input() selected$: Observable<any>;
    @Input('columnList') columnList: CatalogueListColumn[];
    @Input('showSearchBox') showSearchBox: boolean;
    @Input('showAddButton') showAddButton: boolean = true;
    @Input('showDeleteButton') showDeleteButton: boolean = true;
    @Input('showCopyButton') showCopyButton: boolean = false;
    @Input('defaultDataConfig') defaultDataConfig: DefaultDataConfig = {
        type: null,
        showSelector: false
    };

    @Output() addOne = new EventEmitter<any>();
    @Output() copyOne = new EventEmitter<any>();
    @Output() updateOne = new EventEmitter<any>();
    @Output() deleteOne = new EventEmitter<any>();
    @Output() showDetail = new EventEmitter<any>();
    @Output() selectById = new EventEmitter<any>();
    @Output() select = new EventEmitter<any>();

    headingList: string[];
    dataSourceColumnNameList: string[];

    showSelected = false;

    elements: any = [];
    allElements: any = [];
    searchColumnValues = {};

    maxVisibleItems = 15;
    maxTableHeight = window.innerHeight - 250;
    firstItemIndex: any;
    lastItemIndex: any;

    subscription$: Subscription;

    defaults;

    @HostListener('window:resize')
    onResize() {
        this.maxTableHeight = window.innerHeight - 250;
    }

    constructor(
        private tableService: MdbTableService,
        private cdRef: ChangeDetectorRef,
        private translate: TranslateService,
    ) {

    }

    ngOnInit() {
        if (isObservable(this.selected$)) {
            this.showSelected = true;
        }
        this.headingList = this.columnList.map((columnItem: CatalogueListColumn) => columnItem.heading);
        this.translate.addLangs(['en', 'fr', 'ur', 'es', 'it', 'fa', 'de']);
        this.translate.setDefaultLang('en');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr|ur|es|it|fa|de/) ? browserLang : 'en');
        this.translate.get(this.headingList).subscribe(translations => {
            this.headingList = this.headingList.map(el => translations[el]);
        });
        this.dataSourceColumnNameList = this.columnList.map((columnItem: CatalogueListColumn) => columnItem.dataSourceColumnName);


        if (isObservable(this.dataSource$)) {
            this.subscription$ = this.dataSource$.subscribe(data => {
                this.tableService.setDataSource(data);
                this.elements = this.tableService.getDataSource();
                this.allElements = data;
                this.mdbTablePagination['tableEl'] = this.tableService as any;
            });
        } else {
            this.tableService.setDataSource(this.dataSource$);
            this.elements = this.dataSource$;
            this.allElements = this.dataSource$;
            this.mdbTablePagination['tableEl'] = this.tableService as any;
        }
    }

    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.mdbTablePagination['tableEl'] = this.tableService as any;

        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
        this.firstItemIndex = this.mdbTablePagination.firstItemIndex;
        this.lastItemIndex = this.mdbTablePagination.lastItemIndex;

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();

        this.cdRef.detectChanges();
    }

    translateColumnValue(element: any, dataSourceColumnName: string) {
        let columnValue = element[dataSourceColumnName];
        let columnIndex = this.dataSourceColumnNameList.indexOf(dataSourceColumnName);
        let columnValueTranslator = this.columnList[columnIndex].columnValueTranslator;

        if (!columnValueTranslator) {
            return columnValue;
        } else {
            return columnValue ? columnValueTranslator(columnValue) : columnValue;
        }
    }

    onNextPageClick(data: any) {
        this.firstItemIndex = data.first;
        this.lastItemIndex = data.last;
    }

    onPreviousPageClick(data: any) {
        this.firstItemIndex = data.first;
        this.lastItemIndex = data.last;
    }

    selectCatalogueItem(item) {
        this.selectById.emit(item['externalId']);
        this.select.emit(item);
    }

    addCatalogueItem() {
        this.addOne.emit();
    }

    copyCatalogueItem(id: number, event: Event) {
        this.copyOne.emit(this.elements[id]['externalId']);
        event.stopPropagation();
    }

    deleteCatalogueItem(id: number, event: Event) {
        if (confirm("Opravdu chcete smazat vybranou položku?")) {
            this.deleteOne.emit(this.elements[id]['externalId']);
        }
        event.stopPropagation();
    }

    showCatalogItemDetail(id: number) {
        this.showDetail.emit(this.elements[id]['externalId']);
    }

    searchItemsByAllColumns() {
        let items = this.tableService.getDataSource();
        this.columnList.forEach((columnItem: CatalogueListColumn) => {
            items = this.searchItemsByColumn(columnItem.dataSourceColumnName, items);
        });

        this.elements = items;
    }

    private searchItemsByColumn(dataSourceColumnName: string, items: []) {
        if (!this.searchColumnValues[dataSourceColumnName]) {
            // filter is empty
            return items;
        } else {
            // filter selectbox
            const column = this.columnList.filter(item => item.dataSourceColumnName == dataSourceColumnName)[0];
            if (column.allColumnOptions) {
                let allOptions = column.allColumnOptions;
                let translator = (code: string) => {
                    return allOptions.filter(item => item.value == code)[0].label;
                }
                return items.filter(item => item[dataSourceColumnName] && this.isSubstring(translator(item[dataSourceColumnName]), this.searchColumnValues[dataSourceColumnName]));
            } else {
                // filter text
                return items.filter(item => {
                    return item[dataSourceColumnName] && this.isSubstring(this.translateColumnValue(item, dataSourceColumnName), this.searchColumnValues[dataSourceColumnName]);
                });
            }
        }
    }

    private isSubstring(text: string, substring: string): boolean {
        return text.toLowerCase().includes(substring.toLowerCase());
    }
}

export class CatalogueListColumn {
    heading: string;
    dataSourceColumnName: string;
    columnValueTranslator: (any) => string;
    allColumnOptions: { value: string; label: string }[];
}

export interface DefaultDataConfig {
    showSelector: boolean;
    type: string;
}
