import { EntityType } from './enums/entityType';
import { StoreyEntityState } from './enums/StoreyEntityState';
import { ProjectEntity } from './project-entity.model';
import { StructureProperties } from './structure-properties.model';

export class WallLayer extends ProjectEntity {
    entityType: EntityType = EntityType.WallLayer;
    height: number = 3000;
    properties: StructureProperties;
    state: StoreyEntityState = StoreyEntityState.EXISTING_AND_NEW;
}
