import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../../models/current-user.model';
import { EntityType } from '../../models/enums/entityType';
import { Role, ROLE_OPTIONS } from '../../models/user.model';
import { AuthenticationService } from './../../services/authentication.service';
import { ModelService } from './../../services/model.service';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

    headElements: string[] = ['Fullname', 'E-mail', 'Phone', 'Username', 'Password', 'Role'];
    columnElements: string[] = ['fullname', 'mail', 'phone', 'username', 'password', 'role'];
    currentUser: CurrentUser;
    comboboxCofig = [{
        property: 'role',
        options: ROLE_OPTIONS
    }];

    constructor(public model: ModelService, private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.currentUser = this.authService.getUser();
    }

    hasAccess() {
        return this.currentUser && this.currentUser.role == Role.ADMINISTRATOR;
    }

    onAddOne(data: any) {
        this.model.AddEntity(data);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.User, externalId);
    }
}
