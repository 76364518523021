import { Component, OnInit } from '@angular/core';
import { ItemsList } from '@ng-select/ng-select/ng-select/items-list';
import { MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { Storey } from '../../../models/storey.model';
import { ModelService } from './../../../services/model.service';

@Component({
    selector: 'app-copy-storeys-modal',
    templateUrl: './copy-storeys-modal.component.html',
    styleUrls: ['./copy-storeys-modal.component.scss']
})
export class CopyStoreysModalComponent implements OnInit {

    externalId: string;
    storeys: Storey[] = [];
    result$: Subject<Storey[]>;
    selectedStoreys: Storey[] = [];

    constructor(
        public model: ModelService,
        private mbdModalService: MDBModalService
    ) { }

    ngOnInit() {
        this.result$ = new Subject();
        this.storeys = this.model.SelectedBuilding.storeys.filter(storey => storey != this.model.SelectedStorey);
    }

    onSave() {
        this.result$.next(this.selectedStoreys);
        this.mbdModalService.hide(1);
    }

    onCancel() {
        this.storeys = [];
        this.result$.next(null);
        this.mbdModalService.hide(1);
    }
}
