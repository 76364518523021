import { EntityType } from './enums/entityType';
import { MaterialLayerState } from './enums/MaterialLayerState';
import { MaterialStrip } from './material-strip.model';
import { ProjectEntity } from './project-entity.model';
import { WallSide } from './structure-properties.model';

// MaterialLayer describes composition of the structure perpendicular to the structure plane

export enum MaterialLayerType {
    STANDARD = "STANDARD",
    HORIZONTAL_INSULATION = "HORIZONTAL_INSULATION",
    VERTICAL_INSULATION = "VERTICAL_INSULATION"
}
export class MaterialLayer extends ProjectEntity {
    entityType: EntityType = EntityType.MaterialLayer;
    thickness: number = 300;
    wallSide: WallSide = WallSide.INTERNAL;
    strips: MaterialStrip[] = [];
    materialLayerState: MaterialLayerState = MaterialLayerState.DEFAULT_PROJECTED;
    materialLayerType: MaterialLayerType = MaterialLayerType.STANDARD;
    insulationLength: number = 0;
}