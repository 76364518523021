import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ICustomDynamicComponent } from 'ngx-property-grid';

export interface IEnumOptions {
    selectedId: string;
    options: { label: string, value: any }[];
}

@Component({
    selector: 'app-enum-property',
    templateUrl: './enum-property.component.html',
    styleUrls: ['./enum-property.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EnumPropertyComponent implements ICustomDynamicComponent<IEnumOptions> {
    @Input() value: IEnumOptions;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    onChange(newValue: any) {
        this.valueChange.emit(newValue);
    }
}
