import { Injectable } from "@angular/core";
import { CanActivate, Router } from '@angular/router';
import { ModelService } from "../../services/model.service";

@Injectable()
export class ModelReadyGuard implements CanActivate {
    constructor(
        public model: ModelService,
        public router: Router
    ) { }

    canActivate(): boolean {
        if (this.model.SelectedProject == null) {
            this.router.navigate(['/projects']);
            return false;
        } else {
            return true;
        }
    }
}