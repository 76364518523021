import { ModelService } from './../../../services/model.service';
import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { ThermalRegime } from '../../../models/enums/Space-properties-enums.model';
import { ZONE_TYPE_OPTIONS } from '../../../models/enums/ZoneType';
import { ProjectIntention } from '../../../models/project.model';
import { SpaceProperties } from '../../../models/space-properties.model';
import { CLIMA_TYPE_OPTIONS, FLAT_SIZE_OPTIONS, HEATING_MEDIUM_TYPE_OPTIONS, SHIELDING_LEVEL_OPTIONS, ZoneDefinition, ZoneDefinitionType, ZONE_DEFINIITION_TYPE_OPTIONS } from '../../../models/zone-definition.model';
import { Zone } from '../../../models/zone.model';
import { DefaultDataService } from '../../../services/defaultdata.service';
import { SpacePropertiesEditorComponent } from '../space-properties-editor/space-properties-editor.component';
import { CoolingSourceOptions, HeatingSourceOptions, WaterHeatingSourceOptions } from './../../../models/zone-definition.model';
import { EntityType } from '../../../models/enums/entityType';
import { StructurePropertiesType } from '../../../models/structure-properties.model';

@Component({
    selector: 'app-zone-editor',
    templateUrl: 'zone-editor.component.html',
    styleUrls: ['zone-editor.component.scss'],
})
export class ZoneEditorComponent implements OnInit {
    externalId: string;
    zone: Zone = new Zone();
    allZoneDefinitions = [];
    defaultZoneValues: ZoneDefinition = new ZoneDefinition();

    heatingSourceOptions = HeatingSourceOptions;
    coolingSourceOptions = CoolingSourceOptions;
    waterHeatingSourceOptions = WaterHeatingSourceOptions;
    states = ZONE_DEFINIITION_TYPE_OPTIONS;
    zoneTypes = ZONE_TYPE_OPTIONS;
    climaTypes = CLIMA_TYPE_OPTIONS;
    shieldings = SHIELDING_LEVEL_OPTIONS;
    heatingMediumTypeOptions = HEATING_MEDIUM_TYPE_OPTIONS;
    backCoolingLocations = [];
    sflats = FLAT_SIZE_OPTIONS;

    constructor(
        private model: ModelService,
        private mdbModalService: MDBModalService,
        private defaultData: DefaultDataService,
        private modalService: MDBModalService,
    ) {
    }

    ngOnInit() {
        this.initData(this.externalId);
        this.backCoolingLocations = this.model.SelectedProject.zones.map(zone => {
            return {
                value: zone.externalId,
                label: zone.description,
            };
        });
        this.backCoolingLocations.unshift({ value: null, label: 'exterier' });
    }

    openDefaultHeatedRoomModal(spaceProperties: SpaceProperties) {
        this.modalService.show(SpacePropertiesEditorComponent, {
            ignoreBackdropClick: true,
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: spaceProperties.externalId,
                isDefaultRoom: true,
            },
        });
    }
    onAddZoneDefinition() {
        const newZoneDefinition = Object.assign(new ZoneDefinition, this.zone.finalDefinition);
        newZoneDefinition.state = ZoneDefinitionType.Alternative,
            newZoneDefinition.zone = this.zone;

        this.zone.alternativeDefinitions.push(newZoneDefinition);
        this.model.AddEntity(newZoneDefinition);
        this.allZoneDefinitions.push(newZoneDefinition);
    }

    onDeleteZoneDefinition(index: number) {
        const confirmation = confirm('Opravdu chcete smazat definici zóny?');
        if (confirmation) {
            this.model.RemoveEntity(this.zone.alternativeDefinitions[index]);
            this.zone.alternativeDefinitions.splice(index, 1);
            this.allZoneDefinitions.splice(index + 2, 1);
        }
    }

    private initData(zoneExternalId?: string) {
        if (zoneExternalId) {
            this.zone = this.model.getEntityByExternalId(EntityType.Zone, zoneExternalId);
            this.allZoneDefinitions = [];
            if (this.model.SelectedProject.intention == ProjectIntention.RECONSTRUCTION) this.allZoneDefinitions.push(this.zone.initialDefinition);
            this.allZoneDefinitions.push(this.zone.finalDefinition);
            this.allZoneDefinitions = this.allZoneDefinitions.concat(this.zone.alternativeDefinitions);
            this.initForm();
        } else {
            this.zone = new Zone();
            this.zone.zoneName = this.model.generateName(this.zone);
            this.zone.initialDefinition = new ZoneDefinition();
            this.zone.initialDefinition.state = ZoneDefinitionType.Initial;
            this.zone.initialDefinition.defaultSpaceProperties = new SpaceProperties();
            this.zone.initialDefinition.defaultSpaceProperties.thermalRegime_I = ThermalRegime.HEATED;
            this.zone.initialDefinition.defaultSpaceProperties.thermalRegime_F = ThermalRegime.HEATED;
            this.zone.initialDefinition.defaultSpaceProperties.description = 'Default SpaceProperties';
            this.zone.initialDefinition.defaultSpaceProperties.floorComposition_I = this.defaultData.getDefaultStructureProperties(StructurePropertiesType.FLOOR);
            this.zone.initialDefinition.defaultSpaceProperties.floorComposition_F = this.defaultData.getDefaultStructureProperties(StructurePropertiesType.FLOOR);
            delete this.zone.initialDefinition.defaultSpaceProperties.location;

            this.zone.finalDefinition = new ZoneDefinition();
            this.zone.finalDefinition.state = ZoneDefinitionType.Final;
            this.zone.finalDefinition.defaultSpaceProperties = new SpaceProperties();
            this.zone.finalDefinition.defaultSpaceProperties.thermalRegime_I = ThermalRegime.HEATED;
            this.zone.finalDefinition.defaultSpaceProperties.thermalRegime_F = ThermalRegime.HEATED;
            this.zone.finalDefinition.defaultSpaceProperties.floorComposition_I = this.defaultData.getDefaultStructureProperties(StructurePropertiesType.FLOOR);
            this.zone.finalDefinition.defaultSpaceProperties.floorComposition_F = this.defaultData.getDefaultStructureProperties(StructurePropertiesType.FLOOR);
            delete this.zone.finalDefinition.defaultSpaceProperties.location;

            this.model.AddEntities([
                [this.zone.initialDefinition.defaultSpaceProperties, this.zone.finalDefinition.defaultSpaceProperties],
                [this.zone]
            ]).then(_ => this.initData(this.zone.externalId));
        }
    }

    onSave() {
        this.saveData();
        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }

    private saveData() {
        this.allZoneDefinitions.forEach(zd => this.model.UpdateEntity(zd));
        this.model.UpdateEntity(this.zone);
    }

    private initForm() {
        for (const def of this.allZoneDefinitions) {
            if (!def.aciProc1) {
                def['aciProc1'] = Object.create({});
                def.aciProc1['externalId'] = null;
            }
            if (!def.aciProc2) {
                def['aciProc2'] = Object.create({});
                def.aciProc2['externalId'] = null;
            }
        }
    }

    public isAlternative(zoneDefinition: ZoneDefinition): boolean {
        return (zoneDefinition && zoneDefinition.state == ZoneDefinitionType.Alternative);
    }
}