import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { ModelReadyGuard } from '../shared/guard/model-ready-guard';
import { BuildingsListComponent } from './buildings-list/buildings-list.component';
import { CadEditorComponent } from './CadEditor/CadEditor.component';
import { DoorsPropertiesListComponent } from './doors-properties-list/doors-properties-list.component';
import { FloorDefinitionListComponent } from './floor-definition-list/floor-definition-list.component';
import { LayoutComponent } from './layout.component';
import { MaterialsListComponent } from './materials-list/materials-list.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { RoofDefinitionListComponent } from './roof-definition-list/roof-definition-list.component';
import { ShieldingListComponent } from './shielding-list/shielding-list.component';
import { SpacePropertiesListComponent } from './space-properties-list/space-properties-list.component';
import { StoreysListComponent } from './storeys-list/storeys-list.component';
import { StructurePropertiesListComponent } from './structure-properties-list/structure-properties-list.component';
import { StructureSetListComponent } from './structure-set-list/structure-set-list.component';
import { UsersListComponent } from './users-list/users-list.component';
// import { VentilationListComponent } from './ventilation-list/ventilation-list.component';
import { WallSegmentLayersListComponent } from './wall-segment-layers-list/wall-segment-layers-list.component';
import { WindowsPropertiesListComponent } from './windows-properties-list/windows-properties-list.component';
import { ZoneListComponent } from './zone-list/zone-list.component';
import { VersionComponent } from './version/version.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'projects', pathMatch: 'full' },
            { path: 'profile', loadChildren: './profile/profile.module#ProfileModule' },
            { path: 'version', loadChildren: './version/version.module#VersionModule' },
            { path: 'drawing/:id', redirectTo: 'not-found', pathMatch: 'full' },
            {
                path: 'catalogue',
                canActivate: [ModelReadyGuard],
                children: [
                    { path: '', redirectTo: 'materials', pathMatch: 'full' },
                    { path: 'materials', component: MaterialsListComponent },
                    { path: 'doors-properties', component: DoorsPropertiesListComponent },
                    { path: 'windows-properties', component: WindowsPropertiesListComponent },
                    { path: 'wall-segment-layers', component: WallSegmentLayersListComponent },
                    // { path: 'ventilation', component: VentilationListComponent },
                    { path: 'space-properties', component: SpacePropertiesListComponent },
                    { path: 'structure-properties', component: StructurePropertiesListComponent },
                    { path: 'zone', component: ZoneListComponent },
                    { path: 'shielding', component: ShieldingListComponent },
                    { path: 'structure-set', component: StructureSetListComponent },
                    { path: 'roof-definition', component: RoofDefinitionListComponent},
                    { path: 'floor-definition', component: FloorDefinitionListComponent},
                ]
            },
            { path: 'users', component: UsersListComponent },
            {
                path: 'projects',
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    { path: 'list', component: ProjectsListComponent },
                    { path: 'buildings', component: BuildingsListComponent },
                    { path: 'storeys', component: StoreysListComponent },
                ]
            },
            { path: 'cadEditor', component: CadEditorComponent, canActivate: [ModelReadyGuard] },
            { path: 'not-found', loadChildren: '../components/not-found/not-found.module#NotFoundModule'},
            { path: '**', redirectTo: 'not-found' }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutRoutingModule {
}
