import { meta } from 'ngx-property-grid';
import { BORDER_TYPE_OPTIONS } from '../../../../models/enums/borderType';
import { EntityType } from '../../../../models/enums/entityType';
import { SegmentEntityPanelProperties } from './base-entities-for-panel-properties.model';

export class BorderSegmentPanelProperties extends SegmentEntityPanelProperties {
    type = EntityType.Border;

    @meta({ name: 'Typ hranice', group: 'Typ hranice', options: 'borderType', type: 'enum' })
    borderType;

    constructor(element?: any, data?: any) {
        super(element, data);

        this.setupEnum(BORDER_TYPE_OPTIONS, "borderType", element.borderType);
    }
}
