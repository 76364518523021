import { StoreyEntityState } from './enums/StoreyEntityState';
import { ProjectEntity } from './project-entity.model';
import { Storey } from './storey.model';

export abstract class StoreyEntity extends ProjectEntity {
    storey: Storey;
    state: StoreyEntityState = StoreyEntityState.EXISTING_AND_NEW;

    draggable = false;
}
