import { PointInput } from '../../models/pointInput';
import { CadSelection } from "../../models/cadSelection.model";
import { CadAction, NoAction, PointCadAction, SelectCadAction, SelectManyCadAction } from "./cadActions";


export class CadCommand {
    public actions: CadAction[];
    private actionIndex = 0;

    get CurrentAction() {
        return (this.actionIndex < this.actions.length ? this.actions[this.actionIndex] : new NoAction());
    }

    constructor(actions: CadAction[]) {
        this.actions = actions;
    }

    public handlePointInput(pointInput: PointInput): void {
        if (this.CurrentAction instanceof PointCadAction) {
            this.CurrentAction.handlePointInput(pointInput);
        }
    }

    public handleSelection(selection: CadSelection): boolean {
        if (this.CurrentAction instanceof SelectCadAction) {
            return this.CurrentAction.handleSelection(selection);
        } else {
            return false;
        }
    }

    public NextAction() {
        if (this.actionIndex < this.actions.length - 1) this.actionIndex++;
    }
}