import { SpaceProperties } from './space-properties.model';
import { EntityType } from './enums/entityType';
import { ProjectEntity } from './project-entity.model';
import { StructureSet } from './structure-set.model';
import { CoverageType } from './window-properties.model';
import { DefaultDoor } from './default-door';
import { DefaultWindow } from './default-window';
import { DefaultSpaceProperties } from './default-space-properties.model';

export class Defaults extends ProjectEntity {
    entityType: EntityType = EntityType.Defaults;
    structureSet: StructureSet = null;
    innerWallLength = 0.15;
    coverageN: CoverageType = CoverageType.INSIDE_JALOUSIE_WHITE_10;
    coverageNE: CoverageType = CoverageType.INSIDE_JALOUSIE_WHITE_10;
    coverageE: CoverageType = CoverageType.INSIDE_JALOUSIE_WHITE_10;
    coverageSE: CoverageType = CoverageType.INSIDE_JALOUSIE_WHITE_10;
    coverageS: CoverageType = CoverageType.INSIDE_JALOUSIE_WHITE_10;
    coverageSW: CoverageType = CoverageType.INSIDE_JALOUSIE_WHITE_10;
    coverageW: CoverageType = CoverageType.INSIDE_JALOUSIE_WHITE_10;
    coverageNW: CoverageType = CoverageType.INSIDE_JALOUSIE_WHITE_10;

    defaultDoor: DefaultDoor = null;
    defaultWindow: DefaultWindow = null;
    
    defaultSpaceProperties: DefaultSpaceProperties = null;
}
