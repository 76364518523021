import { DrawingSetup } from '../services/drawing-setup';
import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { StoreyEntityState } from './enums/StoreyEntityState';
import { FloorDefinition } from './floor-definition.model';
import { LocationEntity } from './location-entity.model';

export class FloorProperties extends LocationEntity {
    entityType: EntityType = EntityType.FloorProperties;
    floorHeight: number = 0;
    floorDefinition: FloorDefinition = null;

    sceneFunc = (context: any, shape: any) => {
        if (shape.attrs.location && DrawingSetup.isShapeVisible(shape.attrs.state)) {
            const zoomedStrokeWidth = 1 / shape.parent.scale().x;
            if (DrawingSetup.SelectedStateToDraw == StoreyEntityState.JUST_EXISTING || DrawingSetup.SelectedStateToDraw == StoreyEntityState.EXISTING_AND_NEW) {
                FloorProperties.drawOutline(context, shape, zoomedStrokeWidth, 20);
            }
            if (DrawingSetup.SelectedStateToDraw == StoreyEntityState.JUST_NEW || DrawingSetup.SelectedStateToDraw == StoreyEntityState.EXISTING_AND_NEW) {
                FloorProperties.drawOutline(context, shape, zoomedStrokeWidth, 25);
            }
        }
    };
    static drawOutline(context: any, shape: any, zoomedStrokeWidth: number, scale: number) {
        const size = scale / shape.parent.scale().x;
        shape.stroke(BaseEntity.getShapeColor(<FloorProperties>shape.attrs));
        shape.strokeWidth(zoomedStrokeWidth);
        context.beginPath();
        context.rect(shape.attrs.location.x-(size/2), shape.attrs.location.y-(size/2), size, size);
        context.closePath();
        context.fillStrokeShape(shape);
    }
}
