import { DrawingSetup } from '../services/drawing-setup';
import { CommonTools } from '../shared/helpers/common-tools';
import { Line, Point2D } from '../shared/helpers/geometry';
import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { StoreyEntityState } from './enums/StoreyEntityState';
import { Point } from './point.model';
import { RoofDefinition } from './roof-definition.model';
import { StoreyEntity } from './storey-entity.model';

export class RoofProperties extends StoreyEntity {
    entityType: EntityType = EntityType.RoofProperties;
    roofDefinition: RoofDefinition = null;
    edgePolygon: Point[] = [];
    ridgeStart: Point = null;
    ridgeStartHeight: number = 0;
    ridgeEnd: Point = null;
    ridgeEndHeight: number = 0;
    slopeVectorStart: Point = null;
    slopeVectorEnd: Point = null;
    slope: number = 0;

    sceneFunc = (context, shape) => {
        if (DrawingSetup.isShapeVisible(shape.attrs.state)) {
            shape.stroke(BaseEntity.getShapeColor(<RoofProperties>shape.attrs));
            if(shape.attrs.edgePolygon.length >= 3){
                RoofProperties.drawEdge(context, shape, 1 / shape.parent.scale().x);
            }
            if(shape.attrs.ridgeStart && shape.attrs.ridgeEnd){
                RoofProperties.drawRidge(context, shape);
            }
            if(shape.attrs.slopeVectorStart && shape.attrs.slopeVectorEnd){
                RoofProperties.drawSlope(context, shape);
            }

            shape.stroke('transparent');
            RoofProperties.drawEdge(context, shape, 10 / shape.parent.scale().x);
        }
    };

    static drawEdge(context: any, shape: any, strokeWidth: number) {
        shape.strokeWidth(strokeWidth);
        context.beginPath();
        for (let i = 1; i < shape.attrs.edgePolygon.length; i++) {
            context.moveTo(shape.attrs.edgePolygon[i - 1].x, shape.attrs.edgePolygon[i - 1].y);
            context.lineTo(shape.attrs.edgePolygon[i].x, shape.attrs.edgePolygon[i].y)
        }
        context.moveTo(shape.attrs.edgePolygon[shape.attrs.edgePolygon.length - 1].x, shape.attrs.edgePolygon[shape.attrs.edgePolygon.length - 1].y);
        context.lineTo(shape.attrs.edgePolygon[0].x, shape.attrs.edgePolygon[0].y);
        context.closePath();
        context.fillStrokeShape(shape);
    }
    static drawRidge(context: any, shape: any) {
        CommonTools.RenderText(context, shape.attrs.ridgeStartHeight.toFixed(3), shape.attrs.ridgeStart, (20 / shape.parent.scale().x), "black", "bottom", "left");
        CommonTools.RenderText(context, shape.attrs.ridgeEndHeight.toFixed(3), shape.attrs.ridgeEnd, (20 / shape.parent.scale().x), "black", "bottom", "left");

        shape.strokeWidth(5 / shape.parent.scale().x);
        context.beginPath();
        context.moveTo(shape.attrs.ridgeStart.x, shape.attrs.ridgeStart.y);
        context.lineTo(shape.attrs.ridgeEnd.x, shape.attrs.ridgeEnd.y)
        context.closePath();
        context.fillStrokeShape(shape);
    }
    static drawSlope(context: any, shape: any) {
        let start = new Point2D(shape.attrs.slopeVectorStart);
        let end = new Point2D(shape.attrs.slopeVectorEnd);
        const direction = new Line(start, end).direction().scale(10 / shape.parent.scale().x);
        let p1 = end.add(direction.scale(-2)).add(direction.perpendicular().scale(1));
        let p2 = end.add(direction.scale(-2)).add(direction.perpendicular().scale(-1));

        shape.strokeWidth(1 / shape.parent.scale().x);
        context.beginPath();
        context.moveTo(start.x, start.y);
        context.lineTo(end.x, end.y)
        context.lineTo(p1.x, p1.y);
        context.lineTo(end.x, end.y);
        context.lineTo(p2.x, p2.y);
        context.lineTo(end.x, end.y);
        context.closePath();
        context.fillStrokeShape(shape);

        CommonTools.RenderText(context, shape.attrs.slope.toFixed(1) + "°", shape.attrs.slopeVectorStart, (20 / shape.parent.scale().x), "black", "bottom", "left");
    }
}
