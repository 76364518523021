export enum MaterialLayerState {
    //  (dle dokumentace)
    DEFAULT_PROJECTED = "DEFAULT_PROJECTED",

    //Výchozí (odhad)
    DEFAULT_ESTIMATION = "DEFAULT_ESTIMATION",

    // výchozí nacházená při rekonstrukci
    DEFAULT_FOUND_DURING_RECONSTRUCTION = "DEFAULT_FOUND_DURING_RECONSTRUCTION",

    // výchozí (odhad) nacházená při rekonstrukci
    DEFAULT_ESTIMATED_DURING_RECONSTRUCTION = "DEFAULT_ESTIMATED_DURING_RECONSTRUCTION",

    // nová navržená při rekonstrukci
    NEW_DURING_RECONSTRUCTION = "NEW_DURING_RECONSTRUCTION",

    //nevyhovující
    INSUFFICIENT = "INSUFFICIENT",

    // celkově nedostačující
    INSUFFICIENT_GENERALLY = "INSUFFICIENT_GENERALLY",

    // změna navržené konstrukce u novostavby (přidaná vrstva nebo změna za vrstvou označenou INSUFFICIENT či GENERALLY_INSUFFICIENT)
    CHANGE_PREVIOUS_DESIGNED = "CHANGE_PREVIOUS_DESIGNED",

    // změna navržené konstrukce u rekonstrukce (přidaná vrstva nebo změna za vrstvou označenou INSUFFICIENT či GENERALLY_INSUFFICIENT)
    CHANGE_PREVIOUS_RECONSTRUCTION = "CHANGE_PREVIOUS_RECONSTRUCTION",

    // vrstva přídaná nad rámec projektu (doporučení)
    RECOMMENDED_ADDED = "RECOMMENDED_ADDED",

    // nachzuje předchozí vrstvu nad rámec projektu (doporučení)
    RECOMMENDED_REPLACED_PREVIOUS = "RECOMMENDED_REPLACED_PREVIOUS"
}

export const MATERIALLAYER_STATE_OPTIONS = [
    { value: MaterialLayerState.DEFAULT_PROJECTED, label: 'Výchozí (dle dokumentace)' },
    { value: MaterialLayerState.DEFAULT_ESTIMATION, label: 'Výchozí (odhad)' },
    { value: MaterialLayerState.DEFAULT_FOUND_DURING_RECONSTRUCTION, label: 'Nalezeno při rekonstrukci' },
    { value: MaterialLayerState.DEFAULT_ESTIMATED_DURING_RECONSTRUCTION, label: 'Odhad při rekonstrukci' },
    { value: MaterialLayerState.NEW_DURING_RECONSTRUCTION, label: 'Nově navrženo při rekonstrukci' },
    { value: MaterialLayerState.INSUFFICIENT, label: 'Nevyhovující' },
    { value: MaterialLayerState.INSUFFICIENT_GENERALLY, label: 'Celkově nedostačující' },
    { value: MaterialLayerState.CHANGE_PREVIOUS_DESIGNED, label: 'Změna navržené vrstvy u novostavby' },
    { value: MaterialLayerState.CHANGE_PREVIOUS_RECONSTRUCTION, label: 'Změna navržené vrstvy u rekonstrukce' },
    { value: MaterialLayerState.RECOMMENDED_ADDED, label: 'Přidáno nad rámec projektu' },
    { value: MaterialLayerState.RECOMMENDED_REPLACED_PREVIOUS, label: 'Nahrazuje předchozí vrstvu nad rámec projektu' },
];
