import Konva from 'konva';

export class HighlightLayer extends Konva.Layer {

    public clearEntities() {
        while (this.children.length > 0) {
            this.children[0].destroy();
        }
    }

}