import { Defaults } from './defaults.model';
import { EntityType } from './enums/entityType';
import { IEntityWithDefaults } from './interfaces/IEntityWithDefaults';
import { ProjectEntity } from './project-entity.model';
import { Storey } from './storey.model';
import { Zone } from './zone.model';

export class Building extends ProjectEntity implements IEntityWithDefaults {
    entityType: EntityType = EntityType.Building;
    code = '';
    name = '';
    description = '';
    azimut = 0;
    soilHeight = 0;

    storeys: Storey[] = [];
    zones: Zone[] = [];
    defaults: Defaults = null;
}
