import Konva from 'konva';
import { CadSelection } from '../../models/cadSelection.model';
import { EntityType } from '../../models/enums/entityType';
import { StoreyEntity } from '../../models/storey-entity.model';
import { Storey } from '../../models/storey.model';
import { SelectionRectangle } from '../../shared/helpers/geometry';
import { ModelService } from '../../services/model.service';
import { BehaviorSubject } from 'rxjs';

export class MainLayer extends Konva.Layer {
    public Selection$: BehaviorSubject<CadSelection> = new BehaviorSubject(new CadSelection([]));

    constructor(private model: ModelService) {
        super();
    }

    public importStorey(storey: Storey) {
        this.clearEntities();
        storey.auxLines.forEach(item => this.add(new Konva.Shape(item)));
        storey.borderSegments.forEach(item => this.add(new Konva.Shape(item)));
        storey.walls.forEach(item => this.add(new Konva.Shape(item)));
        storey.roofProperties.forEach(item => this.add(new Konva.Shape(item)));
        storey.windows.forEach(item => this.add(new Konva.Shape(item)));
        storey.doors.forEach(item => this.add(new Konva.Shape(item)));
        storey.spaceProperties.forEach(item => this.add(new Konva.Shape(item)));
        storey.soilHeight.forEach(item => this.add(new Konva.Shape(item)));
        storey.floorProperties.forEach(item => this.add(new Konva.Shape(item)));
        storey.points.forEach(item => this.add(new Konva.Shape(item)));
    }

    public clearEntities() {
        while (this.children.length > 0) {
            this.children[0].destroy();
        }
    }

    get Selection(): CadSelection {
        var selectedEntities = Array.from(this.children).filter(shape => shape.attrs.isSelected).map(shape => this.mapShapeToEntity(shape));
        return new CadSelection(selectedEntities);
    }

    public clearSelection(): void {
        for (let i = 0; i < this.children.length; i = i + 1) {
            this.children[i]["attrs"]["isSelected"] = false;
        }
        this.Selection$.next(this.Selection);
        this.batchDraw();
    }

    public selectEntityByClick(shape: any, ctrlKey: boolean, shiftKey: boolean): void {
        if (ctrlKey) {
            shape.isSelected = shape.isSelected ? false : true;
        } else if (shiftKey) {
            shape.isSelected = true;
        } else {
            this.clearSelection();
            shape.isSelected = true;
        }
        this.Selection$.next(this.Selection);
        this.batchDraw();
    }

    public selectByRectangle(rect: SelectionRectangle, keepExistingSelection: boolean): void {
        if (!keepExistingSelection) this.clearSelection();
        for (let i = 0; i < this.children.length; i = i + 1) {
            this.selectEntityByRectangle(rect, this.children[i].attrs);
        }
        this.Selection$.next(this.Selection);
        this.batchDraw();
    }
    private selectEntityByRectangle(rect: SelectionRectangle, entity: any) {
        if (entity.start && entity.end) {   // segments
            if (rect.selectJustWhole) {
                if (rect.containsPoint(entity.start) && rect.containsPoint(entity.end)) entity.isSelected = true;
            } else {
                if (rect.containsPoint(entity.start) || rect.containsPoint(entity.end) || rect.intersectWithLine(entity.start, entity.end) != null) entity.isSelected = true;
            }
        } else if (entity.wallSegment) {    // openings
            if (rect.selectJustWhole) {
                if (rect.containsPoint(entity.wallSegment.start) && rect.containsPoint(entity.wallSegment.end)) entity.isSelected = true;
            } else {
                if (rect.containsPoint(entity.wallSegment.start) || rect.containsPoint(entity.wallSegment.end) || rect.intersectWithLine(entity.wallSegment.start, entity.wallSegment.end) != null) entity.isSelected = true;
            }
        } else if (entity.location) {       // spaceProperties
            if (rect.containsPoint(entity["location"])) entity.isSelected = true;
        } else if (entity.x && entity.y) {  // point
            if (rect.containsPoint(entity)) entity.isSelected = true;
        }
    }

    private mapShapeToEntity(shape: any): any {
        var entityType: EntityType = shape.attrs.entityType;
        var externalId: string = shape.attrs.externalId;
        switch (entityType) {
            case EntityType.AuxLine: return this.model.SelectedStorey.auxLines.filter(l => l.externalId == externalId)[0];
            case EntityType.Point: return this.model.SelectedStorey.points.filter(p => p.externalId == externalId)[0];
            case EntityType.WallSegment: return this.model.SelectedStorey.walls.filter(w => w.externalId == externalId)[0];
            case EntityType.WindowSegment: return this.model.SelectedStorey.windows.filter(w => w.externalId == externalId)[0];
            case EntityType.DoorSegment: return this.model.SelectedStorey.doors.filter(d => d.externalId == externalId)[0];
            case EntityType.SpaceProperties: return this.model.SelectedStorey.spaceProperties.filter(sp => sp.externalId == externalId)[0];
            case EntityType.SoilHeight: return this.model.SelectedStorey.soilHeight.filter(sh => sh.externalId == externalId)[0];
            case EntityType.FloorProperties: return this.model.SelectedStorey.floorProperties.filter(fp => fp.externalId == externalId)[0];
            case EntityType.RoofProperties: return this.model.SelectedStorey.roofProperties.filter(rp => rp.externalId == externalId)[0];
            case EntityType.Border: return this.model.SelectedStorey.borderSegments.filter(fp => fp.externalId == externalId)[0];
            default: throw new Error("Unknown instance type '" + entityType + "'.");
        }
    }
}