import * as uuid from 'uuid4';
import { BaseEntity } from '../../models/base-entity.model';

export class CommonTools {
    public static GetUUID() {
        return uuid();
    }

    public static GetDistinctValues<T>(array: T[]): T[] {
        return array.filter(this.distinctFilter);
    }
    private static distinctFilter(value, index, self) {
        return self.indexOf(value) === index;
    }

    public static TryAddItem<T extends BaseEntity>(array: T[], added: T): boolean {
        var existing = array.find(i => i.externalId == added.externalId);
        if (!existing) {
            array.push(added);
            return true;
        } else {
            return false;
        }
    }
    public static TryDeleteItem<T>(array: T[], deleted: T): boolean {
        var index = array.indexOf(deleted);
        if (index !== -1) {
            array.splice(index, 1);
            return true;
        } else {
            return false;
        }
    }
    public static RenderText(context: any, text: string, location: { x: number, y: number }, fontsize: number, color: string = "black", textBaseline: string = "middle", textAlign: string = "center") {
        context.font = fontsize.toFixed(0) + "px arial";
        context.textBaseline = textBaseline;
        context.textAlign = textAlign;
        context.strokeStyle = color;
        context.fillStyle = color;
        context.fillText(text, location.x, location.y);
        context.strokeText(text, location.x, location.y);
    }
}