import { DrawingSetup } from './services/drawing-setup';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CadEditor } from './layout/CadEditor/cadEditor';
import { NotificationModule } from './layout/notification/notification.module';
import { ToasterModule } from './layout/toast/toaster.module';
import { AuthenticationService } from './services/authentication.service';
import { AuxLineService } from './services/auxline.service';
import { BackendService } from './services/backend.service';
import { DefaultDataService } from './services/defaultdata.service';
import { ModelService } from './services/model.service';
import { NotificationService } from './services/notification.service';
import { RestService } from './services/rest.service';
import { ToasterService } from './services/toaster.service';
import { ErrorInterceptor, TokenInterceptor } from './services/token.interceptor';
import { AuthGuard } from './shared';
import { ModelReadyGuard } from './shared/guard/model-ready-guard';
import { LayoutModule } from './layout/layout.module';
import { UndoRedoService } from './services/undoredo.service';


declare global {
    interface Window {
        Konva: any;
        ClipperLib: any;
        Vector2: any;
    }
}

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    // for development: return new TranslateHttpLoader(http, '/start-angular/oekoplan/master/dist/assets/i18n/', '.json');
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToasterModule,
        NotificationModule,
        MDBBootstrapModule.forRoot(),
        NgSelectModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        // IntercomModule.forRoot(),
        AppRoutingModule,
        LayoutModule
    ],
    declarations: [
        AppComponent,
    ],
    entryComponents: [
    ],
    providers: [
        DrawingSetup,
        AuthGuard,
        ModelReadyGuard,
        RestService,
        ModelService,
        CadEditor,
        AuxLineService,
        BackendService,
        UndoRedoService,
        DefaultDataService,
        ToasterService,
        NotificationService,
        AuthenticationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
