import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { BaseEntity } from '../../models/base-entity.model';
import { Building } from '../../models/building.model';
import { IFilter } from '../../models/interfaces/IFilter';
import { Project } from '../../models/project.model';
import { StoreyEntity } from '../../models/storey-entity.model';
import { Storey } from '../../models/storey.model';
import { StructureSet } from '../../models/structure-set.model';
import { WallLayer } from '../../models/wall-layer.model';
import { WallSegmentLayers } from '../../models/wall-segment-layers.model';
import { WindowSegment } from '../../models/window-segment.model';
import { DoorSegment } from './../../models/door-segment.model';
import { EntityType } from './../../models/enums/entityType';
import { FloorProperties } from './../../models/floor-properties.model';
import { SoilHeight } from './../../models/soil-height.model';
import { SpaceProperties } from './../../models/space-properties.model';
import { StructureProperties } from './../../models/structure-properties.model';
import { WallSegment } from './../../models/wall-segment.model';
import { ModelService } from './../../services/model.service';
import { CadEditor } from './../CadEditor/cadEditor';
import { StructurePropertiesEditor } from './../components/structureProperties-editor/structureProperties-editor';
import { ModelViewerFilterModalComponent } from './model-viewer-filter-modal/model-viewer-filter-modal.component';
import { Defaults } from '../../models/defaults.model';
import { DefaultDoor } from '../../models/default-door';
import { DefaultWindow } from '../../models/default-window';
import { DefaultSpaceProperties } from '../../models/default-space-properties.model';

@Component({
    selector: 'app-ModelViewer',
    templateUrl: './ModelViewer.component.html',
    styleUrls: ['./ModelViewer.component.scss'],
})
export class ModelViewerComponent implements OnInit {
    @ViewChild('TreeView', { read: ElementRef })
    TreeView: ElementRef;
    title: string;

    public filters: IFilter[] = [];

    constructor(private model: ModelService, private cadEditor: CadEditor, private mdbModalService: MDBModalService, private ref: ChangeDetectorRef) {
        this.title = model.SelectedProject.title;
    }

    ngOnInit() {
        this.processEntity(this.TreeView.nativeElement, this.model.SelectedProject);
    }

    public removeFilters() {
        if (confirm('Opravdu chcete odstranit všechny filtry?')) {
            this.filters = [];
            this.refreshViewer();
        }
    }

    private checkFilters(entity: BaseEntity): boolean {
        let result = true;
        this.filters.forEach((filter) => (result = filter.showEntity(entity)));
        return result;
    }

    private processEntity(parent: HTMLElement, entity: BaseEntity) {
        var entityUL: HTMLElement = document.createElement('ul');

        parent.appendChild(entityUL);

        if (this.checkFilters(entity)) {
            switch (entity.entityType) {
                case EntityType.Project: {
                    (<Project>entity).buildings.forEach((building) => this.addPropertyNode(entityUL, building.name, building));
                    this.addPropertyNode(entityUL, 'Defaulty', (<Project>entity).defaults);
                    break;
                }
                case EntityType.Building: {
                    (<Building>entity).storeys.forEach((storey) => this.addPropertyNode(entityUL, storey.description, storey));
                    break;
                }
                case EntityType.Storey: {
                    this.addArrayNode(entityUL, 'Stěny', (<Storey>entity).walls, (item: WallSegment) => item.start + ' - ' + item.end);
                    this.addArrayNode(entityUL, 'Okna', (<Storey>entity).windows, (item: WindowSegment) => {
                        var result = item.width + 'x' + item.height + '(' + item.parapetHeight + ') ';
                        if(item.propertiesNew != undefined) result += item.propertiesNew.description;
                        return result;
                    }); 
                    this.addArrayNode(entityUL, 'Dveře', (<Storey>entity).doors, (item: DoorSegment) => {
                        var result = item.width + 'x' + item.height + ' ';
                        if(item.propertiesNew != undefined) result +=  + item.propertiesNew.description
                        return result;
                    });

                    this.addArrayNode(entityUL, 'Vlastnosti místnosti', (<Storey>entity).spaceProperties, (item: SpaceProperties) => !item.description || item.description.length === 0 ? 'Místnost bez názvu' : item.description);
                    this.addArrayNode(entityUL, 'Podlahy', (<Storey>entity).floorProperties, (item: FloorProperties) => item.location + ' - ' + item.floorDefinition.description);
                    this.addArrayNode(entityUL, 'Výškové body', (<Storey>entity).soilHeight, (item: SoilHeight) => item.location + ' - ' + item.soilHeight_M);
                    break;
                }
                case EntityType.WallSegment: {
                    if((<WallSegment>entity).wallSegmentLayers){
                        this.addPropertyNode(entityUL, 'Horizontální vrstvy', (<WallSegment>entity).wallSegmentLayers);
                    }
                    if((<WallSegment>entity).structureSet){
                        this.addPropertyNode(entityUL, 'Set skladby konstrukce', (<WallSegment>entity).structureSet);
                    }
                    break;
                }
                case EntityType.WallSegmentLayers: {
                    (<WallSegmentLayers>entity).layers.forEach((layer) => this.addPropertyNode(entityUL, layer.height + ' / ' + layer.properties.detailedDescription, layer));
                    break;
                }
                case EntityType.WallLayer: {
                    this.addPropertyNode(entityUL, (<WallLayer>entity).properties.detailedDescription, (<WallLayer>entity).properties);
                    break;
                }
                case EntityType.StructureSet:{
                    this.addPropertyNode(entityUL, (<StructureSet>entity).name, (<StructureSet>entity).floorAboveOutdoorSpace);
                    break;
                }
                case EntityType.Defaults:{
                    this.addArrayNode(entityUL, 'Dveře', [(<Defaults>entity).defaultDoor], (item : DefaultDoor) =>{
                        var result = item.width + 'x' + item.height + ' ';
                        if(item.propertiesNew != undefined) result +=  + item.propertiesNew.description
                        return result;
                    });

                    this.addArrayNode(entityUL, 'Okno', [(<Defaults>entity).defaultWindow], (item: DefaultWindow) => {
                        var result = item.width + 'x' + item.height + '(' + item.parapetHeight + ') ';
                        return result;
                    });
                    this.addArrayNode(entityUL, 'Vlastnosti místností', [(<Defaults>entity).defaultSpaceProperties], (item: DefaultSpaceProperties) => {
                        var result = item.roomType_I;
                        return result;
                    });

                 //   this.addPropertyNode(entityUL, (<Defaults>entity)., (<StructureSet>entity).floorAboveOutdoorSpace);

                    break;
                }
            }
        }
    }

    private processArray(parent: HTMLElement, entities: BaseEntity[], itemDescriptionFunc: any) {
        var arrayUL: HTMLElement = document.createElement('ul');
        parent.appendChild(arrayUL);
        entities.forEach((item) => this.addPropertyNode(arrayUL, itemDescriptionFunc(item), item));
    }

    private addPropertyNode(parent: HTMLElement, description: string, entity: BaseEntity): HTMLElement {
        var newNode: HTMLElement = document.createElement('li');
        if (this.checkFilters(entity)) {
            newNode.appendChild(this.createCaret());
            if (entity instanceof StoreyEntity) {
                var glass: HTMLElement = document.createElement('span');
                glass.innerText = '🔍 ';
                glass.addEventListener(
                    'click',
                    (event) => {
                        this.model.SelectedBuilding = entity.storey.building;
                        this.model.SelectedStorey = entity.storey;
                        this.cadEditor.stage.ZoomToEntity(entity);
                        event.stopPropagation();
                    },
                    false
                );
                newNode.appendChild(glass);
            }
            if (entity instanceof StructureProperties) {
                var btn: HTMLElement = document.createElement('span');
                btn.innerText = '✏ ';
                btn.addEventListener(
                    'click',
                    (event) => {
                        this.openModal(StructurePropertiesEditor, entity.externalId);
                        event.stopPropagation();
                    },
                    false
                );
                newNode.appendChild(btn);
            }

            var text: HTMLElement = document.createElement('span');
            text.innerText = description;
            newNode.addEventListener('click', (event) => this.nodeClick(event, newNode, entity, null), false);
            newNode.appendChild(text);

            parent.appendChild(newNode);
        }
        return newNode;
    }

    private addArrayNode(parent: HTMLElement, description: string, entities: BaseEntity[], itemDescriptionFunc: any): HTMLElement {
        var newNode: HTMLElement = document.createElement('li');
        newNode.appendChild(this.createCaret());
        let entitiesCount = 0;
        entities.forEach((entity) => {
            if (this.checkFilters(entity)) {
                entitiesCount++;
            }
        });
        if (entitiesCount > 0) {
            const text: HTMLElement = document.createElement('span');
            text.innerText = `${description} (${entitiesCount})`;
            newNode.appendChild(text);
            newNode.addEventListener('click', (event) => this.nodeClick(event, newNode, entities, itemDescriptionFunc), false);
            parent.appendChild(newNode);
        }
        return newNode;
    }

    private nodeClick(event: Event, node: HTMLElement, content: BaseEntity | BaseEntity[], itemDescriptionFunc: any) {
        var uls = node.getElementsByTagName('ul');
        if (uls.length == 0) {
            if (Array.isArray(content)) {
                this.processArray(node, content, itemDescriptionFunc);
            } else {
                this.processEntity(node, <BaseEntity>content);
            }
        }

        if (uls.length > 0) {
            var ul: any = uls[0];
            ul.style.display = ul.style.display === 'block' ? 'none' : 'block';
            const icon = ul.parentElement.getElementsByTagName('i')[0];
            icon.className = icon.className == 'fas fa-angle-right' ? 'fas fa-angle-down' : 'fas fa-angle-right';
        }
        event.stopPropagation();
    }

    private createCaret() {
        const caret: HTMLElement = document.createElement('span');
        caret.className = 'caret';
        const icon = document.createElement('i');
        icon.className = 'fas fa-angle-right';
        caret.appendChild(icon);
        return caret;
    }

    private refreshViewer() {
        this.TreeView.nativeElement.childNodes.forEach((node) => node.remove());
        this.processEntity(this.TreeView.nativeElement, this.model.SelectedProject);
    }

    private deepCopy<T>(source: T) {
        let clone = Object.create(Object.getPrototypeOf(source));
        return Object.assign(clone, source);
    }

    private deepCopyArray<T>(source: T[]) {
        const array = [];
        source.forEach((element) => {
            array.push(this.deepCopy(element));
        });
        return array;
    }

    public openFiltersModal() {
        const modalRef = this.openModal(ModelViewerFilterModalComponent);
        modalRef.content.filters = this.deepCopyArray(this.filters);
        modalRef.content.result$.subscribe((filters: IFilter[]) => {
            if (filters != null) {
                this.filters = this.deepCopyArray(filters);
                this.refreshViewer();
            }
        });
    }

    private openModal(component, externalId?: string): MDBModalRef {
        return this.mdbModalService.show(component, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: externalId,
            },
        });
    }
}
