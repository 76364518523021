/* Angular */
import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    ViewChild,
    ViewContainerRef,
    EventEmitter,
    ComponentFactoryResolver } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/* Components */
import { Toast, BodyOutputType } from './toast';

@Component({
    selector: '[app-toast-comp]',
    template: `
        <i class="toaster-icon" [ngClass]="iconClass"></i>
        <div class="toast-content">
            <div [ngClass]="toast.toasterConfig.titleClass">{{toast.title}}</div>
            <div [ngClass]="toast.toasterConfig.messageClass" [ngSwitch]="toast.bodyOutputType">
                <div *ngSwitchCase="bodyOutputType.Component" #componentBody></div>
                <div *ngSwitchCase="bodyOutputType.TrustedHtml" [innerHTML]="toast.body"></div>
                <div *ngSwitchCase="bodyOutputType.Default">{{toast.body}}</div>
            </div>
        </div>
        <div class="toast-close-button" *ngIf="toast.showCloseButton" (click)="click($event, toast)"
            [innerHTML]="safeCloseHtml">
        </div>`,
    outputs: ['clickEvent']
})

export class ToastComponent implements OnInit, AfterViewInit {

    @Input() toast: Toast;
    @Input() iconClass: string;
    @ViewChild('componentBody', { read: ViewContainerRef }) componentBody: ViewContainerRef;

    safeCloseHtml: SafeHtml;

    public clickEvent = new EventEmitter();
    public bodyOutputType = BodyOutputType;

    constructor(
      private sanitizer: DomSanitizer,
      private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnInit() {
        if (this.toast.closeHtml) {
            this.safeCloseHtml = this.sanitizer.bypassSecurityTrustHtml(this.toast.closeHtml);
        }
    }

    ngAfterViewInit() {
        setTimeout( () => { // BUGFIX: https://github.com/angular/angular/issues/6005#issuecomment-165911194
            if (this.toast.bodyOutputType === this.bodyOutputType.Component) {
                let component = this.componentFactoryResolver.resolveComponentFactory(this.toast.body.component);
                let ref = this.componentBody.createComponent(component, null, this.componentBody.injector);

                // Inject parameters
                for (let p in this.toast.body.params) {
                    /* istanbul ignore else  */
                    if (this.toast.body.params.hasOwnProperty(p)) {
                        ref.instance[p] = this.toast.body.params[p];
                    }
                }
            }
        });
    }

    click(event, toast: Toast) {
        event.stopPropagation();
        this.clickEvent.emit({
            value : { toast: toast, isCloseButton: true}
        });
    }
}
