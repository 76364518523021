import { Component } from '@angular/core';
import { EntityType } from '../../models/enums/entityType';
import { Company } from './../../models/company.model';
import { ModelService } from './../../services/model.service';

@Component({
    selector: 'app-customers',
    templateUrl: './customers-list.component.html',
    styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent {

    // TODO: Change (if needed) names and count of fields to show in columns and its headings according to data model
    headElements: string[] = ['Code', 'Popis', 'Thermal resistance'];
    columnElements: string[] = ['svtCode', 'description', 'thermalConductivity'];
    dataSource: Company[] = [];

    constructor(private model: ModelService) {
        this.dataSource = this.model.Companies;
    }

    onAddOne(data: any) {
        this.model.AddEntity(data);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.Customer, externalId);
    }
}
