import { meta } from 'ngx-property-grid';
import { EntityType } from '../../../../models/enums/entityType';
import { IDropdown } from '../dropdown-property/dropdown-property.component';

export class PointPanelProperties {
    type = EntityType.Point;
    @meta({
        name: 'X [mm]', group: 'Point', options: 'x', valueConvert: parseInt, type: 'number'
    })
    x;

    @meta({
        name: 'Y [mm]', group: 'Point', options: 'y', valueConvert: parseInt, type: 'number'
    })
    y;

    constructor(element?: any, data?: any) {
        if (element) {
            if (element.hasOwnProperty('x')) {
                this.x = element.x;
            }
            if (element.hasOwnProperty('y')) {
                this.y = element.y;
            }
        }
    }
}
