import { EntityType } from './enums/entityType';
import { ProjectEntity } from './project-entity.model';

export enum RecuperationType {
    NONE,
    BOARD,
    CROSS,
    COUNTERCURRENT,
    COUNTERCURRENT_SORPTION,
    ROTATIONAL,
    ROTATIONAL_SORPTION,
    SWITCHING,
    HEAT_PIPE,
    INDIRECT
}
export const RecuperationTypeOptions:
    { value: RecuperationType, label: string }[] = [
        { value: RecuperationType.NONE, label: 'není' },
        { value: RecuperationType.BOARD, label: 'deskový' },
        { value: RecuperationType.CROSS, label: 'křížový' },
        { value: RecuperationType.COUNTERCURRENT, label: 'protiproudý' },
        { value: RecuperationType.COUNTERCURRENT_SORPTION, label: 'protiproudý sorpční' },
        { value: RecuperationType.ROTATIONAL, label: 'rotační' },
        { value: RecuperationType.ROTATIONAL_SORPTION, label: 'rotační sorpční' },
        { value: RecuperationType.SWITCHING, label: 'přepínací' },
        { value: RecuperationType.HEAT_PIPE, label: 'tepelná trubice' },
        { value: RecuperationType.INDIRECT, label: 'nepřímý (kapalina-vzduch)' },
    ];

export enum VentilatorType {
    AC = "AC",
    DC_FRONT = "DC_FRONT",
    DC_BACK = "DC_BACK"
}
export const VENTILATION_TYPE_OPTIONS = [
    { value: VentilatorType.AC, label: 'AC motor' },
    { value: VentilatorType.DC_FRONT, label: 'DC nebo EC motor s dopředu zahnutými lopatkami' },
    { value: VentilatorType.DC_BACK, label: 'DC nebo EC motor s dozadu zahnutými lopatkami' },
];

export enum RPMRegulationType {
    ONE = "ONE",
    TWO = "TWO",
    THREE = "THREE",
    CONTINUOUS = "CONTINUOUS"
}
export const RPM_REGULATION_OPTIONS = [
    { value: RPMRegulationType.ONE, label: 'Jednostupňová' },
    { value: RPMRegulationType.TWO, label: 'Dvoutupňová' },
    { value: RPMRegulationType.THREE, label: 'Třístupňová' },
    { value: RPMRegulationType.CONTINUOUS, label: 'Plynulá' },
];

export enum VentilationControlType {
    HAND_OPERATED = 'HAND_OPERATED',
    TIMER = 'TIMER',
    CENTRALIZED = 'CENTRALIZED',
    DECENTRALIZED = 'DECENTRALIZED'
}

export const VENTILATION_CONTROL_OPTIONS = [
    { value: VentilationControlType.HAND_OPERATED, label: 'Ručně ovládaná' },
    { value: VentilationControlType.TIMER, label: 'Časovač' },
    { value: VentilationControlType.CENTRALIZED, label: 'Centralizované ovládaní' },
    { value: VentilationControlType.DECENTRALIZED, label: 'Decentralizované ovládaní' },
];

export enum SoilHeatChangerType {
    NONE = 'NONE',
    SOIL = 'SOIL',
    SALT = 'SALT',
}

export const SOIL_HEAT_CHANGED_OPTIONS = [
    { value: SoilHeatChangerType.NONE, label: 'Není' },
    { value: SoilHeatChangerType.SOIL, label: 'Zemní' },
    { value: SoilHeatChangerType.SALT, label: 'Solankový' },
];

export class Ventilation extends ProjectEntity {
    entityType: EntityType = EntityType.Ventilation;
    description: string;
    frecirc = 0;
    Vsup = 0;
    Vex = 0;
    typr = RecuperationType.BOARD;
    ehr = 0;
    evr = 0;
    vctrl = VentilationControlType.HAND_OPERATED;
    typf = VentilatorType.AC;
    fctrl = RPMRegulationType.ONE;
    Psup = 0;
    Pex = 0;
    xhv = false;
    Pvp = 0;
    Pv = 0;
    xhe = false;
    Pe = 0;
    xhs = false;
    Ps = 0;
    xcv = false;
    Pcp = 0;
    Pcv = 0;
    xcs = false;
    Pcs = 0;
    xg = false;
    typg = SoilHeatChangerType.NONE;
    xa = false;
    xhp = false;
    xha = false;
    xca = false;
    Ph = 0;
    xd = false;
    xda = false;
    xdc = false;
}
