import { Component, OnInit } from "@angular/core";
import { MDBModalService } from "angular-bootstrap-md";
import * as uuid from 'uuid4';
import { EntityType } from '../../../models/enums/entityType';
import { RoofWindow } from "../../../models/roof-window.model";
import { Shielding } from "../../../models/shielding.model";
import { WindowProperties } from "../../../models/window-properties.model";
import { DefaultDataService } from "../../../services/defaultdata.service";
import { STOREY_ENTITY_STATE_OPTIONS } from './../../../models/enums/StoreyEntityState';
import { SpaceProperties } from './../../../models/space-properties.model';
import { COVERAGE_TYPE_OPTIONS } from './../../../models/window-properties.model';
import { ModelService } from './../../../services/model.service';
import { CommonTools } from './../../../shared/helpers/common-tools';

enum RoofWindowEditActions {
    addWindow,
    deleteWindow,
    updateWindow,
    none
}
interface RoofWindowEdits {
    window: RoofWindow,
    action: RoofWindowEditActions
}
@Component({
    selector: 'app-roof-window-editor',
    templateUrl: './roof-window-editor.component.html',
    styleUrls: ['./roof-window-editor.component.scss']
})
export class RoofWindowEditor implements OnInit {
    roofWindows: RoofWindow[] = [];
    externalId: string;
    storeyEntityStateOptions = STOREY_ENTITY_STATE_OPTIONS;
    shieldingOptions: { value: string, label: string }[];
    coverageOptions = COVERAGE_TYPE_OPTIONS;
    windowPropertiesOptions: { value: string, label: string }[]
    spaceProperties: SpaceProperties;

    constructor(
        private model: ModelService,
        private mdbModalService: MDBModalService,
        private defaultDataService: DefaultDataService,
    ) {
    }

    ngOnInit() {
        this.spaceProperties = this.model.getEntityByExternalId<SpaceProperties>(EntityType.SpaceProperties, this.externalId);
        this.roofWindows = this.spaceProperties.roofWindows;
        this.roofWindows.forEach(roofWindow => {
            if (!roofWindow.propertiesNew) roofWindow.propertiesNew = Object.assign(new WindowProperties(), { externalId: null });
            if (!roofWindow.propertiesRecommended) roofWindow.propertiesRecommended = Object.assign(new WindowProperties(), { externalId: null });
            if (!roofWindow.shieldingExisting) roofWindow.shieldingExisting = Object.assign(new Shielding(), { externalId: null });
            if (!roofWindow.shieldingNew) roofWindow.shieldingNew = Object.assign(new Shielding(), { externalId: null });
        })
        this.windowPropertiesOptions = this.model.WindowProperties.map(item => { return { label: item.name, value: item.externalId } });
        this.shieldingOptions = this.model.SelectedProject.shieldings.map(item => { return { value: item.externalId, label: item.externalId } });
    }

    addWindow() {
        let newWindow: RoofWindow = new RoofWindow();
        newWindow.externalId = uuid();
        newWindow.propertiesExisting = this.defaultDataService.getDefaultWindowProperties();
        newWindow.propertiesNew = null;
        newWindow.propertiesRecommended = null;
        newWindow.spaceProperties = this.spaceProperties;
        newWindow.storey = this.spaceProperties.storey;
        this.model.AddEntity(newWindow);
    }

    removeWindow(roofWindow: RoofWindow) {
        CommonTools.TryDeleteItem(this.spaceProperties.roofWindows, roofWindow);
    }

    onSave() {
        for (let roofWindow of this.roofWindows) this.model.UpdateEntity(roofWindow);
        this.model.UpdateEntity(this.spaceProperties);

        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }
}