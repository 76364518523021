import { Ventilation } from './ventilation.model';
import { StructureProperties } from './structure-properties.model';
import { DrawingSetup as DrawingSetup } from '../services/drawing-setup';
import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { LightControl } from './enums/lightControl';
import { RoomTypes } from './enums/RoomType';
import { LightSharing, NonHeatedSpaceType, ThermalRegime } from './enums/Space-properties-enums.model';
import { StoreyEntityState } from './enums/StoreyEntityState';
import { RoofWindow } from './roof-window.model';
import { Zone } from './zone.model';
import { LocationEntity } from './location-entity.model';

export class SpaceProperties extends LocationEntity {
    entityType: EntityType = EntityType.SpaceProperties;
    floorComposition_F: StructureProperties = null; // match
    floorComposition_I: StructureProperties = null; // match
    roofWindows: RoofWindow[] = [];
    zone_F: Zone = null; // match
    zone_I: Zone = null; // match
    code = ''; // match
    compact_fluorescent_lamp_share_F = 0; // match
    compact_fluorescent_lamp_share_I = 0; // match
    compact_fluorescent_lamp_share_R = 0; // match
    criticalRoom_F = false; // match
    criticalRoom_I = false; // match
    // d_F = false; // not provided in Java
    // d_I = false; // not provided in Java
    ddu_F = 0; // match
    ddu_I = 0; // match
    description = ''; // match
    dhc_F = 0; // match
    dhc_I = 0; // match
    dhh_F = 0; // match
    dhh_I = 0; // match
    dhu_F = 0; // match
    dhu_I = 0; // match
    dhv_F = 0; // match
    dhv_I = 0; // match
    elevation = 0; // match
    ema_F = 0; // match
    ema_I = 0; // match
    fan_coil_share_F = 0; // match
    fan_coil_share_I = 0; // match
    fan_coil_share_R = 0; // match
    fAP_F = 0; // case mismatched replaced acc. Java
    fAP_I = 0; // case mismatched replaced acc. Java
    fLA_F = 0; // case mismatched replaced acc. Java
    fLA_I = 0; // case mismatched replaced acc. Java
    fluorescent_light_with_classic_ballast_share_F = 0; // match
    fluorescent_light_with_classic_ballast_share_I = 0; // match
    fluorescent_light_with_classic_ballast_share_R = 0; // match
    fluorescent_light_with_el_ballast_share_F = 0; // match
    fluorescent_light_with_el_ballast_share_I = 0; // match
    fluorescent_light_with_el_ballast_share_R = 0; // match
    fluorescent_light_without_el_ballast_share_F = 0; // match
    fluorescent_light_without_el_ballast_share_I = 0; // match
    fluorescent_light_without_el_ballast_share_R = 0; // match
    fOc_F = 0; // case mismatched replaced acc. Java
    fOc_I = 0; // case mismatched replaced acc. Java
    halogen_light_bulb_share_F = 0; // match
    halogen_light_bulb_share_I = 0; // match
    halogen_light_bulb_share_R = 0; // match
    has_fan_coil_F = false; // match
    has_fan_coil_I = false; // match
    has_heating_convectors_F = false; // match
    has_heating_convectors_I = false; // match
    has_vrf_F = false; // match
    has_vrf_I = false; // match
    heating_convectors_share_F = 0; // match
    heating_convectors_share_I = 0; // match
    heating_convectors_share_R = 0; // match
    isGarage_F = false; // match
    isGarage_I = false; // match
    isolated_F = false; // match
    isolated_I = false; // match
    kra_F = 0; // match
    kra_I = 0; // match
    l_a_F = 0; // match
    l_a_I = 0; // match
    led_light_bulb_share_F = 0; // match
    led_light_bulb_share_I = 0; // match
    led_light_bulb_share_R = 0; // match
    light_bulb_share_F = 0; // match
    light_bulb_share_I = 0; // match
    light_bulb_share_R = 0; // match
    light_sharing_F: LightSharing = LightSharing.OTHER; // match
    light_sharing_I: LightSharing = LightSharing.OTHER; // match
    light_sharing_R: LightSharing = LightSharing.OTHER; // match
    lightControl_F: LightControl = LightControl.AUTOMATIC; // match
    lightControl_I: LightControl = LightControl.AUTOMATIC; // match
    lightControl_R: LightControl = LightControl.AUTOMATIC; // match
    livingSpace_F = true; // match
    livingSpace_I = true; // match
    metal_halogen_discharge_lamp_share_F = 0; // match
    metal_halogen_discharge_lamp_share_I = 0; // match
    metal_halogen_discharge_lamp_share_R = 0; // match
    mwa_F = 0; // match
    mwa_I = 0; // match
    n_a_F = 0; // match
    n_a_I = 0; // match
    nonHeatedSpaceType_F: NonHeatedSpaceType = NonHeatedSpaceType.LOWER_FLOOR; // match
    nonHeatedSpaceType_I: NonHeatedSpaceType = NonHeatedSpaceType.LOWER_FLOOR; // match
    permanentLightning_F = false; // match
    permanentLightning_I = false; // match
    poolArea_F = 0; // match
    poolArea_I = 0; // match
    qAP_F = 0; // case mismatched replaced acc. Java
    qAP_I = 0; // case mismatched replaced acc. Java
    qOc_F = 0; // case mismatched replaced acc. Java
    qOc_I = 0; // case mismatched replaced acc. Java
    requested_humidity_F = 0; // match
    requested_humidity_I = 0; // match
    roomType_F: RoomTypes = RoomTypes.OBYTNE_PROSTORY; // match
    roomType_I: RoomTypes = RoomTypes.OBYTNE_PROSTORY; // match
    sodium_discharge_lamp_share_F = 0; // match
    sodium_discharge_lamp_share_I = 0; // match
    sodium_discharge_lamp_share_R = 0; // match
    tco_F = 0; // match
    tco_I = 0; // match
    tcp_F = 0; // match
    tcp_I = 0; // match
    tDa_F = 0; // case mismatched replaced acc. Java
    tDa_I = 0; // case mismatched replaced acc. Java
    tempered_F = false; // match
    tempered_I = false; // match
    thermalRegime_F: ThermalRegime = ThermalRegime.HEATED; // match
    thermalRegime_I: ThermalRegime = ThermalRegime.HEATED; // match
    tho_F = 0; // match
    tho_I = 0; // match
    thp_F = 0; // match
    thp_I = 0; // match
    tNa_F = 0; // case mismatched replaced acc. Java
    tNa_I = 0; // case mismatched replaced acc. Java
    tue_F = 0; // match
    tue_I = 0; // match
    tus_F = 0; // match
    tus_I = 0; // match
    tve_F = 0; // match
    tve_I = 0; // match
    tvs_F = 0; // match
    tvs_I = 0; // match
    vrf_share_F = 0; // match
    vrf_share_I = 0; // match
    vrf_share_R = 0; // match
    vvl_F = 0; // match
    vvl_I = 0; // match
    vvs_F = 0; // match
    vvs_I = 0; // match
    x_A_F = false;
    x_A_I = false;
    xss_F = 0; // match
    xss_I = 0; // match
    xsw_F = 0; // match
    xsw_I = 0; // match

    x = 0;
    y = 0;
    static drawOutline(context: any, shape: any, zoomedStrokeWidth: number, radiusMult: number) {
        shape.stroke(BaseEntity.getShapeColor(<SpaceProperties>shape.attrs));
        shape.strokeWidth(zoomedStrokeWidth);
        context.beginPath();
        context.arc(shape.attrs.location.x, shape.attrs.location.y, radiusMult / shape.parent.scale().x, 0, Math.PI * 2, false);
        context.closePath();
        context.fillStrokeShape(shape);
    }

    sceneFunc = (context: any, shape: any) => {
        if (shape.attrs.location && DrawingSetup.isShapeVisible(shape.attrs.state)) {
            const zoomedStrokeWidth = 1 / shape.parent.scale().x;
            // SpaceProperties.drawInnerPoint(context, shape, zoomedStrokeWidth, 7);
            if (DrawingSetup.SelectedStateToDraw === StoreyEntityState.JUST_EXISTING || DrawingSetup.SelectedStateToDraw === StoreyEntityState.EXISTING_AND_NEW) {
                SpaceProperties.drawOutline(context, shape, zoomedStrokeWidth, 9);
            }
            if (DrawingSetup.SelectedStateToDraw === StoreyEntityState.JUST_NEW || DrawingSetup.SelectedStateToDraw === StoreyEntityState.EXISTING_AND_NEW) {
                SpaceProperties.drawOutline(context, shape, zoomedStrokeWidth, 12);
            }
        }
    }
}
