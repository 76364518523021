import { meta } from 'ngx-property-grid';
import { EntityType } from '../../../../models/enums/entityType';
import { LocationEntityPanelProperties } from './base-entities-for-panel-properties.model';

export class SoilHeightPanelProperties extends LocationEntityPanelProperties {
    type = EntityType.SoilHeight;

    @meta({
        name: 'Výškový bod upraveného terénu [m]', group: 'Terén', options: 'soilHeight_M', valueConvert: parseFloat, type: 'number'
    })
    soilHeight_M;

    constructor(element?: any, data?: any) {
        super(element, data);
        this.soilHeight_M = element.soilHeight_M;
    }
}
