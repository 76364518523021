import { Component, OnInit } from "@angular/core";
import { MDBModalService } from "angular-bootstrap-md";
import { EntityType } from '../../models/enums/entityType';
import { CatalogueListColumn } from "../readonly-list.component/readonly-list.component";
import { ModelService } from "../../services/model.service";
import { CommonTools } from "../../shared/helpers/common-tools";
import { FloorDefinitionModalComponent } from "./floor-definition-modal/floor-definition-modal.component";
import { FloorDefinition } from "../../models/floor-definition.model";

@Component({
    selector: "app-floor-definition-list",
    templateUrl: "./floor-definition-list.component.html",
    styleUrls: ["./floor-definition-list.component.scss"],
})
export class FloorDefinitionListComponent implements OnInit {
    dataSource: FloorDefinition[] = [];

    constructor(private model: ModelService, private mbdModalService: MDBModalService) { }

    ngOnInit() {
        this.model.SelectedProject$.subscribe((project) => {
            this.dataSource = project.floorDefinition ? project.floorDefinition : [];
        });
    }

    defineColumns() {
        const columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = "Název";
        column.dataSourceColumnName = "name";
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = "Popis";
        column.dataSourceColumnName = "description";
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onCopyOne(externalId: string) {
        let floorDefinition: FloorDefinition = this.model.getEntityByExternalId(EntityType.FloorDefinition, externalId);
        const clone = Object.assign(new FloorDefinition(), floorDefinition);
        clone.externalId = CommonTools.GetUUID();
        this.model.AddEntity(clone);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(data: any) {
        this.model.RemoveEntity(data);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    private openModal(externalId?: string) {
        this.mbdModalService.show(FloorDefinitionModalComponent, {
            containerClass: "modal fade",
            class: "modal-dialog modal-dialog-scrollable modal-xl",
            animated: true,
            data: {
                externalId: externalId,
                projectId: this.model.SelectedProject.externalId,
            },
        });
    }
}

