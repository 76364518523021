import { EntityType } from "./enums/entityType";
import { Shielding } from "./shielding.model";
import { StoreyEntity } from "./storey-entity.model";
import { CoverageType, WindowProperties } from "./window-properties.model";
import { WindowSegment } from "./window-segment.model";

export class DefaultWindow extends StoreyEntity{
    entityType: EntityType = EntityType.DefaultWindow;
    width = 1500;
    height = 1500;

    propertiesExisting: WindowProperties;
    propertiesNew: WindowProperties;
    propertiesRecommended: WindowProperties;
    parapetHeight: number = 900;
    embedding: number = 100;
    designation: string = "";
    changedFromType: string = "";
    constructionDate: Date = new Date(2020, 1, 1);
    coverageExisting: CoverageType = CoverageType.NONE;
    coverageNew: CoverageType = CoverageType.NONE;
    coverageRecommended: CoverageType = CoverageType.NONE;

    shieldingExisting: Shielding;
    shieldingNew: Shielding;

    obstacleDistance1: number = 0;
    obstacleHeight1: number = 0;
    obstacleDistance2: number = 0;
    obstacleHeight2: number = 0;
    obstacleDistance3: number = 0;
    obstacleHeight3: number = 0;
    obstacleDistance4: number = 0;
    obstacleHeight4: number = 0;

    public returnWindowSegment():WindowSegment{
        const obj = new WindowSegment;
        obj.entityType = EntityType.WindowSegment;
        obj.propertiesExisting = this.propertiesExisting;
        obj.propertiesNew = this.propertiesNew;
        obj.propertiesRecommended = this.propertiesRecommended;
        obj.parapetHeight = this.parapetHeight;
        obj.embedding = this.embedding;
        obj.designation = this.designation;
        obj.changedFromType = this.changedFromType;
        obj.changeReason = null;
        obj.constructionDate = this.constructionDate;
        obj.coverageExisting = this.coverageExisting;
        obj.coverageNew = this.coverageNew;
        obj.coverageRecommended = this.coverageRecommended;
    
        obj.shieldingExisting = this.shieldingExisting;
        obj.shieldingNew = this.shieldingNew;
    
        //TODO: prehodiť do DefaultWindow
        obj.obstacleDistance1 = this.obstacleDistance1;
        obj.obstacleHeight1 = this.obstacleHeight1;
        obj.obstacleDistance2 = this.obstacleDistance2;
        obj.obstacleHeight2 = this.obstacleHeight2;
        obj.obstacleDistance3 = this.obstacleDistance3;
        obj.obstacleHeight3 = this.obstacleHeight3;
        obj.obstacleDistance4 = this.obstacleDistance4;
        obj.obstacleHeight4 = this.obstacleHeight4;

        obj.width = this.width;
        obj.height = this.height;

        return obj;
    }

}