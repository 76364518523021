import * as deepEqual from 'fast-deep-equal';
import { OperatorFunction, pipe } from 'rxjs';
import { distinctUntilChanged, map, pairwise, startWith, tap } from 'rxjs/operators';

export interface Dictionary<T = any> {
    [key: string]: T;
}

// -orderSegments------------------------------
interface Coordinate {
    x: number;
    y: number;
}

interface Vector {
    start: Coordinate;
    end: Coordinate;
}

export function orderSegments<V extends Vector>(segments: V[]): V[] {
    if (segments && segments.length > 1) {
        let orderedSegments = [
            segments[0],
        ];

        let unorderedSegments = [
            ...segments.slice(1),
        ];

        let result = orderLastSegments(orderedSegments, unorderedSegments, 'end');

        orderedSegments = result.orderedSegments;
        unorderedSegments = result.unorderedSegments;

        if (unorderedSegments.length > 0) {
            result = orderLastSegments(orderedSegments, unorderedSegments, 'start');
        }

        return result.orderedSegments;
    }

    return segments;
}

function orderLastSegments<V extends Vector>(
    orderedSegments: V[],
    unorderedSegments: V[],
    prevPoint: 'start' | 'end'): {
        orderedSegments: V[],
        unorderedSegments: V[],
    } {

    const nextPoint = prevPoint === 'start'
        ? 'end'
        : 'start';

    const ordered = [...orderedSegments];
    const unordered = [...unorderedSegments];

    while (unordered.length > 0) {
        const index = findNextSegmentIndex(
            ordered[prevPoint === 'start' ? 0 : ordered.length - 1][prevPoint],
            unordered,
            nextPoint,
        );

        if (index === -1) {
            break;
        }

        prevPoint === 'end'
            ? ordered.push(unordered[index])
            : ordered.unshift(unordered[index]);

        unordered.splice(index, 1);
    }

    return {
        orderedSegments: ordered,
        unorderedSegments: unordered,
    };
}

function findNextSegmentIndex<T extends Coordinate, V extends Vector>(
    prevPoint: T, segments: V[], nextPoint: 'start' | 'end'): number {
    if (segments && segments.length > 0) {
        return segments.findIndex(segment =>
            segment[nextPoint].x === prevPoint.x &&
            segment[nextPoint].y === prevPoint.y,
        );
    }

    return -1;
}

// -calculateOutlines--------------------------
export const calculateOutlines = (segments: any, outLine: any, innerWidth) => {
    outLine = [];
    const axisLine = new window.ClipperLib.Paths();
    axisLine[0] = [];
    const innerLine = new window.ClipperLib.Paths();
    const outerLine = new window.ClipperLib.Paths();

    let lastPoint: any;
    segments.forEach(segment => {
        axisLine[0].push({ X: segment.start.x, Y: segment.start.y });
        lastPoint = segment.end;
    });
    if (lastPoint) {
        axisLine[0].push({ X: lastPoint.x, Y: lastPoint.y });
    }
    const co = new window.ClipperLib.ClipperOffset(2, 0.25);
    co.AddPaths(axisLine, window.ClipperLib.JoinType.jtMiter, window.ClipperLib.EndType.etOpenButt);

    co.Execute(innerLine, innerWidth);

    if (innerLine.length > 0 && innerLine[0]) {
        innerLine[0].forEach(point => {
            outLine.push(point);
        });
    }
};

/**
 * Print what is in the pipe
 * @param message - prefix message
 * @returns {MonoTypeOperatorFunction<T>}
 */
export function consoleLog<T>(message?: string): OperatorFunction<T, T> {
    return tap(e => console.log(message, e));
}

/**
 * Track changes in observable stream and log it into 'added', 'existing' and 'deleted'
 * @param addedName - name for 'added'
 * @param existingName - name for 'existing'
 * @param deletedName - name for 'deleted'
 * @returns {'added': [], 'existing': [], 'deleted': []}
 */
export function trackChanges<T>(addedName: string = 'added',
    existingName: string = 'existing',
    deletedName: string = 'deleted'): OperatorFunction<T[], Dictionary<T[]>> {
    return pipe(
        startWith([]),
        pairwise(),
        map(([prev, next]) => {
            const nextSet = new Set(next);
            const deleted = [];
            const existing = [];
            prev.forEach(prevItem => {
                if (nextSet.has(prevItem)) {
                    existing.push(prevItem);
                    nextSet.delete(prevItem);
                } else {
                    deleted.push(prevItem);
                }
            });
            const added = Array.from(nextSet);
            return {
                [addedName]: added,
                [existingName]: existing,
                [deletedName]: deleted
            };
        }),
        distinctUntilChanged((a, b) => deepEqual(a, b)),
    );
}

/**
 * Get from bigger source object the smaller one, selecting only certain properties
 * @param sourceObject - bigger source object
 * @param keyList - list of properties to be selected
 * @returns {any}
 */
export function reduceObject<T, K extends keyof T, S extends T>(sourceObject: S, keyList: K[]): T | null {
    if (sourceObject) {
        return keyList.reduce((obj, key) =>
            ({ ...obj, [key]: sourceObject[key] }), {}) as T;
    }
    return null;
}

export function pickKeys<T>(keyList?: any[]): OperatorFunction<T, T> {
    return map(e => reduceObject(e, keyList));
}

/**
 * Convert array of objects to one object
 * @example:
 * const mapByName = arrayToObject('name');
 * const mappedObject = mapByName([{name: 'first', desc: 'First'}, {name: 'second', desc: 'Second'}]);
 * mappedObject === {first: {name: 'first', desc: 'First'}, second: {name: 'second', desc: 'Second'}}
 * @param keyField - the key, which is used as a primary (id) key
 * @param sourceArray - source array of objects
 * @returns {object - named map}
 */
export const arrayToObject = keyField => sourceArray =>
    Object.assign({}, ...sourceArray.map(item => ({ [item[keyField]]: item })));

/**
 * Merge of object with all nested properties
 * @param source - list od source objects
 * @param target - merged target object
 * @returns {any}
 */
export const mergeObjects = <T extends object = object>(target: T, source: T): T => {
    if (source) {
        Object.keys(source).forEach(key => target[key] = source[key]);
    }
    return target;
};

export const APP_HELPERS = {
    arrayToObject,
    deepMerge: mergeObjects,
    reduceObject,
    consoleLog,
    orderSegments,
};
