import { DrawingSetup } from './../services/drawing-setup';
import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { SegmentEntity } from './segment';

export class AuxLine extends SegmentEntity {
    entityType: EntityType = EntityType.AuxLine;
    id = '';
    stroke = '#666';
    fill = 'red';
    strokeWidth = 2;
    draggable = false;

    // a Konva.Canvas renderer is passed into the sceneFunc function
    sceneFunc = (context: any, shape: any) => {
        shape.stroke(BaseEntity.getShapeColor(<AuxLine>shape.attrs));
        shape.strokeWidth(3 / shape.parent.scale().x)
        context.beginPath();
        context.moveTo(shape.attrs.start.x, shape.attrs.start.y);
        context.lineTo(shape.attrs.end.x, shape.attrs.end.y);
        context.closePath();
        context.fillStrokeShape(shape);
    }
}
