import { DrawingSetup } from '../services/drawing-setup';
import { ChangeReason } from './enums/changeReason';
import { EntityType } from './enums/entityType';
import { Opening } from './opening.model';
import { SegmentEntity } from './segment';
import { Shielding } from './shielding.model';
import { CoverageType, WindowProperties } from './window-properties.model';

export class WindowSegment extends Opening {
    entityType: EntityType = EntityType.WindowSegment;
    propertiesExisting: WindowProperties;
    propertiesNew: WindowProperties;
    propertiesRecommended: WindowProperties;
    parapetHeight: number = 900;
    embedding: number = 100;
    designation: string = "";
    changedFromType: string = "";
    changeReason: ChangeReason = ChangeReason.NoChange;
    constructionDate: Date = new Date(2020, 1, 1);
    coverageExisting: CoverageType = CoverageType.NONE;
    coverageNew: CoverageType = CoverageType.NONE;
    coverageRecommended: CoverageType = CoverageType.NONE;

    shieldingExisting: Shielding;
    shieldingNew: Shielding;
    obstacleDistance1: number = 0;
    obstacleHeight1: number = 0;
    obstacleDistance2: number = 0;
    obstacleHeight2: number = 0;
    obstacleDistance3: number = 0;
    obstacleHeight3: number = 0;
    obstacleDistance4: number = 0;
    obstacleHeight4: number = 0;

    // a Konva.Canvas renderer is passed into the sceneFunc function
    sceneFunc = (context: any, shape: any) => {
        if (DrawingSetup.isShapeVisible(shape.attrs.state)) {
            SegmentEntity.drawSelectRect(context, shape, shape.attrs.wallSegment.start, shape.attrs.wallSegment.end);
            SegmentEntity.drawAxis(context, shape, shape.attrs.wallSegment.start, shape.attrs.wallSegment.end, null, 8);
        }
    };

}
