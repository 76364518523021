﻿import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentUser } from '../models/current-user.model';
import { RestService } from '../services/rest.service';

@Injectable()
export class AuthenticationService {
    private localStorageKey = 'OekoplanBV_User';

    constructor(
        private http: HttpClient,
        private rest: RestService,
    ) { }

    getUser = () => {
        try {
            return JSON.parse(localStorage.getItem(this.localStorageKey));
        } catch (error) {
            return new CurrentUser();
        }
    }

    setUser = (user: CurrentUser) => {
        if (user) {
            localStorage.setItem(this.localStorageKey, JSON.stringify(user));
        }
    }

    getToken = () => {
        const user: CurrentUser = this.getUser();
        if (!user || !user.token || user.token.length < 1) {
            return '';
        } else {
            return user.token;
        }
    }

    login = (username: string, password: string): Observable<any> => {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }),
            params: new HttpParams().set('username', username).set('password', password)
        };
        const request = this.http.post<any>(this.rest.getUrl('auth/login'), null, httpOptions);
        request.subscribe(user => this.setUser(user));
        return request;
    }

    logout = () => {
        localStorage.removeItem(this.localStorageKey);
        location.href = '/#/login';
    }
}
