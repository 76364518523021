import { Component, Input, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { MaterialLayerState, MATERIALLAYER_STATE_OPTIONS } from '../../../models/enums/MaterialLayerState';
import { MaterialLayer, MaterialLayerType } from '../../../models/material-layer.model';
import { MaterialStrip } from '../../../models/material-strip.model';
import { MATERIAL_GROUP_OPTIONS, MATERIAL_TYPE_OPTIONS } from '../../../models/material.model';
import { StructureProperties, STRUCTURE_PROPERTIES_FEAUTURE_OPTIONS, STRUCTURE_PROPERTIES_TYPE_OPTIONS, WallSide } from '../../../models/structure-properties.model';
import { DefaultDataService } from '../../../services/defaultdata.service';
import { MaterialSelectFormComponent } from '../material-select-form/material-select-form.component';
import { ModelService } from './../../../services/model.service';

export class LayerState {
    collapsed: Boolean = false;
    materialId: string;
};

@Component({
    selector: 'app-structurePropeties-editor',
    templateUrl: './structureProperties-editor.html',
    styleUrls: ['./structureProperties-editor.scss']
})
export class StructurePropertiesEditor implements OnInit {

    @Input() uuid;
    materialGroupTranslator: any;
    materialTypeTranslator: any;

    externalId: string;
    interiorMaterialValue: string;
    exteriorMaterialValue: string;

    materialOptions = [];
    toggledInteriorElementIndexArr = [];
    toggledExteriorElementIndexArr = [];

    isCollapseOne: Boolean = false;
    isNewStructure: boolean;
    projectId: string;

    layerTypeOptions = [
        { value: MaterialLayerType.STANDARD, label: 'Standard', selected: false },
        { value: MaterialLayerType.HORIZONTAL_INSULATION, label: 'Horizontální izolace', selected: false },
        { value: MaterialLayerType.VERTICAL_INSULATION, label: 'Vertikální izolace', selected: false },
    ]

    structure: StructureProperties = null;
    materialLayerStateOptions = MATERIALLAYER_STATE_OPTIONS;
    STRUCTURE_PROPERTIES_TYPE_OPTIONS = STRUCTURE_PROPERTIES_TYPE_OPTIONS;
    STRUCTURE_PROPERTIES_FEAUTURE_OPTIONS = STRUCTURE_PROPERTIES_FEAUTURE_OPTIONS;

    constructor(
        private model: ModelService,
        private defaultDataService: DefaultDataService,
        private mdbModalService: MDBModalService
    ) {
        this.materialGroupTranslator = (columnValue: string) => {
            const typeItems = MATERIAL_GROUP_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };

        this.materialTypeTranslator = (columnValue: string) => {
            const typeItems = MATERIAL_TYPE_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
    }

    ngOnInit() {
        this.materialOptions = this.model.Materials.map(material => {
            return {
                value: material['externalId'],
                label: this.translateGroup(material['group']) + ' - ' + material['type'] + ' - ' + (material['name'] ? material['name'] + ' - ' : '') + (material['description'] ? material['description'] : '')
            }
        });
        this.initData(this.externalId);
    }

    private initData(externalId?: string) {
        if (externalId) {
            this.structure = this.model.SelectedProject.structureProperties.find(item => item.externalId == externalId);
            this.isNewStructure = false;
        } else {
            this.structure = new StructureProperties();
            this.structure.detailedDescription = this.model.generateName(this.structure);
            this.isNewStructure = true;
        }
    }

    translateGroup(columnValue) {
        if (!this.materialGroupTranslator) {
            return columnValue;
        } else {
            return this.materialGroupTranslator(columnValue);
        }
    }

    translateType(columnValue) {
        if (!this.materialTypeTranslator) {
            return columnValue;
        } else {
            return this.materialTypeTranslator(columnValue);
        }
    }

    openModal(id: string) {
        this.mdbModalService.show(MaterialSelectFormComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: { id }
        });
    }

    onSave() {
        if (this.isNewStructure) {
            this.model.AddEntity(this.structure);
        } else {
            this.model.UpdateEntity(this.structure);
        }
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    getNewLayer(wallSide: WallSide): MaterialLayer {
        var materialStrip = new MaterialStrip();
        materialStrip.material = this.defaultDataService.getDefaultMaterial();

        var result = new MaterialLayer();
        result.strips = [materialStrip];
        result.thickness = 100;
        result.wallSide = wallSide;
        result.materialLayerState = MaterialLayerState.DEFAULT_PROJECTED;
        result.materialLayerType = MaterialLayerType.STANDARD;
        result.insulationLength = 0;
        result["widthRatioPercentage"] = 100;
        return result;
    }

    addInteriorLayer() {
        this.structure.compositionInterior.unshift(this.getNewLayer(WallSide.INTERNAL));
    }

    deleteInteriorLayer(id) {
        if (id > -1 && id < this.structure.compositionInterior.length) {
            this.structure.compositionInterior.splice(id, 1);
        }
    }

    addExteriorLayer() {
        this.structure.compositionExterior.push(this.getNewLayer(WallSide.EXTERNAL));
    }

    deleteExteriorLayer(id) {
        if (id > -1 && id < this.structure.compositionExterior.length) {
            this.structure.compositionExterior.splice(id, 1);
        }
    }

    updateLayer(layer: MaterialLayer, val?: string) {
        if (val) {
            layer.thickness = parseInt(val);
        }
    }

    onInteriorMaterialChange(data) {
    }

    onExteriorMaterialChange(data) {
    }

    onToggle(index, arrStr) {
        const toggledIndex = this[arrStr].findIndex(value => value === index);
        toggledIndex !== -1 ? this[arrStr].splice(toggledIndex, 1) : this[arrStr].push(index);
    }

    isToggled(index, arrStr) {
        return this[arrStr].findIndex(i => i === index) !== -1 ? false : true;
    }

    openMaterialModal(id: string) {
        this.mdbModalService.show(MaterialSelectFormComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: { id }
        });
    }

    addMaterialLayerStrip(i, isInterior = true, material?, widthRatioPercentage?) {
        const strip = new MaterialStrip();
        strip.material = material ? material : this.defaultDataService.getDefaultMaterial();
        strip.widthRatioPercentage = widthRatioPercentage ? widthRatioPercentage : 100

        if (isInterior) {
            this.structure.compositionInterior[i].strips.push(strip);
        } else {
            this.structure.compositionExterior[i].strips.push(strip);
        }
    }

    deleteMaterialLayerStrip(stripIndex, i, isInterior = true) {
        if (stripIndex > -1 && stripIndex < this.structure.compositionInterior[i].strips.length && isInterior) {
            this.structure.compositionInterior[i].strips.splice(stripIndex, 1);
        } else if (stripIndex > -1 && stripIndex < this.structure.compositionExterior[i].strips.length && !isInterior) {
            this.structure.compositionExterior[i].strips.splice(stripIndex, 1);
        }
    }
}
