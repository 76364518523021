import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { EntityType } from '../../models/enums/entityType';
import { Storey } from '../../models/storey.model';
import { DefaultsEditorComponent } from '../components/defaults-editor/defaults-editor.component';
import { ModelService } from './../../services/model.service';

@Component({
    selector: 'app-storeys-list',
    templateUrl: './storeys-list.component.html',
    styleUrls: ['./storeys-list.component.scss'],
})
export class StoreysListComponent implements OnInit {
    headElements: string[] = ['Popis', 'Výška nad zemí [m]', 'Obrázek', ''];
    columnElements: string[] = ['description', 'elevation', 'imageUrl'];
    dataSource: Storey[] = [];

    constructor(public model: ModelService, private mdbModalService: MDBModalService) { }

    ngOnInit() {
        if (this.model.SelectedBuilding) { this.dataSource = this.model.SelectedBuilding.storeys; }
        this.model.SelectedBuilding$.subscribe((building) => {
            if (building) { this.dataSource = building.storeys; }
        });
    }

    onAddOne(data: any) {
        const newStorey: Storey = Object.assign(new Storey(), data);
        newStorey.description = this.model.generateName(newStorey);
        this.model.AddEntity(newStorey).then(() => {
            this.model.SelectedBuilding$.next(this.model.SelectedBuilding);
        });
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        if (confirm('Opravdu chcete smazat vybrané podalaží?')) {
            const storey = this.model.getEntityByExternalId<Storey>(EntityType.Storey, externalId);
            if (storey.defaults) {
                storey.defaults = null;
                this.model.UpdateEntity(storey).then(() => {
                    this.model.RemoveEntityByExternalId(EntityType.Defaults, storey.defaults.externalId);
                    this.model.RemoveEntityByExternalId(EntityType.Storey, storey.externalId);
                });
            } else {
                this.model.RemoveEntityByExternalId(EntityType.Storey, storey.externalId);
            }
        }
    }

    onSelect(id: string) {
        this.model.SelectStoreyByExternalId(id);
    }

    onEditDefaults(externalId: string) {
        this.openDefaultsModal(externalId);
    }

    openDefaultsModal(externalId: string) {
        this.mdbModalService.show(DefaultsEditorComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                ownerId: externalId,
                ownerType: EntityType.Storey,
            },
        });
    }
}
