import { Component, OnInit } from '@angular/core';
import { UserSettings, UserSettingsService } from '../../../services/user-settings-service.service';

@Component({
    selector: 'app-user-settings-editor',
    templateUrl: './user-settings-editor.component.html',
    styleUrls: ['./user-settings-editor.component.scss'],
})
export class UserSettingsEditorComponent implements OnInit {
    public settings: UserSettings;

    constructor(private userSettingsService: UserSettingsService) {}

    ngOnInit() {
        this.settings = this.userSettingsService.UserSettings;
    }

    public onChangeX(value: number): void {
        this.settings.SnapGrid_X = value;
        this.userSettingsService.saveUserSettings();
    }

    public onChangeY(value: number): void {
        this.settings.SnapGrid_Y = value;
        this.userSettingsService.saveUserSettings();
    }

    public isNotDefaultPropertyValue(propertyName: string): boolean {
        return false;
    }
}
