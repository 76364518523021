/* Angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* 3rd parties */
import { TranslateModule } from '@ngx-translate/core';

/* Components */
import { ToastComponent } from './toast.component';
import { ToasterContainerComponent } from './toaster-container.component';

/* Services */
import { ToasterService } from '../../services/toaster.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        ToastComponent,
        ToasterContainerComponent
    ],
    providers: [
        ToasterService
    ],
    exports: [
        ToasterContainerComponent,
        ToastComponent
    ]
})

export class ToasterModule {}
