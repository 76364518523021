import { EntityType } from '../../../../models/enums/entityType';
import { WallSegmentLayersEditor } from '../../wallsegmentlayers-editor/wallsegmentlayers-editor';
import { SegmentEntityPanelProperties } from './base-entities-for-panel-properties.model';
import { meta } from 'ngx-property-grid';
import { StructureSetModalComponent } from '../../../structure-set-list/structure-set-modal/structure-set-modal.component';

export class WallSegmentPanelProperties extends SegmentEntityPanelProperties {
    type = EntityType.WallSegment;

    @meta({
        name: 'Na začátku [mm]', group: 'Výška stěny', options: 'startHeight', valueConvert: parseInt, type: 'number'
    })
    startHeight;

    @meta({
        name: 'Na konci [mm]', group: 'Výška stěny', options: 'endHeight', valueConvert: parseInt, type: 'number'
    })
    endHeight;

    @meta({ name: 'Skladba stěny', group: 'Skladba stěny', options: 'wallSegmentLayers', type: 'dropdown' })
    wallSegmentLayers;

    @meta({name: 'Set skladby konstrukce', group: 'Skladba stěny', options: 'structureSet', type: 'dropdown' })
    structureSet;

    constructor(element?: any, data?: any) {
        super(element, data);

        if (element) {
            this.startHeight = element.startHeight;
            this.endHeight = element.endHeight;

            this.setupDropdown(data.wallSegmentLayers$, "wallSegmentLayers", element.wallSegmentLayers ? element.wallSegmentLayers.externalId : null, "description", WallSegmentLayersEditor);
            this.setupDropdown(data.structureSets$, "structureSet", element.structureSet ? element.structureSet.externalId : null, "name", StructureSetModalComponent);
        }
    }
}
