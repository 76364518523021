import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { DoorProperties, DoorPropertyVisibility, DOOR_CATEGORY_OPTIONS, DOOR_DISTANCE_FRAME_OPTIONS, DOOR_MATERIAL, DOOR_TYPE_OPTIONS } from '../../../models/door-properties.model';
import { ProjectIntention } from '../../../models/project.model';
import { Role } from '../../../models/user.model';
import { RestService } from '../../../services/rest.service';
import { EDoorCategory } from './../../../models/door-properties.model';
import { AuthenticationService } from './../../../services/authentication.service';
import { ModelService } from './../../../services/model.service';

@Component({
    selector: 'app-door-properties-detail',
    templateUrl: './door-properties-modal.component.html',
    styleUrls: ['./door-properties-modal.component.scss']
})
export class DoorPropertiesModalComponent implements OnInit {

    /** externalId: string | undefined - id of doorProperties element
     *  Could be set in parent component using mbdModalService.show(ModalComponent, {data: {externalId}})
     */
    externalId: string;

    properties: DoorProperties = null;

    typeOptions = DOOR_TYPE_OPTIONS;
    categoryOptions = DOOR_CATEGORY_OPTIONS;
    materialOptions = DOOR_MATERIAL;
    distanceFrameOptions = DOOR_DISTANCE_FRAME_OPTIONS;
    heightRadioValue = true;
    propertyVisibility = new DoorPropertyVisibility();

    private isNewWindow: boolean;
    private currentUser = null;

    constructor(
        private model: ModelService,
        private authService: AuthenticationService,
        private mdbModalService: MDBModalService,
        private rest: RestService,
    ) {
    }

    ngOnInit() {
        if (this.externalId) {
            this.properties = this.model.DoorProperties.find(item => item.externalId == this.externalId);
        }
        if (this.properties) {
            this.isNewWindow = false;
        } else {
            this.properties = new DoorProperties();
            this.properties.name = this.model.generateName(this.properties);
            this.properties.externalId = this.externalId;
            this.isNewWindow = true;
        }

        this.currentUser = this.authService.getUser();
    }

    onChangeMaterial() {
        if (this.model.SelectedProject.intention == ProjectIntention.RECONSTRUCTION) {
            if (this.properties.material.toString() == 'PLASTIC') this.properties.ud = 2.3;
            if (this.properties.material.toString() == 'WOOD') this.properties.ud = 2.3;
            if (this.properties.material.toString() == 'WOOD_ALUMINIUM') this.properties.ud = 2.3;
            if (this.properties.material.toString() == 'METAL') this.properties.ud = 5.65;
            if (this.properties.material.toString() == 'STEEL') this.properties.ud = 5.65;
            if (this.properties.material.toString() == 'ALUMINIUM') this.properties.ud = 5.65;
        } else {
            if (this.properties.category == EDoorCategory.INTERIOR) {
                this.properties.ud = 2.5;
            } else {
                // =Freq(COU,REG,<typ>,"vytápěné","exteriér","",DAT)
            }
        }
    }

    onSave() {
        this.initBeforeSave();

        if (this.isNewWindow) {
            this.model.AddEntity(this.properties);
        } else {
            this.model.UpdateEntity(this.properties);
        }

        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    public isNotDefaultPropertyValue(propertyName: string): boolean {
        return false;
    }

    public isPropertyVisible(propertyName: string): boolean {
        var isVisible = this.propertyVisibility.isPropertyVisible(this.properties, propertyName);
        if (propertyName == "confirmed") {
            isVisible = isVisible && this.currentUser && this.currentUser.role == Role.ADMINISTRATOR;
        }
        return isVisible;
    }

    private initForm() {
        this.heightRadioValue = true;
        const heightDimensions = [
            this.properties.heightTop,
            this.properties.heightBottom,
            this.properties.heightRight,
            this.properties.heightLeft
        ];
        if (!heightDimensions.every(key => key == null || this.properties.heightAverage != null || key == this.properties.heightAverage)) {
            this.heightRadioValue = false;
        }
    }

    private initBeforeSave() {
        if (this.heightRadioValue) {
            this.properties.heightTop = this.properties.heightAverage;
            this.properties.heightBottom = this.properties.heightAverage;
            this.properties.heightLeft = this.properties.heightAverage;
            this.properties.heightRight = this.properties.heightAverage;
        } else {
            this.properties.heightAverage = null;
        }
    }
}