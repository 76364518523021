import { ZoneType } from "./ZoneType";

export enum RoomTypes {
    OBYTNE_PROSTORY = "OBYTNE_PROSTORY",
    OSTATNI_NEOBYVANE_PROSTORY = "OSTATNI_NEOBYVANE_PROSTORY",
    SPOLECNE_PROSTORY_KOMUNIKACE = "SPOLECNE_PROSTORY_KOMUNIKACE",
    OSTATNI_PROSTORY = "OSTATNI_PROSTORY",
    VELKOPLOSNA_KANCELAR = "VELKOPLOSNA_KANCELAR",
    ODDELENE_KANCELARE = "ODDELENE_KANCELARE",
    ZASEDACI_MISTNOSTI = "ZASEDACI_MISTNOSTI",
    SPECIALNI_PROSTORY_SERVEROVNY = "SPECIALNI_PROSTORY_SERVEROVNY",
    SCHODISTE_CHODBY_KOMUNIKACE = "SCHODISTE_CHODBY_KOMUNIKACE",
    SKLADY_ARCHIVY = "SKLADY_ARCHIVY",
    UCEBNY_KABINETY = "UCEBNY_KABINETY",
    POSLUCHARNY_PREDNASKOVE_PROSTORY = "POSLUCHARNY_PREDNASKOVE_PROSTORY",
    CHODBY_KOMUNIKACE = "CHODBY_KOMUNIKACE",
    TELOCVICNY_SPORTOVISTE = "TELOCVICNY_SPORTOVISTE",
    JIDELNY_KANTYNY = "JIDELNY_KANTYNY",
    SATNY = "SATNY",
    POKOJE_PRO_PACIENTY = "POKOJE_PRO_PACIENTY",
    ORDINACE = "ORDINACE",
    CHODBY_CEKARNY = "CHODBY_CEKARNY",
    SALY = "SALY",
    PRIPRAVNY_JIDEL_JIDELNY = "PRIPRAVNY_JIDEL_JIDELNY",
    UBYTOVACI_PROSTORY_POKOJE = "UBYTOVACI_PROSTORY_POKOJE",
    RESTAURACE_STRAVOVACI_PROSTORY = "RESTAURACE_STRAVOVACI_PROSTORY",
    PRIPRAVNY_JIDEL = "PRIPRAVNY_JIDEL",
    SKLADY_POTRAVIN = "SKLADY_POTRAVIN",
    SKLADY_OSTATNI = "SKLADY_OSTATNI",
    SPORTOVNI_PLOCHY = "SPORTOVNI_PLOCHY",
    HLEDISTE = "HLEDISTE",
    OSTATNI_PROSTORY_TECHNICKE_MISTNOSTI = "OSTATNI_PROSTORY_TECHNICKE_MISTNOSTI",
    BAZENOVA_HALA = "BAZENOVA_HALA",
    PRODEJNI_PLOCHY = "PRODEJNI_PLOCHY",
    SATNY_HYGIENICKA_ZARIZENI = "SATNY_HYGIENICKA_ZARIZENI",
    SKLADY_S_TRVALYM_POBYTEM_OSOB = "SKLADY_S_TRVALYM_POBYTEM_OSOB",
    SKLADY_BEZ_TRVALEHO_POBYTU_OSOB = "SKLADY_BEZ_TRVALEHO_POBYTU_OSOB",
    DIVADLA_KINA_HLEDISTE = "DIVADLA_KINA_HLEDISTE",
    DIVADLA_KINA_JEVISTE = "DIVADLA_KINA_JEVISTE",
    VYSTAVNI_PROSTORY = "VYSTAVNI_PROSTORY",
    HROMADNE_GARAZE = "HROMADNE_GARAZE"
}

export const ROOM_TYPES = [
    { value: RoomTypes.OBYTNE_PROSTORY, label: "Obytné prostory", selected: false, externalId: 0 },
    { value: RoomTypes.OSTATNI_NEOBYVANE_PROSTORY, label: "Ostatní neobývané prostory", selected: false, externalId: 1 },
    { value: RoomTypes.SPOLECNE_PROSTORY_KOMUNIKACE, label: "Společné prostory, komunikace", selected: false, externalId: 2 },
    { value: RoomTypes.OSTATNI_PROSTORY, label: "Ostatní prostory", selected: false, externalId: 3 },
    { value: RoomTypes.VELKOPLOSNA_KANCELAR, label: "Velkoplošná kancelář", selected: false, externalId: 4 },
    { value: RoomTypes.ODDELENE_KANCELARE, label: "Oddělené kanceláře", selected: false, externalId: 5 },
    { value: RoomTypes.ZASEDACI_MISTNOSTI, label: "Zasedací místnosti", selected: false, externalId: 6 },
    { value: RoomTypes.SPECIALNI_PROSTORY_SERVEROVNY, label: "Speciální prostory, serverovny", selected: false, externalId: 7 },
    { value: RoomTypes.SCHODISTE_CHODBY_KOMUNIKACE, label: "Schodiště, chodby, komunikace", selected: false, externalId: 8 },
    { value: RoomTypes.SKLADY_ARCHIVY, label: "Sklady, archivy", selected: false, externalId: 9 },
    { value: RoomTypes.UCEBNY_KABINETY, label: "Učebny, kabinety", selected: false, externalId: 10 },
    { value: RoomTypes.POSLUCHARNY_PREDNASKOVE_PROSTORY, label: "Posluchárny, přednáškové prostory", selected: false, externalId: 11 },
    { value: RoomTypes.CHODBY_KOMUNIKACE, label: "Chodby, komunikace", selected: false, externalId: 12 },
    { value: RoomTypes.TELOCVICNY_SPORTOVISTE, label: "Tělocvičný, sportoviště", selected: false, externalId: 13 },
    { value: RoomTypes.JIDELNY_KANTYNY, label: "Jídelny, kantýny", selected: false, externalId: 14 },
    { value: RoomTypes.SATNY, label: "Šatny", selected: false, externalId: 15 },
    { value: RoomTypes.POKOJE_PRO_PACIENTY, label: "Pokoje pro pacienty", selected: false, externalId: 16 },
    { value: RoomTypes.ORDINACE, label: "Ordinace", selected: false, externalId: 17 },
    { value: RoomTypes.CHODBY_CEKARNY, label: "Chodby, čekárny", selected: false, externalId: 18 },
    { value: RoomTypes.SALY, label: "Sály", selected: false, externalId: 19 },
    { value: RoomTypes.PRIPRAVNY_JIDEL_JIDELNY, label: "Přípravny jídel, jídelny", selected: false, externalId: 20 },
    { value: RoomTypes.UBYTOVACI_PROSTORY_POKOJE, label: "Ubytovací prostory, pokoje", selected: false, externalId: 21 },
    { value: RoomTypes.RESTAURACE_STRAVOVACI_PROSTORY, label: "Restaurace, stravovací prostory", selected: false, externalId: 22 },
    { value: RoomTypes.PRIPRAVNY_JIDEL, label: "Přípravny jídel", selected: false, externalId: 23 },
    { value: RoomTypes.SKLADY_POTRAVIN, label: "Sklady potravin", selected: false, externalId: 24 },
    { value: RoomTypes.SKLADY_OSTATNI, label: "Sklady ostatní", selected: false, externalId: 25 },
    { value: RoomTypes.SPORTOVNI_PLOCHY, label: "Sportovní plochy", selected: false, externalId: 26 },
    { value: RoomTypes.HLEDISTE, label: "Hlediště", selected: false, externalId: 27 },
    { value: RoomTypes.OSTATNI_PROSTORY_TECHNICKE_MISTNOSTI, label: "Ostatní prostory, technické místnosti", selected: false, externalId: 28 },
    { value: RoomTypes.BAZENOVA_HALA, label: "Bazénová hala", selected: false, externalId: 29 },
    { value: RoomTypes.PRODEJNI_PLOCHY, label: "Prodejní plochy", selected: false, externalId: 30 },
    { value: RoomTypes.SATNY_HYGIENICKA_ZARIZENI, label: "Šatny, hygienická zařízení", selected: false, externalId: 31 },
    { value: RoomTypes.SKLADY_S_TRVALYM_POBYTEM_OSOB, label: "Sklady s trvalým pobytem osob", selected: false, externalId: 32 },
    { value: RoomTypes.SKLADY_BEZ_TRVALEHO_POBYTU_OSOB, label: "Sklady bez trvalého pobytu osob", selected: false, externalId: 33 },
    { value: RoomTypes.DIVADLA_KINA_HLEDISTE, label: "Divadla, kina, hlediště", selected: false, externalId: 34 },
    { value: RoomTypes.DIVADLA_KINA_JEVISTE, label: "Divadla, kina, jeviště", selected: false, externalId: 35 },
    { value: RoomTypes.VYSTAVNI_PROSTORY, label: "Výstavní prostory", selected: false, externalId: 36 },
    { value: RoomTypes.HROMADNE_GARAZE, label: "Hromadné garáže", selected: false, externalId: 37 },
];

export const FILTERED_ROOM_TYPES = (zoneType: ZoneType) => {
    switch (zoneType) {
        case ZoneType.AccomodationCatering: return [RoomTypes.UBYTOVACI_PROSTORY_POKOJE, RoomTypes.CHODBY_KOMUNIKACE, RoomTypes.RESTAURACE_STRAVOVACI_PROSTORY, RoomTypes.PRIPRAVNY_JIDEL, RoomTypes.SKLADY_POTRAVIN, RoomTypes.SKLADY_OSTATNI, RoomTypes.OSTATNI_PROSTORY];
        case ZoneType.ApartmentsHouse: return [RoomTypes.OBYTNE_PROSTORY, RoomTypes.SPOLECNE_PROSTORY_KOMUNIKACE, RoomTypes.OSTATNI_PROSTORY];
        case ZoneType.Commercial: return [RoomTypes.PRODEJNI_PLOCHY, RoomTypes.SATNY_HYGIENICKA_ZARIZENI, RoomTypes.SKLADY_S_TRVALYM_POBYTEM_OSOB, RoomTypes.SKLADY_BEZ_TRVALEHO_POBYTU_OSOB, RoomTypes.SKLADY_POTRAVIN, RoomTypes.OSTATNI_PROSTORY];
        case ZoneType.Culture: return [RoomTypes.DIVADLA_KINA_HLEDISTE, RoomTypes.DIVADLA_KINA_JEVISTE, RoomTypes.VYSTAVNI_PROSTORY];
        case ZoneType.EducationBuilding: return [RoomTypes.UCEBNY_KABINETY, RoomTypes.POSLUCHARNY_PREDNASKOVE_PROSTORY, RoomTypes.CHODBY_KOMUNIKACE, RoomTypes.TELOCVICNY_SPORTOVISTE, RoomTypes.JIDELNY_KANTYNY, RoomTypes.SATNY];
        case ZoneType.FamilyHouse: return [RoomTypes.OBYTNE_PROSTORY, RoomTypes.OSTATNI_NEOBYVANE_PROSTORY];
        case ZoneType.Medical: return [RoomTypes.POKOJE_PRO_PACIENTY, RoomTypes.ORDINACE, RoomTypes.CHODBY_CEKARNY, RoomTypes.SALY, RoomTypes.PRIPRAVNY_JIDEL_JIDELNY, RoomTypes.OSTATNI_PROSTORY];
        case ZoneType.OfficeBuilding: return [RoomTypes.VELKOPLOSNA_KANCELAR, RoomTypes.ODDELENE_KANCELARE, RoomTypes.ZASEDACI_MISTNOSTI, RoomTypes.SPECIALNI_PROSTORY_SERVEROVNY, RoomTypes.SCHODISTE_CHODBY_KOMUNIKACE, RoomTypes.SKLADY_ARCHIVY];
        case ZoneType.Sport: return [RoomTypes.SPORTOVNI_PLOCHY, RoomTypes.SATNY, RoomTypes.HLEDISTE, RoomTypes.CHODBY_KOMUNIKACE, RoomTypes.BAZENOVA_HALA, RoomTypes.OSTATNI_PROSTORY_TECHNICKE_MISTNOSTI];
        default: return [];
    }
}