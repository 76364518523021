import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { ProjectEntity } from './project-entity.model';

export interface ShieldingData extends ProjectEntity {
    leftIndent: number;
    leftLength: number;
    rightIndent: number;
    rightLength: number;
    topIndent: number;
    topLength: number;
}

export class Shielding extends BaseEntity implements ShieldingData {
    entityType: EntityType.Shielding;
    leftIndent: number = 0;
    leftLength: number = 0;
    rightIndent: number = 0;
    rightLength: number = 0;
    topIndent: number = 0;
    topLength: number = 0;
    project: { externalId: string };
    projectExternalId: string;

    constructor() {
        super();
    }
}