import { ToasterConfig } from './toaster-config';

export interface Toast {
    type: string;
    title?: string;
    body?: any;
    toastId?: string;
    toastContainerId?: number;
    onShowCallback?: OnActionCallback;
    onHideCallback?: OnActionCallback;
    timeout?: number;
    timeoutId?: number;
    bodyOutputType?: BodyOutputType;
    clickHandler?: ClickHandler;
    showCloseButton?: boolean;
    closeHtml?: string;
    toasterConfig?: ToasterConfig;
}

type ClickHandler = (toast: Toast, isCloseButton?: boolean) => boolean;

type OnActionCallback = (toast: Toast) => void;

export enum BodyOutputType {
    Default, TrustedHtml, Component
}
