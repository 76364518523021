import { ChangeReason } from './enums/changeReason';
import { EntityType } from './enums/entityType';
import { Shielding } from './shielding.model';
import { SpaceProperties } from './space-properties.model';
import { StoreyEntity } from './storey-entity.model';
import { CoverageType, WindowProperties } from './window-properties.model';

export class RoofWindow extends StoreyEntity {
    entityType: EntityType = EntityType.RoofWindow;
    height: number = 1200;
    width: number = 800;
    pieces: number = 1;
    propertiesExisting: WindowProperties;
    propertiesNew: WindowProperties;
    propertiesRecommended: WindowProperties;
    embedding: number = 100;
    designation: string = "";
    changedFromType: string = "";
    changeReason: ChangeReason = ChangeReason.NoChange;
    constructionDate: Date = new Date(2020, 1, 1);
    spaceProperties: SpaceProperties;
    coverageExisting: CoverageType = CoverageType.NONE;
    coverageNew: CoverageType = CoverageType.NONE;
    coverageRecommended: CoverageType = CoverageType.NONE;

    shieldingExisting: Shielding;
    shieldingNew: Shielding;
    obstacleDistance1: number = 0;
    obstacleHeight1: number = 0;
    obstacleDistance2: number = 0;
    obstacleHeight2: number = 0;
    obstacleDistance3: number = 0;
    obstacleHeight3: number = 0;
    obstacleDistance4: number = 0;
    obstacleHeight4: number = 0;
}