import { Point } from './../../../../models/point.model';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ICustomDynamicComponent } from 'ngx-property-grid';

@Component({
  selector: 'app-point-property',
  templateUrl: './point-property.component.html',
  styleUrls: ['./point-property.component.css']
})
export class PointPropertyComponent implements ICustomDynamicComponent<Point>, OnInit {
  @Input() value: Point;
  @Output() valueChange: EventEmitter<Point> = new EventEmitter<Point>();
  @Input() labelClass: string;
  @Input() iconClass: string;

  ngOnInit() {
    this.value.x = Math.round(this.value.x);
    this.value.y = Math.round(this.value.y);
  }

  onChangeX(data: any) {
    this.value.x = parseFloat(data.target.value);
    this.valueChange.emit(this.value);
  }
  onChangeY(data: any) {
    this.value.y = parseFloat(data.target.value);
    this.valueChange.emit(this.value);
  }
}
