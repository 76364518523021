import { WallLayer } from './../models/wall-layer.model';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import * as uuid from 'uuid4';
import { BaseEntity } from '../models/base-entity.model';
import { BorderSegment } from '../models/border-segment.model';
import { Company } from '../models/company.model';
import { CurrentUser } from '../models/current-user.model';
import { DoorSegment } from '../models/door-segment.model';
import { StoreyEntityState } from '../models/enums/StoreyEntityState';
import { FloorDefinition } from '../models/floor-definition.model';
import { FloorProperties } from '../models/floor-properties.model';
import { LocationEntity } from '../models/location-entity.model';
import { Point } from '../models/point.model';
import { ProjectEntity } from '../models/project-entity.model';
import { RoofDefinition } from '../models/roof-definition.model';
import { RoofWindow } from '../models/roof-window.model';
import { SegmentEntity } from '../models/segment';
import { Shielding } from '../models/shielding.model';
import { SoilHeight } from '../models/soil-height.model';
import { SpaceProperties } from '../models/space-properties.model';
import { StoreyEntity } from '../models/storey-entity.model';
import { StructureProperties } from '../models/structure-properties.model';
import { StructureSet } from '../models/structure-set.model';
import { Ventilation } from '../models/ventilation.model';
import { WallSegmentLayers } from '../models/wall-segment-layers.model';
import { WallSegment } from '../models/wall-segment.model';
import { WindowSegment } from '../models/window-segment.model';
import { ZoneDefinition } from '../models/zone-definition.model';
import { Zone } from '../models/zone.model';
import { Point2D } from '../shared/helpers/geometry';
import { Address } from './../models/address.model';
import { AuxLine } from './../models/auxline.model';
import { Building } from './../models/building.model';
import { Defaults } from './../models/defaults.model';
import { DoorProperties } from './../models/door-properties.model';
import { EntityType } from './../models/enums/entityType';
import { MaterialLayer } from './../models/material-layer.model';
import { Material } from './../models/material.model';
import { Project } from './../models/project.model';
import { Storey } from './../models/storey.model';
import { WindowProperties } from './../models/window-properties.model';
import { CommonTools } from './../shared/helpers/common-tools';
import { BackendService } from './backend.service';
import { RoofProperties } from '../models/roof-properties.model';
import { EntitiesHistory } from '../models/entities-history.model';
import { UndoRedoService } from './undoredo.service';
import { UndoRedoAction } from './../models/enums/undoredo';
import { DefaultDoor } from '../models/default-door';
import { DefaultWindow } from '../models/default-window';
import { DefaultWall } from '../models/default-wall';
import { DefaultSpaceProperties } from '../models/default-space-properties.model';
import { Version } from '../models/version';

@Injectable({
    providedIn: 'root',
})
export class ModelService {

    get SelectedProject(): Project {
        return this.selectedProject;
    }
    set SelectedProject(value: Project) {
        this.loadProject(value.externalId);
    }
    get SelectedBuilding(): Building {
        return this.selectedBuilding;
    }
    set SelectedBuilding(value: Building) {
        this.selectedBuilding = value;
        this.Storeys = this.selectedBuilding != null ? this.selectedBuilding.storeys : [];
        this.selectedStorey = this.selectedBuilding.storeys[0];
        this.SelectedBuilding$.next(this.selectedBuilding);
        this.SelectedStorey$.next(this.selectedStorey);
    }
    get SelectedStorey(): Storey {
        return this.selectedStorey;
    }
    set SelectedStorey(value: Storey) {
        this.selectedStorey = value;
        this.SelectedStorey$.next(value);
    }
    get BacklightedStoreys(): Storey[] {
        return this.backlightedStoreys;
    }
    set BacklightedStoreys(value: Storey[]) {
        this.backlightedStoreys = value;
        this.BacklightedStoreys$.next(value);
    }

    // Undo Redo Variables
    private undoredo: UndoRedoService;

    constructor(private backendService: BackendService, injector: Injector) {

        setTimeout(() => this.undoredo = injector.get(UndoRedoService));

        this.backendService.getVersion().subscribe((result) => {
            this.Version$.next(result);
        });

        const model = this;
        forkJoin([
            this.backendService.loadEntities<Company>(EntityType.User, {}),
            this.backendService.loadEntities<Company>(EntityType.WindowProperties, {}),
            this.backendService.loadEntities<Company>(EntityType.DoorProperties, {}),
            this.backendService.loadEntities<Company>(EntityType.Material, {}),
            this.backendService.loadEntities<Company>(EntityType.Address, {}),
            this.backendService.loadEntities<Company>(EntityType.Company, {}),
            this.backendService.loadEntities<Company>(EntityType.Project, {})
        ]).subscribe((data: any[]) => {
            let i = 0;
            model.Users = data[i++];
            model.WindowProperties = data[i++];
            model.DoorProperties = data[i++];
            model.Materials = data[i++];
            model.Addresses = data[i++];
            model.Companies = data[i++];
            model.Projects = data[i++];
            model.Projects.forEach((project: Project) => {
                project.address = model.Addresses.find(a => a.externalId === (<any>project.address));
                project.customer = model.Companies.find(a => a.externalId === (<any>project.customer));
                project.elaboratedBy = model.Users.find(a => a.externalId === (<any>project.elaboratedBy));
                project.checkedBy = model.Users.find(a => a.externalId === (<any>project.checkedBy));
                project.approvedBy = model.Users.find(a => a.externalId === (<any>project.approvedBy));
            });
            model.Projects$.next(model.Projects);

            const lastProject = localStorage.getItem('OekoplanBV_SelectedProject');
            if (lastProject) {
                this.loadProject(lastProject);
            } else {
                if (this.Projects.length > 0) { this.loadProject(this.Projects[0].externalId); }
            }
        });
    }
    // APP-LEVEL ENTITIES
    public Projects: Project[];
    public Projects$: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>(null);
    public WindowProperties: WindowProperties[];
    public DoorProperties: DoorProperties[];
    public Materials: Material[];
    public Users: CurrentUser[];
    public Addresses: Address[];
    public Companies: Company[];

    // PROJECT-LEVEL ENTITIES
    public Buildings: Building[] = [];
    public Storeys: Storey[] = [];
    public Defaults: Defaults[] = [];

    public Version$: BehaviorSubject<Version> = new BehaviorSubject<Version>(null);
    private version: Version;
    // CURRENT ENTITIES
    public SelectedProject$: BehaviorSubject<Project> = new BehaviorSubject<Project>(null);
    private selectedProject: Project = null;

    public SelectedBuilding$: BehaviorSubject<Building> = new BehaviorSubject<Building>(null);
    private selectedBuilding: Building = null;

    public SelectedStorey$: BehaviorSubject<Storey> = new BehaviorSubject<Storey>(null);
    private selectedStorey: Storey = null;

    public BacklightedStoreys$: BehaviorSubject<Storey[]> = new BehaviorSubject<Storey[]>([]);
    private backlightedStoreys: Storey[] = [];

    public DrawnEntityState: StoreyEntityState = StoreyEntityState.EXISTING_AND_NEW;

    public EntitiesHistory: EntitiesHistory = new EntitiesHistory();

    public static deepCopy<T>(source: T): T {
        let clone = Object.create(Object.getPrototypeOf(source));
        clone = Object.assign(clone, source);
        clone.externalId = CommonTools.GetUUID();
        return clone;
    }

    public loadProject(externalId: string): void {
        const projectFilter = { projectExternalId: externalId };
        const model = this;
        const project = this.Projects.find(p => p.externalId === externalId);
        if (!project) { return; }   // nepodarilo se nacist projekt, nepujde nacist nic jineho

        forkJoin([
            this.backendService.loadEntities<Building>(EntityType.Building, projectFilter),
            this.backendService.loadEntities<Storey>(EntityType.Storey, projectFilter),
            this.backendService.loadEntities<Point>(EntityType.Point, projectFilter),
            this.backendService.loadEntities<WallSegment>(EntityType.WallSegment, projectFilter),
            this.backendService.loadEntities<WindowSegment>(EntityType.WindowSegment, projectFilter),
            this.backendService.loadEntities<DoorSegment>(EntityType.DoorSegment, projectFilter),
            //this.backendService.loadEntities<DefaultWall>(EntityType.DefaultWall, projectFilter),
            this.backendService.loadEntities<DefaultWindow>(EntityType.DefaultWindow, projectFilter),
            this.backendService.loadEntities<DefaultDoor>(EntityType.DefaultDoor, projectFilter),
            this.backendService.loadEntities<SpaceProperties>(EntityType.SpaceProperties, projectFilter),
            this.backendService.loadEntities<DefaultSpaceProperties>(EntityType.DefaultSpaceProperties, projectFilter),
            this.backendService.loadEntities<AuxLine>(EntityType.AuxLine, projectFilter),
            this.backendService.loadEntities<WallSegmentLayers>(EntityType.WallSegmentLayers, projectFilter),
            this.backendService.loadEntities<RoofWindow>(EntityType.RoofWindow, projectFilter),
            this.backendService.loadEntities<Shielding>(EntityType.Shielding, projectFilter),
            this.backendService.loadEntities<StructureProperties>(EntityType.StructureProperties, projectFilter),
            // this.backendService.loadEntities<Ventilation>(EntityType.Ventilation, projectFilter),
            this.backendService.loadEntities<Zone>(EntityType.Zone, projectFilter),
            this.backendService.loadEntities<ZoneDefinition>(EntityType.ZoneDefinition, projectFilter),
            this.backendService.loadEntities<StructureSet>(EntityType.StructureSet, projectFilter),
            this.backendService.loadEntities<RoofDefinition>(EntityType.RoofDefinition, projectFilter),
            this.backendService.loadEntities<FloorDefinition>(EntityType.FloorDefinition, projectFilter),
            this.backendService.loadEntities<Defaults>(EntityType.Defaults, projectFilter),
            this.backendService.loadEntities<SoilHeight>(EntityType.SoilHeight, projectFilter),
            this.backendService.loadEntities<FloorProperties>(EntityType.FloorProperties, projectFilter),
            this.backendService.loadEntities<RoofProperties>(EntityType.RoofProperties, projectFilter),
            this.backendService.loadEntities<BorderSegment>(EntityType.Border, projectFilter)
        ]).subscribe((data: any[]) => {
            let i = 0;
            const buildings: Building[] = data[i++];
            const storeys: Storey[] = data[i++];
            const points: Point[] = data[i++];
            const walls: WallSegment[] = data[i++];
            const windows: WindowSegment[] = data[i++];
            const doors: DoorSegment[] = data[i++];
            //const defaultWalls: DefaultWall[] = data[i++];
            const defaultWindows: DefaultWindow[] = data[i++];
            const defaultDoors: DefaultDoor[] = data[i++];
            const spaceProperties: SpaceProperties[] = data[i++];
            const defaultSpaceProperties: DefaultSpaceProperties[] = data[i++];
            const auxLines: AuxLine[] = data[i++];
            const wallSegmentLayers: WallSegmentLayers[] = data[i++];
            const roofWindows: RoofWindow[] = data[i++];
            const shieldings: Shielding[] = data[i++];
            const structureProperties: StructureProperties[] = data[i++];
            // var ventilations: Ventilation[] = data[i++];
            const zones: Zone[] = data[i++];
            const zoneDefinitions: ZoneDefinition[] = data[i++];
            const structureSet: StructureSet[] = data[i++];
            const roofDefinition: RoofDefinition[] = data[i++];
            const floorDefinition: FloorDefinition[] = data[i++];
            const defaults: Defaults[] = data[i++];
            const soilHeight: SoilHeight[] = data[i++];
            const floorProperties: FloorProperties[] = data[i++];
            const roofProperties: RoofProperties[] = data[i++];
            const borderSegments: BorderSegment[] = data[i++];

            wallSegmentLayers.forEach(wsl => {
                wsl.layers.forEach(layer => {
                    model.bindById(layer, 'properties', structureProperties);
                });
            });
            walls.forEach(wall => {
                model.bindById(wall, 'start', points);
                model.bindById(wall, 'end', points);
                model.bindById(wall, 'wallSegmentLayers', wallSegmentLayers);
                model.bindById(wall, 'structureSet', structureSet);
            });
            auxLines.forEach(auxLine => {
                model.bindById(auxLine, 'start', points);
                model.bindById(auxLine, 'end', points);
            });
            borderSegments.forEach(borderSegment => {
                model.bindById(borderSegment, 'start', points);
                model.bindById(borderSegment, 'end', points);
            });
            windows.forEach((window: WindowSegment) => {
                model.bindById(window, 'wallSegment', walls);
                model.bindById(window, 'shieldingNew', shieldings);
                model.bindById(window, 'shieldingExisting', shieldings);
                model.bindById(window, 'propertiesExisting', model.WindowProperties);
                model.bindById(window, 'propertiesNew', model.WindowProperties);
                model.bindById(window, 'propertiesRecommended', model.WindowProperties);
            });
            doors.forEach((door: DoorSegment) => {
                model.bindById(door, 'propertiesExisting', model.DoorProperties);
                model.bindById(door, 'propertiesNew', model.DoorProperties);
                model.bindById(door, 'propertiesRecommended', model.DoorProperties);
                model.bindById(door, 'wallSegment', walls);
            });
            spaceProperties.forEach((sp: SpaceProperties) => {
                model.bindById(sp, 'location', points);
                model.bindById(sp, 'ceilingComposition_F', zones);
                model.bindById(sp, 'ceilingComposition_I', zones);
                model.bindById(sp, 'floorComposition_F', structureProperties);
                model.bindById(sp, 'floorComposition_I', structureProperties);
                sp.roofWindows = roofWindows.filter(rw => (<any>rw.spaceProperties) === sp.externalId);
                model.bindById(sp, 'zone_I', zones);
                model.bindById(sp, 'zone_F', zones);
            });
            defaultSpaceProperties.forEach((dsp: DefaultSpaceProperties) => {
                model.bindById(dsp, 'ceilingComposition_F', zones);
                model.bindById(dsp, 'ceilingComposition_I', zones);
                model.bindById(dsp, 'floorComposition_F', structureProperties);
                model.bindById(dsp, 'floorComposition_I', structureProperties);
                dsp.roofWindows = roofWindows.filter(rw => (<any>rw.spaceProperties) === dsp.externalId);
                model.bindById(dsp, 'zone_I', zones);
                model.bindById(dsp, 'zone_F', zones);
            });
            soilHeight.forEach((sh: SoilHeight) => {
                model.bindById(sh, 'location', points);
            });
            structureProperties.forEach((sp: StructureProperties) => {
                sp.compositionExterior.forEach((materialLayer: MaterialLayer) => {
                    materialLayer.strips.forEach(materialStrip => {
                        model.bindById(materialStrip, 'material', model.Materials);
                    });
                });
                sp.compositionInterior.forEach((materialLayer: MaterialLayer) => {
                    materialLayer.strips.forEach(materialStrip => {
                        model.bindById(materialStrip, 'material', model.Materials);
                    });
                });
            });
            wallSegmentLayers.forEach((wsl: WallSegmentLayers) => {
                const stronglyTypedLayers: WallLayer[] = [];
                wsl.layers.forEach(layer => {
                    model.bindById(layer, 'properties', structureProperties);
                    stronglyTypedLayers.push(Object.assign(new WallLayer(), layer));
                });
                wsl.layers = stronglyTypedLayers;
            });
            zoneDefinitions.forEach((zd: ZoneDefinition) => {
                model.bindById(zd, 'aciProc1', zones);
                model.bindById(zd, 'aciProc2', zones);
                model.bindById(zd, 'defaultSpaceProperties', spaceProperties);
            });
            structureSet.forEach((ss: StructureSet) => {
                model.bindById(ss, 'roofAboveHeatedSpace', structureProperties);
                model.bindById(ss, 'floorUnderUnheatedSpace', structureProperties);
                model.bindById(ss, 'innerFloorConstruction', structureProperties);
                model.bindById(ss, 'roofUnheatedSpace', structureProperties);
                model.bindById(ss, 'floorUnderTerrace', structureProperties);
                model.bindById(ss, 'floorUnderNeighbourZone', structureProperties);
                model.bindById(ss, 'floorBetweenZones', structureProperties);
                model.bindById(ss, 'outerWall', structureProperties);
                model.bindById(ss, 'innerWall', structureProperties);
                model.bindById(ss, 'wallAdjacentGround', structureProperties);
                model.bindById(ss, 'wallAdjacentUnheatedSpace', structureProperties);
                model.bindById(ss, 'wallAdjacentUnheatedGF', structureProperties);
                model.bindById(ss, 'wallAdjacentUnheatedBasement', structureProperties);
                model.bindById(ss, 'outerWallUnheatedSpace', structureProperties);
                model.bindById(ss, 'outerWallUnheatedGF', structureProperties);
                model.bindById(ss, 'outerWallUnheatedBasement', structureProperties);
                model.bindById(ss, 'wallAdjacentUnheatedSpaceSoil', structureProperties);
                model.bindById(ss, 'wallAdjacentUnheatedGFSoil', structureProperties);
                model.bindById(ss, 'wallAdjacentUnheatedBasementSoil', structureProperties);
                model.bindById(ss, 'wallBetweenZones', structureProperties);
                model.bindById(ss, 'wallAdjacentNeighbourZone', structureProperties);
                model.bindById(ss, 'neighbourBuildingAdjacentWall', structureProperties);
                model.bindById(ss, 'wallUnheatedSpaceAdjacentNeighbourHeatedBuilding', structureProperties);
                model.bindById(ss, 'floorAboveTerrain', structureProperties);
                model.bindById(ss, 'floorAboveOutdoorSpace', structureProperties);
                model.bindById(ss, 'floorAboveUnheatedSpace', structureProperties);
                model.bindById(ss, 'floorAboveUnheatedGF', structureProperties);
                model.bindById(ss, 'floorAboveUnheatedBasement', structureProperties);
                model.bindById(ss, 'floorAboveTerrainUnheatedSpace', structureProperties);
                model.bindById(ss, 'floorAboveTerrainUnheatedGF', structureProperties);
                model.bindById(ss, 'floorAboveTerrainUnheatedBasement', structureProperties);
                model.bindById(ss, 'floorAboveOutdoorSpaceUnheatedSpace', structureProperties);
                model.bindById(ss, 'floorAboveOutdoorSpaceUnheatedGF', structureProperties);
                model.bindById(ss, 'floorAboveOutdoorSpaceUnheatedBasement', structureProperties);
                model.bindById(ss, 'floorAboveNeighbourZone', structureProperties);
            });

            floorDefinition.forEach((fd: FloorDefinition) => {
                model.bindById(fd, 'floorComposition', structureProperties);
            });

            floorProperties.forEach((fp: FloorProperties) => {
                model.bindById(fp, 'location', points);
                model.bindById(fp, 'floorDefinition', floorDefinition);
            });

            roofDefinition.forEach((rd: RoofDefinition) => {
                model.bindById(rd, 'roofComposition', structureProperties);
            });

            roofProperties.forEach((rp: RoofProperties) => {
                model.bindById(rp, 'roofDefinition', roofDefinition);
                model.bindById(rp, 'ridgeStart', points);
                model.bindById(rp, 'ridgeEnd', points);
                model.bindById(rp, 'slopeVectorStart', points);
                model.bindById(rp, 'slopeVectorEnd', points);
            });

            defaults.forEach((d: Defaults) => {
                model.bindById(d, 'structureSet', structureSet);
                model.bindById(d, 'defaultWindow', defaultWindows);
                model.bindById(d, 'defaultDoor', defaultDoors);
                model.bindById(d, 'defaultSpaceProperties', defaultSpaceProperties);
            });

            project.buildings = buildings;
            project.buildings.forEach((building: Building) => {
                building.project = project;
                building.storeys = storeys.filter(s => building.externalId === (<any>s.building));
                building.zones = zones.filter(z => building.externalId === (<any>z.building));
                building.defaults = defaults[0];
                //model.bindById(building, 'defaults', defaults); // Building nemá v DB odkaz na default

                building.storeys.forEach((storey: Storey) => {
                    storey.project = project;
                    storey.entityType = EntityType.Storey;
                    storey.points = points.filter(p => <any>p.storey === storey.externalId);
                    storey.points.forEach(p => p.storey = storey);
                    storey.auxLines = auxLines.filter(l => <any>l.storey === storey.externalId);
                    storey.auxLines.forEach(l => l.storey = storey);
                    storey.walls = walls.filter(w => <any>w.storey === storey.externalId);
                    storey.walls.forEach(w => w.storey = storey);
                    storey.windows = windows.filter(w => <any>w.storey === storey.externalId);
                    storey.windows.forEach(w => w.storey = storey);
                    storey.doors = doors.filter(d => <any>d.storey === storey.externalId);
                    storey.doors.forEach(d => d.storey = storey);
                    storey.spaceProperties = spaceProperties.filter(sp => <any>sp.storey === storey.externalId);
                    storey.spaceProperties.forEach(p => p.storey = storey);
                    storey.roofWindows = roofWindows.filter(rw => <any>rw.storey === storey.externalId);
                    storey.roofWindows.forEach(w => w.storey = storey);
                    storey.soilHeight = soilHeight.filter(sh => <any>sh.storey === storey.externalId);
                    storey.soilHeight.forEach(sh => sh.storey = storey);
                    storey.floorProperties = floorProperties.filter(fp => <any>fp.storey === storey.externalId);
                    storey.floorProperties.forEach(fp => fp.storey = storey);
                    storey.roofProperties = roofProperties.filter(rp => <any>rp.storey === storey.externalId);
                    storey.roofProperties.forEach(rp => rp.storey = storey);
                    
                    //TODO: dočasné riešenie, v DB sa neukladajú odkazy na defaulty ani do Building, ani do Storey entity
                    //a nedá sa použiť bindById metóda (building.defaults = null - má tam byť externalId)
                    storey.defaults = defaults[0];
                    storey.defaults.defaultDoor.storey = storey;
                    storey.defaults.defaultWindow.storey = storey;
                    storey.defaults.defaultSpaceProperties.storey = storey;

                    storey.borderSegments = borderSegments.filter(bs => <any>bs.storey === storey.externalId);
                    storey.borderSegments.forEach(bs => bs.storey = storey);
                });
            });
            project.wallSegmentLayers = wallSegmentLayers;
            project.shieldings = shieldings;
            project.structureProperties = structureProperties;
            project.zones = zones;
            project.zoneDefinitions = zoneDefinitions;
            project.structureSet = structureSet;
            project.roofDefinition = roofDefinition;
            project.floorDefinition = floorDefinition;
            project.defaults = defaults[0];
            //model.bindById(project, 'defaults', defaults);

            localStorage.setItem('OekoplanBV_SelectedProject', project.externalId);
            model.selectedProject = project;
            model.Buildings = project.buildings;
            model.selectedBuilding = this.Buildings.length > 0 ? this.Buildings[0] : null;
            model.Storeys = this.selectedBuilding != null ? this.selectedBuilding.storeys : [];
            model.selectedStorey = this.Storeys.length > 0 ? this.Storeys[0] : null;
            model.Defaults = defaults;

            model.SelectedProject$.next(project);
            model.SelectedBuilding$.next(this.selectedBuilding);
            model.SelectedStorey$.next(this.selectedStorey);
            this.updateStructureKinds(StoreyEntityState.EXISTING_AND_NEW);
        });
    }

    public AddEntity(entity: BaseEntity): Promise<any> {
        return this.AddEntities([[entity]]);
    }

    public AddEntities(entityArrays: BaseEntity[][]): Promise<any> {
        if (!this.undoredo.processingUndoRedoAction) { this.undoredo.trackUndoRedo(entityArrays, UndoRedoAction.ADD); }
        // const urEntities: Array<BaseEntity> = [];
        return entityArrays.reduce((promise, entities) => promise.then(_ => {
            const calls: Observable<boolean>[] = [];
            entities.forEach(entity => {
                if (entity instanceof StoreyEntity) { entity.storey = this.selectedStorey; }
                if (entity instanceof ProjectEntity) { entity.project = this.selectedProject; }
                if (CommonTools.TryAddItem(this.getEntityArray(entity), entity)) {
                    const dto = this.updateReferences(entity);        // musim pouzit DTO, abych nezrusil reference u objektu z modelu
                    calls.push(this.backendService.saveEntity(dto));
                    // if (!this.undoredo.undoRedoAction) { urEntities.push(dto); }
                }
            });
            return forkJoin(calls).toPromise();
        }), Promise.resolve([true])/*.then(() => {if (!this.undoredo.undoRedoAction) { this.undoredo.trackAdd(urEntities); } })*/);
    }

    public CloneEntitiesToStoreys(entityArrays: StoreyEntity[], storeys: Storey[]): Promise<any> {
        const calls: Observable<boolean>[] = [];
        storeys.forEach(storey => {
            entityArrays.forEach(entity => {
                const newEntity = ModelService.deepCopy(entity);
                newEntity.storey = storey;
                if (CommonTools.TryAddItem(this.getEntityArray(newEntity, storey), newEntity)) {
                    const dto = this.updateReferences(newEntity);        // musim pouzit DTO, abych nezrusil reference u objektu z modelu
                    calls.push(this.backendService.saveEntity(dto));
                }
            });
        });
        return forkJoin(calls).toPromise();
    }

    public UpdateEntity(entity: any): Promise<boolean> {
        const dto = this.updateReferences(entity);
        return this.backendService.UpdateEntity(dto).toPromise();
    }

    public RemoveEntityByExternalId(entityType: EntityType, externalId: string) {
        this.RemoveEntities([this.getEntityByExternalId(entityType, externalId)]);
    }

    public RemoveEntities(entities: BaseEntity[]) {
        if (!this.undoredo.processingUndoRedoAction) { this.undoredo.trackUndoRedo(entities, UndoRedoAction.REMOVE); }
        if (entities.length > 0) {
            Promise.all(entities.map(e => this.RemoveEntity(e))).then(data => {
                this.clearUnusedPoints(this.selectedStorey);
                this.SelectedStorey$.next(this.selectedStorey);
            });
        }
    }

    public RemoveEntity(entity: BaseEntity): Promise<boolean> {
        if (entity instanceof Point) {
            return Promise.resolve(false);
        } else {
            const array = this.getEntityArray(entity);
            if (CommonTools.TryDeleteItem(array, entity)) {
                return this.backendService.deleteEntity(entity).toPromise();
            } else {
                return Promise.resolve(false);
            }
        }
    }
    private clearUnusedPoints(storey: Storey): void {
        const usedPoints: Point[] = this.getAllUsedPoints(storey);
        const pointsToDelete: Point[] = storey.points.filter(x => !usedPoints.includes(x));
        pointsToDelete.forEach(point => {
            if (CommonTools.TryDeleteItem(storey.points, point)) {
                this.backendService.deleteEntity(point);
            }
        });
    }

    public SplitSegmentEntity(segment: SegmentEntity, points: { x: number, y: number }[]): Promise<SegmentEntity[]> {
        const s1 = new Point2D(segment.start);
        const s2 = new Point2D(segment.end);
        const length = s1.distance(s2);
        const pts = points
            .map(p => new Point2D(p).perpendicularToLine(s1, s2))   // prumet bodu na segment
            .filter(p => p.distance(s1) > 0 && p.distance(s1) < length && p.distance(s2) > 0 && p.distance(s2) < length)    // odstraneni bodu mimo vnitrek segmentu
            .sort((a, b) => a.distance(s1) - b.distance(s1))   // setrideni dle vzdalenosti od s1
            .map(p => { const result = new Point(); result.x = Math.round(p.x); result.y = Math.round(p.y); return result; });    // konverze zpet na modelovy typ Point

        pts.unshift(segment.start);
        pts.push(segment.end);
        const segments = [];
        for (let i = 1; i < pts.length; i++) {
            const newSegment = this.backendService.createInstance(segment.entityType) as SegmentEntity;
            Object.assign(newSegment, segment);
            newSegment.externalId = uuid();
            newSegment.start = pts[i - 1];
            newSegment.end = pts[i];
            segments.push(newSegment);
        }

        return new Promise((resolve, reject) => {
            this.RemoveEntity(segment)
                .then(_ => {
                    this.AddEntities([pts, segments])
                        // tslint:disable-next-line: no-shadowed-variable
                        .then(_ => resolve(segments))
                        // tslint:disable-next-line: no-shadowed-variable
                        .catch(_ => resolve(null));
                })
                .catch(_ => reject(null));
        });
    }

    private getAllUsedPoints(storey: Storey) {
        const result: Point[] = [];
        storey.auxLines.forEach(item => { result.push(item.start); result.push(item.end); });
        storey.walls.forEach(item => { result.push(item.start); result.push(item.end); });
        storey.borderSegments.forEach(item => { result.push(item.start); result.push(item.end); });
        storey.spaceProperties.forEach(item => { result.push(item.location); });
        storey.floorProperties.forEach(item => { result.push(item.location); });
        storey.roofProperties.forEach(item => { result.push(...item.edgePolygon); result.push(item.ridgeStart); result.push(item.ridgeEnd); result.push(item.slopeVectorStart); result.push(item.slopeVectorEnd); });
        storey.soilHeight.forEach(item => { result.push(item.location); });
        return result;
    }
    private getEntityArray(entity: BaseEntity, storey: Storey = null, building: Building = null, project: Project = null): BaseEntity[] {
        switch (entity.entityType) {
            case EntityType.Address: return this.Addresses;
            case EntityType.Company: return this.Companies;
            case EntityType.User: return this.Users;
            case EntityType.Project: return this.Projects;
            case EntityType.DoorProperties: return this.DoorProperties;
            case EntityType.WindowProperties: return this.WindowProperties;
            case EntityType.Building: return (project ? project : this.selectedProject).buildings;
            case EntityType.Storey: return (building ? building : this.selectedBuilding).storeys;
            case EntityType.Point: return (storey ? storey : this.selectedStorey).points;
            case EntityType.WallSegmentLayers: return (project ? project : this.selectedProject).wallSegmentLayers;
            case EntityType.AuxLine: return (storey ? storey : this.selectedStorey).auxLines;
            case EntityType.WallSegment: return (storey ? storey : this.selectedStorey).walls;
            case EntityType.WindowSegment: return (storey ? storey : this.selectedStorey).windows;
            case EntityType.DoorSegment: return (storey ? storey : this.selectedStorey).doors;
            case EntityType.SpaceProperties: return (storey ? storey : this.selectedStorey).spaceProperties;
            case EntityType.StructureProperties: return (project ? project : this.selectedProject).structureProperties;
            case EntityType.StructureSet: return (project ? project : this.selectedProject).structureSet;
            case EntityType.RoofDefinition: return (project ? project : this.selectedProject).roofDefinition;
            case EntityType.FloorDefinition: return (project ? project : this.selectedProject).floorDefinition;
            case EntityType.SoilHeight: return (storey ? storey : this.selectedStorey).soilHeight;
            case EntityType.FloorProperties: return (storey ? storey : this.selectedStorey).floorProperties;
            case EntityType.Defaults: return this.Defaults;
            case EntityType.SoilHeight: return (storey ? storey : this.selectedStorey).soilHeight;
            case EntityType.FloorProperties: return (storey ? storey : this.selectedStorey).floorProperties;
            case EntityType.Border: return (storey ? storey : this.selectedStorey).borderSegments;
            case EntityType.RoofProperties: return (storey ? storey : this.selectedStorey).roofProperties;
            case EntityType.Zone: return (project ? project : this.selectedProject).zones;
            case EntityType.Ventilation: return (project ? project : this.selectedProject).ventilations;
            case EntityType.DefaultDoor: return this.Defaults[0] ? [this.Defaults[0].defaultDoor] : [];
            case EntityType.DefaultWindow: return this.Defaults[0] ? [this.Defaults[0].defaultWindow] : [];
            case EntityType.DefaultSpaceProperties: return this.Defaults[0] ? [this.Defaults[0].defaultSpaceProperties] : []
            //case EntityType.DefaultWall: return (storey ? storey : this.selectedStorey).walls;
            default: throw new Error('Unable to add instance type \'' + entity.entityType + '\' to the storey.');
        }
    }
    private updateReferences<T extends BaseEntity>(entity: T): T {
        const DTO = { ...entity };
        if (entity instanceof ProjectEntity) {
            DTO['project'] = <Project>{ externalId: this.selectedProject.externalId };
        }

        if (entity instanceof Storey) {
            DTO['building'] = <Building>{ externalId: this.selectedBuilding.externalId };
        }

        if (entity instanceof StoreyEntity) {
            DTO['storey'] = <Storey>{ externalId: entity.storey ? entity.storey.externalId : this.selectedStorey.externalId };
        }

        if (entity instanceof StructureProperties) {
            DTO['compositionExterior'].forEach(materialLayer => {
                materialLayer['project'] = { externalId: this.selectedProject.externalId };
                materialLayer.strips.forEach(materialStrip => {
                    materialStrip['project'] = { externalId: this.selectedProject.externalId };
                    materialStrip['material'] = { externalId: materialStrip.material.externalId };
                });
            });
            DTO['compositionInterior'].forEach(materialLayer => {
                materialLayer['project'] = { externalId: this.selectedProject.externalId };
                materialLayer.strips.forEach(materialStrip => {
                    materialStrip['project'] = { externalId: this.selectedProject.externalId };
                    materialStrip['material'] = { externalId: materialStrip.material.externalId };
                });
            });
            return DTO;
        }
        if (entity instanceof WallSegmentLayers) {
            DTO['layers'].forEach(wallLayer => {
                wallLayer['project'] = { externalId: this.selectedProject.externalId };
                wallLayer['properties'] = { externalId: wallLayer.properties.externalId };
            });
            return DTO;
        }

        Object.keys(DTO).forEach(property => {
            if (DTO[property] && DTO[property].externalId) {
                DTO[property] = { externalId: DTO[property].externalId };
            }
            if (Array.isArray(DTO[property])) {
                const updatedArray = [];
                DTO[property].forEach(item => updatedArray.push(item.externalId ? { externalId: item.externalId } : item));
                DTO[property] = updatedArray;
            }
        });
        return DTO;
    }

    public SelectProjectByExternalId(externalId: string): void {
        try {
            this.loadProject(externalId);
        } catch {
            // intentionally empty
        }
    }
    public SelectBuildingByExternalId(externalId: string): void {
        try {
            const building = this.Buildings.filter(b => b.externalId === externalId)[0];
            if (building) { this.SelectedBuilding = building; }
            this.updateStructureKinds(StoreyEntityState.EXISTING_AND_NEW);
        } catch {
            // intentionally empty
        }
    }
    public SelectStoreyByExternalId(externalId: string): void {
        try {
            const storey = this.Storeys.filter(s => s.externalId === externalId)[0];
            if (storey) { this.SelectedStorey = storey; }
        } catch {
            // intentionally empty
        }
    }

    public getEntityByExternalId<T>(entityType: EntityType, externalId: string): T {
        let result: any;
        switch (entityType) {
            case EntityType.Project: result = this.Projects.find(i => i.externalId === externalId); break;
            case EntityType.WallSegmentLayers: result = this.selectedProject.wallSegmentLayers.find(i => i.externalId === externalId); break;
            case EntityType.Shielding: result = this.selectedProject.shieldings.find(i => i.externalId === externalId); break;
            case EntityType.StructureProperties: result = this.selectedProject.structureProperties.find(i => i.externalId === externalId); break;
            case EntityType.Ventilation: result = this.selectedProject.ventilations.find(i => i.externalId === externalId); break;
            case EntityType.Zone: result = this.selectedProject.zones.find(i => i.externalId === externalId); break;
            case EntityType.ZoneDefinition: result = this.selectedProject.zoneDefinitions.find(i => i.externalId === externalId); break;
            case EntityType.Material: result = this.Materials.find(i => i.externalId === externalId); break;
            case EntityType.WindowProperties: result = this.WindowProperties.find(i => i.externalId === externalId); break;
            case EntityType.DoorProperties: result = this.DoorProperties.find(i => i.externalId === externalId); break;
            case EntityType.StructureSet: result = this.selectedProject.structureSet.find(i => i.externalId === externalId); break;
            case EntityType.RoofDefinition: result = this.selectedProject.roofDefinition.find(i => i.externalId === externalId); break;
            case EntityType.FloorDefinition: result = this.selectedProject.floorDefinition.find(i => i.externalId === externalId); break;
            case EntityType.Defaults: result = this.Defaults.find(i => i.externalId === externalId); break;
            case EntityType.Building: result = this.selectedProject.buildings.find(i => i.externalId === externalId); break;
            case EntityType.Storey: result = this.Storeys.find(i => i.externalId === externalId); break;
            case EntityType.SpaceProperties: result = this.selectedStorey.spaceProperties.find(i => i.externalId === externalId); break;
            case EntityType.DefaultSpaceProperties: result = this.Defaults[0].defaultSpaceProperties.externalId == externalId ? this.Defaults[0].defaultSpaceProperties : null ; break;
            default: throw new Error('Unable to get instance type \'' + entityType + '\' by externalID.');
        }
        return result;
    }

    private bindById<T extends { externalId: any }>(parent: any, propertyName: string, array: T[]) {
        if (parent[propertyName]) {
            const externalId = parent[propertyName];
            let boundValue = array.filter(item => item.externalId === externalId)[0];
            if (!boundValue && array.length > 0) { boundValue = array[0]; }
            parent[propertyName] = boundValue;
        }
    }

    public generateName(entity: BaseEntity): string {
        let entityName: string;
        const entityNumber: string = `${this.getEntityArray(entity).length + 1}`.padStart(3, '0');

        switch (entity.entityType) {
            case EntityType.Building: entityName = 'Budova'; break;
            case EntityType.Storey: entityName = 'Podlaží'; break;
            case EntityType.Point: entityName = 'P'; break;
            case EntityType.WallSegmentLayers: entityName = 'WSL'; break;
            case EntityType.AuxLine: entityName = 'AL'; break;
            case EntityType.WallSegment: entityName = 'WS'; break;
            case EntityType.WindowSegment: entityName = 'WIS'; break;
            case EntityType.DoorSegment: entityName = 'DS'; break;
            case EntityType.SpaceProperties: entityName = 'SP'; break;
            case EntityType.StructureProperties: entityName = 'STP'; break;
            case EntityType.StructureSet: entityName = 'SS'; break;
            case EntityType.RoofDefinition: entityName = 'RD'; break;
            case EntityType.FloorDefinition: entityName = 'FD'; break;
            case EntityType.SoilHeight: entityName = 'SH'; break;
            case EntityType.FloorProperties: entityName = 'FP'; break;
            case EntityType.Defaults: entityName = 'D'; break;
            case EntityType.Border: entityName = 'BR'; break;
            case EntityType.RoofProperties: entityName = 'RP'; break;
            case EntityType.Zone: entityName = 'Z'; break;
            case EntityType.Ventilation: entityName = 'V'; break;
        }
        return entityName + entityNumber;
    }

    public updateStructureKinds(state: StoreyEntityState) {
        // TODO:
        return this.backendService.getStructureKinds(this.selectedBuilding, state);
    }

    public getDefaults(): Defaults {
        if (this.SelectedStorey.defaults) {
            return this.SelectedStorey.defaults;
        } else if (this.SelectedBuilding.defaults) {
            return this.SelectedBuilding.defaults;
        } else {
            return this.SelectedProject.defaults;
        }
    }

}
