import { Component, OnDestroy, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { EntityType } from '../../models/enums/entityType';
import { THERMAL_REGIME_OPTIONS } from '../../models/enums/Space-properties-enums.model';
import { Storey } from '../../models/storey.model';
import { Zone } from '../../models/zone.model';
import { SpacePropertiesEditorComponent } from '../components/space-properties-editor/space-properties-editor.component';
import { CatalogueListColumn } from '../readonly-list.component/readonly-list.component';
import { SpaceProperties } from './../../models/space-properties.model';
import { ModelService } from './../../services/model.service';

@Component({
    selector: 'app-space-properties-list',
    templateUrl: './space-properties-list.component.html',
    styleUrls: ['./space-properties-list.component.scss']
})
export class SpacePropertiesListComponent implements OnInit, OnDestroy {
    dataSource: SpaceProperties[] = [];
    subscrition: Subscription;
    constructor(private model: ModelService,
        private mbdModalService: MDBModalService) {
    }

    ngOnInit() {
        this.subscrition = this.model.SelectedProject$.subscribe(project => {
            const allSpaceProperties = [];
            project.buildings.forEach(building => {
                building.storeys.forEach(storey => allSpaceProperties.push(...storey.spaceProperties));
            })
            this.dataSource = allSpaceProperties.filter(sp => sp.location); // chceme pouze realne SpaceProperties. Ne ty z Defaults, ktere maji Location = undefined
        });
    }

    defineColumns(): CatalogueListColumn[] {
        const columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = 'Jméno';
        column.dataSourceColumnName = 'code';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Popis';
        column.dataSourceColumnName = 'description';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Podlaží';
        column.dataSourceColumnName = 'storey';
        column.columnValueTranslator = (storey: Storey) => {
            return storey.description;
        };
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Zóna';
        column.dataSourceColumnName = 'zone';
        column.columnValueTranslator = (zone: Zone) => {
            return zone.description;
        };
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Režim vytápění';
        column.dataSourceColumnName = 'thermalRegime';
        column.columnValueTranslator = (columnValue: any) => {
            const typeItems = THERMAL_REGIME_OPTIONS.filter(item => item.value == columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.SpaceProperties, externalId);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    private openModal(externalId?: string) {
        const modalRef = this.mbdModalService.show(SpacePropertiesEditorComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: externalId
            }
        });
    }

    ngOnDestroy() {
        this.subscrition.unsubscribe();
    }
}
