import { Address } from './address.model';
import { BaseEntity } from './base-entity.model';
import { Building } from './building.model';
import { Company } from './company.model';
import { Defaults } from './defaults.model';
import { EntityType } from './enums/entityType';
import { FloorDefinition } from './floor-definition.model';
import { IEntityWithDefaults } from './interfaces/IEntityWithDefaults';
import { PlatformUser } from './platform-user.model';
import { RoofDefinition } from './roof-definition.model';
import { Shielding } from './shielding.model';
import { SoilHeight } from './soil-height.model';
import { StructureProperties } from './structure-properties.model';
import { StructureSet } from './structure-set.model';
import { Ventilation } from './ventilation.model';
import { WallSegmentLayers } from './wall-segment-layers.model';
import { ZoneDefinition } from './zone-definition.model';
import { Zone } from './zone.model';

export class Project extends BaseEntity implements IEntityWithDefaults {
    entityType: EntityType = EntityType.Project;
    title = 'Nový projekt';
    subTitle = 'Nový projekt';
    description = 'Nový projekt';
    startDate: Date = new Date();
    endDate: Date = new Date();
    customer: Company;
    address: Address;
    approvedBy: PlatformUser;
    elaboratedBy: PlatformUser;
    checkedBy: PlatformUser;
    projectStatus: ProjectStatus = ProjectStatus.NEW;
    repeatedThermalBridgesCoefficient = 0;
    latitude = 0;
    intention: ProjectIntention = ProjectIntention.NEW;
    imageUrl = '';
    region = 0;
    thermalConductionSoil = 0;
    soilType: SoilType = SoilType.CLAY;

    buildings: Building[] = [];
    wallSegmentLayers: WallSegmentLayers[] = [];
    shieldings: Shielding[] = [];
    structureProperties: StructureProperties[] = [];
    ventilations: Ventilation[] = [];
    zones: Zone[] = [];
    zoneDefinitions: ZoneDefinition[] = [];
    structureSet: StructureSet[] = [];
    roofDefinition: RoofDefinition[] = [];
    floorDefinition: FloorDefinition[] = [];
    defaults: Defaults = null;
}

export enum SoilType {
    CLAY = 'CLAY',
    SAND = 'SAND',
    ROCK = 'ROCK'
}

export enum ProjectStatus {
    NEW = 'New',
    DENIED = 'Denied',
    CANCELEDBYCLIENT = 'CanceledByClient',
    ASSIGNED = 'Assigned',
    RETURNEDTOASSIGNMENT = 'ReturnedToAssignment',
    PROCESSING = 'Processing',
    WAITINGFORASSETS = 'WaitingForAssets',
    HASBUDGET = 'HasBudget',
    REPAIR = 'Repair',
    CHECKED = 'Checked',
    SENTINPDF = 'SentInPDF',
    PRINTED = 'Printed',
    DISTRIBUTED = 'Distributed',
    INVOICED = 'Invoiced',
    ENDED = 'Ended'
}

export const PROJECT_STATUS_OPTIONS = [
    { value: ProjectStatus.NEW, label: 'Nový', selected: false },
    { value: ProjectStatus.DENIED, label: 'Odmítnuto', selected: false },
    { value: ProjectStatus.CANCELEDBYCLIENT, label: 'Zrušeno klientem', selected: false },
    { value: ProjectStatus.ASSIGNED, label: 'Podepsáno', selected: false },
    { value: ProjectStatus.RETURNEDTOASSIGNMENT, label: 'Vráceno k podpisu', selected: false },
    { value: ProjectStatus.PROCESSING, label: 'Vyřizováno', selected: false },
    { value: ProjectStatus.WAITINGFORASSETS, label: 'Čeká na podklady', selected: false },
    { value: ProjectStatus.HASBUDGET, label: 'Naceněno', selected: false },
    { value: ProjectStatus.REPAIR, label: 'Oprava', selected: false },
    { value: ProjectStatus.CHECKED, label: 'Zkontrolováno', selected: false },
    { value: ProjectStatus.SENTINPDF, label: 'Odesláno v PDF', selected: false },
    { value: ProjectStatus.PRINTED, label: 'Vytištěno', selected: false },
    { value: ProjectStatus.DISTRIBUTED, label: 'Odesláno', selected: false },
    { value: ProjectStatus.INVOICED, label: 'Fakturováno', selected: false },
    { value: ProjectStatus.ENDED, label: 'Ukončeno', selected: false }
];

export enum ProjectIntention {
    NEW = 'NEW',
    RECONSTRUCTION = 'RECONSTRUCTION',
    SELL = 'SELL',
    LEASE = 'LEASE',
    DUTY = 'DUTY',
}

export const PROJECT_INTENTION_OPTIONS = [
    { value: ProjectIntention.NEW, label: 'Novostavba', selected: false },
    { value: ProjectIntention.RECONSTRUCTION, label: 'Rekonstrukce', selected: false },
    { value: ProjectIntention.SELL, label: 'Prodej', selected: false },
    { value: ProjectIntention.LEASE, label: 'Pronájem', selected: false },
    { value: ProjectIntention.DUTY, label: 'Zákonná povinnost', selected: false },
];

export const PROJECT_REGION_OPTIONS = [
    { value: 0, label: 'Region 1', selected: false },
    { value: 1, label: 'Region 2', selected: false },
    { value: 2, label: 'Region 3', selected: false },
    { value: 3, label: 'Region 4', selected: false }
];
