import { BaseEntity } from './base-entity.model';
import { Building } from './building.model';
import { EntityType } from './enums/entityType';
import { ZoneType } from './enums/ZoneType';
import { ZoneDefinition, ZoneDefinitionType } from './zone-definition.model';

export class Zone extends BaseEntity {
    entityType: EntityType = EntityType.Zone;
    building: Building;
    zoneName: string;
    zoneType: ZoneType = ZoneType.FamilyHouse;
    detailedDescription: string = "";
    description = "";
    initialDefinition: ZoneDefinition;
    finalDefinition: ZoneDefinition;
    alternativeDefinitions: ZoneDefinition[];
    inactive = false;
    tus = 0;
    tue = 0;
    ddu = 0;
    dhu = 0;
    l_a = 0;
    thp = 0;
    tho = 0;
    dhh = 0;
    tcp = 0;
    tco = 0;
    dhc = 0;
    vvl = 0;
    vvs = 0;
    n_a = 0;
    dhv = 0;
    tvs = 0;
    tve = 0;
    xsw = 0;
    xss = 0;
    mwa = 0;
    qoc = 0;
    foc = 0;
    qap = 0;
    fap = 0;
    tda = 0;
    tna = 0;
    ema = 0;
    fla = 0;
    kra = 0;
    project: { externalId: string };

    constructor() {
        super();
        this.initialDefinition = new ZoneDefinition();
        this.initialDefinition.state = ZoneDefinitionType.Initial;
        this.finalDefinition = new ZoneDefinition();
        this.finalDefinition.state = ZoneDefinitionType.Final;
        this.alternativeDefinitions = [];
    }
}

export enum HeatingRegime {
    // nevytápěné
    NONHEATED = 'NONHEATED',

    // nezateplené schodiště
    NOTISOLATED_STAIRS = 'NOTISOLATED_STAIRS',

    // temperované
    TEMPERED = 'TEMPERED',

    // zateplené schodiště
    ISOLATED_STAIRS = 'ISOLATED_STAIRS',

    // jiný vytápěný prostor
    OTHER_HEATED_SPACE = 'OTHER_HEATED_SPACE',

    // vytápěný obytný prostor
    HEATED_LIVING_SPACE = 'HEATED_LIVING_SPACE',

    // vytápěné
    HEATED_SPACE = 'HEATED_SPACE',
}

export const HEATING_REGIME_OPTIONS = [
    { value: HeatingRegime.NONHEATED, label: 'nevytápěné' },
    { value: HeatingRegime.NOTISOLATED_STAIRS, label: 'nezateplené schodiště' },
    { value: HeatingRegime.TEMPERED, label: 'temperované' },
    { value: HeatingRegime.ISOLATED_STAIRS, label: 'zateplené schodiště' },
    { value: HeatingRegime.OTHER_HEATED_SPACE, label: 'jiný vytápěný prostor' },
    { value: HeatingRegime.HEATED_LIVING_SPACE, label: 'vytápěný obytný prostor' },
    { value: HeatingRegime.HEATED_SPACE, label: 'vytápěné' },
];
