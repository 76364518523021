import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ICustomDynamicComponent } from 'ngx-property-grid';
import { MDBModalService } from 'angular-bootstrap-md';

export interface IDropdown {
    propName?: string;
    selectedId: string;
    selectedValues?: Array<any>;
    selectedValue?: number;
    options: { name: string, id: string, object: any }[];
    modal: { component: any };
}
export interface IOption { value: any; label: any; selected: boolean; icon: string; }

@Component({
    selector: 'app-dropdown-property',
    templateUrl: './dropdown-property.component.html',
    styleUrls: ['./dropdown-property.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DropdownPropertyComponent implements ICustomDynamicComponent<IDropdown> {
    @Input() value: IDropdown;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private mbdModalService: MDBModalService) {
    }

    onChange(value: any) {
        var selectedValue = value != null ? this.value.options.filter(option => option.object.externalId == value)[0].object : null;
        this.valueChange.emit(selectedValue);
    }


    openModalWithComponent(component, selectedId) {
        if (this.value.selectedId) {
            const modalRef = this.mbdModalService.show(component, {
                containerClass: 'modal fade',
                class: 'modal-dialog modal-dialog-scrollable modal-xl',
                animated: true,
                data: {
                    dynamicExternalId: selectedId,
                    externalId: selectedId
                },
            });
        }
    }
}
