import { AuxLine } from './../../../models/auxline.model';
import { AuthenticationService } from './../../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Commands } from '../../../models/commands';
import { CurrentUser } from '../../../models/current-user.model';
import { Storey } from '../../../models/storey.model';
import { Role } from '../../../models/user.model';
import { DefaultDataService } from '../../../services/defaultdata.service';
import { CommonTools } from '../../../shared/helpers/common-tools';
import { CadEditor } from '../../CadEditor/cadEditor';
import { CadModeTypes } from './../../../models/cad-state.model';
import { ModelService } from './../../../services/model.service';
import { UndoRedoService } from '../../../services/undoredo.service';
interface sideMenuStructure {
    name: string;
    icon: string;
    link?: string;
    collapsed?: boolean;
    menu?: {
        name?: string,
        icon?: string,
        link?: string,
        divider?: boolean,
        type?: string,
        command?: Commands
    }[];
}
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    cadMode$: Observable<CadModeTypes>;
    activeStorey$: Observable<Storey>;
    currentUser: CurrentUser;
    sideMenu: sideMenuStructure[] = [
        {
            name: 'Projekty', icon: 'far fa-building', link: '/projects/list', collapsed: true,
            menu: [
                { name: 'Projekty', icon: 'fas fa-folder-open', link: '/projects/list' },
                { name: 'Budovy', icon: 'fas fa-building', link: '/projects/buildings' },
                { name: 'Podlaží', icon: 'fas fa-bars', link: '/projects/storeys' },
            ]
        },
        {
            name: 'Kreslení a editace', icon: 'fas fa-edit', link: '/cadEditor', collapsed: true,
            menu: [
                { name: 'Pomocné čáry', icon: 'fas fa-grip-lines', command: Commands.DrawAuxLine },
                { name: 'Stěny', icon: 'fas fa-draw-polygon', command: Commands.DrawWall },
                { name: 'Okna', icon: 'fas fa-square', command: Commands.DrawWindow },
                { name: 'Dveře', icon: 'fas fa-door-open', command: Commands.DrawDoor },
                { name: 'Hranice', icon: 'fas fa-border-none', command: Commands.DrawBorder },
                { name: 'Vlastnosti místnosti', icon: 'fas fa-bullseye', command: Commands.DrawSpaceProperties },
                { name: 'Výškový bod terénu', icon: 'fas fa-arrow-up', command: Commands.SoilHeight },
                { name: 'Parametry podlahy', icon: 'fas fa-minus', command: Commands.FloorProperties },
                { name: 'Obrys střechy', icon: 'fas fa-caret-up', command: Commands.RoofProperties },
                { divider: true },
                { name: 'Posun', icon: 'fas fa-arrow-right', command: Commands.Move },
                { name: 'Kopie', icon: 'fas fa-copy', command: Commands.Copy },
                { name: 'Rozdělení segmentu', icon: 'fas fa-cut', command: Commands.SplitSegment },
                { name: 'Kopie mezi podlažími', icon: 'fas fa-clone', command: Commands.CopyToStoreys },
                { name: 'Mazání', icon: 'fas fa-eraser', command: Commands.Delete },
                { divider: true },
                { name: 'Undo', icon: 'fas fa-undo', command: Commands.Undo },
                { name: 'Redo', icon: 'fas fa-redo', command: Commands.Redo },
            ]
        },
        {
            name: 'Vizualizace', icon: 'fas fa-lightbulb', collapsed: true,
            menu: [
                { name: 'Vizualizace místnosti', icon: 'fas fa-lightbulb', command: Commands.ShowRooms },
                { name: 'Vizualizace zón', icon: 'fas fa-lightbulb', command: Commands.ShowZones },
                { name: 'Vizualizace střech', icon: 'fas fa-lightbulb', command: Commands.ShowRoofs },
                { name: 'Vizualizace iso ploch', icon: 'fas fa-lightbulb', command: Commands.ShowIsoAreas }
            ]
        },
        {
            name: 'Knihovna', icon: 'fas fa-list-alt', collapsed: true,
            menu: [
                { name: 'Stavební materiály', icon: 'fas fa-book', link: '/catalogue/materials' },
                { name: 'Typy oken', icon: 'fas fa-book', link: '/catalogue/windows-properties' },
                { name: 'Typy dveří', icon: 'fas fa-book', link: '/catalogue/doors-properties' },
                { divider: true },
                { name: 'Horizontální vrstvy stěn', icon: 'fas fa-book', link: '/catalogue/wall-segment-layers' },
                { name: 'Skladba konstrukce', icon: 'fas fa-book', link: '/catalogue/structure-properties' },
                { name: 'Set skladby konstrukce', icon: 'fas fa-book', link: '/catalogue/structure-set'},
                { name: 'Definice střech', icon: 'fas fa-book', link: '/catalogue/roof-definition'},
                { name: 'Definice podlah', icon: 'fas fa-book', link: '/catalogue/floor-definition'},
                { divider: true },
                { name: 'Místnosti', icon: 'fas fa-book', link: '/catalogue/space-properties' },
                { name: 'Zóny', icon: 'fas fa-book', link: '/catalogue/zone' },
                { name: 'Stínění oken', icon: 'fas fa-book', link: '/catalogue/shielding' },
                { name: 'Větrání', icon: 'fas fa-book', link: '/catalogue/ventilation' },
            ]
        },
        { name: 'Uživatelé', link: '/users', icon: 'fas fa-users', },
    ];
    defaultDataServiceReady = true;

    constructor(
        private model: ModelService,
        private authService: AuthenticationService,
        public cadEditor: CadEditor,
        private translate: TranslateService,
        private undoredo: UndoRedoService
    ) {
        this.translate.addLangs(['en', 'fr', 'ur', 'es', 'it', 'fa', 'de']);
        this.translate.setDefaultLang('en');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr|ur|es|it|fa|de/) ? browserLang : 'en');
        this.onTranslateMenuItems();
    }

    onTranslateMenuItems() {
        let newPlainArr = [];
        const tempMenuItems = this.sideMenu.map(
            item => {
                if (item.menu && item.menu.length > 0) {
                    newPlainArr = newPlainArr.concat(item.menu);
                }
                return item.name;
            });
        const itemsToTranslate = tempMenuItems.concat(newPlainArr.map(item => item.name));

        this.translate.get(itemsToTranslate.filter(Boolean)).subscribe(translations => {
            this.sideMenu.forEach(item => {
                item.name = translations[item.name];
                if (item.menu && item.menu.length > 0) {
                    for (const subMenuItem of item.menu) {
                        if (subMenuItem.name) {
                            subMenuItem.name = translations[subMenuItem.name];
                        }
                    }
                }
            });
        });
    }

    ngOnInit() {
        this.activeStorey$ = this.model.SelectedStorey$;
        this.currentUser = this.authService.getUser();
    }

    hasAccess(item: any) {
        switch (item.name) {
            case 'Users':
                return this.currentUser && this.currentUser.role === Role.ADMINISTRATOR;
            default:
                return true;
        }
    }

    changeLang(language: string) {
        this.translate.use(language);
    }

    onLogout() {
        this.authService.logout();
    }

    getToken() {
        const uuid = CommonTools.GetUUID();
        return uuid;
    }

    onItemClick(command: Commands, event: Event) {
        switch (command) {
            case Commands.DrawAuxLine: this.cadEditor.DrawAuxLine(); break;
            case Commands.DrawWall: this.cadEditor.DrawWall(); break;
            case Commands.DrawWindow: this.cadEditor.DrawWindow(); break;
            case Commands.DrawDoor: this.cadEditor.DrawDoor(); break;
            case Commands.DrawBorder: this.cadEditor.DrawBorder(); break;
            case Commands.DrawSpaceProperties: this.cadEditor.DrawSpaceProperties(); break;
            case Commands.Copy: this.cadEditor.Copy(); break;
            case Commands.CopyToStoreys: this.cadEditor.CopyToStoreys(); break;
            case Commands.Move: this.cadEditor.Move(); break;
            case Commands.SplitSegment: this.cadEditor.SplitSegment(); break;
            case Commands.Delete: this.cadEditor.Delete(); break;
            // Undo Redo
            case Commands.Undo: this.undoredo.Undo(); break;
            case Commands.Redo: this.undoredo.Redo(); break;
            // Vizualizace
            case Commands.ShowRooms: this.cadEditor.showRooms(); break;
            case Commands.ShowZones: this.cadEditor.ShowZones(); break;
            case Commands.ShowRoofs: this.cadEditor.ShowRoofs(); break;
            case Commands.ShowIsoAreas: this.cadEditor.ShowIsoAreas(); break;

            case Commands.SoilHeight: this.cadEditor.DrawSoilHeight(); break;
            case Commands.FloorProperties: this.cadEditor.DrawFloorProperties(); break;
            case Commands.RoofProperties: this.cadEditor.DrawRoofProperties(); break;
        }
        event.preventDefault();
    }
}
