import { AuxLine } from './auxline.model';
import { BorderSegment } from './border-segment.model';
import { Building } from './building.model';
import { Defaults } from './defaults.model';
import { DoorSegment } from './door-segment.model';
import { EntityType } from './enums/entityType';
import { FloorProperties } from './floor-properties.model';
import { IEntityWithDefaults } from './interfaces/IEntityWithDefaults';
import { Point } from './point.model';
import { ProjectEntity } from './project-entity.model';
import { RoofProperties } from './roof-properties.model';
import { RoofWindow } from './roof-window.model';
import { SoilHeight } from './soil-height.model';
import { SpaceProperties } from './space-properties.model';
import { WallSegment } from './wall-segment.model';
import { WindowSegment } from './window-segment.model';

export class Storey extends ProjectEntity implements IEntityWithDefaults {
    entityType: EntityType = EntityType.Storey;
    building: Building;
    description = '';
    elevation = 0;
    backLighted = false;

    points: Point[] = [];
    auxLines: AuxLine[] = [];
    walls: WallSegment[] = [];
    spaceProperties: SpaceProperties[] = [];
    windows: WindowSegment[] = [];
    doors: DoorSegment[] = [];
    roofWindows: RoofWindow[] = [];
    soilHeight: SoilHeight[] = [];
    defaults: Defaults = null;
    floorProperties: FloorProperties[] = [];
    roofProperties: RoofProperties[] = [];
    borderSegments: BorderSegment[] = [];
}
