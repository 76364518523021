import { DrawingSetup } from '../services/drawing-setup';
import { Line, Point2D } from '../shared/helpers/geometry';
import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { StoreyEntityState } from './enums/StoreyEntityState';
import { StructureKind } from './enums/StructureKind';
import { Project } from './project.model';
import { SegmentEntity } from './segment';
import { StructureProperties } from './structure-properties.model';
import { StructureSet } from './structure-set.model';
import { WallSegmentLayers } from './wall-segment-layers.model';

export class WallSegment extends SegmentEntity {
    entityType: EntityType = EntityType.WallSegment;
    wallSegmentLayers: WallSegmentLayers;
    startHeight = 3000;
    endHeight = 3000;
    isBorder: boolean = false;
    structureKind: StructureKind = StructureKind.vnejsi_stena;
    structureSet: StructureSet = null;

    // a Konva.Canvas renderer is passed into the sceneFunc function
    sceneFunc = (context, shape) => {
        if (DrawingSetup.isShapeVisible(shape.attrs.state)) {
            SegmentEntity.drawAxis(context, shape, shape.attrs.start, shape.attrs.end, null, 2);
            SegmentEntity.drawSelectRect(context, shape, shape.attrs.start, shape.attrs.end);

            if (!shape.attrs.isBorder) {
                WallSegment.drawArrows(context, shape);
                const structureProperties = this.getStructureProperties();
                if (DrawingSetup.SelectedStateToDraw != StoreyEntityState.JUST_NEW) {
                    WallSegment.drawOuterLine(context, shape, StoreyEntityState.JUST_EXISTING, structureProperties);
                }
                if (DrawingSetup.SelectedStateToDraw != StoreyEntityState.JUST_EXISTING) {
                    WallSegment.drawOuterLine(context, shape, StoreyEntityState.JUST_NEW, structureProperties);
                }
            }
        }
    };

    static drawOuterLine(context: any, shape: any, drawnState: StoreyEntityState, properties: StructureProperties) {
        let innerOffset = 0;
        let outerOffset = 0;
        if (properties) {
            if (properties.compositionInterior) {
                properties.compositionInterior.forEach((l) => (innerOffset += l.thickness));
            }
            if (properties.compositionExterior) {
                properties.compositionExterior.forEach((l) => (outerOffset -= l.thickness));
            }
        }
        shape.stroke(BaseEntity.getShapeColor(<WallSegment>shape.attrs, 'black'));
        shape.strokeWidth(1 / shape.parent.scale().x);
        context.beginPath();
        const axis = new Line(new Point2D(shape.attrs.start), new Point2D(shape.attrs.end));
        const offsetedLeft = axis.offsetted(innerOffset);
        const offsetedRight = axis.offsetted(outerOffset);
        context.moveTo(offsetedLeft.start.x, offsetedLeft.start.y);
        context.lineTo(offsetedLeft.end.x, offsetedLeft.end.y);
        context.moveTo(offsetedLeft.end.x, offsetedLeft.end.y);
        context.lineTo(offsetedRight.end.x, offsetedRight.end.y);
        context.moveTo(offsetedRight.end.x, offsetedRight.end.y);
        context.lineTo(offsetedRight.start.x, offsetedRight.start.y);
        context.moveTo(offsetedRight.start.x, offsetedRight.start.y);
        context.lineTo(offsetedLeft.start.x, offsetedLeft.start.y);
        context.closePath();
        context.fillStrokeShape(shape);
    }
    static drawArrows(context: any, shape: any) {
        const axis = new Line(new Point2D(shape.attrs.start), new Point2D(shape.attrs.end));
        const start = axis.midpoint();
        const direction = axis.direction().scale(5 / shape.parent.scale().x);
        const interiorColor = shape.attrs.isSelected ? BaseEntity.selectionColor : '#f79797';
        const exteriorColor = shape.attrs.isSelected ? BaseEntity.selectionColor : '#97ccf7';
        WallSegment.drawArrow(context, shape, start, direction, interiorColor);
        WallSegment.drawArrow(context, shape, start, direction.scale(-1), exteriorColor);
    }
    static drawArrow(context: any, shape: any, start: any, direction: any, color: string) {
        const normal = direction.perpendicular();
        const p1 = start.add(direction);
        const p2 = p1.add(normal);
        const p3 = p2.add(direction);
        const p4 = start.add(normal.scale(3));
        const p7 = start.add(direction.scale(-1));
        const p6 = p7.add(normal);
        const p5 = p6.add(direction.scale(-1));

        context.beginPath();
        shape.stroke(color);
        shape.fill(color);
        context.moveTo(start.x, start.y);
        context.lineTo(p1.x, p1.y);
        context.lineTo(p2.x, p2.y);
        context.lineTo(p3.x, p3.y);
        context.lineTo(p4.x, p4.y);
        context.lineTo(p5.x, p5.y);
        context.lineTo(p6.x, p6.y);
        context.lineTo(p7.x, p7.y);
        context.closePath();
        context.fillStrokeShape(shape);
    }

    private getStructureProperties(): StructureProperties {
        if (this.wallSegmentLayers) {
            return this.wallSegmentLayers.layers[0].properties;
        } else if (this.structureSet) {
            return this.structureSet.getStructureProperties(this.structureKind);
        } else if (this.storey.defaults) {
            return this.storey.defaults.structureSet.getStructureProperties(this.structureKind);
        } else if (this.storey.building.defaults) {
            return this.storey.building.defaults.structureSet.getStructureProperties(this.structureKind);
        } else {
            return (<Project>this.storey.project).defaults.structureSet.getStructureProperties(this.structureKind);
        }
    }
}
