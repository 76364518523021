import { EntityType } from './enums/entityType';
import { Company } from './company.model';
import { CatalogueEntity } from './catalogue-entity.model';

export class Material extends CatalogueEntity {
    entityType: EntityType = EntityType.Material;
    group?: MaterialGroupEnum;
    type?: EMaterialType;
    name?: string;
    confirmed: boolean;
    declaredValue?: number;
    overcharge?: number;
    calculatingValue?: number;
    volumeWeight?: number;
    measurableThermalCapacity?: number;
    minDiffusionResistanceFactor?: number;
    maxDiffusionResistanceFactor?: number;
    averageDiffusionResistanceFactor?: number;
    thermalConductivity?: number;
    source?: string;
    description?: string;
    priceWithoutVAT?: number;
    yearOfPriceInfo?: number;
    leadsToConstructionChange: boolean;
    suitableForHomgeneousLayer: boolean;
    carrier: boolean;
    nonAbsorbent: boolean;
    thickness: number;
    fillThickness: number;
    wallThickness: number;
    svtCode: string;
    producer: Company;
    isIsolator: boolean;
}

export enum MaterialGroupEnum {
    CONCRETE = 'CONCRETE',
    CELLULOSE = 'CELLULOSE',
    BRICKS = 'BRICKS',
    BOARD_MATERIALS = 'BOARD_MATERIALS',
    DESTRATIFIER = 'DESTRATIFIER',
    WOOD = 'WOOD',
    PHENOLIC_FOAM = 'PHENOLIC_FOAM',
    FOIL = 'FOIL',
    FOIL_THERMAL_INSULATION = 'FOIL_THERMAL_INSULATION',
    ROCKS = 'ROCKS',
    HYDRO_ISOLATION = 'HYDRO_ISOLATION',
    HEMP = 'HEMP',
    METAL = 'METAL',
    IMPACT_INSULATION = 'IMPACT_INSULATION',
    CALCIUM_SILICATE = 'CALCIUM_SILICATE',
    LINEN = 'LINEN',
    MINERAL_WOOL = 'MINERAL_WOOL',
    FLOOR_LAYERS = 'FLOOR_LAYERS',
    PLASTER = 'PLASTER',
    SHEEP_WOOL = 'SHEEP_WOOL',
    FOAM_GLASS = 'FOAM_GLASS',
    FLOOR = 'FLOOR',
    POLYETHYLENE = 'POLYETHYLENE',
    POLYISOCYANURATE_PIR = 'POLYISOCYANURATE_PIR',
    POLYSTYRENE = 'POLYSTYRENE',
    POLYURETHANE_PUR = 'POLYURETHANE_PUR',
    GLASS_WOOL = 'GLASS_WOOL',
    GLASS = 'GLASS',
    STRAW = 'STRAW',
    WALL_PANELS = 'WALL_PANELS',
    CEILING_SLABS = 'CEILING_SLABS',
    CEILING_PANELS = 'CEILING_PANELS',
    CEILING_INSERTS = 'CEILING_INSERTS',
    ROOFING = 'ROOFING',
    BULK_MATERIALS = 'BULK_MATERIALS',
    CEMENT = 'CEMENT',
    BLOCKS = 'BLOCKS',
    FORMWORK_BLOCKS = 'FORMWORK_BLOCKS',
    AIR_GAP = 'AIR_GAP'
}

export enum EMaterialType {
    // ROCKWOOL
    ROCKWOOL = 'ROCKWOOL',
    // zelezobeton
    REINFORCED_CONCRETE = 'REINFORCED_CONCRETE',
    // krokev
    RAFTER = 'RAFTER',
    // plne, palene
    FULL_BURNT = 'FULL_BURNT',
    // liaporbetonove s vyplni z polystyrenu s primesi grafitu
    LIAPOR_CONCRETE__WITH_POLYSTYRENE_FILLING_WITH_GRAPHITE_ADMIXTURE = 'LIAPOR_CONCRETE__WITH_POLYSTYRENE_FILLING_WITH_GRAPHITE_ADMIXTURE',
    // drevocementove
    WOOD_CEMENT = 'WOOD_CEMENT',
    // drevocementove s polyuretanovou vyplni
    WOOD_CEMENT_WITH_POLYURETHANE_FILLING = 'WOOD_CEMENT_WITH_POLYURETHANE_FILLING',
    // uzavrena, tepelny tok dolu
    CLOSED_HEAT_FLOW_DOWN = 'CLOSED_HEAT_FLOW_DOWN',
    // s podkladem pod taskami (bedneni, lepenka apd.)
    WITH_SUBSTRATE_UNDER_BAGS = 'WITH_SUBSTRATE_UNDER_BAGS',
    // sterk z penoveho skla
    FOAM_GLASS_GRAVEL = 'FOAM_GLASS_GRAVEL',
    // desky 25 az 40 mm
    PALTES_25_TO_40 = 'PALTES_25_TO_40',
    // podlahova
    FLOOR = 'FLOOR',
}

export const MATERIAL_GROUP_OPTIONS = [
    { value: MaterialGroupEnum.CONCRETE, label: 'Concrete' },
    { value: MaterialGroupEnum.CELLULOSE, label: 'Cellulose' },
    { value: MaterialGroupEnum.BRICKS, label: 'Bricks' },
    { value: MaterialGroupEnum.BOARD_MATERIALS, label: 'Board materials' },
    { value: MaterialGroupEnum.DESTRATIFIER, label: 'Destratifier' },
    { value: MaterialGroupEnum.WOOD, label: 'Wood' },
    { value: MaterialGroupEnum.PHENOLIC_FOAM, label: 'Phenolic foam' },
    { value: MaterialGroupEnum.FOIL, label: 'Foil' },
    { value: MaterialGroupEnum.FOIL_THERMAL_INSULATION, label: 'Foil thermal insulation' },
    { value: MaterialGroupEnum.ROCKS, label: 'Rocks' },
    { value: MaterialGroupEnum.HYDRO_ISOLATION, label: 'Hydro isolation' },
    { value: MaterialGroupEnum.HEMP, label: 'Hemp' },
    { value: MaterialGroupEnum.METAL, label: 'Metal' },
    { value: MaterialGroupEnum.IMPACT_INSULATION, label: 'Impact insulation' },
    { value: MaterialGroupEnum.CALCIUM_SILICATE, label: 'Calcium silicate' },
    { value: MaterialGroupEnum.LINEN, label: 'Linen' },
    { value: MaterialGroupEnum.MINERAL_WOOL, label: 'Mineral wool' },
    { value: MaterialGroupEnum.FLOOR_LAYERS, label: 'Floor layers' },
    { value: MaterialGroupEnum.PLASTER, label: 'Plaster' },
    { value: MaterialGroupEnum.SHEEP_WOOL, label: 'Sheep wool' },
    { value: MaterialGroupEnum.FOAM_GLASS, label: 'Foam glass' },
    { value: MaterialGroupEnum.FLOOR, label: 'Floor' },
    { value: MaterialGroupEnum.POLYETHYLENE, label: 'Polyethylene' },
    { value: MaterialGroupEnum.POLYISOCYANURATE_PIR, label: 'Polyisocyanurate pir' },
    { value: MaterialGroupEnum.POLYSTYRENE, label: 'Polystyrene' },
    { value: MaterialGroupEnum.POLYURETHANE_PUR, label: 'Polyurethane pur' },
    { value: MaterialGroupEnum.GLASS_WOOL, label: 'Glass wool' },
    { value: MaterialGroupEnum.GLASS, label: 'Glass' },
    { value: MaterialGroupEnum.STRAW, label: 'Straw' },
    { value: MaterialGroupEnum.WALL_PANELS, label: 'Wall panels' },
    { value: MaterialGroupEnum.CEILING_SLABS, label: 'Ceiling slabs' },
    { value: MaterialGroupEnum.CEILING_PANELS, label: 'Ceiling panels' },
    { value: MaterialGroupEnum.CEILING_INSERTS, label: 'Ceiling inserts' },
    { value: MaterialGroupEnum.ROOFING, label: 'Roofling' },
    { value: MaterialGroupEnum.BULK_MATERIALS, label: 'Bulk materials' },
    { value: MaterialGroupEnum.CEMENT, label: 'Cement' },
    { value: MaterialGroupEnum.BLOCKS, label: 'Blocks' },
    { value: MaterialGroupEnum.FORMWORK_BLOCKS, label: 'Formwork blocks' },
    { value: MaterialGroupEnum.AIR_GAP, label: 'Air gap' },
];

export const MATERIAL_TYPE_OPTIONS = [
    { value: EMaterialType.ROCKWOOL, label: 'ROCKWOOL' },
    { value: EMaterialType.REINFORCED_CONCRETE, label: 'železobeton' },
    { value: EMaterialType.RAFTER, label: 'krokev' },
    { value: EMaterialType.FULL_BURNT, label: 'plné, pálené' },
    { value: EMaterialType.LIAPOR_CONCRETE__WITH_POLYSTYRENE_FILLING_WITH_GRAPHITE_ADMIXTURE, label: 'liaporbetonové s výplní z polystyrénu s příměsí grafitu' },
    { value: EMaterialType.WOOD_CEMENT, label: 'dřevocementové' },
    { value: EMaterialType.WOOD_CEMENT_WITH_POLYURETHANE_FILLING, label: 'dřevocementové s polyuretanovou výplní' },
    { value: EMaterialType.CLOSED_HEAT_FLOW_DOWN, label: 'uzavřená, tepelný tok dolů' },
    { value: EMaterialType.WITH_SUBSTRATE_UNDER_BAGS, label: 's podkladem pod taškami (bednění, lepenka apd.)' },
    { value: EMaterialType.FOAM_GLASS_GRAVEL, label: 'štěrk z pěnového skla' },
    { value: EMaterialType.PALTES_25_TO_40, label: 'desky 25 až 40 mm' },
    { value: EMaterialType.FLOOR, label: 'podlahová' },
];

/**
 * This class defines weather the property should be visible in catalogue detail form.
 */
export class MaterialPropertyVisibility {
    isPropertyVisible(entity: Material, attributeName: string): boolean {
        if (attributeName === 'group') {
            return this.isGroupVisible(entity);
        } else if (attributeName === 'type') {
            return this.isTypeVisible(entity);
        }
        return true;
    }

    private isGroupVisible(entity: Material): boolean {
        return true;
    }

    private isTypeVisible(entity: Material): boolean {
        return true;
    }

}