export enum StructureKind {
    strecha_nad_vytapenym_prostorem = 'strecha_nad_vytapenym_prostorem',
    strop_pod_nevytapenym_prostorem = 'strop_pod_nevytapenym_prostorem',
    vnitrni_stropni_konstrukce = 'vnitrni_stropni_konstrukce',
    strecha_nevytapeneho_prostoru = 'strecha_nevytapeneho_prostoru',
    strop_pod_terasou = 'strop_pod_terasou',
    strop_pod_sousedni_zonou = 'strop_pod_sousedni_zonou',
    strop_mezi_zonami = 'strop_mezi_zonami',
    vnejsi_stena = 'vnejsi_stena',
    vnitrni_pricka = 'vnitrni_pricka',
    stena_prilehla_k_zemine = 'stena_prilehla_k_zemine',
    stena_prilehla_k_nevytapenemu_prostoru = 'stena_prilehla_k_nevytapenemu_prostoru',
    stena_prilehla_k_nevytapenemu_prizemi = 'stena_prilehla_k_nevytapenemu_prizemi',
    stena_prilehla_k_nevytapenemu_suterenu = 'stena_prilehla_k_nevytapenemu_suterenu',
    vnejsi_stena_nevytapeneho_prostoru = 'vnejsi_stena_nevytapeneho_prostoru',
    vnejsi_stena_nevytapeneho_prizemi = 'vnejsi_stena_nevytapeneho_prizemi',
    vnejsi_stena_nevytapeneho_suterenu = 'vnejsi_stena_nevytapeneho_suterenu',
    stena_prilehla_k_zemine_nevytapeneho_prostoru = 'stena_prilehla_k_zemine_nevytapeneho_prostoru',
    stena_prilehla_k_zemine_nevytapeneho_prizemi = 'stena_prilehla_k_zemine_nevytapeneho_prizemi',
    stena_prilehla_k_zemine_nevytapeneho_suterenu = 'stena_prilehla_k_zemine_nevytapeneho_suterenu',
    stena_mezi_zonami = 'stena_mezi_zonami',
    stena_prilehla_k_sousedni_zone = 'stena_prilehla_k_sousedni_zone',
    stena_prilehla_k_sousedni_budove = 'stena_prilehla_k_sousedni_budove',
    stena_nevytapeneho_prostoru_prilehla_k_sousedni_vytapene_budove = 'stena_nevytapeneho_prostoru_prilehla_k_sousedni_vytapene_budove',
    podlaha_nad_terenem = 'podlaha_nad_terenem',
    podlaha_nad_venkovnim_prostorem = 'podlaha_nad_venkovnim_prostorem',
    podlaha_nad_nevytapenym_prostorem = 'podlaha_nad_nevytapenym_prostorem',
    podlaha_nad_nevytapenym_prizemim = 'podlaha_nad_nevytapenym_prizemim',
    podlaha_nad_nevytapenym_suterenem = 'podlaha_nad_nevytapenym_suterenem',
    podlaha_nad_terenem_nevytapeneho_prostoru = 'podlaha_nad_terenem_nevytapeneho_prostoru',
    podlaha_nad_terenem_nevytapeneho_prizemi = 'podlaha_nad_terenem_nevytapeneho_prizemi',
    podlaha_nad_terenem_nevytapeneho_suterenu = 'podlaha_nad_terenem_nevytapeneho_suterenu',
    podlaha_nad_venkovnim_prostorem_nevytapeneho_prostoru = 'podlaha_nad_venkovnim_prostorem_nevytapeneho_prostoru',
    podlaha_nad_venkovnim_prostorem_nevytapeneho_prizemi = 'podlaha_nad_venkovnim_prostorem_nevytapeneho_prizemi',
    podlaha_nad_venkovnim_prostorem_nevytapeneho_suterenu = 'podlaha_nad_venkovnim_prostorem_nevytapeneho_suterenu',
    podlaha_nad_sousedni_zonou = 'podlaha_nad_sousedni_zonou',
}
