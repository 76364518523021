import { EntityType } from './enums/entityType';
import { OpeningProperties } from './opening-properties.model';

export class WindowProperties extends OpeningProperties {
    entityType = EntityType.WindowProperties;
    name: string;
    type: EWindowType;
    glazing?: EWindowGlazing;
    material?: EWindowMaterial;
    description?: string;
    age?: EWindowAge;
    uw?: number;
    glazingInfill?: EWindowGlazingInfill;
    heightOpenableAverage?: number;
    heightOpenableTop?: number;
    heightOpenableBottom?: number;
    heightOpenableRight?: number;
    heightOpenableLeft?: number;
    heightImmovableAverage?: number;
    heightImmovableTop?: number;
    heightImmovableBottom?: number;
    heightImmovableRight?: number;
    heightImmovableLeft?: number;
    distanceFrame: EWindowDistanceFrame;
    ug: number;
    uf: number;
    psi_g: number;
    g: number;
    u_wp: number;
    openable: boolean;
    coverageType: CoverageType;
    svtCode: string;
}

export enum EWindowType {
    // svisla okna
    VERTICAL_WINDOW,
    // sikma okna
    ANGLED_WINDOW,
    // HS portaly
    HS_PORTAL,
    // jina vypln
    OTHER_INFILL
}

export enum EWindowGlazing {
    // jednosklo
    SINGLE_GLASS,
    // zdvojene 2x1-sklo
    DOUBLE_LAYER_GLASS_2x1,
    // zdvojene 1+2-sklo
    DOUBLE_LAYER_GLASS_1_plus_2,
    // dvojite 2x1-sklo
    BIGLASS_2x1,
    // dvojite 1+2-sklo
    BIGLASS_1_pus_2,
    // dvojite 2x2-sklo
    BIGLASS_2x2,
    // dvojsklo
    DOUBLE_GLASS,
    // trojsklo
    TRIPPLE_GLASS,
    // heat mirror
    HEAT_MIRROR,
    // ctyrsklo
    QUADRO_GLASS,
    // luxfero
    LUXFER,
    // polykarbonat
    POLYCARBONATE
}

export enum EWindowDistanceFrame {
    ALUMINIUM,
    STEEL,
    STEEL_STAINLESS,
    CHROMATEC_ULTRA_F,
    TGI,
    SWISSPACER_V,
}

export enum EWindowAge {
    BEFORE_R0,
    AFTER_R0,
}

export enum EWindowGlazingInfill {
    ARGON,
    CRYPTOM,
    AIR,
}

export enum EWindowMaterial {
    PLASTIC,
    WOOD,
    ALUMINIUM,
    WOOD_ALUMINIUM,
    METAL,
    STEEL,
}

export enum CoverageType {
    OUTSIDE_JALOUSIE_WHITE_10 = "OUTSIDE_JALOUSIE_WHITE_10",
    OUTSIDE_JALOUSIE_DARKGREY_10 = "OUTSIDE_JALOUSIE_DARKGREY_10",
    OUTSIDE_JALOUSIE_WHITE_45 = "OUTSIDE_JALOUSIE_WHITE_45",
    OUTSIDE_JALOUSIE_DARKGREY_45 = "OUTSIDE_JALOUSIE_DARKGREY_45",
    VERTICAL_AWNING_WHITE = "VERTICAL_AWNING_WHITE",
    VERTICAL_AWNING_GREY = "VERTICAL_AWNING_GREY",
    INSIDE_JALOUSIE_WHITE_10 = "INSIDE_JALOUSIE_WHITE_10",
    INSIDE_JALOUSIE_DARKGREY_10 = "INSIDE_JALOUSIE_DARKGREY_10",
    INSIDE_JALOUSIE_WHITE_45 = "INSIDE_JALOUSIE_WHITE_45",
    INSIDE_JALOUSIE_DARKGREY_45 = "INSIDE_JALOUSIE_DARKGREY_45",
    TEXTILE_BLIND_WHITE = "TEXTILE_BLIND_WHITE",
    TEXTILE_BLIND_GREY = "TEXTILE_BLIND_GREY",
    FOIL_WHITE = "FOIL_WHITE",
    NONE = "NONE"
}

export const COVERAGE_TYPE_OPTIONS = [
    { value: CoverageType.OUTSIDE_JALOUSIE_WHITE_10, label: 'Vnější žaluzie bílé 10', selected: false, externalId: 0 },
    { value: CoverageType.OUTSIDE_JALOUSIE_DARKGREY_10, label: 'Vnější žaluzie tmavě šedé 10', selected: false, externalId: 1 },
    { value: CoverageType.OUTSIDE_JALOUSIE_WHITE_45, label: 'Vnější žaluzie bílé 45', selected: false, externalId: 2 },
    { value: CoverageType.OUTSIDE_JALOUSIE_DARKGREY_45, label: 'Vnější žaluzie tmavě šedé 45', selected: false, externalId: 3 },
    { value: CoverageType.VERTICAL_AWNING_WHITE, label: 'Vertical awning bílé', selected: false, externalId: 4 },
    { value: CoverageType.VERTICAL_AWNING_GREY, label: 'Vertical awning šedé', selected: false, externalId: 5 },
    { value: CoverageType.INSIDE_JALOUSIE_WHITE_10, label: 'Vnitřní žaluzie bílé 10', selected: false, externalId: 6 },
    { value: CoverageType.INSIDE_JALOUSIE_DARKGREY_10, label: 'Vnitřní žaluzie tmavě šedé 10', selected: false, externalId: 7 },
    { value: CoverageType.INSIDE_JALOUSIE_WHITE_45, label: 'Vnitřní žaluzie bílé 45', selected: false, externalId: 8 },
    { value: CoverageType.INSIDE_JALOUSIE_DARKGREY_45, label: 'Vnitřní žaluzie tmavě šedé 45', selected: false, externalId: 9 },
    { value: CoverageType.TEXTILE_BLIND_WHITE, label: 'Textilní zakrytí bílé', selected: false, externalId: 10 },
    { value: CoverageType.TEXTILE_BLIND_GREY, label: 'Textilní zakrytí šedé', selected: false, externalId: 11 },
    { value: CoverageType.FOIL_WHITE, label: 'Fólie bílá', selected: false, externalId: 12 },
    { value: CoverageType.NONE, label: 'Žádné', selected: false, externalId: 13 },
];

export const WINDOW_TYPE_OPTIONS = [
    { value: EWindowType[EWindowType.VERTICAL_WINDOW], label: 'Svislá okna' },
    { value: EWindowType[EWindowType.ANGLED_WINDOW], label: 'Šikmá okna' },
    { value: EWindowType[EWindowType.HS_PORTAL], label: 'HS portály' },
    { value: EWindowType[EWindowType.OTHER_INFILL], label: 'Jiná výplň' },
];

export const WINDOW_GLAZING_OPTIONS = [
    { value: EWindowGlazing[EWindowGlazing.SINGLE_GLASS], label: 'Jednosklo' },
    { value: EWindowGlazing[EWindowGlazing.DOUBLE_LAYER_GLASS_2x1], label: 'Zdvojené 2x1-sklo' },
    { value: EWindowGlazing[EWindowGlazing.DOUBLE_LAYER_GLASS_1_plus_2], label: 'Zdvojené 1+2-sklo' },
    { value: EWindowGlazing[EWindowGlazing.BIGLASS_2x1], label: 'Dvojité 2x1-sklo' },
    { value: EWindowGlazing[EWindowGlazing.BIGLASS_1_pus_2], label: 'Dvojité 1+2-sklo' },
    { value: EWindowGlazing[EWindowGlazing.BIGLASS_2x2], label: 'Dvojité 2x2-sklo' },
    { value: EWindowGlazing[EWindowGlazing.DOUBLE_GLASS], label: 'Dvojsklo' },
    { value: EWindowGlazing[EWindowGlazing.TRIPPLE_GLASS], label: 'Trojsklo' },
    { value: EWindowGlazing[EWindowGlazing.HEAT_MIRROR], label: 'Heat mirror' },
    { value: EWindowGlazing[EWindowGlazing.QUADRO_GLASS], label: 'Čtyřsklo' },
    { value: EWindowGlazing[EWindowGlazing.LUXFER], label: 'Luxfer' },
    { value: EWindowGlazing[EWindowGlazing.POLYCARBONATE], label: 'Polykarbonát' },
];

export const WINDOW_DISTANCE_FRAME_OPTIONS = [
    { value: EWindowDistanceFrame[EWindowDistanceFrame.ALUMINIUM], label: 'Hliník' },
    { value: EWindowDistanceFrame[EWindowDistanceFrame.STEEL], label: 'Ocel' },
    { value: EWindowDistanceFrame[EWindowDistanceFrame.STEEL_STAINLESS], label: 'Nerez' },
    { value: EWindowDistanceFrame[EWindowDistanceFrame.CHROMATEC_ULTRA_F], label: 'Chromatec ultra F' },
    { value: EWindowDistanceFrame[EWindowDistanceFrame.TGI], label: 'TGI' },
    { value: EWindowDistanceFrame[EWindowDistanceFrame.SWISSPACER_V], label: 'Swisspacer V' },
];

export const WINDOW_AGE_OPTIONS = [
    { value: EWindowAge[EWindowAge.BEFORE_R0], label: 'Před rokem 2003' },
    { value: EWindowAge[EWindowAge.AFTER_R0], label: 'Po roce 2003' },
];

export const WINDOW_GLAZING_INFILL_OPTIONS = [
    { value: EWindowGlazingInfill[EWindowGlazingInfill.ARGON], label: 'Argon' },
    { value: EWindowGlazingInfill[EWindowGlazingInfill.CRYPTOM], label: 'Krypton' },
    { value: EWindowGlazingInfill[EWindowGlazingInfill.AIR], label: 'Vzduch' },
];

export const WINDOW_MATERIAL = [
    { value: EWindowMaterial[EWindowMaterial.PLASTIC], label: 'Plast' },
    { value: EWindowMaterial[EWindowMaterial.WOOD], label: 'Dřevo' },
    { value: EWindowMaterial[EWindowMaterial.ALUMINIUM], label: 'Hliník' },
    { value: EWindowMaterial[EWindowMaterial.WOOD_ALUMINIUM], label: 'Dřevo-Hliník' },
    { value: EWindowMaterial[EWindowMaterial.METAL], label: 'Kov' },
    { value: EWindowMaterial[EWindowMaterial.STEEL], label: 'Ocel' },
];

/**
 * This class defines weather the property should be visible in catalogue detail form.
 */
export class WindowPropertyVisibility {
    isPropertyVisible(windowProperties: WindowProperties, attributeName: string): boolean {
        if (attributeName == 'name') {
        } else if (attributeName == 'type') {
        } else if (attributeName == 'glazing') {
        } else if (attributeName == 'material') {
        } else if (attributeName == 'description') {
        } else if (attributeName == 'age') {
            return this.isAgeVisible(windowProperties);
        } else if (attributeName == 'uw') {
        } else if (attributeName == 'glazingInfill') {
        } else if (attributeName == 'heightOpenableAverage') {
        } else if (attributeName == 'heightOpenableTop') {
        } else if (attributeName == 'heightOpenableBottom') {
        } else if (attributeName == 'heightOpenableRight') {
        } else if (attributeName == 'heightOpenableLeft') {
        } else if (attributeName == 'heightImmovableAverage') {
        } else if (attributeName == 'heightImmovableTop') {
        } else if (attributeName == 'heightImmovableBottom') {
        } else if (attributeName == 'heightImmovableRight') {
        } else if (attributeName == 'heightImmovableLeft') {
        } else if (attributeName == 'distanceFrame') {
        } else if (attributeName == 'ug') {
        } else if (attributeName == 'uf') {
        } else if (attributeName == 'psi_g') {
        } else if (attributeName == 'g') {
        }

        return true;
    }

    isAgeVisible(windowProperties: WindowProperties): boolean {
        if (windowProperties.glazing) {
            const glazing: EWindowGlazing = (<any>EWindowGlazing)[windowProperties.glazing];
            switch (glazing) {
                case EWindowGlazing.DOUBLE_GLASS: return true;
                case EWindowGlazing.LUXFER: return true;
                case EWindowGlazing.POLYCARBONATE: return true;
                default: false;
            }
        }

        return false;
    }
}

/**
 * This class defines the default value for a property. If null, it means that <blank> option should be selected.
 */
export class WindowDefaultGenerator {
    getDefaultValue(windowProperties: WindowProperties, attributeName: string) {
        if (attributeName == 'type') {
            return this.getDefaultType(windowProperties);
        } else if (attributeName == 'glazing') {
            return this.getDefaultGlazing(windowProperties);
        } else if (attributeName == 'material') {
            return this.getDefaultMaterial(windowProperties);
        } else if (attributeName == 'age') {
            return this.getDefaultAge(windowProperties);
        } else if (attributeName == 'uw') {
            return this.getDefaultUw(windowProperties);
        } else if (attributeName == 'glazingInfill') {
            return this.getDefaultGlazingInfill(windowProperties);
        } else if (attributeName == 'heightOpenableAverage') {
            return this.getHeightOpenableAverage(windowProperties);
        } else if (attributeName == 'heightOpenableTop') {
            return this.getHeightOpenableDimension(windowProperties);
        } else if (attributeName == 'heightOpenableBottom') {
            return this.getHeightOpenableDimension(windowProperties);
        } else if (attributeName == 'heightOpenableRight') {
            return this.getHeightOpenableDimension(windowProperties);
        } else if (attributeName == 'heightOpenableLeft') {
            return this.getHeightOpenableDimension(windowProperties);
        } else if (attributeName == 'heightImmovableAverage') {
            return this.getHeightImmovableAverage(windowProperties);
        } else if (attributeName == 'heightImmovableTop') {
            return this.getHeightImmovableDimension(windowProperties);
        } else if (attributeName == 'heightImmovableBottom') {
            return this.getHeightImmovableDimension(windowProperties);
        } else if (attributeName == 'heightImmovableRight') {
            return this.getHeightImmovableDimension(windowProperties);
        } else if (attributeName == 'heightImmovableLeft') {
            return this.getHeightImmovableDimension(windowProperties);
        } else if (attributeName == 'distanceFrame') {
            return this.getDefaultDistanceFrame(windowProperties);
        } else if (attributeName == 'ug') {
            return this.getDefaultUg(windowProperties);
        } else if (attributeName == 'uf') {
            return this.getDefaultUf(windowProperties);
        } else if (attributeName == 'psi_g') {
            return this.getDefaultPsig(windowProperties);
        } else if (attributeName == 'g') {
            return this.getDefaultG(windowProperties);
        }
    }

    getDefaultName(windowProperties: WindowProperties) {
        return 'O1';
    }

    getDefaultType(windowProperties: WindowProperties) {
        return EWindowType[EWindowType.VERTICAL_WINDOW];
    }

    getDefaultGlazing(windowProperties: WindowProperties) {
        return EWindowGlazing[EWindowGlazing.DOUBLE_GLASS];
    }

    getDefaultMaterial(windowProperties: WindowProperties) {
        if (windowProperties.glazing) {
            const glazing: EWindowGlazing = (<any>EWindowGlazing)[windowProperties.glazing];
            switch (glazing) {
                case EWindowGlazing.DOUBLE_GLASS: return EWindowMaterial[EWindowMaterial.PLASTIC];
                case EWindowGlazing.TRIPPLE_GLASS: return EWindowMaterial[EWindowMaterial.PLASTIC];
                case EWindowGlazing.QUADRO_GLASS: return EWindowMaterial[EWindowMaterial.PLASTIC];
                case EWindowGlazing.HEAT_MIRROR: return EWindowMaterial[EWindowMaterial.PLASTIC];
                case EWindowGlazing.LUXFER: return null;
                case EWindowGlazing.SINGLE_GLASS: return EWindowMaterial[EWindowMaterial.WOOD];
                case EWindowGlazing.DOUBLE_LAYER_GLASS_2x1: return EWindowMaterial[EWindowMaterial.WOOD];
                case EWindowGlazing.DOUBLE_LAYER_GLASS_1_plus_2: return EWindowMaterial[EWindowMaterial.WOOD];
                case EWindowGlazing.BIGLASS_2x1: return EWindowMaterial[EWindowMaterial.WOOD];
                case EWindowGlazing.BIGLASS_1_pus_2: return EWindowMaterial[EWindowMaterial.WOOD];
                case EWindowGlazing.BIGLASS_2x2: return EWindowMaterial[EWindowMaterial.WOOD];
                case EWindowGlazing.POLYCARBONATE: return EWindowMaterial[EWindowMaterial.ALUMINIUM];
            }
        }
    }

    getDefaultAge(windowProperties: WindowProperties) {
        return null;
    }

    getDefaultUw(windowProperties: WindowProperties) {
        let result = this.getDefaultParameters(windowProperties);
        if (result && result[0]) {
            return result[0];
        }
    }

    getDefaultG(windowProperties: WindowProperties) {
        let result = this.getDefaultParameters(windowProperties);
        if (result && result[1]) {
            return result[1];
        }
    }

    getDefaultDistanceFrame(windowProperties: WindowProperties) {
        if (!this.filterGlazingAndAge(windowProperties)) {
            return;
        }

        let result = this.getDefaultParameters(windowProperties);
        if (result && result[2]) {
            return result[2];
        }
    }

    getDefaultUf(windowProperties: WindowProperties) {
        if (!this.filterGlazingAndAge(windowProperties)) {
            return;
        }

        let uwAndG = this.getDefaultParameters(windowProperties);
        if (uwAndG && uwAndG[3]) {
            return uwAndG[3];
        }
    }

    getDefaultUg(windowProperties: WindowProperties) {
        if (!this.filterGlazingAndAge(windowProperties)) {
            return;
        }

        let uwAndG = this.getDefaultParameters(windowProperties);
        if (uwAndG && uwAndG[4]) {
            return uwAndG[4];
        }
    }

    private filterGlazingAndAge(windowProperties: WindowProperties): boolean {
        if (!windowProperties.glazing) {
            return false;
        }

        const glazing: EWindowGlazing = (<any>EWindowGlazing)[windowProperties.glazing];

        if (glazing == EWindowGlazing.DOUBLE_GLASS) {
            if (windowProperties.age) {
                const age: EWindowAge = (<any>EWindowAge)[windowProperties.age];
                return age == EWindowAge.BEFORE_R0;
            }
        } else {
            return [EWindowGlazing.TRIPPLE_GLASS, EWindowGlazing.QUADRO_GLASS, EWindowGlazing.HEAT_MIRROR].includes(glazing);
        }

        return false;
    }

    /**
     * Metoda vraci defaultni hodnoty v tomto tvaru [Uw, g, distanceFrame, Uf, Ug]
     */
    public getDefaultParameters(windowProperties: WindowProperties) {
        let type: EWindowType;
        if (windowProperties.type) {
            type = (<any>EWindowType)[windowProperties.type];
        }

        let glazing: EWindowGlazing;
        if (windowProperties.glazing) {
            glazing = (<any>EWindowGlazing)[windowProperties.glazing];
        }

        let material: EWindowMaterial;
        if (windowProperties.material) {
            material = (<any>EWindowMaterial)[windowProperties.material];
        }

        let age: EWindowAge;
        if (windowProperties.age) {
            age = (<any>EWindowAge)[windowProperties.age];
        }

        let distanceFrame: EWindowDistanceFrame;
        if (windowProperties.distanceFrame) {
            distanceFrame = (<any>EWindowDistanceFrame)[windowProperties.distanceFrame];
        }

        if (material != null) {
            if ([EWindowMaterial.WOOD, EWindowMaterial.PLASTIC].includes(material)) {
                switch (glazing) {
                    case EWindowGlazing.SINGLE_GLASS: return [4.5, 0.85, null, null, null];
                    case EWindowGlazing.DOUBLE_LAYER_GLASS_2x1: return [2.5, 0.75, null, null, null];
                    case EWindowGlazing.DOUBLE_LAYER_GLASS_1_plus_2: return [1.7, 0.5, null, null, null];
                    case EWindowGlazing.BIGLASS_2x1: return [2.35, 0.7, null, null, null];
                    case EWindowGlazing.BIGLASS_1_pus_2: return [1.4, 0.5, null, null, null];
                    case EWindowGlazing.BIGLASS_2x2: return [0.85, 0.4, null, null, null];
                }
            } else if ([EWindowMaterial.STEEL, EWindowMaterial.METAL].includes(material)) {
                switch (glazing) {
                    case EWindowGlazing.SINGLE_GLASS: return [5.65, 0.85, null, null, null];
                    case EWindowGlazing.DOUBLE_LAYER_GLASS_2x1: return [3.9, 0.75, null, null, null];
                }
            }
        }

        if (glazing != null && age != null) {
            if (glazing == EWindowGlazing.LUXFER) {
                switch (age) {
                    case EWindowAge.AFTER_R0: return [1.5, 0.75, null, null, null];
                    case EWindowAge.BEFORE_R0: return [3.0, 0.6, null, null, null];
                }
            } else if (glazing == EWindowGlazing.POLYCARBONATE) {
                switch (age) {
                    case EWindowAge.AFTER_R0: return [1.5, 0.6, null, null, null];
                    case EWindowAge.BEFORE_R0: return [3.0, 0.8, null, null, null];
                }
            }
        }

        if (material != null && glazing != null && age != null) {
            if (EWindowGlazing.DOUBLE_GLASS == glazing && age == EWindowAge.BEFORE_R0) {
                switch (material) {
                    case EWindowMaterial.PLASTIC: return [2.5, 0.75, null, null, null];
                    case EWindowMaterial.WOOD: return [2.5, 0.75, null, null, null];
                    case EWindowMaterial.ALUMINIUM: return [3, 0.75, null, null, null];
                    case EWindowMaterial.STEEL: return [3, 0.75, null, null, null];
                    case EWindowMaterial.METAL: return [3, 0.75, null, null, null];
                }
            }
        }

        if (material != null && glazing != null && type != null && distanceFrame != null) {
            switch (material) {
                case EWindowMaterial.PLASTIC:
                    switch (type) {
                        case EWindowType.VERTICAL_WINDOW:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.3, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.1, 0.5];
                                case EWindowGlazing.HEAT_MIRROR: return [null, 0.48, EWindowDistanceFrame[EWindowDistanceFrame.STEEL], 1.1, 0.6];
                                case EWindowGlazing.QUADRO_GLASS: return [null, 0.48, EWindowDistanceFrame[EWindowDistanceFrame.SWISSPACER_V], 0.9, 0.4];
                            };
                            break;
                        case EWindowType.HS_PORTAL:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.3, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.3, 0.5];
                            };
                            break;
                        case EWindowType.ANGLED_WINDOW:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.STEEL], 1.4, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.STEEL], 1.4, 0.5];
                                case EWindowGlazing.HEAT_MIRROR: return [null, 0.48, EWindowDistanceFrame[EWindowDistanceFrame.CHROMATEC_ULTRA_F], 1.4, 0.6];
                            };
                            break;
                    };
                    break;
                case EWindowMaterial.WOOD:
                    switch (type) {
                        case EWindowType.HS_PORTAL:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.4, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.4, 0.5];
                            };
                            break;
                        case EWindowType.VERTICAL_WINDOW:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.4, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.4, 0.5];
                                case EWindowGlazing.HEAT_MIRROR: return [null, 0.48, EWindowDistanceFrame[EWindowDistanceFrame.CHROMATEC_ULTRA_F], 1.3, 0.6];
                                case EWindowGlazing.QUADRO_GLASS: return [null, 0.48, EWindowDistanceFrame[EWindowDistanceFrame.SWISSPACER_V], 1.1, 0.4];
                            }
                            break;
                        case EWindowType.ANGLED_WINDOW:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.STEEL], 1.5, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.STEEL], 1.5, 0.5];
                                case EWindowGlazing.HEAT_MIRROR: return [null, 0.48, EWindowDistanceFrame[EWindowDistanceFrame.CHROMATEC_ULTRA_F], 1.5, 0.6];
                            }
                    }
                    break;
                case EWindowMaterial.WOOD_ALUMINIUM:
                    switch (type) {
                        case EWindowType.VERTICAL_WINDOW:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.3, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.1, 0.5];
                                case EWindowGlazing.HEAT_MIRROR: return [null, 0.48, EWindowDistanceFrame[EWindowDistanceFrame.CHROMATEC_ULTRA_F], 1.1, 0.6];
                                case EWindowGlazing.QUADRO_GLASS: return [null, 0.48, EWindowDistanceFrame[EWindowDistanceFrame.SWISSPACER_V], 1.1, 0.4];
                            }
                            break;
                        case EWindowType.HS_PORTAL:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.3, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.TGI], 1.2, 0.5];
                            };
                            break;
                    }
                    break;
                case EWindowMaterial.ALUMINIUM:
                    switch (type) {
                        case EWindowType.VERTICAL_WINDOW:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.STEEL_STAINLESS], 1.9, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.STEEL_STAINLESS], 1.7, 0.5];
                                case EWindowGlazing.HEAT_MIRROR: return [null, 0.48, EWindowDistanceFrame[EWindowDistanceFrame.CHROMATEC_ULTRA_F], 1.7, 0.6];
                            }
                            break;
                        case EWindowType.HS_PORTAL:
                            switch (glazing) {
                                case EWindowGlazing.DOUBLE_GLASS: return [null, 0.67, EWindowDistanceFrame[EWindowDistanceFrame.STEEL_STAINLESS], 1.9, 1.1];
                                case EWindowGlazing.TRIPPLE_GLASS: return [null, 0.5, EWindowDistanceFrame[EWindowDistanceFrame.STEEL_STAINLESS], 1.7, 0.5];
                            };
                            break;
                    }
                    break;
            }
        }
    }

    getDefaultGlazingInfill(windowProperties: WindowProperties) {
        if (windowProperties.glazing) {
            const glazing: EWindowGlazing = (<any>EWindowGlazing)[windowProperties.glazing];

            let age: EWindowAge;
            if (windowProperties.age) {
                age = (<any>EWindowAge)[windowProperties.age];
            }

            switch (glazing) {
                case (EWindowGlazing.DOUBLE_GLASS): if (age != null && age != EWindowAge.BEFORE_R0) return EWindowGlazingInfill[EWindowGlazingInfill.ARGON]; else break;
                case (EWindowGlazing.TRIPPLE_GLASS): return EWindowGlazingInfill[EWindowGlazingInfill.ARGON];
                case (EWindowGlazing.QUADRO_GLASS): return EWindowGlazingInfill[EWindowGlazingInfill.ARGON];
                case (EWindowGlazing.HEAT_MIRROR): return EWindowGlazingInfill[EWindowGlazingInfill.ARGON];
                case (EWindowGlazing.POLYCARBONATE): return null;
            }
        }
    }

    getHeightOpenableAverage(windowProperties: WindowProperties) {
        if (windowProperties.heightOpenableTop != null || windowProperties.heightOpenableBottom != null
            || windowProperties.heightOpenableRight != null || windowProperties.heightOpenableLeft != null) {
            this.calculateAverageHeight(windowProperties, windowProperties.heightOpenableTop, windowProperties.heightOpenableBottom,
                windowProperties.heightOpenableRight, windowProperties.heightOpenableLeft);
        } else if (windowProperties.glazing) {
            const glazing: EWindowGlazing = (<any>EWindowGlazing)[windowProperties.glazing];

            switch (glazing) {
                case (EWindowGlazing.LUXFER): return 0;
                case (EWindowGlazing.POLYCARBONATE): return 0;
                default: return 120;
            }
        }
    }

    getHeightImmovableAverage(windowProperties: WindowProperties) {
        if (windowProperties.heightImmovableTop != null || windowProperties.heightImmovableBottom != null
            || windowProperties.heightImmovableRight != null || windowProperties.heightImmovableLeft != null) {
            this.calculateAverageHeight(windowProperties, windowProperties.heightImmovableTop, windowProperties.heightImmovableBottom,
                windowProperties.heightImmovableRight, windowProperties.heightImmovableLeft);
        } else if (windowProperties.glazing) {
            const glazing: EWindowGlazing = (<any>EWindowGlazing)[windowProperties.glazing];

            switch (glazing) {
                case (EWindowGlazing.LUXFER): return 0;
                case (EWindowGlazing.POLYCARBONATE): return 0;
                default: return 80;
            }
        }
    }

    private calculateAverageHeight(windowProperties: WindowProperties, top: number, bottom: number, right: number, left: number) {
        if (windowProperties.glazing) {
            let height: number;
            let width: number;

            const type: EWindowType = (<any>EWindowType)[windowProperties.type];
            switch (type) {
                case (EWindowType.VERTICAL_WINDOW): height = 1.48; width = 1.23; break;
                case (EWindowType.ANGLED_WINDOW): height = 1.48; width = 1.23; break;
                case (EWindowType.HS_PORTAL): height = 1.48; width = 1.23; break;
                default: return;
            }

            let glazingArea = (height - top / 1000 - bottom / 1000) * (width - right / 1000 - left / 1000);

            return 1000 * ((width + height) / 2 - Math.pow(Math.pow(width + height, 2) / 4 - width * height * (1 - glazingArea / (width * height)), 0.5)) / 2;
        }
    }

    getHeightOpenableDimension(windowProperties: WindowProperties) {
        if (windowProperties.heightOpenableTop == null && windowProperties.heightOpenableBottom == null
            && windowProperties.heightOpenableRight == null && windowProperties.heightOpenableLeft == null) {
            return 120;
        }
    }

    getHeightImmovableDimension(windowProperties: WindowProperties) {
        if (windowProperties.heightImmovableTop == null && windowProperties.heightImmovableBottom == null
            && windowProperties.heightImmovableRight == null && windowProperties.heightImmovableLeft == null) {
            return 80;
        }
    }

    getDefaultPsig(windowProperties: WindowProperties) {
        if (!this.filterGlazingAndAge(windowProperties)) {
            return;
        }

        if (windowProperties.glazing && windowProperties.material && windowProperties.distanceFrame) {
            const glazing: EWindowGlazing = (<any>EWindowGlazing)[windowProperties.glazing];
            const material: EWindowMaterial = (<any>EWindowMaterial)[windowProperties.material];
            const distanceFrame: EWindowDistanceFrame = (<any>EWindowDistanceFrame)[windowProperties.distanceFrame];

            let valuesMatrix;

            if ([EWindowGlazing.DOUBLE_GLASS, EWindowGlazing.HEAT_MIRROR].includes(glazing)) {
                valuesMatrix = [
                    [0.070, 0.066, 0.081, 0.097],
                    [0.060, 0.060, 0.070, 0.080],
                    [0.051, 0.049, 0.056, 0.064],
                    [0.039, 0.039, 0.043, 0.048],
                    [0.040, 0.040, 0.044, 0.049],
                    [0.032, 0.033, 0.034, 0.037]
                ];
            } else if ([EWindowGlazing.TRIPPLE_GLASS, EWindowGlazing.QUADRO_GLASS].includes(glazing)) {
                valuesMatrix = [
                    [0.075, 0.064, 0.085, 0.097],
                    [0.060, 0.060, 0.070, 0.080],
                    [0.051, 0.048, 0.056, 0.060],
                    [0.038, 0.037, 0.041, 0.043],
                    [0.039, 0.038, 0.042, 0.044],
                    [0.030, 0.031, 0.032, 0.033]
                ];
            }

            if (valuesMatrix) {
                let distanceFrameIndex;
                let materialIndex;

                switch (distanceFrame) {
                    case (EWindowDistanceFrame.ALUMINIUM): distanceFrameIndex = 0; break;
                    case (EWindowDistanceFrame.STEEL): distanceFrameIndex = 1; break;
                    case (EWindowDistanceFrame.CHROMATEC_ULTRA_F): distanceFrameIndex = 2; break;
                    case (EWindowDistanceFrame.TGI): distanceFrameIndex = 3; break;
                    case (EWindowDistanceFrame.SWISSPACER_V): distanceFrameIndex = 4; break;
                }

                switch (material) {
                    case (EWindowMaterial.WOOD): materialIndex = 0; break;
                    case (EWindowMaterial.PLASTIC): materialIndex = 1; break;
                    case (EWindowMaterial.WOOD_ALUMINIUM): materialIndex = 2; break;
                    case (EWindowMaterial.ALUMINIUM): materialIndex = 3; break;
                }

                if (distanceFrameIndex != null && materialIndex != null) {
                    return valuesMatrix[distanceFrameIndex][materialIndex];
                }
            }
        }
    }
}