import { Component } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { FilterType, FILTER_TYPE_OPTIONS } from '../../../models/enums/filterType';
import { IFilter } from '../../../models/interfaces/IFilter';
import { StructurePropertiesFilter } from '../filters/structure-properties-filter/structure-properties-filter';

@Component({
    selector: 'app-model-viewer-filter-modal',
    templateUrl: './model-viewer-filter-modal.component.html',
    styleUrls: ['./model-viewer-filter-modal.component.scss'],
})
export class ModelViewerFilterModalComponent {
    filters: IFilter[] = [];
    newFilterType: FilterType;
    filterTypeOptions = FILTER_TYPE_OPTIONS;
    result$: Subject<IFilter[]>;

    constructor(private mdbModalService: MDBModalService) {
        this.result$ = new Subject();
    }

    public addFilter() {
        switch (this.newFilterType) {
            case FilterType.STRUCTURE_PROPERTIES:
                this.filters.push(new StructurePropertiesFilter());
                break;
        }
        this.newFilterType = null;
    }

    public removeFilter(index: number) {
        this.filters.splice(index, 1);
    }

    public onSave() {
        this.result$.next(this.filters);
        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }

    public onCancel() {
        this.result$.next(null);
        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }
}
