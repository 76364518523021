import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NOTIFICATIONS } from './config';
import { BodyOutputType } from './layout/toast/toast';
import { ToasterConfig } from './layout/toast/toaster-config';
import { ToasterService } from './services/toaster.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public toasterConfig: ToasterConfig = new ToasterConfig({
        showCloseButton: true,
        tapToDismiss: NOTIFICATIONS.tapToDismiss,
        positionClass: 'toast-' + NOTIFICATIONS.position,
        timeout: NOTIFICATIONS.visibilityTime * 1000,
        mouseoverTimerStop: true,
        newestOnTop: NOTIFICATIONS.newestOnTop,
        limit: NOTIFICATIONS.maxNotifications,
        bodyOutputType: BodyOutputType.Component,
    });

    constructor(
        public toasterService: ToasterService,
        public translateService: TranslateService,
    ) { }

    ngOnInit() {
    }
}
