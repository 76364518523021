import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { Company } from '../../../models/company.model';
import { EntityType } from '../../../models/enums/entityType';
import { AddressEditorComponent } from '../address-editor/address-editor.component';
import { ModelService } from './../../../services/model.service';

@Component({
    selector: 'app-company-editor',
    templateUrl: './company-editor.component.html',
    styleUrls: ['./company-editor.component.scss']
})
export class CompanyEditorComponent implements OnInit {
    externalId: string;
    addressExternalId: string;
    isNewCompany: boolean;
    public company: Company = new Company();

    constructor(
        private model: ModelService,
        private mbdModalService: MDBModalService,
    ) { }

    private initData(companyId?: string) {
        if (companyId) {
            this.company = this.model.getEntityByExternalId(EntityType.Company, companyId);
            this.isNewCompany = false;
        } else {
            this.company = new Company();
            this.isNewCompany = true;
        }

        if (this.addressExternalId) {
            this.company.address.externalId = this.addressExternalId;
        }
    }
    onSave() {
        this.saveData();
        this.mbdModalService.hide(1);
    }

    onCancel() {
        this.mbdModalService.hide(1);
    }

    private saveData() {
        if (this.isNewCompany) {
            this.model.AddEntity(this.company);
        } else {
            this.model.UpdateEntity(this.company);
        }
    }
    ngOnInit() {
        this.initData(this.externalId);
    }

    public isNotDefaultPropertyValue(propertyName: string): boolean {
        return false;
    }

    public openModal() {
        this.mbdModalService.show(AddressEditorComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: this.company.address.externalId
            }
        });
    }

}
