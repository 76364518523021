import { Injectable } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import Konva from 'konva';
import { BehaviorSubject } from 'rxjs';
import { BorderSegment } from '../../models/border-segment.model';
import { CadSelection } from '../../models/cadSelection.model';
import { SelectionMode } from '../../models/enums/selectionMode';
import { StoreyEntityState } from '../../models/enums/StoreyEntityState';
import { FloorDefinition } from '../../models/floor-definition.model';
import { FloorProperties } from '../../models/floor-properties.model';
import { LocationEntity } from '../../models/location-entity.model';
import { Point } from '../../models/point.model';
import { PointInput } from '../../models/pointInput';
import { RoofProperties } from '../../models/roof-properties.model';
import { Room } from '../../models/room.model';
import { SegmentEntity } from '../../models/segment';
import { SoilHeight } from '../../models/soil-height.model';
import { SpaceProperties } from '../../models/space-properties.model';
import { StoreyEntity } from '../../models/storey-entity.model';
import { Storey } from '../../models/storey.model';
import { StructurePropertiesType } from '../../models/structure-properties.model';
import { WallSegment } from '../../models/wall-segment.model';
import { BackendService } from '../../services/backend.service';
import { DefaultDataService } from '../../services/defaultdata.service';
import { ModelService } from '../../services/model.service';
import { UndoRedoService } from '../../services/undoredo.service';
import { UserSettingsService } from '../../services/user-settings-service.service';
import { CommonTools } from '../../shared/helpers/common-tools';
import { Point2D, Vector2D } from '../../shared/helpers/geometry';
import { CopyStoreysModalComponent } from '../components/copy-storeys-modal/copy-storeys-modal.component';
import { AuxLine } from './../../models/auxline.model';
import { OsnapMode } from './../../models/pointInput';
import { DrawingSetup } from './../../services/drawing-setup';
import { CallbackCadAction, FirstPointCadAction, NextPointCadAction, NoAction, PointCadAction, SecondPointCadAction, SelectCadAction, SelectManyCadAction, SelectOneCadAction, SinglePointAction } from './cadActions';
import { CadCommand } from './cadCommand';
import { CadStage } from './cadStage';
import { DoorSegment } from '../../models/door-segment.model';
import { WindowSegment } from '../../models/window-segment.model';

@Injectable({
    providedIn: 'root',
})
export class CadEditor {
    public stage: CadStage;
    private activeCommand: CadCommand = new CadCommand([new NoAction()]);
    private selectionMode: SelectionMode = SelectionMode.Many;
    private allowedOsnapModes: OsnapMode[] = [];
    public coordinates = '';

    set inputText(value: string) {
        this._inputText = value;
        this.stage.auxLayer.updateTooltip(value);
    }
    get inputText(): string {
        return this._inputText;
    }
    private _inputText = '';

    private _leadStart: { x: number, y: number } = null;
    set leadStart(value: { x: number, y: number }) {
        this._leadStart = value;
        this.stage.auxLayer.setLeadStart(value);
    }
    get leadStart(): { x: number, y: number } {
        return this._leadStart;
    }
    private _leadEnd: { x: number, y: number } = null;
    set leadEnd(value: { x: number, y: number }) {
        this._leadEnd = value;
        this.stage.auxLayer.setLeadEnd(value);
    }
    get leadEnd(): { x: number, y: number } {
        return this._leadEnd;
    }

    get Selection$(): BehaviorSubject<CadSelection> {
        return this.stage.mainLayer.Selection$;
    }

    get Selection(): CadSelection {
        return this.stage.mainLayer.Selection;
    }
    get CommandPrompt() {
        return this.activeCommand.CurrentAction.prompt;
    }

    constructor(
        private model: ModelService,
        private backendService: BackendService,
        private defaultDataService: DefaultDataService,
        private userSettingsService: UserSettingsService,
        private mbdModalService: MDBModalService
    ) {
    }


    public Init(width: number, heigth: number, containerId: string): void {
        const stageConfig = {
            container: containerId,
            width: width,
            height: heigth,
        };
        this.stage = new CadStage(stageConfig, this.model, this.userSettingsService);

        const container = this.stage.container();
        container.tabIndex = 1;
        container.addEventListener('keydown', this.cadEditorKeyDown.bind(this));
        container.addEventListener('contextmenu', function (e: any) {
            if (e.preventDefault) { e.preventDefault(); }
            if (e.stropPropagation) { e.stropPropagation(); }
            e.cancelBubble = true;
        });
        this.model.SelectedBuilding$.subscribe(building => {
            if (building) {
                const compassElement = document.getElementById('compass');
                compassElement.style.transform = 'rotate(' + (building.azimut ? building.azimut : 0) + 'deg)';
            }
        });

        this.stage.addEventListener('mousedown', this.cadEditorMouseDown.bind(this));
        this.stage.PointInput.subscribe(pointInput => this.handlePointInput(pointInput));

        this.model.SelectedStorey$.subscribe(selectedStorey => this.importStorey(true));
        this.importStorey(true);

        this.model.BacklightedStoreys$.subscribe(backlightedStoreys => this.stage.backlightStorey(backlightedStoreys));
        this.stage.backlightStorey(this.model.BacklightedStoreys);

        DrawingSetup.SelectedStateToDraw$.subscribe(newState => this.stage.batchDraw());
    }

    public importStorey(zoomAll: boolean) {
        if (this.model.SelectedStorey) {
            this.stage.mainLayer.clearEntities();
            this.stage.mainLayer.importStorey(this.model.SelectedStorey);
            if (zoomAll) {
                this.stage.ZoomAll();
                this.resetAuxLayer();
            }
            this.stage.batchDraw();
        }
    }

    private cadEditorKeyDown(event: any): void {
        switch (event.keyCode) {
            case 8: {      // BACKSPACE
                if (this.leadStart != null) {
                    if (this.inputText.length > 0) { this.inputText = this.inputText.slice(0, -1); }
                } else if (this.Selection.entities.length > 0) {
                    this.Delete();
                }
                break;
            }
            case 13: {      // ENTER
                this.handleKeyboardInput(this.inputText);
                break;
            }
            case 27: {      // ESC
                this.cancelAction();
                this.stage.mainLayer.clearSelection();
                break;
            }
            case 46: {      // DELETE
                if (this.Selection.entities.length > 0) {
                    this.Delete();
                }
                break;
            }
            default: {
                if (this.leadStart != null && ((event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode === 188 || event.keyCode === 109 || event.keyCode === 189 || event.keyCode === 222)) {
                    this.inputText = this.inputText + event.key;
                }
                break;
            }
        }
    }

    private cadEditorMouseDown(event: any): void {
        switch (event.buttons) {
            case 2: {   // right
                this.nextAction();
                break;
            }
        }
    }
    private handleKeyboardInput(text: string) {
        try {
            if (text.indexOf('!') > 0) {
                const parts = text.split('!');
                const x = parseInt(parts[0]);
                const y = -parseInt(parts[1]);
                this.handlePointInput(new PointInput(x, y, OsnapMode.NEW, null, null, true, false, false));
            } else if (text.indexOf(',') > 0) {
                const parts = text.split(',');
                const dist = parseInt(parts[0]);
                const angle = parseInt(parts[1]) / (180 / Math.PI);
                const x = this.leadStart.x + dist * Math.cos(angle);
                const y = this.leadStart.y - dist * Math.sin(angle);
                this.handlePointInput(new PointInput(x, y, OsnapMode.NEW, null, null, true, false, false));
            } else {
                const dist = parseInt(text);
                const angle = new Vector2D(this.leadEnd.x - this.leadStart.x, -(this.leadEnd.y - this.leadStart.y)).angle() / (180 / Math.PI);
                const x = this.leadStart.x + dist * Math.cos(angle);
                const y = this.leadStart.y - dist * Math.sin(angle);
                this.handlePointInput(new PointInput(x, y, OsnapMode.NEW, null, null, true, false, false));
            }
            this.inputText = '';
        } catch {

        }
    }
    private handlePointInput(pointInput: PointInput): void {
        if (pointInput.Clicked) {
            if (this.activeCommand.CurrentAction instanceof PointCadAction) {
                if (this.allowedOsnapModes == null || this.allowedOsnapModes.includes(pointInput.PointInputType)) {
                    this.activeCommand.handlePointInput(pointInput);
                    this.leadStart = pointInput.Location;
                    if (this.activeCommand.CurrentAction instanceof SinglePointAction) {
                        this.nextAction();
                        return;
                    } else if (this.activeCommand.CurrentAction instanceof NextPointCadAction) {
                        this.activeCommand.CurrentAction.invokeCallback(this.activeCommand);
                    }
                }
            }
            switch (this.selectionMode) {
                case SelectionMode.Single: {
                    if (pointInput.Entity) {
                        this.stage.mainLayer.selectEntityByClick(pointInput.Entity, pointInput.CtrlKey, pointInput.ShiftKey);
                        this.nextAction();
                        return;
                    }
                    break;
                }
                case SelectionMode.Many: {
                    if (pointInput.Entity) {
                        this.stage.mainLayer.selectEntityByClick(pointInput.Entity, pointInput.CtrlKey, pointInput.ShiftKey);
                    } else {
                        const selectionRectangle = this.stage.auxLayer.setSelectionRectanglePoint(this.stage.getPosition(), true);
                        if (selectionRectangle) { this.stage.mainLayer.selectByRectangle(selectionRectangle, pointInput.CtrlKey || pointInput.ShiftKey); }
                    }
                    break;
                }
            }
        } else {
            if (this.activeCommand.CurrentAction instanceof SecondPointCadAction || this.activeCommand.CurrentAction instanceof NextPointCadAction) {
                this.leadEnd = pointInput.Location;
            }
            if (this.selectionMode === SelectionMode.Many) { this.stage.auxLayer.setSelectionRectanglePoint(this.stage.getPosition(), false); }
        }
        if (pointInput.Location) {
            this.coordinates = this.getCoordinatesString(pointInput.Location);
        }
        this.stage.auxLayer.updateOsnapMark(pointInput);
    }
    private getCoordinatesString(p: { x: number, y: number }): string {
        if (this.leadStart != null) {
            const dx = p.x - this.leadStart.x;
            const dy = -(p.y - this.leadStart.y);
            const dist = Math.sqrt(dx * dx + dy * dy);
            const angle = new Vector2D(dx, dy).angle();
            return p.x.toFixed(0) + ', ' + -p.y.toFixed(0) + '\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0' + dx.toFixed(0) + ', ' + dy.toFixed(0) + '\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0' + dist.toFixed(0) + '@' + angle.toFixed(0) + '°';
        } else {
            return p.x.toFixed(0) + ', ' + -p.y.toFixed(0);
        }
    }

    private removeSpaceHighlighting() {
        this.removeRoomHighlighting();
    }

    private setActiveCommand(command: CadCommand) {
        this.removeSpaceHighlighting();
        command.actions = [new NoAction(), ...command.actions, new NoAction()];
        this.activeCommand = command;
        this.nextAction();
        if (this.activeCommand.CurrentAction instanceof SelectManyCadAction && this.Selection.entities.length > 0) { this.nextAction(); }
    }
    private nextAction(): void {
        if (this.activeCommand.CurrentAction instanceof SelectCadAction) {
            if (!this.activeCommand.handleSelection(this.Selection)) {      // selection is not valid
                this.stage.mainLayer.clearSelection();
                return;
            }
        }

        this.activeCommand.NextAction();
        this.stage.container().style.cursor = this.activeCommand.CurrentAction.cursor;

        this.stage.OsnapActive = (this.activeCommand.CurrentAction instanceof PointCadAction);
        this.allowedOsnapModes = this.activeCommand.CurrentAction.AllowedOsnapModes;
        this.selectionMode = this.activeCommand.CurrentAction.SelectionMode;

        if (this.activeCommand.CurrentAction instanceof NoAction) { this.resetAuxLayer(); }
        if (this.activeCommand.CurrentAction instanceof CallbackCadAction) {
            this.activeCommand.CurrentAction.invokeCallback(this.activeCommand);
            this.nextAction();
        }
    }

    private cancelAction() {
        this.activeCommand = new CadCommand([new NoAction()]);
        this.resetAuxLayer();
    }
    private resetAuxLayer() {
        this.leadStart = null;
        this.stage.auxLayer.hideLeadLine();
        this.stage.auxLayer.hideSelectionRectangle();
        this.inputText = '';
    }

    public DrawAuxLine(): void {
        this.setActiveCommand(new CadCommand([
            new FirstPointCadAction('Zadejte první bod pomocné čáry', null),
            new NextPointCadAction('Zadejte další bod pomocné čáry', null, e => this.drawAuxLine(e)),
        ]));
    }
    private drawAuxLine(command: CadCommand): void {
        const points = (<NextPointCadAction>command.actions[2]).points;
        const start: Point = (points.length >= 2 ? points[points.length - 2] : (<FirstPointCadAction>command.actions[1]).point);
        const end: Point = points[points.length - 1];

        const auxline = new AuxLine();
        auxline.start = start;
        auxline.end = end;

        this.model.AddEntities([[start, end], [auxline]]).then(_ => {
            this.importStorey(false);
        });
    }

    public DrawWall(): void {
        this.setActiveCommand(new CadCommand([
            new FirstPointCadAction('Zadejte první bod stěny', null),
            new NextPointCadAction('Zadejte další bod stěny', null, e => this.drawWall(e)),
        ]));
    }
    private drawWall(command: CadCommand): void {
        const points = (<NextPointCadAction>command.actions[2]).points;
        const start: Point = (points.length >= 2 ? points[points.length - 2] : (<FirstPointCadAction>command.actions[1]).point);
        const end: Point = points[points.length - 1];

        const wallSegment = new WallSegment();
        wallSegment.start = start;
        wallSegment.end = end;

        this.model.AddEntities([[start, end], [wallSegment]]).then(_ => {
            this.importStorey(false);
            this.model.updateStructureKinds(StoreyEntityState.EXISTING_AND_NEW);
        });
    }

    public DrawWindow(): void {
        this.setActiveCommand(new CadCommand([
            new SelectOneCadAction('Vyberte stěnu, ve které bude okno', e => e instanceof WallSegment),
            new FirstPointCadAction('Zadejte první bod okna', [OsnapMode.EXISTING_POINT, OsnapMode.MID_POINT, OsnapMode.NEAREST_ON_LINE]),
            new SecondPointCadAction('Zadejte druhý bod okna', [OsnapMode.EXISTING_POINT, OsnapMode.MID_POINT, OsnapMode.NEAREST_ON_LINE]),
            new CallbackCadAction(e => this.drawWindow(e))
        ]));
    }
    private drawWindow(command: CadCommand): void {
        const targetSegment = (<SelectOneCadAction>command.actions[1]).selection.entities[0] as WallSegment;
        const firstPoint = (<FirstPointCadAction>command.actions[2]).point;
        const secondPoint = (<SecondPointCadAction>command.actions[3]).point;

        this.model.SplitSegmentEntity(targetSegment, [firstPoint, secondPoint]).then(segments => {
            const windowSegment = ModelService.deepCopy(this.model.getDefaults().defaultWindow.returnWindowSegment());
            if (segments.length === 3) {
                windowSegment.wallSegment = segments[1] as WallSegment;
            } else {
                windowSegment.wallSegment = (new Point2D(firstPoint).distance(segments[0].start) < 1 || new Point2D(secondPoint).distance(segments[0].start) < 1 ? segments[0] : segments[1]) as WallSegment;
            }
            windowSegment.height = this.model.EntitiesHistory.window.height ? this.model.EntitiesHistory.window.height : windowSegment.height;
            this.model.AddEntities([[windowSegment]]).then(_ => {
                this.importStorey(false);
            });
        });
    }

    public DrawDoor(): void {
        this.setActiveCommand(new CadCommand([
            new SelectOneCadAction('Vyberte stěnu, ve které budou dveře', e => e instanceof WallSegment),
            new FirstPointCadAction('Zadejte první bod dveří', [OsnapMode.EXISTING_POINT, OsnapMode.MID_POINT, OsnapMode.NEAREST_ON_LINE]),
            new SecondPointCadAction('Zadejte druhý bod dveří', [OsnapMode.EXISTING_POINT, OsnapMode.MID_POINT, OsnapMode.NEAREST_ON_LINE]),
            new CallbackCadAction(e => this.drawDoor(e))
        ]));
    }
    private drawDoor(command: CadCommand): void {
        const targetSegment = (<SelectOneCadAction>command.actions[1]).selection.entities[0] as WallSegment;
        const firstPoint = (<FirstPointCadAction>command.actions[2]).point;
        const secondPoint = (<SecondPointCadAction>command.actions[3]).point;

        this.model.SplitSegmentEntity(targetSegment, [firstPoint, secondPoint]).then(segments => {
            const doorSegment = ModelService.deepCopy(this.model.getDefaults().defaultDoor.returnDoorSegment())
            if (segments.length === 3) {
                doorSegment.wallSegment = segments[1] as WallSegment;
            } else {
                doorSegment.wallSegment = (new Point2D(firstPoint).distance(segments[0].start) < 1 || new Point2D(secondPoint).distance(segments[0].start) < 1 ? segments[0] : segments[1]) as WallSegment;
            }
            doorSegment.height = this.model.EntitiesHistory.door.height ? this.model.EntitiesHistory.door.height : doorSegment.height;
            this.model.AddEntities([[doorSegment]]).then(_ => {
                this.importStorey(false);
            });
        });
    }

    public DrawBorder(): void {
        this.setActiveCommand(new CadCommand([
            new FirstPointCadAction('Zadejte první bod hranice', null),
            new NextPointCadAction('Zadejte další bod hranice', null, e => this.drawBorder(e)),
        ]));
    }
    private drawBorder(command: CadCommand): void {
        const points = (<NextPointCadAction>command.actions[2]).points;
        const start: Point = (points.length >= 2 ? points[points.length - 2] : (<FirstPointCadAction>command.actions[1]).point);
        const end: Point = points[points.length - 1];

        const borderSegment = new BorderSegment();
        borderSegment.start = start;
        borderSegment.end = end;

        this.model.AddEntities([[start, end], [borderSegment]]).then(_ => {
            this.importStorey(false);
        });
    }

    public DrawSpaceProperties(): void {
        this.setActiveCommand(new CadCommand([
            new FirstPointCadAction('Zadejte umístění vlastností místnosti', [OsnapMode.NEW]),
            new CallbackCadAction(e => this.drawSpaceProperties(e)),
            new NextPointCadAction('Zadejte umístění vlastností místnosti', [OsnapMode.NEW], e => this.drawSpaceProperties(e)),
        ]));
    }
    private drawSpaceProperties(command: CadCommand): void {
        const points = (<NextPointCadAction>command.actions[3]).points;
        const location: Point = (points.length >= 2 ? points[points.length - 2] : (<FirstPointCadAction>command.actions[1]).point);

        if (location) {
            const spaceproperties = new SpaceProperties();
            spaceproperties.location = location;
            spaceproperties.floorComposition_F = this.defaultDataService.getDefaultStructureProperties(StructurePropertiesType.FLOOR);
            spaceproperties.floorComposition_I = this.defaultDataService.getDefaultStructureProperties(StructurePropertiesType.FLOOR);

            this.model.AddEntities([[location], [spaceproperties]]).then(_ => {
                this.model.updateStructureKinds(StoreyEntityState.EXISTING_AND_NEW).subscribe(() => this.importStorey(false));
            });
        } else {
            this.cancelAction();
        }
    }

    public DrawSoilHeight(): void {
        this.setActiveCommand(new CadCommand([
            new FirstPointCadAction('Zadejte výškový bod terénu', [OsnapMode.EXISTING_POINT, OsnapMode.MID_POINT, OsnapMode.NEAREST_ON_LINE]),
            new CallbackCadAction(e => this.drawSoilHeight(e))
        ]));
    }
    private drawSoilHeight(command: CadCommand): void {
        const location: Point = (<FirstPointCadAction>command.actions[1]).point;
        const heightStr: string = prompt('Zadejte výšku upraveného terénu v metrech nad +/-0.000', '0');
        const height: number = parseFloat(heightStr.replace(',', '.'));
        if (!isNaN(height)) {
            const soilHeight = new SoilHeight();
            soilHeight.location = location;
            soilHeight.soilHeight_M = height;

            this.model.AddEntities([[location], [soilHeight]]).then((_) => {
                this.importStorey(false);
            });
        } else { alert('Nezadali jste číselnou hodnotu!'); }
    }

    public DrawFloorProperties(): void {
        this.setActiveCommand(new CadCommand([
            new FirstPointCadAction('Zadání parametrů podlahy', [OsnapMode.NEW]),
            new CallbackCadAction(e => this.drawFloorProperties(e))
        ]));
    }
    private drawFloorProperties(command: CadCommand): void {
        const location: Point = (<FirstPointCadAction>command.actions[1]).point;
        const floorDefinition = new FloorDefinition();

        const floorProperties = new FloorProperties();
        floorProperties.location = location;
        floorProperties.floorDefinition = floorDefinition;
        floorProperties.floorHeight = this.model.SelectedStorey.elevation;

        this.model.AddEntities([[location], [floorDefinition], [floorProperties]]).then(_ => {
            this.importStorey(false);
        });
    }

    public DrawRoofProperties(): void {
        const roofProperties = new RoofProperties();
        this.model.SelectedStorey.roofProperties.push(roofProperties);
        this.setActiveCommand(new CadCommand([
            new FirstPointCadAction('Zadejte první bod střechy', null),
            new NextPointCadAction('Zadejte další bod střechy', null, e => this.drawRoofPropertiesEdge(e, roofProperties)),
            new FirstPointCadAction('Vyberte první řídící bod', [OsnapMode.EXISTING_POINT]),
            new CallbackCadAction(e => this.drawRoofPropertiesRidgeHeight(e, roofProperties)),
            new SecondPointCadAction('Vyberte druhý řídící bod', [OsnapMode.EXISTING_POINT]),
            new CallbackCadAction(e => this.drawRoofPropertiesRidgeHeight(e, roofProperties)),
            new FirstPointCadAction('Zadejte první bod vektoru sklonu', null),
            new SecondPointCadAction('Zadejte druhý bod vektoru sklonu', null),
            new CallbackCadAction(e => this.drawRoofPropertiesSlopeVector(e, roofProperties)),
        ]));
    }
    private drawRoofPropertiesEdge(command: CadCommand, roofProperties: RoofProperties): void {
        const points = (<NextPointCadAction>command.actions[2]).points;
        if (points.length === 1) {   // jedna se o zadani prvníhoh bodu akce "NextPointCadAction", celkově tedy druhého bodu polygonu
            const firstPoint: Point = (<FirstPointCadAction>command.actions[1]).point;
            roofProperties.edgePolygon.push(firstPoint);
            this.model.AddEntities([[firstPoint]]);
        }

        const nextPoint: Point = points[points.length - 1];
        roofProperties.edgePolygon.push(nextPoint);
        this.model.AddEntities([[nextPoint]]).then(_ => {
            this.model.SelectedStorey.roofProperties[this.model.SelectedStorey.roofProperties.length - 1] = roofProperties;
            this.importStorey(false);
        });
    }

    private drawRoofPropertiesRidgeHeight(command: CadCommand, roofProperties: RoofProperties) {
        const height = parseFloat(prompt('Zadejte výšku řídícího bodu').replace(',', '.'));
        if (!roofProperties.ridgeStart) {
            roofProperties.ridgeStart = (<FirstPointCadAction>command.actions[3]).point;
            roofProperties.ridgeStartHeight = height;
        } else {
            roofProperties.ridgeEnd = (<SecondPointCadAction>command.actions[5]).point;
            roofProperties.ridgeEndHeight = height;
        }
        this.model.SelectedStorey.roofProperties[this.model.SelectedStorey.roofProperties.length - 1] = roofProperties;
    }

    private drawRoofPropertiesSlopeVector(command: CadCommand, roofProperties: RoofProperties) {
        const slope = parseFloat(prompt('Zadejte sklon střechy').replace(',', '.'));
        const start: Point = (<FirstPointCadAction>command.actions[7]).point;
        const end: Point = (<SecondPointCadAction>command.actions[8]).point;
        roofProperties.slopeVectorStart = start;
        roofProperties.slopeVectorEnd = end;
        roofProperties.slope = slope;
        this.model.SelectedStorey.roofProperties.pop(); // remove tmp roofProperties
        this.model.AddEntities([[start, end], [roofProperties]]).then(() => {
            this.importStorey(false);
        });
    }

    public Copy(): void {
        this.setActiveCommand(new CadCommand([
            new SelectManyCadAction('Vyberte entity ke zkopírování'),
            new FirstPointCadAction('Zadejte první bod vektoru kopírování', null),
            new SecondPointCadAction('Zadejte druhý bod vektoru kopírování', null),
            new CallbackCadAction(e => this.copyEntities(e))
        ]));
    }
    private copyEntities(command: CadCommand): void {
        const selection: CadSelection = (<SelectManyCadAction>command.actions[1]).selection;
        const start: { x: number, y: number } = (<FirstPointCadAction>command.actions[2]).point;
        const end: { x: number, y: number } = (<SecondPointCadAction>command.actions[3]).point;
        const dx = end.x - start.x;
        const dy = end.y - start.y;

        const promises = [];
        selection.entities.forEach(e => {
            const clone: StoreyEntity = this.backendService.cloneEntity(e.entityType, e);
            clone.externalId = CommonTools.GetUUID();
            if (clone instanceof SegmentEntity) {
                clone.start = (<SegmentEntity>e).start.Clone(dx, dy);
                clone.end = (<SegmentEntity>e).end.Clone(dx, dy);
                promises.push(this.model.AddEntities([[clone.start, clone.end], [clone]]));
            } else if (clone instanceof LocationEntity) {
                clone.location = (<LocationEntity>e).location.Clone(dx, dy);
                promises.push(this.model.AddEntities([[clone.location], [clone]]));
            } else if (clone instanceof Point) {
                // nothing
            } else {
                promises.push(this.model.AddEntity(clone));
            }
        });
        Promise.all(promises).then(_ => this.importStorey(false));
    }

    public CopyToStoreys(): void {
        this.setActiveCommand(new CadCommand([
            new SelectManyCadAction('Vyberte entity ke zkopírování mezi podlažími'),
            new CallbackCadAction(e => this.copyToStoreys(e))
        ]));
    }

    private copyToStoreys(command: CadCommand): void {
        const selection: CadSelection = (<SelectManyCadAction>command.actions[1]).selection;

        const modalRef = this.mbdModalService.show(CopyStoreysModalComponent, {
            ignoreBackdropClick: true,
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-l',
            animated: true,
            data: {},
        });
        modalRef.content.result$.subscribe((selectedStoreys: Storey[]) => {
            if (selectedStoreys) {
                this.model.CloneEntitiesToStoreys(selection.entities, selectedStoreys).then(() => {
                    this.importStorey(false);
                });
            }
        });
    }

    public Move(): void {
        this.setActiveCommand(new CadCommand([
            new SelectManyCadAction('Vyberte entity k posunu'),
            new FirstPointCadAction('Zadejte první bod vektoru posunu', null),
            new SecondPointCadAction('Zadejte druhý bod vektoru posunu', null),
            new CallbackCadAction(e => this.moveEntities(e))
        ]));
    }
    private moveEntities(command: CadCommand): void {
        const selection: CadSelection = (<SelectManyCadAction>command.actions[1]).selection;
        const start: { x: number, y: number } = (<FirstPointCadAction>command.actions[2]).point;
        const end: { x: number, y: number } = (<SecondPointCadAction>command.actions[3]).point;
        const dx = end.x - start.x;
        const dy = end.y - start.y;

        let points: Point[] = [];
        selection.entities.forEach(e => {
            if (e instanceof SegmentEntity) {
                points.push(e.start);
                points.push(e.end);
            }
            if (e instanceof SpaceProperties) {
                points.push(e.location);
            }
            if (e instanceof Point) {
                points.push(e);
            }
        });
        points = CommonTools.GetDistinctValues(points);

        points.forEach(pt => {
            pt.x += dx;
            pt.y += dy;
            this.model.UpdateEntity(pt);
        });

        this.importStorey(false);
    }

    public SplitSegment(): void {
        this.setActiveCommand(new CadCommand([
            new SelectOneCadAction('Vyberte segment k rozdělení', e => e instanceof SegmentEntity),
            new FirstPointCadAction('Zadejte bod rozdělení', [OsnapMode.NEAREST_ON_LINE]),
            new NextPointCadAction('Zadejte další bod rozdělení', [OsnapMode.NEAREST_ON_LINE], e => { }),
            new CallbackCadAction(e => this.splitSegment(e))
        ]));
    }
    private splitSegment(command: CadCommand): void {
        const selection = (<SelectManyCadAction>command.actions[1]).selection;
        const firstPoint = (<FirstPointCadAction>command.actions[2]).point;
        const nextPoints = (<NextPointCadAction>command.actions[3]).points;

        this.model.SplitSegmentEntity(selection.entities[0] as SegmentEntity, [firstPoint, ...nextPoints]).then(_ => {
            this.importStorey(false);
        });
    }

    public Delete(): void {
        this.setActiveCommand(new CadCommand([
            new SelectManyCadAction('Vyberte entity ke smazání'),
            new CallbackCadAction(e => this.deleteEntities(e))
        ]));
    }

    private deleteEntities(command: CadCommand): void {
        const selection: CadSelection = (<SelectManyCadAction>command.actions[1]).selection;
        if (confirm('Opravdu chcete smazat vybrané entity? Entit ke smazání: ' + selection.entities.length)) {
            this.model.RemoveEntities(selection.entities);
            this.model.updateStructureKinds(StoreyEntityState.EXISTING_AND_NEW).subscribe(() => this.importStorey(false));
        }
    }

    // Vizualizace
    public showRooms() {
        this.backendService.getRoomVisualization(this.model.SelectedStorey, DrawingSetup.SelectedStateToDraw).subscribe((roomInfos: any[]) => {
            this.stage.highlightLayer.clearEntities();
            roomInfos.forEach(roomInfo => {
                this.stage.highlightLayer.add(new Konva.Shape(new Room(roomInfo)));
            });
            this.stage.batchDraw();
        });
    }
    public ShowZones() {
        this.backendService.getRoomZonesVisualization(this.model.SelectedStorey, DrawingSetup.SelectedStateToDraw).subscribe((roomGroups: any[]) => {
            this.stage.highlightLayer.clearEntities();
            roomGroups.forEach(roomGroup => {
                this.stage.highlightLayer.add(new Konva.Shape(new Room(roomGroup)));
            });
            this.stage.batchDraw();
        });
    }
    public ShowRoofs() {
        this.backendService.getRoofVisualization(this.model.SelectedBuilding, DrawingSetup.SelectedStateToDraw).subscribe((roofProps: any[]) => {
            this.stage.highlightLayer.clearEntities();
            roofProps.forEach(roofProp => {
                this.stage.highlightLayer.add(new Konva.Shape(new Room(roofProp)));
            });
            this.stage.batchDraw();
        });
    }
    public ShowIsoAreas() {
        this.backendService.getIsoAreasVisualization(this.model.SelectedStorey, DrawingSetup.SelectedStateToDraw).subscribe((isoAreas: any[]) => {
            this.stage.highlightLayer.clearEntities();
            isoAreas.forEach(isoArea => {
                this.stage.highlightLayer.add(new Konva.Shape(new Room(isoArea)));
            });
            this.stage.batchDraw();
        });
    }

    public removeRoomHighlighting() {
        this.stage.highlightLayer.clearEntities();
        this.stage.batchDraw();
    }
}
