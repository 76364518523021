import { EntityType } from "./enums/entityType";
import { ThermalRegime } from "./enums/Space-properties-enums.model"
import { ProjectEntity } from "./project-entity.model";
import { StructureProperties } from "./structure-properties.model"

export class RoofDefinition extends ProjectEntity {
    entityType: EntityType = EntityType.RoofDefinition;
    name: string;
    description: string = "";
    roofComposition: StructureProperties = null;
    thermalRegimeAboveRoof: ThermalRegime = null;
}