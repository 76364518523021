/* Angular */
import { Component } from '@angular/core';

/* 3rd parties */
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-notification',
    templateUrl: 'notification.component.html'
})

export class NotificationComponent {
    public type: string;
    public showMessageDetails = false;
    public key = '';
    public params: any;
    /*
     Keep this comment. It shows the variables that will be dynamically injected in the component

     private key = '';
     private keyDetails = '';
     private params: any;
     */

    constructor(
        public translateService: TranslateService) {
    }

    getTranslatedKey() {
        let text: string;

        this.translateService.get(this.key).subscribe((res: string) => {
            text = res;
        });

        if (this.params) {
            for (let i = 0; i < this.params.length; i++) {
                text = text.replace('{' + i + '}', this.params[i]);
            }
        }

        return text;
    }

    toggleDetails() {
        this.showMessageDetails = !this.showMessageDetails;
    }

    isError() {
        return this.type === 'error';
    }
}
