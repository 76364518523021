import { AuthenticationService } from './../../services/authentication.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalService, ModalDirective } from 'angular-bootstrap-md';
import { EntityType } from '../../models/enums/entityType';
import { Material, MaterialPropertyVisibility, MATERIAL_GROUP_OPTIONS, MATERIAL_TYPE_OPTIONS } from '../../models/material.model';
import { Role } from '../../models/user.model';
import { CompanyEditorComponent } from '../components/company-editor/company-editor.component';
import { ModelService } from './../../services/model.service';

@Component({
    selector: 'app-material-detail',
    templateUrl: './material-detail.component.html',
    styleUrls: ['./material-detail.component.scss']
})
export class MaterialDetailComponent implements OnInit {

    //@ViewChild('propertiesModal') detailModal: ModalDirective;

    externalId: string;
    properties: Material = null;
    typeOptions = MATERIAL_TYPE_OPTIONS;
    groupOptions = MATERIAL_GROUP_OPTIONS;
    private isNewMaterial: boolean;
    private currentUser = null;

    propertyVisibility = new MaterialPropertyVisibility();

    constructor(
        private model: ModelService,
        private authService: AuthenticationService,
        private mbdModalService: MDBModalService
    ) { }

    ngOnInit() {
        this.currentUser = this.authService.getUser();
        this.initData();
    }

    onCancel() {
        this.mbdModalService.hide(this.mbdModalService["loaders"].length);
    }

    onSave() {
        this.saveData();
        this.mbdModalService.hide(this.mbdModalService["loaders"].length);
    }

    initData(externalId?: string) {
        if (this.externalId) {
            this.properties = this.model.Materials.find(item => item.externalId == this.externalId);
            this.isNewMaterial = false;
        } else {
            this.properties = new Material();
            this.isNewMaterial = true;
        }
    }

    saveData() {
        if (this.isNewMaterial) {
            this.model.AddEntity(this.properties);
        } else {
            this.model.UpdateEntity(this.properties);
        }
    }

    openModal(externalId?: string) {
        this.initData(externalId);
        //this.detailModal.show();
    }

    openProducerModal() {
        this.mbdModalService.show(CompanyEditorComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: this.properties.producer ? this.properties.producer.externalId : null,
            }
        });
    }

   isNotDefaultPropertyValue(propertyName: string): boolean {
        return false;
    }

    isPropertyVisible(propertyName: string): boolean {
        var isVisible = this.propertyVisibility.isPropertyVisible(this.properties, propertyName);
        if (propertyName == "confirmed") {
            isVisible = isVisible && this.currentUser && this.currentUser.role == Role.ADMINISTRATOR;
        }
        return isVisible;
    }
}
