import { Point } from '../../models/point.model';
import { OsnapMode, PointInput } from '../../models/pointInput';
import { CadSelection } from "../../models/cadSelection.model";
import { CadCommand } from "./cadCommand";
import { SelectionMode } from '../../models/enums/selectionMode';

export abstract class CadAction {
    abstract prompt: string;
    abstract cursor: string;
    SelectionMode: SelectionMode = SelectionMode.None;
    AllowedOsnapModes: OsnapMode[] = null;
}
export class NoAction extends CadAction {
    prompt: string = "Žádný příkaz";
    cursor: string = "default";
    SelectionMode: SelectionMode = SelectionMode.Many;
}
export abstract class PointCadAction extends CadAction {
    prompt: string;
    cursor: string = "crosshair";
    abstract handlePointInput(pointInput: PointInput): void;

    constructor(prompt: string, allowedOsnapModes: OsnapMode[]) {
        super();
        this.prompt = prompt;
        this.AllowedOsnapModes = allowedOsnapModes;
    }
}
export abstract class SinglePointAction extends PointCadAction {
    point: Point;

    handlePointInput(pointInput: PointInput): void {
        this.point = pointInput.Point ? pointInput.Point : Object.assign(new Point(), pointInput.Location);
    }
}
export class FirstPointCadAction extends SinglePointAction {

}
export class SecondPointCadAction extends SinglePointAction {

}
export class NextPointCadAction extends PointCadAction {
    prompt: string;
    points: Point[] = [];
    callback: any;

    constructor(prompt: string, allowedOsnapModes: OsnapMode[], callback: any) {
        super(prompt, allowedOsnapModes);
        this.callback = callback;
    }

    handlePointInput(pointInput: PointInput): void {
        this.points.push(pointInput.Point ? pointInput.Point : Object.assign(new Point(), pointInput.Location));
    }
    invokeCallback(command: CadCommand): void {
        return this.callback(command);
    }
}

export abstract class SelectCadAction extends CadAction {
    prompt: string;
    cursor: string = "hand";
    selection: CadSelection;

    constructor(prompt: string) {
        super();
        this.prompt = prompt;
    }

    public abstract handleSelection(selection: CadSelection): boolean;
}
export class SelectOneCadAction extends SelectCadAction {
    filter: any = null;
    SelectionMode = SelectionMode.Single;

    constructor(prompt: string, filter: any) {
        super(prompt);
        this.filter = filter;
    }

    handleSelection(selection: CadSelection): boolean {
        let filteredSelection = selection.entities.filter(e => this.filter(e));

        if (filteredSelection.length > 0) {
            this.selection = new CadSelection(filteredSelection);
            return true;
        } else {
            return false;
        }
    }
}

export class SelectManyCadAction extends SelectCadAction {
    SelectionMode = SelectionMode.Many;

    constructor(prompt: string) {
        super(prompt);
    }

    handleSelection(selection: CadSelection): boolean {
        this.selection = selection;
        return selection.entities.length > 0;
    }
}
export class CallbackCadAction extends CadAction {
    prompt: string = "";
    cursor: string = "default";
    callback: any;

    constructor(callback: any) {
        super();
        this.callback = callback;
    }

    invokeCallback(command: CadCommand): void {
        return this.callback(command);
    }
}
