import { ModelService } from './../../../services/model.service';
import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { Shielding } from '../../../models/shielding.model';
import { RestService } from '../../../services/rest.service';
import { CommonTools } from '../../../shared/helpers/common-tools';
import { EntityType } from '../../../models/enums/entityType';

@Component({
    selector: 'app-window-shielding-editor',
    templateUrl: './window-shielding-editor.component.html',
    styleUrls: ['./window-shielding-editor.component.scss']
})
export class WindowShieldingEditorComponent implements OnInit {
    shielding: Shielding = <Shielding>{};
    /** externalId: string | undefined - id of properties element
       *  Could be set in parent component using mbdModalService.show(ModalComponent, {data: {externalId}})
       */
    externalId: string;
    isNewShielding: boolean;
    constructor(
        private model: ModelService,
        private mdbModalService: MDBModalService) { }

    ngOnInit() {
        this.initData(this.externalId);
    }

    initData(externalId?: string) {
        if (externalId) {
            this.shielding = this.model.getEntityByExternalId(EntityType.Shielding, externalId);
            this.isNewShielding = false;
        } else {
            this.shielding = new Shielding();
            this.isNewShielding = true;
        }
    }


    onSave() {
        this.saveData();
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.saveData();
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }


    private saveData() {
        if (this.isNewShielding) {
            this.model.AddEntity(this.shielding);
        } else {
            this.model.UpdateEntity(this.shielding);
        }
    }

    public isNotDefaultPropertyValue(propertyName: string): boolean {
        return false;
    }
}