import { EntityType } from './enums/entityType';
import { OpeningProperties } from './opening-properties.model';

export class DoorProperties extends OpeningProperties {
    entityType = EntityType.DoorProperties;
    name?: string;
    type?: EDoorType;
    category?: EDoorCategory;
    material?: EDoorMaterial;
    description?: string;
    ud?: number;
    heightAverage?: number;
    heightTop?: number;
    heightBottom?: number;
    heightRight?: number;
    heightLeft?: number;
    distanceFrame: EDoorDistanceFrame;
    uf: number;
    ug: number;
    psi_g: number;
}

export enum EDoorType {
    // dvere
    DOOR,
    // vrata
    GATE,
    // vylez
    EGRESS
}

export enum EDoorCategory {
    // venkovni
    EXTERIOR,
    // vchodove
    ENTRANCE,
    // interierove
    INTERIOR,
    // zateplene
    INSULATED,
    // nezateplene
    NON_INSULATED
}

export enum EDoorDistanceFrame {
    ALUMINIUM,
    STEEL,
    STEEL_STAINLESS,
    CHROMATEC_ULTRA_F,
    TGI,
    SWISSPACER_V,
}

export enum EDoorMaterial {
    PLASTIC,
    WOOD,
    ALUMINIUM,
    WOOD_ALUMINIUM,
    METAL,
    STEEL
}

export const DOOR_TYPE_OPTIONS = [
    { value: EDoorType[EDoorType.DOOR], label: 'Dveře  ' },
    { value: EDoorType[EDoorType.GATE], label: 'Vrata' },
    { value: EDoorType[EDoorType.EGRESS], label: 'Výlezy' },
];

export const DOOR_CATEGORY_OPTIONS = [
    { value: EDoorCategory[EDoorCategory.EXTERIOR], label: 'Exteriérové' },
    { value: EDoorCategory[EDoorCategory.ENTRANCE], label: 'Vchodové' },
    { value: EDoorCategory[EDoorCategory.INTERIOR], label: 'Interiérové' },
    { value: EDoorCategory[EDoorCategory.INSULATED], label: 'Zateplené' },
    { value: EDoorCategory[EDoorCategory.NON_INSULATED], label: 'Nezateplené' },
];

export const DOOR_MATERIAL = [
    { value: EDoorMaterial[EDoorMaterial.PLASTIC], label: 'Plast' },
    { value: EDoorMaterial[EDoorMaterial.WOOD], label: 'Dřevo' },
    { value: EDoorMaterial[EDoorMaterial.ALUMINIUM], label: 'Hliník' },
    { value: EDoorMaterial[EDoorMaterial.WOOD_ALUMINIUM], label: 'Dřevo-hliník' },
    { value: EDoorMaterial[EDoorMaterial.METAL], label: 'Kov' },
    { value: EDoorMaterial[EDoorMaterial.STEEL], label: 'Ocel' },
];

export const DOOR_DISTANCE_FRAME_OPTIONS = [
    { value: EDoorDistanceFrame[EDoorDistanceFrame.ALUMINIUM], label: 'Hliník' },
    { value: EDoorDistanceFrame[EDoorDistanceFrame.STEEL], label: 'Ocel' },
    { value: EDoorDistanceFrame[EDoorDistanceFrame.STEEL_STAINLESS], label: 'Nerez ocel' },
    { value: EDoorDistanceFrame[EDoorDistanceFrame.CHROMATEC_ULTRA_F], label: 'Chromatec ultra F' },
    { value: EDoorDistanceFrame[EDoorDistanceFrame.TGI], label: 'TGI' },
    { value: EDoorDistanceFrame[EDoorDistanceFrame.SWISSPACER_V], label: 'Swisspacer V' },
];

/**
 * This class defines weather the property should be visible in catalogue detail form.
 */
export class DoorPropertyVisibility {
    isPropertyVisible(doorProperties: DoorProperties, attributeName: string): boolean {
        if (attributeName == 'heightAverage') {
            return this.isHeightAverageVisible(doorProperties);
        } else if (attributeName == 'heightTop') {
            return this.isHeightTopVisible(doorProperties);
        } else if (attributeName == 'heightBottom') {
            return this.isHeightBottomVisible(doorProperties);
        } else if (attributeName == 'heightRight') {
            return this.isHeightRightVisible(doorProperties);
        } else if (attributeName == 'heightLeft') {
            return this.isHeightLeftVisible(doorProperties);
        } else if (attributeName == 'distanceFrame') {
            return this.isDistanceFrameVisible(doorProperties);
        } else if (attributeName == 'ug') {
            return this.isUgVisible(doorProperties);
        } else if (attributeName == 'uf') {
            return this.isUfVisible(doorProperties);
        } else if (attributeName == 'psi_g') {
            return this.isPsigVisible(doorProperties);
        }

        return true;
    }

    private isHeightAverageVisible(doorProperties: DoorProperties): boolean {
        return this.isEntranceOrExterior(doorProperties);
    }

    private isHeightTopVisible(doorProperties: DoorProperties): boolean {
        return this.isEntranceOrExterior(doorProperties);
    }

    private isHeightBottomVisible(doorProperties: DoorProperties): boolean {
        return this.isEntranceOrExterior(doorProperties);
    }

    private isHeightRightVisible(doorProperties: DoorProperties): boolean {
        return this.isEntranceOrExterior(doorProperties);
    }

    private isHeightLeftVisible(doorProperties: DoorProperties): boolean {
        return this.isEntranceOrExterior(doorProperties);
    }

    private isDistanceFrameVisible(doorProperties: DoorProperties): boolean {
        return this.isEntranceOrExterior(doorProperties);
    }

    private isUgVisible(doorProperties: DoorProperties): boolean {
        return this.isEntranceOrExterior(doorProperties);
    }

    private isUfVisible(doorProperties: DoorProperties): boolean {
        return this.isEntranceOrExterior(doorProperties);
    }

    private isPsigVisible(doorProperties: DoorProperties): boolean {
        return this.isEntranceOrExterior(doorProperties);
    }

    private isEntranceOrExterior(doorProperties: DoorProperties): boolean {
        return doorProperties.category.toString() == EDoorCategory[EDoorCategory.ENTRANCE] || doorProperties.category.toString() == EDoorCategory[EDoorCategory.EXTERIOR];
    }
}