import { DrawingSetup } from '../services/drawing-setup';
import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { StoreyEntity } from './storey-entity.model';

export class Point extends StoreyEntity {
    entityType: EntityType = EntityType.Point;
    x: number = 0;
    y: number = 0;

    // a Konva.Canvas renderer is passed into the sceneFunc function
    sceneFunc = (context: any, shape: any) => {
        shape.stroke(BaseEntity.getShapeColor(<Point>shape.attrs));
        shape.strokeWidth(3 / shape.parent.scale().x)
        context.beginPath();
        context.arc(0, 0, 6 / shape.parent.scale().x, 0, Math.PI * 2, false);
        context.closePath();
        context.fillStrokeShape(shape);
    }

    public Clone(dx: number, dy: number): Point {
        var result = new Point();
        result.x = this.x + dx;
        result.y = this.y + dy;
        return result;
    }
    public toString = (): string => {
        return "[" + this.x.toFixed(0) + ", " + this.y.toFixed(0) + "]";
    }
}
