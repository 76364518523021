import { EntityType } from './enums/entityType';
import { MaterialLayer } from './material-layer.model';
import { ProjectEntity } from './project-entity.model';

export class StructureProperties extends ProjectEntity {
    entityType: EntityType = EntityType.StructureProperties;
    compositionInterior: MaterialLayer[] = [];
    compositionExterior: MaterialLayer[] = [];
    deltaU: number = 0;
    preserved: boolean = true;
    heatingInConstruction: boolean = false;
    insulationWithoutDowels: boolean = false;
    kind: string = "";
    detailedDescription: string = "Nová skladba konstrukce";
    designDescription: string = "";
    designChangeDescription: string = "";
    sunlightAbsorbtion: number = 0;
    structureType: StructurePropertiesType = StructurePropertiesType.WALL;
    structureFeature: StructurePropertiesFeature = StructurePropertiesFeature.NONE;

    interiorWidth?: any = () => {
        let result = 0;
        this.compositionInterior.forEach(l => result += l.thickness);
        return result;
    }
    exteriorWidth?: any = () => {
        let result = 0;
        this.compositionExterior.forEach(l => result += l.thickness);
        return result;
    }
}

export enum WallSide {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL'
}

export enum StructurePropertiesType {
    NONE = 'NONE',
    WALL = 'WALL',
    ROOF = 'ROOF',
    FLOOR = 'FLOOR'
}

export const STRUCTURE_PROPERTIES_TYPE_OPTIONS = [
    { value: StructurePropertiesType.NONE, label: 'žádný', selected: false },
    { value: StructurePropertiesType.WALL, label: 'stěna', selected: false },
    { value: StructurePropertiesType.ROOF, label: 'střecha', selected: false },
    { value: StructurePropertiesType.FLOOR, label: 'podlaha', selected: false }
];

export enum StructurePropertiesFeature {
    HARD = 'HARD',
    LIGHT = 'LIGHT',
    ANGLE_BIGGER_45 = 'ANGLE_BIGGER_45',
    ANGLE_LOWER_EQUAL_4 = 'ANGLE_LOWER_EQUAL_4',
    T_LOWER_EQUAL_5 = 'T_LOWER_EQUAL_5,',
    T_LOWER_EQUAL_10 = 'T_LOWER_equal_10',
    NONE = 'NONE'
}

export const STRUCTURE_PROPERTIES_FEAUTURE_OPTIONS = [
    { value: StructurePropertiesFeature.HARD, label: 'hard', selected: false },
    { value: StructurePropertiesFeature.LIGHT, label: 'light', selected: false },
    { value: StructurePropertiesFeature.ANGLE_BIGGER_45, label: 'angle bigger 45', selected: false },
    { value: StructurePropertiesFeature.ANGLE_LOWER_EQUAL_4, label: 'angel lower equal 4', selected: false },
    { value: StructurePropertiesFeature.T_LOWER_EQUAL_5, label: 't lower equal 4', selected: false },
    { value: StructurePropertiesFeature.T_LOWER_EQUAL_10, label: 't lower equal 10', selected: false },
    { value: StructurePropertiesFeature.NONE, label: 'none', selected: false }
];