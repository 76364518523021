export enum EntityType {
    Project = 'Project',
    Building = 'Building',
    Storey = 'Storey',
    Point = 'Point',
    WallSegment = 'Wall',
    AuxLine = 'AuxLine',
    Border = 'Border',
    WindowSegment = 'Window',
    DoorSegment = 'Door',
    SpaceProperties = 'SpaceProperties',
    WallLayer = 'WallLayer',
    WallSegmentLayers = 'WallSegmentLayer',
    MaterialLayer = 'MaterialLayer',
    MaterialStrip = 'MaterialStrip',
    RoofWindow = 'RoofWindow',
    Shielding = 'Shielding',
    StructureProperties = 'StructureProperties',
    Ventilation = 'Ventilation',
    Zone = 'Zone',
    ZoneDefinition = 'ZoneDefinition',
    StructureSet = 'StructureSet',
    RoofDefinition = 'RoofDefinition',
    FloorDefinition = 'FloorDefinition',
    SoilHeight = 'SoilHeight',
    FloorProperties = 'FloorProperties',
    RoofProperties = 'RoofProperties',

    Material = 'Material',
    WindowProperties = 'WindowProperties',
    DoorProperties = 'DoorProperties',
    ZoneProfile = 'ZoneProfile',

    Address = 'Address',
    Company = 'Company',
    User = 'User',
    Defaults = 'Defaults',
    Customer = 'Customer',
    DefaultWindow = "DefaultWindow",
    DefaultDoor = "DefaultDoor",
    DefaultWall = "DefaultWall",
    DefaultSpaceProperties = "DefaultSpaceProperties"
    
}
