import { Point } from './point.model';
import { StoreyEntity } from './storey-entity.model';

export class PointInput {
    public PointInputType: OsnapMode;
    public Location: { x: number, y: number };
    public Entity: StoreyEntity;
    public Point: Point;
    public Clicked: boolean;
    public CtrlKey: boolean;
    public ShiftKey: boolean;

    constructor(x: number, y: number, pointInputType: OsnapMode, entity: StoreyEntity, point: Point, clicked: boolean, ctrlKey: boolean, shiftKey: boolean) {
        this.Location = { x: x, y: y };
        this.PointInputType = pointInputType;
        this.Entity = entity;
        this.Point = point;
        this.Clicked = clicked;
        this.CtrlKey = ctrlKey;
        this.ShiftKey = shiftKey;
    }
}

export enum OsnapMode {
    NEW,
    MID_POINT,
    EXISTING_POINT,
    NEAREST_ON_LINE,
}