import { CadEditor } from './../../CadEditor/cadEditor';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { meta } from 'ngx-property-grid';
import { BehaviorSubject, Subject } from 'rxjs';
import { CadSelection } from '../../../models/cadSelection.model';
import { EntityType } from '../../../models/enums/entityType';
import { ModelService } from './../../../services/model.service';
import { BorderSegmentPanelProperties } from './models/border-segment-panel-propertie.model';
import { DoorSegmentPanelProperties } from './models/door-segment-panel-properties.model';
import { LinePanelProperties } from './models/line-panel-properties.model';
import { PointPanelProperties } from './models/point-panel-properties.model';
import { SpacePropertiesPanelProperties } from './models/space-properties-panel-properties.model';
import { WallSegmentPanelProperties } from './models/wall-segment-panel-properties.model';
import { WindowSegmentPanelProperties } from './models/window-segment-panel-properties.model';
import { FloorPropertiesPanelProperties } from './models/floor-properties-panel-properties.model';
import { SoilHeightPanelProperties } from './models/soil-height-panel-properties.model';
import { LocationEntity } from '../../../models/location-entity.model';
import { RoofPropertiesPanelProperties } from './models/roof-properties-panel-properties.model';

@Component({
    selector: 'app-property-panel',
    templateUrl: './property-panel.component.html',
    styleUrls: ['./property-panel.component.scss'],
})
export class PropertyPanelComponent implements OnInit, OnDestroy {
    id;
    type;
    panel;

    allCategories = null;
    selectedCategory = null;
    selectedItems = null;
    editedItems = null;
    selectedProject = null;
    selection: CadSelection;

    private ngOnDestroy$: Subject<void> = new Subject();

    constructor(
        private model: ModelService,
        private cadEditor: CadEditor
    ) {
        this.selectedProject = model.SelectedProject;
    }

    ngOnInit(): void {
        var propGrid = this;
        this.cadEditor.Selection$.subscribe(selection => {
            propGrid.selection = selection;
            propGrid.allCategories = selection.getAllCategories().map(c => {
                return { label: this.translateCategory(c) + ' (' + selection.getEntitiesByType(c).length + ')', type: c };
            });
            propGrid.selectedCategory = this.allCategories.length > 0 ? this.allCategories[0].type : '';
            propGrid.selectedCategoryChanged(this.allCategories.length > 0 ? this.allCategories[0] : { type: '' });
        });
    }

    selectedCategoryChanged(newValue: any) {
        this.editedItems = this.selection.getEntitiesByType(newValue.type);

        if (this.editedItems && this.editedItems.length > 0) {
            const { id, type, panel } = this.dataTypePanels(this.editedItems[0]);
            if (id && type && panel) {
                this.id = id;
                this.type = type;
                this.panel = panel;
            } else {
                this.panel = new NoProperties(null);
            }
        } else {
            this.panel = new NoProperties(null);
        }
    }

    objectChanged(propertyInfo, newValue) {
        this.editedItems.forEach(item => {
            this.setObjectPropertyValue(item, propertyInfo.meta.options, newValue);
            this.model.UpdateEntity(item);
        });
        this.cadEditor.importStorey(false);
        this.selectedCategoryChanged({ type: this.selectedCategory });
    }

    setObjectPropertyValue(targetObject, fullPath, newValue) {
        if (typeof fullPath == 'string') {
            return this.setObjectPropertyValue(targetObject, fullPath.split('.'), newValue);
        } else if (fullPath.length == 1 && newValue !== undefined) {
            if (typeof targetObject[fullPath[0]] == 'number') {
                targetObject[fullPath[0]] = Number(newValue);
                this.model.EntitiesHistory.update(targetObject, fullPath[0], Number(newValue));
            } else {
                targetObject[fullPath[0]] = newValue;
            }
            return targetObject;
        } else if (fullPath.length <= 1) {  // chyba, nemelo by nastat
            return null;
        } else {
            return this.setObjectPropertyValue(targetObject[fullPath[0]], fullPath.slice(1), newValue);
        }
    }

    isObjectArray(object): boolean {
        const empty = {};
        return empty.toString.apply(object) === '[object Array]';
    }

    dataTypePanels(selectedElement) {
        const id = selectedElement.externalId;
        const type = selectedElement.entityType;
        switch (type) {
            case EntityType.Point:
                return {
                    id: id,
                    type: type,
                    panel: new PointPanelProperties(selectedElement, {})
                };
            case EntityType.AuxLine:
                return {
                    id: id,
                    type: type,
                    panel: new LinePanelProperties(selectedElement, {})
                };
            case EntityType.WindowSegment:
                return {
                    id: id,
                    type: type,
                    panel: new WindowSegmentPanelProperties(selectedElement, {
                        windowProperties$: this.model.WindowProperties,
                        windowShielding$: this.model.SelectedProject.shieldings,
                        selectedProject: this.selectedProject
                    })
                };
            case EntityType.WallSegment:
                return {
                    id: id,
                    type: type,
                    panel: new WallSegmentPanelProperties(selectedElement, {
                        wallSegmentLayers$: this.model.SelectedProject.wallSegmentLayers,
                        structureSets$: this.model.SelectedProject.structureSet,
                        allSegments: this.editedItems
                    })
                };
            case EntityType.DoorSegment:
                return {
                    id: id,
                    type: type,
                    panel: new DoorSegmentPanelProperties(selectedElement, {
                        doorProperties$: this.model.DoorProperties,
                        selectedProject: this.selectedProject
                    })
                };
            case EntityType.SpaceProperties:
                return {
                    id: id,
                    type: type,
                    panel: new SpacePropertiesPanelProperties(selectedElement, {
                        structureProperties: this.model.SelectedProject.structureProperties,
                        zones: this.model.SelectedProject.zones,
                        ventilations: this.model.SelectedProject.ventilations,
                    })
                };
            case EntityType.Border:
                return {
                    id: id,
                    type: type,
                    panel: new BorderSegmentPanelProperties(selectedElement, {})
                };
            case EntityType.FloorProperties:
                return {
                    id: id,
                    type: type,
                    panel: new FloorPropertiesPanelProperties(selectedElement, {
                        floorDefinition$: this.model.SelectedProject.floorDefinition,
                    })
                };
            case EntityType.SoilHeight:
                return {
                    id: id,
                    type: type,
                    panel: new SoilHeightPanelProperties(selectedElement, {})
                };
            case EntityType.RoofProperties:
                return {
                    id: id,
                    type: type,
                    panel: new RoofPropertiesPanelProperties(selectedElement, {})
                };
            default:
                return {
                    id: id,
                    type: type,
                    panel: new NoProperties()
                };
        }
    }
    ngOnDestroy() {
        this.ngOnDestroy$.next();
    }

    translateCategory(category: EntityType): string {
        switch (category) {
            case EntityType.Point: return 'Bod';
            case EntityType.WallSegment: return 'Stěna';
            case EntityType.WindowSegment: return 'Okno';
            case EntityType.DoorSegment: return 'Dveře';
            case EntityType.SpaceProperties: return 'Místnost';
            case EntityType.Border: return 'Hranice';
            case EntityType.SoilHeight: return 'Výška zeminy';
            default: return category;
        }
    }
}

class NoProperties {
    @meta({
        name: 'Element',
        description: 'Please select an element',
        colSpan2: false,
        type: 'text',
        group: 'Elements',
    })
    element = 'No Element Selected';

    constructor(element?: any) {
        if (element && element.hasOwnProperty('id') && element.hasOwnProperty('type')) {
            this.element = `Unsupported type (${element.type}) for id: ${element.id}`;
        }
    }
}
