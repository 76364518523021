import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SpaceProperties } from '../../../models/space-properties.model';

@Component({
    selector: 'ifr_input',
    templateUrl: './ifr_input.component.html',
    styleUrls: ['./ifr_input.component.scss'],
})
export class IFR_InputComponent implements OnInit {
    @Input() description: string;
    @Input() visible_I: boolean;
    @Input() visible_F: boolean;
    @Input() visible_R: boolean;
    @Input() spaceProperties: SpaceProperties;
    @Input() spacePropertiesCompare?: SpaceProperties;
    @Input() propertyName: string;
    @Input() type: string;
    @Input() items?: unknown[];
    @Input() items_F?: unknown[];
    @Input() bindLabel?: string;
    @Input() bindValue?: string;
    @Output() changed?: EventEmitter<any> = new EventEmitter();

    public disabledVals = false;

    constructor() {}

    ngOnInit() {
        if (this.type === 'number') {
            if (this.hasPropertyF() && this.compareFloat(this.spaceProperties[this.propertyName + '_I'], this.spaceProperties[this.propertyName + '_F'])) {
                this.disabledVals = true;
                this.spaceProperties[this.propertyName + '_F'] = this.spaceProperties[this.propertyName + '_I'];
                if (this.hasPropertyR()) {
                    this.spaceProperties[this.propertyName + '_R'] = this.spaceProperties[this.propertyName + '_I'];
                }
            }
        }
    }

    valueChanged(event: any) {
        if (this.disabledVals) {
            this.spaceProperties[this.propertyName + '_F'] = this.spaceProperties[this.propertyName + '_I'];
            if (this.hasPropertyR()) {
                this.spaceProperties[this.propertyName + '_R'] = this.spaceProperties[this.propertyName + '_I'];
            }
        }
        this.changed.emit(event);
    }
    checkBoxChanged(event: any) {
        if (this.disabledVals) {
            this.spaceProperties[this.propertyName + '_F'] = this.spaceProperties[this.propertyName + '_I'];
            if (this.hasPropertyR()) {
                this.spaceProperties[this.propertyName + '_R'] = this.spaceProperties[this.propertyName + '_I'];
            }
        }
    }

    private hasPropertyF = (): boolean => this.spaceProperties.hasOwnProperty(this.propertyName + '_F');
    private hasPropertyR = (): boolean => this.spaceProperties.hasOwnProperty(this.propertyName + '_R');

    private compareFloat(a: number, b: number): boolean {
        if (Math.abs(a - b) <= 0.01) {
            return true;
        }
        return false;
    }
}
