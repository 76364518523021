import { CommonTools } from './../../../shared/helpers/common-tools';
import { DefaultDataService } from './../../../services/defaultdata.service';
import { Component, OnInit } from "@angular/core";
import { MDBModalService } from "angular-bootstrap-md";
import { EntityType } from "../../../models/enums/entityType";
import { StructureProperties, StructurePropertiesType } from "../../../models/structure-properties.model";
import { StructureSet } from "../../../models/structure-set.model";
import { ModelService } from "../../../services/model.service";

@Component({
    selector: "app-structure-set-modal",
    templateUrl: "./structure-set-modal.component.html",
    styleUrls: ["./structure-set-modal.component.scss"],
})
export class StructureSetModalComponent implements OnInit {
    public externalId: string;

    public structureSet: StructureSet = null;
    public structureProperties: StructureProperties[] = [];

    private isNewWindow: boolean;

    constructor(private model: ModelService, private defaultDataService: DefaultDataService, private mdbModalService: MDBModalService) {
        this.model.SelectedProject$.subscribe((project) => {
            if (project) this.structureProperties = project.structureProperties;
        });
    }

    ngOnInit() {
        if (this.externalId) {
            this.structureSet = this.model.getEntityByExternalId<StructureSet>(EntityType.StructureSet, this.externalId);
        }
        if (this.structureSet) {
            this.isNewWindow = false;
        } else {
            const defaultRoofProperties = this.defaultDataService.getDefaultStructureProperties(StructurePropertiesType.ROOF);
            const defaultFloorProperties = this.defaultDataService.getDefaultStructureProperties(StructurePropertiesType.FLOOR);
            const defaultWallProperties = this.defaultDataService.getDefaultStructureProperties(StructurePropertiesType.WALL);
            this.structureSet = this.defaultDataService.createStructureSet(defaultRoofProperties, defaultFloorProperties, defaultWallProperties, this.model.SelectedProject);
            this.structureSet.name = this.model.generateName(this.structureSet);
            this.structureSet.externalId = this.externalId ? this.externalId : CommonTools.GetUUID();
            
            this.isNewWindow = true;
        }
    }

    onSave() {
        if (this.isNewWindow) {
            this.model.AddEntity(this.structureSet);
        } else {
            this.model.UpdateEntity(this.structureSet);
        }

        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }
}
