/* Config */

/**
 * @constant
 * @type {Object}
 * @description Notifications (Toaster)
 */
export const NOTIFICATIONS = {
    position: 'bottom-right',
    visibilityTime: 5, // in seconds (if set to 0 it will stay open until it's manually closed)
    maxNotifications: 3, // max number of notifications visible at the same time
    newestOnTop: false,
    tapToDismiss: false
};
