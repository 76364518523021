import { DoorProperties } from "./door-properties.model";
import { DoorSegment } from "./door-segment.model";
import { EntityType } from "./enums/entityType";
import { StoreyEntity } from "./storey-entity.model";

export class DefaultDoor extends StoreyEntity{
    entityType: EntityType = EntityType.DefaultDoor;
    propertiesExisting: DoorProperties;
    propertiesNew: DoorProperties;
    propertiesRecommended: DoorProperties;
    designation: string;
    changedFromType: string;
    changeReason: string;
    constructionDate: Date;
    width = 1500;
    height = 1500;


    public returnDoorSegment():DoorSegment{
        const obj = new DoorSegment;
        obj.entityType = EntityType.DoorSegment;
        obj.propertiesExisting = this.propertiesExisting;
        obj.propertiesNew = this.propertiesNew;
        obj.propertiesRecommended = this.propertiesRecommended;
        obj.designation = this.designation;
        obj.changedFromType = this.changedFromType;
        obj.changeReason = this.changeReason;
        obj.constructionDate = this.constructionDate;

        return obj;
        
    }
}