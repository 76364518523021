import { StoreyEntityState } from './../models/enums/StoreyEntityState';
import { MaterialStrip } from './../models/material-strip.model';
import { MaterialLayer } from './../models/material-layer.model';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Address } from '../models/address.model';
import { AuxLine } from '../models/auxline.model';
import { BaseEntity } from '../models/base-entity.model';
import { Building } from '../models/building.model';
import { Company } from '../models/company.model';
import { DoorSegment } from '../models/door-segment.model';
import { EntityType } from '../models/enums/entityType';
import { Material } from '../models/material.model';
import { Point } from '../models/point.model';
import { ProjectEntity } from '../models/project-entity.model';
import { Project } from '../models/project.model';
import { RoofWindow } from '../models/roof-window.model';
import { Shielding } from '../models/shielding.model';
import { SpaceProperties } from '../models/space-properties.model';
import { StoreyEntity } from '../models/storey-entity.model';
import { Storey } from '../models/storey.model';
import { StructureProperties } from '../models/structure-properties.model';
import { Ventilation } from '../models/ventilation.model';
import { WallLayer } from '../models/wall-layer.model';
import { WallSegmentLayers } from '../models/wall-segment-layers.model';
import { WallSegment } from '../models/wall-segment.model';
import { WindowSegment } from '../models/window-segment.model';
import { ZoneDefinition } from '../models/zone-definition.model';
import { Zone } from '../models/zone.model';
import { CurrentUser } from './../models/current-user.model';
import { RestService } from './rest.service';
import { StructureSet } from '../models/structure-set.model';
import { RoofDefinition } from '../models/roof-definition.model';
import { FloorDefinition } from '../models/floor-definition.model';
import { SoilHeight } from '../models/soil-height.model';
import { FloorProperties } from '../models/floor-properties.model';
import { Defaults } from '../models/defaults.model';
import { BorderSegment } from '../models/border-segment.model';
import { RoofProperties } from '../models/roof-properties.model';
import { StructureKind } from '../models/enums/StructureKind';
import { WindowProperties } from '../models/window-properties.model';
import { DoorProperties } from '../models/door-properties.model';
import { DefaultDoor } from '../models/default-door';
import { DefaultWindow } from '../models/default-window';
import { DefaultSpaceProperties } from '../models/default-space-properties.model';

@Injectable({
    providedIn: 'root',
})
export class BackendService {

    constructor(private rest: RestService) {
    }

    public saveEntity<T extends BaseEntity>(entity: T): Observable<boolean> {
        const result$ = new Subject<boolean>();
        const endpoint = this.getEndpoint(entity.entityType);

        this.rest.post<any>(endpoint, entity).subscribe({
            next: any => result$.next(true),
            error: error => { console.log(error); result$.next(false); },
            complete: () => result$.complete()
        });
        return result$;
    }

    public UpdateEntity<T extends BaseEntity>(entity: T): Observable<boolean> {
        const result$ = new Subject<boolean>();
        const endpoint = this.getEndpoint(entity.entityType);

        this.rest.put<any>(endpoint, entity.externalId, entity).subscribe({
            next: any => result$.next(true),
            error: error => { console.log(error); result$.next(false); },
            complete: () => result$.complete()
        });
        return result$;
    }

    public deleteEntity<T extends BaseEntity>(entity: T): Observable<boolean> {
        const result$ = new Subject<boolean>();
        const endpoint = this.getEndpoint(entity.entityType);

        this.rest.delete<any>(endpoint, entity.externalId).subscribe({
            next: any => result$.next(true),
            error: error => { console.log(error); result$.next(false); },
            complete: () => result$.complete()
        });
        return result$;
    }

    public loadEntity<T>(entityType: EntityType, externalId: any): Observable<T[]> {
        const endpoint = this.getEndpoint(entityType);
        return this.rest.get(endpoint, externalId).pipe(
            map(entity => this.ensureStrongType(entityType, entity))
        );
    }
    public loadEntities<T>(entityType: EntityType, filter: any): Observable<T[]> {
        const endpoint = this.getEndpoint(entityType);
        return this.rest.post(endpoint + '/list', filter).pipe(
            map(data => data.content.map(item => this.ensureStrongType(entityType, item)))
        );
    }

    public cloneEntity<T>(entityType: EntityType, item: any): T {
        return this.ensureStrongType(entityType, item);
    }

    // Vizualizace
    public getRoomVisualization(storey: Storey, selectecStoreyEntityState: StoreyEntityState): Observable<any> {
        return this.rest.get('model/storey/rooms', storey.externalId + '/' + selectecStoreyEntityState);
    }
    public getRoomZonesVisualization(storey: Storey, selectecStoreyEntityState: StoreyEntityState): Observable<any> {
        return this.rest.get('model/storey/roomgroups', storey.externalId + '/' + selectecStoreyEntityState);
    }
    public getRoofVisualization(building: Building, selectecStoreyEntityState: StoreyEntityState): Observable<any> {
        return this.rest.get('model/roofproperties', building.externalId + '/' + selectecStoreyEntityState);
    }
    public getIsoAreasVisualization(storey: Storey, selectecStoreyEntityState: StoreyEntityState): Observable<any> {
        return this.rest.get('model/storey/isoareas', storey.externalId + '/' + selectecStoreyEntityState);
    }

    public getVersion():Observable<any> {
        return this.rest.getVersion();
    }

    public getStructureKinds(building: Building, storeyEntityState: StoreyEntityState): Observable<any> {
        return this.rest.get('model/structurekinds', `${building.externalId}/${storeyEntityState}`);
    }

    public createInstance(entityType: EntityType): object {
        switch (entityType) {
            case EntityType.User: return new CurrentUser();
            case EntityType.Address: return new Address();
            case EntityType.Company: return new Company();
            case EntityType.Project: return new Project();
            case EntityType.Building: return new Building();
            case EntityType.Storey: return new Storey();
            case EntityType.Point: return new Point();
            case EntityType.AuxLine: return new AuxLine();
            case EntityType.WallSegment: return new WallSegment();
            case EntityType.WindowSegment: return new WindowSegment();
            case EntityType.DoorSegment: return new DoorSegment();
            case EntityType.SpaceProperties: return new SpaceProperties();
            case EntityType.WallSegmentLayers: return new WallSegmentLayers();
            case EntityType.WallLayer: return new WallLayer();
            case EntityType.RoofWindow: return new RoofWindow();
            case EntityType.Shielding: return new Shielding();
            case EntityType.Material: return new Material();
            case EntityType.StructureProperties: return new StructureProperties();
            case EntityType.MaterialLayer: return new MaterialLayer();
            case EntityType.MaterialStrip: return new MaterialStrip();
            case EntityType.Ventilation: return new Ventilation();
            case EntityType.Zone: return new Zone();
            case EntityType.ZoneDefinition: return new ZoneDefinition();
            case EntityType.WindowProperties: return new WindowProperties();  // FIX
            case EntityType.DoorProperties: return new DoorProperties();  // FIX
            case EntityType.StructureSet: return new StructureSet();
            case EntityType.RoofDefinition: return new RoofDefinition();
            case EntityType.FloorDefinition: return new FloorDefinition();
            case EntityType.SoilHeight: return new SoilHeight();
            case EntityType.Defaults: return new Defaults();
            case EntityType.FloorProperties: return new FloorProperties();
            case EntityType.RoofProperties: return new RoofProperties();
            case EntityType.Border: return new BorderSegment();
            case EntityType.DefaultDoor: return new DefaultDoor();
            case EntityType.DefaultWindow: return new DefaultWindow();
            case EntityType.DefaultSpaceProperties: return new DefaultSpaceProperties();
            default: throw new Error('Unable to create instance of type \'' + entityType + '\'.');
        }
    }

    private ensureStrongType<T>(entityType: EntityType, item: any): T {
        return <T>Object.assign(this.createInstance(entityType), item);
    }

    private getEndpoint(entityType: EntityType) {
        switch (entityType) {
            case EntityType.Project: return 'project';
            case EntityType.Building: return 'building';
            case EntityType.Storey: return 'storey';
            case EntityType.Point: return 'point';
            case EntityType.Address: return 'address';
            case EntityType.Company: return 'company';
            case EntityType.User: return 'user';
            case EntityType.AuxLine: return 'auxline';
            case EntityType.WallSegment: return 'wall_segment';
            case EntityType.WindowSegment: return 'window_segment';
            case EntityType.DoorSegment: return 'door_segment';
            case EntityType.SpaceProperties: return 'spaceproperties';
            case EntityType.WallSegmentLayers: return 'wall_segment_layer';
            case EntityType.WallLayer: return 'wall_layer';
            case EntityType.RoofWindow: return 'roofWindow';
            case EntityType.Shielding: return 'shielding';
            case EntityType.StructureProperties: return 'structure_properties';
            case EntityType.MaterialLayer: return 'material_layer';
            case EntityType.MaterialStrip: return 'material_strip';
            case EntityType.Material: return 'material';
            case EntityType.Ventilation: return 'ventilation';
            case EntityType.Zone: return 'zone';
            case EntityType.ZoneDefinition: return 'zoneDefinition';
            case EntityType.WindowProperties: return 'window_properties';
            case EntityType.DoorProperties: return 'door_properties';
            case EntityType.StructureSet: return 'structure_set';
            case EntityType.RoofDefinition: return 'roof_definition';
            case EntityType.FloorDefinition: return 'floor_definition';
            case EntityType.SoilHeight: return 'soil_height';
            case EntityType.FloorProperties: return 'floor_properties';
            case EntityType.RoofProperties: return 'roof_properties';
            case EntityType.Defaults: return 'defaults';
            case EntityType.Border: return 'border_segment';
            case EntityType.DefaultWall: return 'default_wall';
            case EntityType.DefaultWindow: return 'default_window';
            case EntityType.DefaultDoor: return 'default_door';
            case EntityType.DefaultSpaceProperties: return 'default_spaceproperties'
            default: throw new Error('Unknown instance type \'' + entityType + '\'.');
        }
    }
    public changePassword(item: any) {
        return this.rest.post<any>('change_password', item);
    }
}
