import { Component, OnDestroy, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { DefaultsEditorComponent } from '../components/defaults-editor/defaults-editor.component';
import { EntityType } from '../../models/enums/entityType';
import { Building } from './../../models/building.model';
import { ModelService } from './../../services/model.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-buildings-list',
    templateUrl: './buildings-list.component.html',
    styleUrls: ['./buildings-list.component.scss'],
})
export class BuildingsListComponent implements OnInit, OnDestroy {
    headElements: string[] = ['Název', 'Kód', 'Popis', 'Obrázek', 'Azimut', 'Výška terénu', ''];
    columnElements: string[] = ['name', 'code', 'description', 'imageUrl', 'azimut', 'soilHeight'];
    dataSource: Building[] = [];
    modelSubscription: Subscription;

    constructor(public model: ModelService, private mdbModalService: MDBModalService) { }

    ngOnInit() {
        if (this.model.SelectedProject) {
            this.dataSource = this.model.SelectedProject.buildings;
        }
        this.modelSubscription = this.model.SelectedProject$.subscribe((project) => {
            if (project) { this.dataSource = project.buildings; }
        });
    }

    onAddOne(data: any) {
        const newBuilding: Building = Object.assign(new Building(), data);
        newBuilding.name = this.model.generateName(newBuilding);
        this.model.AddEntity(newBuilding).then(() => {
            this.model.SelectedProject$.next(this.model.SelectedProject);
        });
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        if (confirm('Opravdu chcete smazat vybranou budovu?')) {
            const building = this.model.getEntityByExternalId<Building>(EntityType.Building, externalId);
            if (building.defaults) {
                building.defaults = null;
                this.model.UpdateEntity(building).then(() => {
                    this.model.RemoveEntityByExternalId(EntityType.Defaults, building.defaults.externalId);
                    this.model.RemoveEntityByExternalId(EntityType.Building, building.externalId);
                });
            } else {
                this.model.RemoveEntityByExternalId(EntityType.Building, building.externalId);
            }
        }
    }

    onSelect(id: string) {
        this.model.SelectBuildingByExternalId(id);
    }

    onEditDefaults(externalId: string) {
        this.openDefaultsModal(externalId);
    }

    openDefaultsModal(externalId: string) {
        this.mdbModalService.show(DefaultsEditorComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                ownerId: externalId,
                ownerType: EntityType.Building,
            },
        });
    }

    ngOnDestroy() {
        this.modelSubscription.unsubscribe();
    }
}
