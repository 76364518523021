import { BaseEntity } from "../../../../models/base-entity.model";
import { Building } from "../../../../models/building.model";
import { EntityType } from "../../../../models/enums/entityType";
import { FilterType } from "../../../../models/enums/filterType";
import { IFilter } from "../../../../models/interfaces/IFilter";
import { Storey } from "../../../../models/storey.model";
import { StructureProperties } from "../../../../models/structure-properties.model";
import { WallLayer } from "../../../../models/wall-layer.model";
import { WallSegmentLayers } from "../../../../models/wall-segment-layers.model";
import { WallSegment } from "../../../../models/wall-segment.model";

export class StructurePropertiesFilter implements IFilter{
    filterType: FilterType = FilterType.STRUCTURE_PROPERTIES;
    properties: StructureProperties;
    
    constructor(){}

    public showEntity(entity: BaseEntity): boolean {
        switch (entity.entityType) {
            case EntityType.Project: {
                return true;
            }
            case EntityType.Building: {
                return (<Building>entity).storeys.some((storey) => this.showEntity(storey));
            }
            case EntityType.Storey: {
                return (<Storey>entity).walls.some((wall) => this.showEntity(wall));
            }
            case EntityType.WallSegment: {
                return this.showEntity((<WallSegment>entity).wallSegmentLayers);
            }
            case EntityType.WallSegmentLayers: {
                return (<WallSegmentLayers>entity).layers.some((layer) => this.showEntity(layer));
            }
            case EntityType.WallLayer: {
                return (<WallLayer>entity).properties === this.properties;
            }
            default:
                return false;
        }
    }
}