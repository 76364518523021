import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { EntityType } from '../../models/enums/entityType';
import { CommonTools } from '../../shared/helpers/common-tools';
import { WindowShieldingEditorComponent } from '../components/window-shielding-editor/window-shielding-editor.component';
import { CatalogueListColumn } from '../readonly-list.component/readonly-list.component';
import { Shielding } from './../../models/shielding.model';
import { ModelService } from './../../services/model.service';

@Component({
    selector: 'app-shielding-list',
    templateUrl: './shielding-list.component.html',
    styleUrls: ['./shielding-list.component.scss']
})
export class ShieldingListComponent implements OnInit {

    dataSource: Shielding[] = [];

    constructor(private model: ModelService,
        private mbdModalService: MDBModalService) {
    }

    ngOnInit() {
        this.dataSource = this.model.SelectedProject.shieldings;
    }

    defineColumns(): CatalogueListColumn[] {
        const columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = 'Vysazení vlevo';
        column.dataSourceColumnName = 'leftIndent';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Délka vlevo';
        column.dataSourceColumnName = 'leftLength';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Vysazení vpravo';
        column.dataSourceColumnName = 'rightIndent';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Délka vpravo';
        column.dataSourceColumnName = 'rightLength';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Vysazení nahoře';
        column.dataSourceColumnName = 'topIndent';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Délka nahoře';
        column.dataSourceColumnName = 'topLength';
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onCopyOne(externalId: string) {
        var shielding: Shielding = this.dataSource.find(m => m.externalId == externalId);
        var clone = Object.assign(new Shielding(), shielding);
        clone.externalId = CommonTools.GetUUID();
        this.model.AddEntity(clone);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.Shielding, externalId);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    private openModal(externalId?: string) {
        const modalRef = this.mbdModalService.show(WindowShieldingEditorComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: externalId
            }
        });
    }

}
