export enum ChangeReason {
    NoChange = "NoChange",
    Unsatisfactory = "Unsatisfactory",
    GlobalInsufficient = "GlobalInsufficient",
}

export const CHANGEREASON_OPTIONS = [
    { value: ChangeReason.NoChange, label: 'Beze změny' },
    { value: ChangeReason.Unsatisfactory, label: 'Nevyhovující' },
    { value: ChangeReason.GlobalInsufficient, label: 'Celkově nedostačující' },
];
