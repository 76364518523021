import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {
    private localStorageKey = 'user-settings';
    public UserSettings: UserSettings;

    constructor() {
        this.UserSettings = JSON.parse(localStorage.getItem(this.localStorageKey));
        if (!this.UserSettings) {
            this.UserSettings = new UserSettings();
        }
    }

    public getUserSettings(): UserSettings {
        return this.UserSettings;
    }
    public saveUserSettings() {
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.UserSettings));
    }
}

export class UserSettings {
    public SnapGrid_X: number = 100;
    public SnapGrid_Y: number = 100;
}