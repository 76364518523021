import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent, MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgDatepickerModule } from 'ng2-datepicker';
import { PropertyGridModule } from 'ngx-property-grid';
import { NgxTemplateModule } from 'ngx-template';
import { ValidationModule } from './../components/validation/validation.module';
import { BuildingsListComponent } from './buildings-list/buildings-list.component';
import { CadEditorComponent } from './CadEditor/CadEditor.component';
import { CatalogueModalComponent } from './catalogue-modal/catalogue-modal.component';
import { AddressEditorComponent } from './components/address-editor/address-editor.component';
import { CadSidePanelComponent } from './components/cad-side-panel/cad-side-panel.component';
import { CompanyEditorComponent } from './components/company-editor/company-editor.component';
import { CopyStoreysModalComponent } from './components/copy-storeys-modal/copy-storeys-modal.component';
import { DefaultsEditorComponent } from './components/defaults-editor/defaults-editor.component';
import { EditableListComponent } from './components/editable-list.component/editable-list.component';
import { HeaderComponent } from './components/header/header.component';
import { IFR_InputComponent } from './components/ifr_input/ifr_input.component';
import { JsonSchemaFormModule } from './components/json-schema-form/json-schema-form.module';
import { MaterialLayerStripsComponent } from './components/material-layer-strips/material-layer-strips.component';
import { MaterialSelectFormComponent } from './components/material-select-form/material-select-form.component';
import { CheckboxPropertyComponent } from './components/property-panel/checkbox-property/checkbox-property.component';
import { DropdownPropertyComponent } from './components/property-panel/dropdown-property/dropdown-property.component';
import { EnumPropertyComponent } from './components/property-panel/enum-property/enum-property.component';
import { MultiselectPropertyComponent } from './components/property-panel/multiselect-property copy/multiselect-property.component';
import { PointPropertyComponent } from './components/property-panel/point-property/point-property.component';
import { PropertyPanelComponent } from './components/property-panel/property-panel.component';
import { TextPropertyComponent } from './components/property-panel/text-property/text-property.component';
import { TogglePropertyComponent } from './components/property-panel/toggle-property/toggle-property.component';
import { RoofWindowEditor } from './components/roof-window-editor/roof-window-editor.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SpacePropertiesEditorComponent } from './components/space-properties-editor/space-properties-editor.component';
import { StructurePropertiesEditor } from './components/structureProperties-editor/structureProperties-editor';
import { UserSettingsEditorComponent } from './components/user-settings-editor/user-settings-editor.component';
import { VentilationEditorComponent } from './components/ventilation-editor/ventilation-editor.component';
import { WallSegmentLayersEditor } from './components/wallsegmentlayers-editor/wallsegmentlayers-editor';
import { WindowShieldingEditorComponent } from './components/window-shielding-editor/window-shielding-editor.component';
import { ZoneEditorComponent } from './components/zone-editor/zone-editor.component';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { DoorPropertiesModalComponent } from './doors-properties-list/door-properties-modal/door-properties-modal.component';
import { DoorsPropertiesListComponent } from './doors-properties-list/doors-properties-list.component';
import { FloorDefinitionListComponent } from './floor-definition-list/floor-definition-list.component';
import { FloorDefinitionModalComponent } from './floor-definition-list/floor-definition-modal/floor-definition-modal.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { MaterialDetailComponent } from './material-detail/material-detail.component';
import { MaterialsListComponent } from './materials-list/materials-list.component';
import { StructurePropertiesFilterEditor } from './ModelViewer/filters/structure-properties-filter/structure-properties-filter-editor.component';
import { ModelViewerFilterModalComponent } from './ModelViewer/model-viewer-filter-modal/model-viewer-filter-modal.component';
import { ModelViewerComponent } from './ModelViewer/ModelViewer.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { ReadOnlyListComponent } from './readonly-list.component/readonly-list.component';
import { RoofDefinitionListComponent } from './roof-definition-list/roof-definition-list.component';
import { RoofDefinitionModalComponent } from './roof-definition-list/roof-definition-modal/roof-definition-modal.component';
import { ShieldingListComponent } from './shielding-list/shielding-list.component';
import { SpacePropertiesListComponent } from './space-properties-list/space-properties-list.component';
import { StoreysListComponent } from './storeys-list/storeys-list.component';
import { StructurePropertiesListComponent } from './structure-properties-list/structure-properties-list.component';
import { StructureSetListComponent } from './structure-set-list/structure-set-list.component';
import { StructureSetModalComponent } from './structure-set-list/structure-set-modal/structure-set-modal.component';
import { UsersListComponent } from './users-list/users-list.component';
// import { VentilationListComponent } from './ventilation-list/ventilation-list.component';
import { WallSegmentLayersListComponent } from './wall-segment-layers-list/wall-segment-layers-list.component';
import { WindowPropertiesModalComponent } from './windows-properties-list/window-properties-modal/window-properties-modal.component';
import { WindowsPropertiesListComponent } from './windows-properties-list/windows-properties-list.component';
import { ZoneListComponent } from './zone-list/zone-list.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MDBBootstrapModule.forRoot(),
        TranslateModule,
        JsonSchemaFormModule,
        LayoutRoutingModule,
        NgSelectModule,
        PropertyGridModule,
        NgDatepickerModule,
        ValidationModule,
        NgxTemplateModule,
    ],
    entryComponents: [
        AddressEditorComponent,
        EditableListComponent,
        ReadOnlyListComponent,
        CompanyEditorComponent,
        CopyStoreysModalComponent,
        IFR_InputComponent,
        HeaderComponent,
        MaterialLayerStripsComponent,
        MaterialSelectFormComponent,
        RoofWindowEditor,
        SidebarComponent,
        SpacePropertiesEditorComponent,
        StructurePropertiesEditor,
        VentilationEditorComponent,
        WallSegmentLayersEditor,
        WindowShieldingEditorComponent,
        ZoneEditorComponent,
        StructureSetModalComponent,
        RoofDefinitionModalComponent,
        FloorDefinitionModalComponent,
        DefaultsEditorComponent,
        ModelViewerFilterModalComponent,
        StructurePropertiesFilterEditor,
        UserSettingsEditorComponent,

        LayoutComponent,
        MaterialDetailComponent,
        DoorPropertiesModalComponent,
        WindowPropertiesModalComponent,
        BuildingsListComponent,
        CatalogueModalComponent,
        CustomersListComponent,
        DoorsPropertiesListComponent,
        MaterialsListComponent,
        CadEditorComponent,
        ProjectsListComponent,
        ShieldingListComponent,
        SpacePropertiesListComponent,
        StoreysListComponent,
        StructurePropertiesListComponent,
        UsersListComponent,
        // VentilationListComponent,
        WallSegmentLayersListComponent,
        WindowsPropertiesListComponent,
        ZoneListComponent,
        StructureSetListComponent,
        RoofDefinitionListComponent,
        FloorDefinitionListComponent,
        CadSidePanelComponent,
        ModelViewerComponent,
        PointPropertyComponent,
        TextPropertyComponent,
        TogglePropertyComponent,
        CheckboxPropertyComponent,
        MultiselectPropertyComponent,
        DropdownPropertyComponent,
        EnumPropertyComponent,
        CheckboxComponent,
    ],
    declarations: [
        AddressEditorComponent,
        EditableListComponent,
        ReadOnlyListComponent,
        CompanyEditorComponent,
        CopyStoreysModalComponent,
        IFR_InputComponent,
        HeaderComponent,
        MaterialLayerStripsComponent,
        MaterialSelectFormComponent,
        RoofWindowEditor,
        SidebarComponent,
        SpacePropertiesEditorComponent,
        StructurePropertiesEditor,
        VentilationEditorComponent,
        WallSegmentLayersEditor,
        WindowShieldingEditorComponent,
        ZoneEditorComponent,
        StructureSetModalComponent,
        RoofDefinitionModalComponent,
        FloorDefinitionModalComponent,
        DefaultsEditorComponent,
        ModelViewerFilterModalComponent,
        StructurePropertiesFilterEditor,
        UserSettingsEditorComponent,

        LayoutComponent,
        MaterialDetailComponent,
        DoorPropertiesModalComponent,
        WindowPropertiesModalComponent,
        BuildingsListComponent,
        CatalogueModalComponent,
        CustomersListComponent,
        DoorsPropertiesListComponent,
        MaterialsListComponent,
        CadEditorComponent,
        ProjectsListComponent,
        ShieldingListComponent,
        SpacePropertiesListComponent,
        StoreysListComponent,
        StructurePropertiesListComponent,
        UsersListComponent,
        // VentilationListComponent,
        WallSegmentLayersListComponent,
        WindowsPropertiesListComponent,
        ZoneListComponent,
        StructureSetListComponent,
        RoofDefinitionListComponent,
        FloorDefinitionListComponent,
        CadSidePanelComponent,
        ModelViewerComponent,
        PointPropertyComponent,
        PropertyPanelComponent,
        TextPropertyComponent,
        TogglePropertyComponent,
        CheckboxPropertyComponent,
        MultiselectPropertyComponent,
        DropdownPropertyComponent,
        EnumPropertyComponent,
    ],
    exports: [
        AddressEditorComponent,
        EditableListComponent,
        ReadOnlyListComponent,
        CompanyEditorComponent,
        CopyStoreysModalComponent,
        IFR_InputComponent,
        HeaderComponent,
        MaterialLayerStripsComponent,
        MaterialSelectFormComponent,
        RoofWindowEditor,
        SidebarComponent,
        SpacePropertiesEditorComponent,
        StructurePropertiesEditor,
        VentilationEditorComponent,
        WallSegmentLayersEditor,
        WindowShieldingEditorComponent,
        ZoneEditorComponent,
        StructureSetModalComponent,
        RoofDefinitionModalComponent,
        FloorDefinitionModalComponent,
        DefaultsEditorComponent,
        ModelViewerFilterModalComponent,
        StructurePropertiesFilterEditor,
        UserSettingsEditorComponent,

        LayoutComponent,
        ReadOnlyListComponent,
        MaterialDetailComponent,
        DoorPropertiesModalComponent,
        WindowPropertiesModalComponent,
        BuildingsListComponent,
        CatalogueModalComponent,
        CustomersListComponent,
        DoorsPropertiesListComponent,
        MaterialsListComponent,
        CadEditorComponent,
        ProjectsListComponent,
        ShieldingListComponent,
        SpacePropertiesListComponent,
        StoreysListComponent,
        StructurePropertiesListComponent,
        UsersListComponent,
        // VentilationListComponent,
        WallSegmentLayersListComponent,
        WindowsPropertiesListComponent,
        ZoneListComponent,
        StructureSetListComponent,
        RoofDefinitionListComponent,
        FloorDefinitionListComponent,
        CadSidePanelComponent,
        ModelViewerComponent,
        PropertyPanelComponent,
    ],
})
export class LayoutModule {
}
