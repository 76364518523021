import { Component, Input, OnInit, ElementRef, SimpleChanges, SimpleChange } from "@angular/core";

@Component({
    selector: 'validated-input',
    templateUrl: './validated-input.component.html',
    styleUrls: ['./validated-input.component.css']
})
export class ValidatedInputComponent {
    @Input() validation: any;
    @Input() defaultValue: any;

    constructor(private element: ElementRef) {
    }

    get isError() {
        this.compareDefaultValue();
        return (this.validation && this.validation.invalid && (this.validation.dirty || this.validation.touched));
    }

    compareDefaultValue() {
        if (this.defaultValue != undefined) {
            let inputs = this.element.nativeElement.getElementsByTagName('input');
            if (inputs != undefined && inputs.length > 0) {
                inputs.forEach(input => {
                    if (input.value != this.defaultValue) {
                        input.style = "background-color: #FFFACD;"
                    } else {
                        input.style = "";
                    }
                });
            }
        }
    }
}