import { DrawingSetup } from '../services/drawing-setup';
import { BaseEntity } from './base-entity.model';
import { BorderType } from './enums/borderType';
import { EntityType } from './enums/entityType';
import { SegmentEntity } from './segment';

export class BorderSegment extends SegmentEntity {
    entityType: EntityType = EntityType.Border;

    borderType: BorderType = BorderType.FLOOR_CHANGE;

    sceneFunc = (context, shape) => {
        if (DrawingSetup.isShapeVisible(shape.attrs.state)) {
            BorderSegment.drawOuterLine(context, shape);
        }
    };

    static drawOuterLine(context: any, shape: any) {
        shape.stroke(BaseEntity.getShapeColor(<BorderSegment>shape.attrs));
        shape.strokeWidth(3 / shape.parent.scale().x);
        context.beginPath();
        context.moveTo(shape.attrs.start.x, shape.attrs.start.y);
        context.lineTo(shape.attrs.end.x, shape.attrs.end.y);
        context.closePath();
        context.fillStrokeShape(shape);
    }
}
