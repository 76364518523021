export enum ZoneType {
    FamilyHouse = 'FamilyHouse',
    ApartmentsHouse = 'ApartmentsHouse',
    OfficeBuilding = 'OfficeBuilding',
    EducationBuilding = 'EducationBuilding',
    Medical = 'Medical',
    AccomodationCatering = 'AccomodationCatering',
    Sport = 'Sport',
    Commercial = 'Commercial',
    Culture = 'Culture',
}

export const ZONE_TYPE_OPTIONS = [
    { value: ZoneType.FamilyHouse, label: 'Rodinný dům' },
    { value: ZoneType.ApartmentsHouse, label: 'Bytový dům' },
    { value: ZoneType.OfficeBuilding, label: 'Administrativní budova' },
    { value: ZoneType.EducationBuilding, label: 'Budova pro vzdělání' },
    { value: ZoneType.Medical, label: 'Zdravotnické zařízení' },
    { value: ZoneType.AccomodationCatering, label: 'Ubytovací a stravovací zařízení' },
    { value: ZoneType.Sport, label: 'Sportovní zařízení' },
    { value: ZoneType.Commercial, label: 'Budova pro obchodní účely' },
    { value: ZoneType.Culture, label: 'Kulturní zařízení' },
];