import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { EntityType } from '../../models/enums/entityType';
import { WindowProperties, WINDOW_TYPE_OPTIONS } from '../../models/window-properties.model';
import { CommonTools } from '../../shared/helpers/common-tools';
import { CatalogueListColumn } from '../readonly-list.component/readonly-list.component';
import { WINDOW_GLAZING_OPTIONS, WINDOW_MATERIAL } from './../../models/window-properties.model';
import { ModelService } from './../../services/model.service';
import { WindowPropertiesModalComponent } from './window-properties-modal/window-properties-modal.component';

@Component({
    selector: 'app-windows-properties-list',
    templateUrl: './windows-properties-list.component.html',
    styleUrls: ['./windows-properties-list.component.scss']
})
export class WindowsPropertiesListComponent implements OnInit {

    dataSource: WindowProperties[] = [];

    constructor(private model: ModelService,
        private mbdModalService: MDBModalService) {
    }

    ngOnInit() {
        this.dataSource = this.model.WindowProperties;
    }

    defineColumns(): CatalogueListColumn[] {
        const columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = 'Jméno';
        column.dataSourceColumnName = 'name';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Typ';
        column.dataSourceColumnName = 'type';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = WINDOW_TYPE_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        column.allColumnOptions = WINDOW_TYPE_OPTIONS;
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Zasklení';
        column.dataSourceColumnName = 'glazing';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = WINDOW_GLAZING_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        column.allColumnOptions = WINDOW_GLAZING_OPTIONS;
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Materiál';
        column.dataSourceColumnName = 'material';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = WINDOW_MATERIAL.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        column.allColumnOptions = WINDOW_MATERIAL;
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'SVT kód';
        column.dataSourceColumnName = 'svtCode';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Popis';
        column.dataSourceColumnName = 'description';
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onCopyOne(externalId: string) {
        var sample: WindowProperties = this.model.WindowProperties.find(item => item.externalId == externalId);
        var clone = Object.assign(new WindowProperties(), sample);
        clone.externalId = CommonTools.GetUUID();
        this.model.AddEntity(clone);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.WindowProperties, externalId);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    private openModal(externalId?: string) {
        const modalRef = this.mbdModalService.show(WindowPropertiesModalComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: externalId
            }
        });
    }
}
