export enum Role {
    ADMINISTRATOR = 'administrator',
    INTERNAL_TECHNIC = 'INTERNAL_TECHNIC',
    EXTERNAL_TECHNIC = 'EXTERNAL_TECHNIC'
}

export const ROLE_OPTIONS = [
    { value: Role.ADMINISTRATOR, label: 'Administrator' },
    { value: Role.INTERNAL_TECHNIC, label: 'Internal technic' },
    { value: Role.EXTERNAL_TECHNIC, label: 'External technic' },
]
