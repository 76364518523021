export enum NonHeatedSpaceType {
    ATTIC = 'ATTIC',
    WINTER_GARDEN = 'WINTER_GARDEN',
    LOWER_FLOOR = 'LOWER_FLOOR',
    OTHER = 'OTHER',
}

export enum ThermalRegime {
    OUTSIDE_AIR = 'OUTSIDE_AIR',
    SOIL = 'SOIL',
    NON_HEATED = 'NON_HEATED',
    NEIGHBOR_HOUSE = 'NEIGHBOR_HOUSE',
    HEATED = 'HEATED'
}

export enum LightSharing {
    DIRECT = 'DIRECT',
    INDIRECT = 'INDIRECT',
    OTHER = 'OTHER'
}

export const NON_HEATED_SPACE_TYPE_OPTIONS = [
    { value: NonHeatedSpaceType.ATTIC, label: 'Půda', externalId: 0 },
    { value: NonHeatedSpaceType.WINTER_GARDEN, label: 'Zimní zahrada', externalId: 1 },
    { value: NonHeatedSpaceType.LOWER_FLOOR, label: 'Spodní podlaží', externalId: 2 },
    { value: NonHeatedSpaceType.OTHER, label: 'Jiný', externalId: 3 },
];

export const THERMAL_REGIME_OPTIONS = [
    { value: ThermalRegime.OUTSIDE_AIR, label: 'Vnější vzduch', selected: false, externalId: 0 },
    { value: ThermalRegime.SOIL, label: 'Zemina', selected: false, externalId: 1 },
    { value: ThermalRegime.NON_HEATED, label: 'Nevytápěný prostor', selected: false, externalId: 2 },
    { value: ThermalRegime.NEIGHBOR_HOUSE, label: 'Sousední objekt', selected: false, externalId: 3 },
    { value: ThermalRegime.HEATED, label: 'Vytápěný prostor', selected: false, externalId: 4 },
];
export const THERMAL_REGIME_OPTIONS_REDUCED = [
    { value: ThermalRegime.NON_HEATED, label: 'Nevytápěný prostor', externalId: 2 },
    { value: ThermalRegime.HEATED, label: 'Vytápěný prostor', externalId: 4 },
];

export const THERMAL_REGIME_UNDER_FLOOR_OPTIONS_REDUCED = [
    { value: ThermalRegime.OUTSIDE_AIR, label: 'Vnější vzduch', externalId: 0 },
    { value: ThermalRegime.SOIL, label: 'Zemina', externalId: 1 },
];

export const LIGHT_SHARING_TYPES = [
    { value: LightSharing.DIRECT, label: 'Přímé', selected: false },
    { value: LightSharing.INDIRECT, label: 'Nepřímé', selected: false },
    { value: LightSharing.OTHER, label: 'Jiné', selected: false }
];
