import { Line, Point2D } from "../shared/helpers/geometry";
import { BaseEntity } from "./base-entity.model";
import { Point } from "./point.model";
import { StoreyEntity } from "./storey-entity.model";

export abstract class SegmentEntity extends StoreyEntity {
    start: Point = new Point();
    end: Point = new Point();

    static drawAxis(context: any, shape: any, start: { x: number, y: number }, end: { x: number, y: number }, color: string, width: number) {
        shape.stroke(BaseEntity.getShapeColor(<SegmentEntity>shape.attrs), color);
        shape.strokeWidth(width / shape.parent.scale().x);
        context.beginPath();
        context.moveTo(start.x, start.y);
        context.lineTo(end.x, end.y);
        context.closePath();
        context.fillStrokeShape(shape);
    }
    static drawSelectRect(context: any, shape: any, start: { x: number, y: number }, end: { x: number, y: number }) {
        shape.stroke('transparent');
        shape.fill('transparent');
        const axis = new Line(new Point2D(start), new Point2D(end));
        const offsetedLeft = axis.offsetted(10 / shape.parent.scale().x);
        const offsetedRight = axis.offsetted(-10 / shape.parent.scale().x);

        context.beginPath();
        context.moveTo(offsetedLeft.start.x, offsetedLeft.start.y);
        context.lineTo(offsetedLeft.end.x, offsetedLeft.end.y);
        context.lineTo(offsetedRight.end.x, offsetedRight.end.y);
        context.lineTo(offsetedRight.start.x, offsetedRight.start.y);
        context.lineTo(offsetedLeft.start.x, offsetedLeft.start.y);
        context.closePath();
        context.fillStrokeShape(shape);
    }
}