import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private authService: AuthenticationService;

    constructor(private injector: Injector) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authService = this.injector.get(AuthenticationService);
        const token: string = this.authService.getToken();
        const requestClone = request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
        return next.handle(requestClone);
    }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
                   .pipe(catchError(response => {
                       if (response instanceof HttpErrorResponse && response.status === 401) {
                           localStorage.removeItem('token');
                           this.router.navigateByUrl('/login');
                       }
                       return throwError(response);
                   }));
    }
}
