import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { EntityType } from '../../../models/enums/entityType';
import { ModelService } from '../../../services/model.service';
import { Defaults } from '../../../models/defaults.model';
import { COVERAGE_TYPE_OPTIONS, WindowProperties, WINDOW_GLAZING_OPTIONS, WINDOW_MATERIAL, WINDOW_TYPE_OPTIONS } from '../../../models/window-properties.model';
import { FloorDefinition } from '../../../models/floor-definition.model';
import { RoofDefinition } from '../../../models/roof-definition.model';
import { StructureSet } from '../../../models/structure-set.model';
import { DoorProperties, DOOR_CATEGORY_OPTIONS, DOOR_MATERIAL, DOOR_TYPE_OPTIONS } from '../../../models/door-properties.model';
import { IEntityWithDefaults } from '../../../models/interfaces/IEntityWithDefaults';
import { CHANGEREASON_OPTIONS } from '../../../models/enums/changeReason';
import { Shielding } from '../../../models/shielding.model';
import { SpaceProperties } from '../../../models/space-properties.model';
import { LIGHT_SHARING_TYPES, NON_HEATED_SPACE_TYPE_OPTIONS, ThermalRegime, THERMAL_REGIME_OPTIONS, THERMAL_REGIME_OPTIONS_REDUCED } from '../../../models/enums/Space-properties-enums.model';
import { FILTERED_ROOM_TYPES, RoomTypes, ROOM_TYPES } from '../../../models/enums/RoomType';
import { LIGHT_CONTROL_TYPES } from '../../../models/enums/lightControl';
import { ZoneTemplate } from '../../../models/zoneTemplate.model';
import { Project } from '../../../models/project.model';
import { Building } from '../../../models/building.model';
import { Storey } from '../../../models/storey.model';
@Component({
    selector: 'app-defaults-editor',
    templateUrl: './defaults-editor.component.html',
    styleUrls: ['./defaults-editor.component.scss'],
})
export class DefaultsEditorComponent implements OnInit {
    externalId: string;
    ownerId: string;
    ownerType: EntityType;
    defaultsOwner: IEntityWithDefaults = null;

    defaults: Defaults = null;

    floorDefinitions: FloorDefinition[] = [];
    roofDefinitions: RoofDefinition[] = [];
    structureSets: StructureSet[] = [];
    doorProperties: DoorProperties[] = [];
    windowProperties: WindowProperties[] = [];
    shieldings: Shielding[] = [];
    spaceProperties: SpaceProperties[] = [];
    spacePropertiesCompare: SpaceProperties[] = [];

    coverageTypes = COVERAGE_TYPE_OPTIONS;
    CHANGEREASON_OPTIONS = CHANGEREASON_OPTIONS;
    COVERAGE_TYPE_OPTIONS = COVERAGE_TYPE_OPTIONS;

    // mistnost
    THERMAL_REGIME_OPTIONS_REDUCED = THERMAL_REGIME_OPTIONS_REDUCED;
    NON_HEATED_SPACE_TYPE_OPTIONS = NON_HEATED_SPACE_TYPE_OPTIONS;
    properties = <SpaceProperties>{};
    THERMAL_REGIMES = ThermalRegime;
    structurePropertiesOptions = [];
    zoneOptions = [];
    roomTypes_I = ROOM_TYPES;
    roomTypes_F = ROOM_TYPES;
    ventilationOptions = [];
    lightSharingTypes = LIGHT_SHARING_TYPES;
    lightControlTypes = LIGHT_CONTROL_TYPES;
    templates: { value: string, label: string, item: ZoneTemplate, selected: boolean }[];
    activeTemplate: ZoneTemplate = new ZoneTemplate();

    isNewDefaults: boolean;

    public get ownerEntityIsNotProject() {
        return this.ownerType !== EntityType.Project;
    }

    constructor(public model: ModelService, private mdbModalService: MDBModalService) { }

    ngOnInit() {
        this.doorProperties = this.model.DoorProperties;
        this.windowProperties = this.model.WindowProperties;
        this.shieldings = this.model.SelectedProject.shieldings;
        this.structureSets = this.model.SelectedProject.structureSet;
        this.floorDefinitions = this.model.SelectedProject.floorDefinition;
        this.roofDefinitions = this.model.SelectedProject.roofDefinition;
        this.defaultsOwner = this.model.getEntityByExternalId(this.ownerType, this.ownerId);
        this.defaults = this.defaultsOwner.defaults;
        if(this.defaults == null)  this.defaults = this.model.Defaults[0];
        console.log('OnInit:' + this.ownerType);
        if (this.defaults) {
            this.isNewDefaults = false;
        } else {
            switch (this.ownerType) {
                case EntityType.Project:
                    throw new Error('Project.Default is NULL.');    // toto by nikdy nemelo nastat
                case EntityType.Building:
                    console.log('Kopirujem z urovne projekt');
                    this.copyPropertiesFromProject();
                    break;
                case EntityType.Storey:
                    if (this.model.SelectedBuilding.defaults) {
                        console.log('Kopirujem z urovne building');
                        this.copyPropertiesFromBuilding();
                    } else {
                        console.log('Kopirujem z urovne projekt');
                        this.copyPropertiesFromProject();
                    }
                    break;
            }
            this.isNewDefaults = true;
        }
        console.log('newDefaults:' + this.isNewDefaults);

        this.structurePropertiesOptions = this.model.SelectedProject.structureProperties;
    }
    parentProperties() {
        switch (this.ownerType) {
            case EntityType.Project:
                return this.model.SelectedProject.defaults.defaultSpaceProperties;
            case EntityType.Building:
                return this.model.SelectedProject.defaults.defaultSpaceProperties;
            case EntityType.Storey:
                if (this.model.SelectedBuilding.defaults) {
                    return this.model.SelectedBuilding.defaults.defaultSpaceProperties;
                } else {
                    return this.model.SelectedProject.defaults.defaultSpaceProperties;
                }
        }
    }

    copyPropertiesFromBuilding() {
        //const wallSegment = ModelService.deepCopy(this.model.SelectedBuilding.defaults.defaultDoor.wallSegment);
        this.defaults = ModelService.deepCopy(this.model.SelectedBuilding.defaults);
        this.defaults.structureSet = ModelService.deepCopy(this.model.SelectedBuilding.defaults.structureSet);
        this.defaults.defaultDoor = ModelService.deepCopy(this.model.SelectedBuilding.defaults.defaultDoor);
        //this.defaults.defaultDoor.wallSegment = wallSegment;
        this.defaults.defaultWindow = ModelService.deepCopy(this.model.SelectedBuilding.defaults.defaultWindow);
       // this.defaults.defaultWindow.wallSegment = wallSegment;
        this.defaults.defaultSpaceProperties = ModelService.deepCopy(this.model.SelectedBuilding.defaults.defaultSpaceProperties);
    }
    copyPropertiesFromProject() {
        //const wallSegment = ModelService.deepCopy(this.model.SelectedProject.defaults.defaultDoor.wallSegment);
        this.defaults = ModelService.deepCopy(this.model.SelectedProject.defaults);
        this.defaults.structureSet = ModelService.deepCopy(this.model.SelectedProject.defaults.structureSet);
        this.defaults.defaultDoor = ModelService.deepCopy(this.model.SelectedProject.defaults.defaultDoor);
        //this.defaults.defaultDoor.wallSegment = wallSegment;
        this.defaults.defaultWindow = ModelService.deepCopy(this.model.SelectedProject.defaults.defaultWindow);
        //this.defaults.defaultWindow.wallSegment = wallSegment;
        this.defaults.defaultSpaceProperties = ModelService.deepCopy(this.model.SelectedProject.defaults.defaultSpaceProperties);
    }

    onSave() {
        if (this.isNewDefaults) {
            this.model.AddEntities([
                [this.defaults.structureSet],       
                [this.defaults.defaultDoor],
                [this.defaults.defaultWindow],
                [this.defaults.defaultSpaceProperties],
                [this.defaults],
            ]).then(() => {
                this.defaultsOwner.defaults = this.defaults;
                this.model.UpdateEntity(this.defaultsOwner);
            });
        } else {
            this.model.UpdateEntity(this.defaults);
            this.model.UpdateEntity(this.defaults.defaultDoor);
            this.model.UpdateEntity(this.defaults.defaultWindow);
            this.model.UpdateEntity(this.defaults.defaultSpaceProperties);
        }

        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }

    onDelete() {
        this.defaultsOwner.defaults = null;
        this.model.UpdateEntity(this.defaultsOwner).then(() => {
            this.model.RemoveEntityByExternalId(EntityType.Defaults, this.defaults.externalId);
        });
        this.onCancel();
    }

    toggleTable(tableId: string) {
        document.getElementById(tableId).classList.toggle('hidden');
    }

    public getFilteredRoomTypes(propertyName: string): any {
        if (this.properties[propertyName]) {
            const zoneOption = this.zoneOptions.find(z => z.item.externalId = this.properties[propertyName].externalId);
            if (zoneOption) {
                const zoneDefinition = (propertyName === 'zone_I' ? zoneOption.item.initialDefinition : zoneOption.item.finalDefinition);
                return FILTERED_ROOM_TYPES(zoneDefinition.zoneType);
            }
        }
        return [];
    }

    zoneChanged() {
        this.roomTypes_I = this.getFilteredRoomTypes('zone_I').map(v => ROOM_TYPES.find(r => r.value === v));
        this.roomTypes_F = this.getFilteredRoomTypes('zone_F').map(v => ROOM_TYPES.find(r => r.value === v));
    }

    applyZoneTemplate(selectedItem) {
        const template = this.templates.find(t => t.item.roomType === selectedItem.value);
        if (template) {
            this.properties.ema_I = template.item.ema; this.properties.ema_F = template.item.ema;
            this.properties.fLA_I = template.item.fla; this.properties.fLA_F = template.item.fla;
            this.properties.l_a_I = template.item.l_a; this.properties.l_a_F = template.item.l_a;
            this.properties.mwa_I = template.item.mwa; this.properties.mwa_F = template.item.mwa;
            this.properties.tco_I = template.item.tco; this.properties.tco_F = template.item.tco;
            this.properties.tcp_I = template.item.tcp; this.properties.tcp_F = template.item.tcp;
            this.properties.tho_I = template.item.tho; this.properties.tho_F = template.item.tho;
            this.properties.thp_I = template.item.thp; this.properties.thp_F = template.item.thp;
            this.properties.ddu_I = template.item.ddu; this.properties.ddu_F = template.item.ddu;
            this.properties.dhc_I = template.item.dhc; this.properties.dhc_F = template.item.dhc;
            this.properties.dhh_I = template.item.dhh; this.properties.dhh_F = template.item.dhh;
            this.properties.dhu_I = template.item.dhu; this.properties.dhu_F = template.item.dhu;
            this.properties.dhv_I = template.item.dhv; this.properties.dhv_F = template.item.dhv;
            this.properties.fAP_I = template.item.fap; this.properties.fAP_F = template.item.fap;
            this.properties.fOc_I = template.item.foc; this.properties.fOc_F = template.item.foc;
            this.properties.kra_I = template.item.kra; this.properties.kra_F = template.item.kra;
            this.properties.n_a_I = template.item.n_a; this.properties.n_a_F = template.item.n_a;
            this.properties.qAP_I = template.item.qap; this.properties.qAP_F = template.item.qap;
            this.properties.qOc_I = template.item.qoc; this.properties.qOc_F = template.item.qoc;
            this.properties.tDa_I = template.item.tda; this.properties.tDa_F = template.item.tda;
            this.properties.tNa_I = template.item.tna; this.properties.tNa_F = template.item.tna;
            this.properties.tue_I = template.item.tue; this.properties.tue_F = template.item.tue;
            this.properties.tus_I = template.item.tus; this.properties.tus_F = template.item.tus;
            this.properties.tve_I = template.item.tve; this.properties.tve_F = template.item.tve;
            this.properties.tvs_I = template.item.tvs; this.properties.tvs_F = template.item.tvs;
            this.properties.vvl_I = template.item.vvl; this.properties.vvl_F = template.item.vvl;
            this.properties.vvs_I = template.item.vvs; this.properties.vvs_F = template.item.vvs;
            this.properties.xss_I = template.item.xss; this.properties.xss_F = template.item.xss;
            this.properties.xsw_I = template.item.xsw; this.properties.xsw_F = template.item.xsw;

            this.activeTemplate = template.item;
        }
    }

    private translateWindowType = (value: string) => WINDOW_TYPE_OPTIONS.find((item) => item.value === value).label;
    private translateWindowGlazing = (value: string) => WINDOW_GLAZING_OPTIONS.find((item) => item.value === value).label;
    private translateWindowMaterial = (value: string) => WINDOW_MATERIAL.find((item) => item.value === value).label;
    private translateDoorType = (value: string) => DOOR_TYPE_OPTIONS.find((item) => item.value === value).label;
    private translateDoorCategory = (value: string) => DOOR_CATEGORY_OPTIONS.find((item) => item.value === value).label;
    private translateDoorMaterial = (value: string) => DOOR_MATERIAL.find((item) => item.value === value).label;
}
