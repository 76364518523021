import { ModelService } from './../../../services/model.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { CatalogueListColumn } from '../../readonly-list.component/readonly-list.component';
import { Material, MATERIAL_GROUP_OPTIONS, MATERIAL_TYPE_OPTIONS } from '../../../models/material.model';
import { MaterialDetailComponent } from '../../material-detail/material-detail.component';
import { MDBModalService } from 'angular-bootstrap-md';

@Component({
    selector: 'app-material-select-form',
    templateUrl: './material-select-form.component.html',
    styleUrls: ['./material-select-form.component.scss']
})
export class MaterialSelectFormComponent implements OnInit {

    @ViewChild(MaterialDetailComponent) detailComponent: MaterialDetailComponent;

    materials: Material[]
    selectedMaterial: Material;

    constructor(
        private model: ModelService,
        private mdbModalService: MDBModalService,
    ) { }

    ngOnInit() {
        this.materials = this.model.Materials;
        this.selectedMaterial = this.materials[0];
    }

    defineColumns(): CatalogueListColumn[] {
        const columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = 'Group';
        column.dataSourceColumnName = 'group';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = MATERIAL_GROUP_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Typ';
        column.dataSourceColumnName = 'type';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = MATERIAL_TYPE_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        column.allColumnOptions = MATERIAL_TYPE_OPTIONS;
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Jméno';
        column.dataSourceColumnName = 'name';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'SVT kód';
        column.dataSourceColumnName = 'svtCode';
        columns.push(column);

        return columns;
    }

    onSelect(materialExternalId: string) {
        this.selectedMaterial = this.materials.find(material => material.externalId == materialExternalId);
    }

    onOK() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }
}