import { DrawingSetup } from './../../../services/drawing-setup';
import { ModelService } from './../../../services/model.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { LIGHT_CONTROL_TYPES } from '../../../models/enums/lightControl';
import { FILTERED_ROOM_TYPES, ROOM_TYPES } from '../../../models/enums/RoomType';
import { STOREY_ENTITY_STATE_OPTIONS } from '../../../models/enums/StoreyEntityState';
import { ProjectIntention } from '../../../models/project.model';
import { LIGHT_SHARING_TYPES, NON_HEATED_SPACE_TYPE_OPTIONS, ThermalRegime, THERMAL_REGIME_OPTIONS, THERMAL_REGIME_OPTIONS_REDUCED } from '../../../models/enums/Space-properties-enums.model';
import { SpaceProperties } from '../../../models/space-properties.model';
import { StructureProperties } from '../../../models/structure-properties.model';
import { RestService } from '../../../services/rest.service';
import { StructurePropertiesEditor } from '../structureProperties-editor/structureProperties-editor';
import { Zone } from '../../../models/zone.model';
import { EntityType } from '../../../models/enums/entityType';
import { RoofWindowEditor } from '../roof-window-editor/roof-window-editor.component';
import { Subscription } from 'rxjs';
import { ZoneTemplate } from '../../../models/zoneTemplate.model';

@Component({
    selector: 'app-space-properties-editor',
    templateUrl: 'space-properties-editor.component.html',
    styleUrls: ['space-properties-editor.component.scss'],
    animations: [
        trigger('inOutAnim', [
            transition(':enter', [
                style({ transform: 'translate(0, -25px)', opacity: 0 }),
                animate('0.25s ease-out', style({ transform: 'translate(0, 0)', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ transform: 'translate(0, 0)', opacity: 1 }),
                animate('0.25s ease-out', style({ transform: 'translate(0, -25px)', opacity: 0 })),
            ]),
        ]),
    ],
})
export class SpacePropertiesEditorComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    externalId: string;
    zoneOptions = [];
    structurePropertiesOptions = [];
    ventilationOptions = [];
    storeyEntityStateOptions = STOREY_ENTITY_STATE_OPTIONS;
    roomTypes_I = ROOM_TYPES;
    roomTypes_F = ROOM_TYPES;
    NON_HEATED_SPACE_TYPE_OPTIONS = NON_HEATED_SPACE_TYPE_OPTIONS;
    lightSharingTypes = LIGHT_SHARING_TYPES;
    THERMAL_REGIME_OPTIONS = THERMAL_REGIME_OPTIONS;
    THERMAL_REGIME_OPTIONS_REDUCED = THERMAL_REGIME_OPTIONS_REDUCED;
    lightControlTypes = LIGHT_CONTROL_TYPES;
    THERMAL_REGIMES = ThermalRegime;
    spacePropertiesCompare: SpaceProperties[] = [];
    properties: SpaceProperties = <SpaceProperties>{};
    isDefaultRoom = false;
    isReconstruction = false;
    templates: { value: string, label: string, item: ZoneTemplate, selected: boolean }[];
    activeTemplate: ZoneTemplate = new ZoneTemplate();

    constructor(
        private model: ModelService,
        private rest: RestService,
        private mdbModalService: MDBModalService
    ) {
    }

    ngOnInit() {
        this.initData(this.externalId);
        this.model.SelectedProject.intention = ProjectIntention.RECONSTRUCTION;
        this.structurePropertiesOptions = this.model.SelectedProject.structureProperties;
        this.ventilationOptions = this.model.SelectedProject.ventilations;

        this.subscription = this.rest.post('zoneProfile/list', {}).subscribe(data => {
            this.templates = data.content.map(p => {
                return {
                    value: p.externalId,
                    label: p.building + ' ' + p.room,
                    item: p,
                    selected: false,
                };
            });

            const zoneTemplate = this.templates.find(t => t.item.roomType === this.properties.roomType_I);
            if (zoneTemplate) {
                this.activeTemplate = zoneTemplate.item;
            }
        });

    }

    onSave() {
        this.saveData();
        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }
    onCancel() {
        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }

    toggleTable(tableId: string) {
        document.getElementById(tableId).classList.toggle('hidden');
    }

    public isPropertyVisible(propertyName: string): boolean {
        switch (propertyName) {
            case 'heated_I':
                return this.properties.thermalRegime_I === this.THERMAL_REGIMES.HEATED;
            case 'heated_F':
                return this.properties.thermalRegime_F === this.THERMAL_REGIMES.HEATED;
            case 'nonHeated_I':
                return this.properties.thermalRegime_I === this.THERMAL_REGIMES.NON_HEATED;
            case 'nonHeated_F':
                return this.properties.thermalRegime_F === this.THERMAL_REGIMES.NON_HEATED;
            case 'roomType_I':
                return (
                    this.properties.thermalRegime_I !== this.THERMAL_REGIMES.OUTSIDE_AIR &&
                    this.properties.thermalRegime_I !== this.THERMAL_REGIMES.SOIL
                );
            case 'roomType_F':
                return (
                    this.properties.thermalRegime_F !== this.THERMAL_REGIMES.OUTSIDE_AIR &&
                    this.properties.thermalRegime_F !== this.THERMAL_REGIMES.SOIL
                );
            default:
                return true;
        }
    }

    private initData(propertiesId?: string) {
        // fetchning of window
        if (propertiesId) {
            this.properties = this.model.getEntityByExternalId(EntityType.SpaceProperties, propertiesId);
            this.initForm();
        }

        this.zoneOptions = this.model.SelectedProject.zones.map(z => {
            return {
                value: z['externalId'],
                label: z['description'],
                item: z,
                selected: false,
            };
        });
        this.zoneChanged();
    }

    private saveData() {
        if (this.properties.zone_I.externalId === null) { delete this.properties.zone_I; }
        if (this.properties.zone_F.externalId === null) { delete this.properties.zone_F; }

        this.model.UpdateEntity(this.properties).then(() => {
            this.model.updateStructureKinds(DrawingSetup.SelectedStateToDraw);
        });
    }

    public openCompositionEditor(propertyName: string) {
        this.mdbModalService.show(StructurePropertiesEditor, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: this.properties[propertyName].externalId,
            },
        });
    }

    openRoofWindowEditor() {
        this.mdbModalService.show(RoofWindowEditor, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: this.externalId,
            },
        });
    }

    public getFilteredRoomTypes(propertyName: string): any {
        if (this.properties[propertyName]) {
            const zoneOption = this.zoneOptions.find(z => z.item.externalId === this.properties[propertyName].externalId);
            if (zoneOption) {
                const zoneDefinition = (propertyName === 'zone_I' ? zoneOption.item.initialDefinition : zoneOption.item.finalDefinition);
                return FILTERED_ROOM_TYPES(zoneDefinition.zoneType);
            }
        }
        return [];
    }

    zoneChanged() {
        this.roomTypes_I = this.getFilteredRoomTypes('zone_I').map(v => ROOM_TYPES.find(r => r.value === v));
        this.roomTypes_F = this.getFilteredRoomTypes('zone_F').map(v => ROOM_TYPES.find(r => r.value === v));
    }

    public applyZoneTemplate(selectedItem) {
        const template = this.templates.find(t => t.item.roomType === selectedItem.value);
        if (template) {
            this.properties.ema_I = template.item.ema; this.properties.ema_F = template.item.ema;
            this.properties.fLA_I = template.item.fla; this.properties.fLA_F = template.item.fla;
            this.properties.l_a_I = template.item.l_a; this.properties.l_a_F = template.item.l_a;
            this.properties.mwa_I = template.item.mwa; this.properties.mwa_F = template.item.mwa;
            this.properties.tco_I = template.item.tco; this.properties.tco_F = template.item.tco;
            this.properties.tcp_I = template.item.tcp; this.properties.tcp_F = template.item.tcp;
            this.properties.tho_I = template.item.tho; this.properties.tho_F = template.item.tho;
            this.properties.thp_I = template.item.thp; this.properties.thp_F = template.item.thp;
            this.properties.ddu_I = template.item.ddu; this.properties.ddu_F = template.item.ddu;
            this.properties.dhc_I = template.item.dhc; this.properties.dhc_F = template.item.dhc;
            this.properties.dhh_I = template.item.dhh; this.properties.dhh_F = template.item.dhh;
            this.properties.dhu_I = template.item.dhu; this.properties.dhu_F = template.item.dhu;
            this.properties.dhv_I = template.item.dhv; this.properties.dhv_F = template.item.dhv;
            this.properties.fAP_I = template.item.fap; this.properties.fAP_F = template.item.fap;
            this.properties.fOc_I = template.item.foc; this.properties.fOc_F = template.item.foc;
            this.properties.kra_I = template.item.kra; this.properties.kra_F = template.item.kra;
            this.properties.n_a_I = template.item.n_a; this.properties.n_a_F = template.item.n_a;
            this.properties.qAP_I = template.item.qap; this.properties.qAP_F = template.item.qap;
            this.properties.qOc_I = template.item.qoc; this.properties.qOc_F = template.item.qoc;
            this.properties.tDa_I = template.item.tda; this.properties.tDa_F = template.item.tda;
            this.properties.tNa_I = template.item.tna; this.properties.tNa_F = template.item.tna;
            this.properties.tue_I = template.item.tue; this.properties.tue_F = template.item.tue;
            this.properties.tus_I = template.item.tus; this.properties.tus_F = template.item.tus;
            this.properties.tve_I = template.item.tve; this.properties.tve_F = template.item.tve;
            this.properties.tvs_I = template.item.tvs; this.properties.tvs_F = template.item.tvs;
            this.properties.vvl_I = template.item.vvl; this.properties.vvl_F = template.item.vvl;
            this.properties.vvs_I = template.item.vvs; this.properties.vvs_F = template.item.vvs;
            this.properties.xss_I = template.item.xss; this.properties.xss_F = template.item.xss;
            this.properties.xsw_I = template.item.xsw; this.properties.xsw_F = template.item.xsw;

            this.activeTemplate = template.item;
        }
    }

    public isNotDefaultPropertyValue(propertyName: string): boolean {
        return false;
    }

    private initForm() {
        if (!this.properties.zone_I) { this.properties.zone_I = <Zone>{ externalId: null }; }
        if (!this.properties.zone_F) { this.properties.zone_F = <Zone>{ externalId: null }; }
        if (!this.properties.floorComposition_I) { this.properties.floorComposition_I = new StructureProperties(); }
        if (!this.properties.floorComposition_F) { this.properties.floorComposition_F = new StructureProperties(); }
        this.zoneChanged();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    parentProperties() {
        if (this.model.SelectedStorey.defaults) {
            return this.model.SelectedStorey.defaults.defaultSpaceProperties;
        }
        if (this.model.SelectedBuilding.defaults) {
            return this.model.SelectedBuilding.defaults.defaultSpaceProperties;
        }
        if (this.model.SelectedProject.defaults) {
            return this.model.SelectedProject.defaults.defaultSpaceProperties;
        }
    }
}


