export enum Commands {
    DrawAuxLine,
    DrawWall,
    DrawWindow,
    DrawDoor,
    DrawBorder,
    DrawSpaceProperties,
    Shift,
    Move,
    Copy,
    SplitSegment,
    Delete,
    Undo,
    Redo,
    CopyToStoreys,
    ShowRooms,
    ShowZones,
    ShowRoofs,
    ShowIsoAreas,
    SoilHeight,
    FloorProperties,
    RoofProperties,
}
