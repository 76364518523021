import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { EntityType } from '../../models/enums/entityType';
import { Zone } from '../../models/zone.model';
import { ModelService } from '../../services/model.service';
import { CommonTools } from '../../shared/helpers/common-tools';
import { ZoneEditorComponent } from '../components/zone-editor/zone-editor.component';
import { CatalogueListColumn } from '../readonly-list.component/readonly-list.component';

@Component({
    selector: 'app-zone-list',
    templateUrl: './zone-list.component.html',
    styleUrls: ['./zone-list.component.scss'],
})
export class ZoneListComponent implements OnInit {
    dataSource: Zone[] = [];

    constructor(private model: ModelService,
        private mbdModalService: MDBModalService) {
    }

    ngOnInit() {
        this.model.SelectedProject$.subscribe(project => {
            var allZones = [];
            project.buildings.forEach(building => allZones.push(...building.zones));
            this.dataSource = allZones;
        })
    }

    defineColumns(): CatalogueListColumn[] {
        const columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = 'Jméno';
        column.dataSourceColumnName = 'zoneName';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Deaktivovaná';
        column.dataSourceColumnName = 'inactive';
        column.columnValueTranslator = (inactive: boolean) => {
            return inactive ? "ANO" : "NE";
        };
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onCopyOne(externalId: string) {
        var zone: Zone = this.model.SelectedProject.zones.find(m => m.externalId == externalId);
        var clone = Object.assign(new Zone(), zone);
        clone.externalId = CommonTools.GetUUID();
        this.model.AddEntity(clone);
    }


    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.Zone, externalId);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    private openModal(externalId?: string) {
        const modalRef = this.mbdModalService.show(ZoneEditorComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: externalId
            }
        });
    }
}
