import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { Role } from './user.model';

export class PlatformUser extends BaseEntity {
    entityType: EntityType = EntityType.User;
    fullname?: string;
    username: string;
    password?: string;
    role?: Role;
    email?: string;
    phone?: string;
    [key: string]: any;
}
