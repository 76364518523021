import { meta } from 'ngx-property-grid';
import { EntityType } from '../../../../models/enums/entityType';


export class RoofPropertiesPanelProperties  {
    type = EntityType.SoilHeight;

    @meta({
        name: 'Sklon', group: 'Střecha', options: 'slope', valueConvert: parseFloat, type: 'number'
    })
    slope;
    @meta({
        name: 'Výškový bod začátku [m]', group: 'Hřeben', options: 'ridgeStartHeight', valueConvert: parseFloat, type: 'number'
    })
    ridgeStartHeight;
    @meta({
        name: 'Výškový bod konce [m]', group: 'Hřeben', options: 'ridgeEndHeight', valueConvert: parseFloat, type: 'number'
    })
    ridgeEndHeight;

    constructor(element?: any, data?: any) {
        this.ridgeStartHeight = element.ridgeStartHeight;
        this.ridgeEndHeight = element.ridgeEndHeight;
        this.slope = element.slope;
    }
}
