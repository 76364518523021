import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { WindowPropertiesModalComponent } from '../windows-properties-list/window-properties-modal/window-properties-modal.component';

/**
 * This component represents modal defaultWindow for catalogue entity properties. It contains a Save button for saving the properties to BE.
 */
@Component({
  selector: 'app-editable-list-modal',
  templateUrl: './catalogue-modal.component.html',
  styleUrls: ['./catalogue-modal.component.scss']
})
export class CatalogueModalComponent implements OnInit {

  @ViewChild('modalWindow') modalWindow: ModalDirective;
  @ViewChild(WindowPropertiesModalComponent) windowPropertiesDetailComponent: WindowPropertiesModalComponent;

  @Input('titleText') modalTitle: string;

  constructor() { }

  ngOnInit() {
  }

  openModal(externalId?: string) {
  }

  onSave() {
  }
}
