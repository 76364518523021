import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { DOOR_TYPE_OPTIONS } from '../../models/door-properties.model';
import { EntityType } from '../../models/enums/entityType';
import { ModelService } from '../../services/model.service';
import { CommonTools } from '../../shared/helpers/common-tools';
import { CatalogueListColumn } from '../readonly-list.component/readonly-list.component';
import { DoorProperties, DOOR_CATEGORY_OPTIONS, DOOR_MATERIAL } from './../../models/door-properties.model';
import { DoorPropertiesModalComponent } from './door-properties-modal/door-properties-modal.component';

@Component({
    selector: 'app-doors-properties-list',
    templateUrl: './doors-properties-list.component.html',
    styleUrls: ['./doors-properties-list.component.scss']
})
export class DoorsPropertiesListComponent implements OnInit {

    @ViewChild(DoorPropertiesModalComponent) detailComponent: DoorPropertiesModalComponent;

    dataSource: DoorProperties[] = [];

    constructor(private model: ModelService,
        private mbdModalService: MDBModalService) {
    }

    ngOnInit() {
        this.dataSource = this.model.DoorProperties;
    }

    defineColumns(): CatalogueListColumn[] {
        const columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = 'Jméno';
        column.dataSourceColumnName = 'name';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Typ';
        column.dataSourceColumnName = 'type';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = DOOR_TYPE_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        column.allColumnOptions = DOOR_TYPE_OPTIONS;
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Kategorie';
        column.dataSourceColumnName = 'category';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = DOOR_CATEGORY_OPTIONS.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        column.allColumnOptions = DOOR_CATEGORY_OPTIONS;
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Materiál';
        column.dataSourceColumnName = 'material';
        // translates enum code to name
        column.columnValueTranslator = (columnValue: string) => {
            const typeItems = DOOR_MATERIAL.filter(item => item.value === columnValue);
            if (typeItems && typeItems.length > 0) {
                return typeItems[0].label;
            } else {
                return null;
            }
        };
        column.allColumnOptions = DOOR_MATERIAL;
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'SVT kód';
        column.dataSourceColumnName = 'svtCode';
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onCopyOne(externalId: string) {
        var sample: DoorProperties = this.model.DoorProperties.find(item => item.externalId == externalId);
        var clone = Object.assign(new DoorProperties(), sample);
        clone.externalId = CommonTools.GetUUID();
        this.model.AddEntity(clone);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.DoorProperties, externalId);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    private openModal(externalId?: string) {
        const modalRef = this.mbdModalService.show(DoorPropertiesModalComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: externalId
            }
        });
    }
}
