import { PointInput, OsnapMode } from '../../models/pointInput';
import Konva from 'konva';
import { SelectionRectangle } from '../../shared/helpers/geometry';

export class AuxLayer extends Konva.Layer {
    public OsnapActive = false;
    private leadLine: Konva.Line;
    private rectangle: Konva.Rect;
    private osnapMarker1: Konva.RegularPolygon;
    private osnapMarker2: Konva.Star;
    private tooltip: Konva.Text;
    private leadStart: { x: number, y: number } = { x: 0, y: 0 };
    private selectionRectStart: { x: number, y: number } = null;

    constructor() {
        super();
        this.leadLine = new Konva.Line({
            points: [0, 0, 0, 0],
            stroke: 'red',
            strokeWidth: 3,
            lineCap: 'round',
            lineJoin: 'round',
            dash: [29, 20, 0.001, 20],
        });
        this.add(this.leadLine);

        this.rectangle = new Konva.Rect({
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            stroke: 'green',
            strokeWidth: 3,
        });
        this.add(this.rectangle);

        this.osnapMarker1 = new Konva.RegularPolygon({
            x: 100,
            y: 150,
            sides: 6,
            radius: 70,
            fill: 'red',
            stroke: 'black',
            strokeWidth: 4,
        });
        this.add(this.osnapMarker1);

        this.osnapMarker2 = new Konva.Star({
            x: 0,
            y: 0,
            numPoints: 6,
            innerRadius: 40,
            outerRadius: 70,
            fill: 'red',
            stroke: 'black',
            strokeWidth: 4,
        });
        this.add(this.osnapMarker2);

        this.tooltip = new Konva.Text({
            x: 0,
            y: 0,
            text: '',
            fontSize: 30,
            fontFamily: 'Calibri',
            fill: 'black'
        });
        this.add(this.tooltip);
    }

    get selectionRectangleActive() {
        return this.rectangle.width() > 0 || this.rectangle.height() > 0;
    }

    public setLeadStart(point: { x: number, y: number }): void {
        this.leadStart = point;
        this.moveTooltip(point);
        this.batchDraw();
    }
    public setLeadEnd(point: { x: number, y: number }): void {
        this.leadLine.points([this.leadStart.x, this.leadStart.y, point.x, point.y]);
        this.leadLine.strokeWidth(3 / this.scale().x);
        this.leadLine.dash([20 / this.scale().x, 12 / this.scale().x, 1 / this.scale().x, 12 / this.scale().x]);
        this.moveTooltip(point);
        this.batchDraw();
    }
    public hideLeadLine(): void {
        this.leadStart = null;
        this.leadLine.points([0, 0, 0, 0]);
        this.batchDraw();
    }
    private moveTooltip(point: { x: number, y: number }): void {
        if (point) {
            this.tooltip.setPosition(point);
            this.tooltip.fontSize(15 / this.scale().x);
            this.tooltip.padding(10 / this.scale().x);
        }
    }
    public updateTooltip(text: string): void {
        this.tooltip.text(text);
        this.batchDraw();
    }

    public setSelectionRectanglePoint(point: { x: number, y: number }, clicked: boolean): SelectionRectangle {
        let result = null;
        if (clicked) {
            if (this.selectionRectStart) {
                result = new SelectionRectangle(this.selectionRectStart.x, this.selectionRectStart.y, point.x, point.y, this.selectionRectStart.x < point.x);
                this.selectionRectStart = null;
                this.rectangle.width(0);
                this.rectangle.height(0);
            } else {
                this.selectionRectStart = { x: point.x, y: point.y };
                this.rectangle.x(point.x);
                this.rectangle.y(point.y);
            }
        } else {
            if (this.selectionRectStart) {
                this.rectangle.x(Math.min(point.x, this.selectionRectStart.x));
                this.rectangle.y(Math.min(point.y, this.selectionRectStart.y));
                this.rectangle.width(Math.abs(point.x - this.selectionRectStart.x));
                this.rectangle.height(Math.abs(point.y - this.selectionRectStart.y));
            }
        }

        this.rectangle.strokeWidth(2 / this.scale().x);
        this.rectangle.dash([10 / this.scale().x, 5 / this.scale().x]);
        this.batchDraw();
        return result;
    }
    public hideSelectionRectangle() {
        this.rectangle.width(0);
        this.rectangle.height(0);
    }

    public updateOsnapMark(pointInput: PointInput): void {
        if (this.OsnapActive) {
            switch (pointInput.PointInputType) {
                case OsnapMode.EXISTING_POINT: {
                    this.osnapMarker1.sides(12);
                    this.osnapMarker1.radius(8 / this.scale().x);
                    this.osnapMarker2.outerRadius(0);
                    break;
                }
                case OsnapMode.NEW: {
                    this.osnapMarker1.radius(0);
                    this.osnapMarker2.numPoints(4);
                    this.osnapMarker2.innerRadius(1 / this.scale().x);
                    this.osnapMarker2.outerRadius(8 / this.scale().x);
                    break;
                }
                case OsnapMode.MID_POINT: {
                    this.osnapMarker1.sides(3);
                    this.osnapMarker1.radius(8 / this.scale().x);
                    this.osnapMarker2.outerRadius(0);
                    break;
                }
                case OsnapMode.NEAREST_ON_LINE: {
                    this.osnapMarker1.radius(0);
                    this.osnapMarker2.numPoints(6);
                    this.osnapMarker2.innerRadius(1 / this.scale().x);
                    this.osnapMarker2.outerRadius(8 / this.scale().x);
                    break;
                }
            }
            this.osnapMarker1.x(pointInput.Location.x);
            this.osnapMarker1.y(pointInput.Location.y);
            this.osnapMarker1.strokeWidth(1 / this.scale().x);
            this.osnapMarker2.x(pointInput.Location.x);
            this.osnapMarker2.y(pointInput.Location.y);
            this.osnapMarker2.strokeWidth(1 / this.scale().x);
        } else {
            this.osnapMarker1.radius(0);
            this.osnapMarker2.outerRadius(0);
        }
        this.batchDraw();
    }
}
