import { CommonTools } from './../shared/helpers/common-tools';
import { EntityType } from "./enums/entityType";
import { StoreyEntity } from './storey-entity.model';

export class CadSelection {
    public entities: StoreyEntity[];

    constructor(items: StoreyEntity[]) {
        this.entities = items;
    }

    public getAllCategories(): EntityType[] {
        return CommonTools.GetDistinctValues(this.entities.map(e => e.entityType));
    }

    public getEntitiesByType(entityType: EntityType): StoreyEntity[] {
        return this.entities.filter(e => e.entityType == entityType);
    }
}

