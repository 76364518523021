export class EntitiesHistory {
    public window = {
        height: null,
    };
    public door = {
        height: null,
    };

    constructor(){
        if(localStorage.getItem('windowHistory')){
            this.window = JSON.parse(localStorage.getItem('windowHistory'));
        }
        if(localStorage.getItem('doorHistory')){
            this.door = JSON.parse(localStorage.getItem('doorHistory'));
        }
    }

    public update(targetObject: any, property: string, newValue: any) {
        switch (targetObject.entityType) {
            case 'Window':
                this.window[property] = newValue;
                localStorage.setItem('windowHistory', JSON.stringify(this.window));
                break;
            case 'Door':
                this.door[property] = newValue;
                localStorage.setItem('doorHistory', JSON.stringify(this.door));
                break;
        }
    }
}
