import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormioModule } from 'angular-formio';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { JsonSchemaFormComponent } from './json-schema-form.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FormioModule,
        MDBBootstrapModule.forRoot(),
        HttpClientModule
    ],
    declarations: [JsonSchemaFormComponent],
    providers: [],
    exports: [JsonSchemaFormComponent]
})
export class JsonSchemaFormModule {
}
