import { STOREY_ENTITY_STATE_OPTIONS } from './../../../../models/enums/StoreyEntityState';
import { IEnumOptions } from './../enum-property/enum-property.component';
import { meta } from 'ngx-property-grid';
import { IDropdown } from '../dropdown-property/dropdown-property.component';
import { first } from 'rxjs/operators';
import { LocationEntity } from '../../../../models/location-entity.model';
import { SegmentEntity } from '../../../../models/segment';
import { StoreyEntity } from '../../../../models/storey-entity.model';
import { BaseEntity } from '../../../../models/base-entity.model';

export class BasePropertyGridEntity {
    constructor(element: BaseEntity, data?: any) {
    }

    setupDropdown(dataSource: any, propertyName: string, selectedExternalId: string, descriptionPropName: string, component: any) {
        this[propertyName] = <IDropdown>{};
        if (Array.isArray(dataSource)) {
            this.mapItems(dataSource, propertyName, selectedExternalId, descriptionPropName, component);
        } else {
            dataSource.pipe(first()).subscribe((items: any[]) => {
                this.mapItems(items, propertyName, selectedExternalId, descriptionPropName, component);
            });
        }
    }

    setupEnum(dataSource: any, propertyName: string, selectedValue: string) {
        this[propertyName] = <IEnumOptions>{
            selectedId: selectedValue,
            options: dataSource,
        };
    }

    setupButton(propertyName: string, component: any, items: any[] = []) {
        this[propertyName] = {
            modal: {
                component: component,
                items: items
            },
        };
    }

    mapItems(items: any[], propertyName: string, selectedExternalId: string, descriptionPropName: string, component: any) {
        this[propertyName] = {
            selectedId: selectedExternalId,
            options: items.map(item => {
                return {
                    id: item.externalId,
                    name: item[descriptionPropName],
                    object: item
                };
            }),
            modal: {
                component: component
            },
        };
    }

    hideProperty(propertyName: string, hidden: boolean) {
        this["__proto__"]["__meta__"][propertyName]["hidden"] = hidden;
    }
}

export class EntityWithStatePanelProperties extends BasePropertyGridEntity {
    @meta({ name: 'Platnost', group: 'Platnost', options: 'state', type: 'enum' })
    state;

    constructor(element: StoreyEntity, data?: any) {
        super(element, data);
        this.setupEnum(STOREY_ENTITY_STATE_OPTIONS, "state", element.state);
    }

    onGetState(selectedValues) {
        let state = 0;
        selectedValues.forEach(element => {
            state += element;
        });
        return state = (state === 0 ? null : state);
    }
}

export class SegmentEntityPanelProperties extends EntityWithStatePanelProperties {
    @meta({ name: 'Počáteční bod [mm]', group: 'Geometrie', options: 'start', valueConvert: parseInt, type: 'point' })
    start;

    @meta({ name: 'Koncový bod [mm]', group: 'Geometrie', options: 'end', valueConvert: parseInt, type: 'point' })
    end;

    constructor(element: SegmentEntity, data?: any) {
        super(element, data);
        this.start = element.start;
        this.end = element.end;
    }
}

export class LocationEntityPanelProperties extends EntityWithStatePanelProperties {
    @meta({ name: 'Umístění [mm]', group: 'Geometrie', options: 'location', valueConvert: parseInt, type: 'point' })
    location;

    constructor(element: LocationEntity, data?: any) {
        super(element, data);
        this.location = element.location;
    }
}
