import { Component, Input } from '@angular/core';
import { StructureProperties } from '../../../../models/structure-properties.model';
import { ModelService } from '../../../../services/model.service';
import { StructurePropertiesFilter } from './structure-properties-filter';


@Component({
    selector: 'app-structure-properties-filter-editor',
    templateUrl: './structure-properties-filter-editor.component.html',
    styleUrls: ['./structure-properties-filter-editor.component.scss'],
})
export class StructurePropertiesFilterEditor{
    @Input() filter: StructurePropertiesFilter;
    propertiesOptions: StructureProperties[];
    
    constructor(private model: ModelService) {
        this.propertiesOptions = this.model.SelectedProject.structureProperties;
    }
}
