import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { Address } from '../../../models/address.model';
import { EntityType } from '../../../models/enums/entityType';
import { ModelService } from './../../../services/model.service';

@Component({
    selector: 'app-address-editor',
    templateUrl: './address-editor.component.html',
    styleUrls: ['./address-editor.component.scss']
})
export class AddressEditorComponent implements OnInit {
    externalId: string;
    isNewAddress: boolean;

    public address: Address = <Address>{};

    constructor(
        private model: ModelService,
        private mdbModalService: MDBModalService) { }


    ngOnInit() {
        this.initData(this.externalId);
    }

    private initData(addressId?: string) {
        if (addressId) {
            this.address = this.model.getEntityByExternalId(EntityType.Address, addressId);
            this.isNewAddress = false;
        } else {
            this.address = new Address();
            this.isNewAddress = true;
        }
    }

    onSave() {
        this.saveData();
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    private saveData() {
        if (this.isNewAddress) {
            this.model.AddEntity(this.address);
        } else {
            this.model.UpdateEntity(this.address);
        }
    }

    public isNotDefaultPropertyValue(propertyName: string): boolean {
        return false;
    }
}
