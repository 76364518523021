import { meta } from 'ngx-property-grid';
import { EntityType } from '../../../../models/enums/entityType';
import { FILTERED_ROOM_TYPES } from '../../../../models/enums/RoomType';
import { LIGHT_SHARING_TYPES, NON_HEATED_SPACE_TYPE_OPTIONS, ThermalRegime, THERMAL_REGIME_OPTIONS, THERMAL_REGIME_OPTIONS_REDUCED } from '../../../../models/enums/Space-properties-enums.model';
import { StoreyEntityState } from '../../../../models/enums/StoreyEntityState';
import { StructurePropertiesEditor } from '../../structureProperties-editor/structureProperties-editor';
import { VentilationEditorComponent } from '../../ventilation-editor/ventilation-editor.component';
import { ZoneEditorComponent } from '../../zone-editor/zone-editor.component';
import { LIGHT_CONTROL_TYPES } from './../../../../models/enums/lightControl';
import { LocationEntityPanelProperties } from './base-entities-for-panel-properties.model';

export class SpacePropertiesPanelProperties extends LocationEntityPanelProperties {
    type = EntityType.SpaceProperties;

    @meta({ name: 'Popis', group: 'Obecné', options: 'description', type: 'text' })
    description;

    @meta({ name: 'Výška podlahy [m]', group: 'Obecné', options: 'elevation', type: 'text' })
    elevation;

    @meta({ name: 'Skladba podlahy', group: 'Skladba konstrukcí', options: 'floorComposition', type: 'dropdown' })
    floorComposition;

    @meta({ name: 'Skladba stropu', group: 'Skladba konstrukcí', options: 'ceilingComposition', type: 'dropdown' })
    ceilingComposition;

    @meta({ name: 'Režim vytápění', group: 'Režim vytápění', options: 'thermalRegime', type: 'enum' })
    thermalRegime;
    @meta({ name: 'Zóna', group: 'Režim vytápění', options: 'zone', type: 'dropdown' })
    zone;
    @meta({ name: 'Typ místnosti', group: 'Režim vytápění', options: 'roomType', type: 'enum' })
    roomType;
    @meta({ name: 'Kritická místnost', group: 'Režim vytápění', options: 'criticalRoom', type: 'checkBox' })
    criticalRoom;
    @meta({ name: 'Obytný prostor', group: 'Režim vytápění', options: 'livingSpace', type: 'checkBox' })
    livingSpace;
    @meta({ name: 'Type nevytápěného prostoru', group: 'Režim vytápění', options: 'nonHeatedSpaceType', type: 'enum' })
    nonHeatedSpaceType;
    @meta({ name: 'Prostor izolován', group: 'Režim vytápění', options: 'isolated', type: 'checkBox' })
    isolated;
    @meta({ name: 'Prostor temperován', group: 'Režim vytápění', options: 'tempered', type: 'checkBox' })
    tempered;
    @meta({ name: 'Hromadné garáže', group: 'Režim vytápění', options: 'isGarage', type: 'checkBox' })
    isGarage;
    @meta({ name: 'Režim pod místností', group: 'Režim vytápění', options: 'belowType', type: 'enum' })
    belowType;

    @meta({ name: 'VZT jednotka 1', group: 'Větrání', options: 'vzt', type: 'dropdown' })
    vzt;
    @meta({ name: 'VZT jednotka 2', group: 'Větrání', options: 'vzt2', type: 'dropdown' })
    vzt2;
    @meta({ name: 'Produkce vlhkosti g/h/m2', group: 'Větrání - výchozí', options: 'mwa_I', type: 'number' })
    mwa_I;
    @meta({ name: 'Produkce vlhkosti g/h/m2', group: 'Větrání - konečné', options: 'mwa_F', type: 'number' })
    mwa_F;
    @meta({ name: 'Měrná vlhkost - zima [g/kg]', group: 'Větrání - výchozí', options: 'xsw_I', type: 'number' })
    xsw_I;
    @meta({ name: 'Měrná vlhkost - zima [g/kg]', group: 'Větrání - konečné', options: 'xsw_F', type: 'number' })
    xsw_F;
    @meta({ name: 'Měrná vlhkost - léto [g/kg]', group: 'Větrání - výchozí', options: 'xss_I', type: 'number' })
    xss_I;
    @meta({ name: 'Měrná vlhkost - léto [g/kg]', group: 'Větrání - konečné', options: 'xss_F', type: 'number' })
    xss_F;
    @meta({ name: 'Výměna nucená [m3/h/osob]', group: 'Větrání - výchozí', options: 'vvl_I', type: 'number' })
    vvl_I;
    @meta({ name: 'Výměna nucená [m3/h/osob]', group: 'Větrání - konečné', options: 'vvl_F', type: 'number' })
    vvl_F;
    @meta({ name: 'Výměna nucená [m3/h/m2]', group: 'Větrání - výchozí', options: 'vvs_I', type: 'number' })
    vvs_I;
    @meta({ name: 'Výměna nucená [m3/h/m2]', group: 'Větrání - konečné', options: 'vvs_F', type: 'number' })
    vvs_F;
    @meta({ name: 'Požadovaná intenzita větrání', group: 'Větrání - výchozí', options: 'ventilation_intensity_I', type: 'number' })
    ventilation_intensity_I;
    @meta({ name: 'Požadovaná intenzita větrání', group: 'Větrání - konečné', options: 'ventilation_intensity_F', type: 'number' })
    ventilation_intensity_F;
    @meta({ name: 'Doba [h/den]', group: 'Větrání - výchozí', options: 'dhv_I', type: 'number' })
    dhv_I;
    @meta({ name: 'Doba [h/den]', group: 'Větrání - konečné', options: 'dhv_F', type: 'number' })
    dhv_F;
    @meta({ name: 'Začátek', group: 'Větrání - výchozí', options: 'tvs_I', type: 'number' })
    tvs_I;
    @meta({ name: 'Začátek', group: 'Větrání - konečné', options: 'tvs_F', type: 'number' })
    tvs_F;
    @meta({ name: 'Konec', group: 'Větrání - výchozí', options: 'tve_I', type: 'number' })
    tve_I;
    @meta({ name: 'Konec', group: 'Větrání - konečné', options: 'tve_F', type: 'number' })
    tve_F;

    @meta({ name: 'Ovládání', group: 'Osvětlení', options: 'lightControl', type: 'enum' })
    lightControl;
    @meta({ name: 'Sdílení světla', group: 'Osvětlení', options: 'light_sharing', type: 'enum' })
    light_sharing;
    @meta({ name: 'Trvalé osvětlení', group: 'Osvětlení', options: 'permanentLightning', type: 'checkbox' })
    permanentLightning;
    @meta({ name: 'Klasické žárovky', group: 'Osvětlení - výchozí', options: 'light_bulb_share_I', type: 'number' })
    light_bulb_share_I;
    @meta({ name: 'Klasické žárovky', group: 'Osvětlení - konečné', options: 'light_bulb_share_F', type: 'number' })
    light_bulb_share_F;
    @meta({ name: 'Klasické žárovky', group: 'Osvětlení - doporučené', options: 'light_bulb_share_R', type: 'number' })
    light_bulb_share_R;
    @meta({ name: 'Halogenové žárovky', group: 'Osvětlení - výchozí', options: 'halogen_light_bulb_share_I', type: 'number' })
    halogen_light_bulb_share_I;
    @meta({ name: 'Halogenové žárovky', group: 'Osvětlení - konečné', options: 'halogen_light_bulb_share_F', type: 'number' })
    halogen_light_bulb_share_F;
    @meta({ name: 'Halogenové žárovky', group: 'Osvětlení - doporučené', options: 'halogen_light_bulb_share_R', type: 'number' })
    halogen_light_bulb_share_R;
    @meta({ name: 'LED zdroje', group: 'Osvětlení - výchozí', options: 'led_light_bulb_share_I', type: 'number' })
    led_light_bulb_share_I;
    @meta({ name: 'LED zdroje', group: 'Osvětlení - konečné', options: 'led_light_bulb_share_F', type: 'number' })
    led_light_bulb_share_F;
    @meta({ name: 'LED zdroje', group: 'Osvětlení - doporučené', options: 'led_light_bulb_share_R', type: 'number' })
    led_light_bulb_share_R;
    @meta({ name: 'Kompaktní zářivky', group: 'Osvětlení - výchozí', options: 'compact_fluorescent_lamp_share_I', type: 'number' })
    compact_fluorescent_lamp_share_I;
    @meta({ name: 'Kompaktní zářivky', group: 'Osvětlení - konečné', options: 'compact_fluorescent_lamp_share_F', type: 'number' })
    compact_fluorescent_lamp_share_F;
    @meta({ name: 'Kompaktní zářivky', group: 'Osvětlení - doporučené', options: 'compact_fluorescent_lamp_share_R', type: 'number' })
    compact_fluorescent_lamp_share_R;
    @meta({ name: 'Zářivky s předřadníkem T16', group: 'Osvětlení - výchozí', options: 'fluorescent_light_with_el_ballast_share_I', type: 'number' })
    fluorescent_light_with_el_ballast_share_I;
    @meta({ name: 'Zářivky s předřadníkem T16', group: 'Osvětlení - konečné', options: 'fluorescent_light_with_el_ballast_share_F', type: 'number' })
    fluorescent_light_with_el_ballast_share_F;
    @meta({ name: 'Zářivky s předřadníkem T16', group: 'Osvětlení - doporučené', options: 'fluorescent_light_with_el_ballast_share_R', type: 'number' })
    fluorescent_light_with_el_ballast_share_R;
    @meta({ name: 'Zářivky s předřadníkem T26', group: 'Osvětlení - výchozí', options: 'fluorescent_light_without_el_ballast_share_I', type: 'number' })
    fluorescent_light_without_el_ballast_share_I;
    @meta({ name: 'Zářivky s předřadníkem T26', group: 'Osvětlení - konečné', options: 'fluorescent_light_without_el_ballast_share_F', type: 'number' })
    fluorescent_light_without_el_ballast_share_F;
    @meta({ name: 'Zářivky s předřadníkem T26', group: 'Osvětlení - doporučené', options: 'fluorescent_light_without_el_ballast_share_R', type: 'number' })
    fluorescent_light_without_el_ballast_share_R;
    @meta({ name: 'Zářivky s klasickým předřadníkem', group: 'Osvětlení - výchozí', options: 'fluorescent_light_with_classic_ballast_share_I', type: 'number' })
    fluorescent_light_with_classic_ballast_share_I;
    @meta({ name: 'Zářivky s klasickým předřadníkem', group: 'Osvětlení - konečné', options: 'fluorescent_light_with_classic_ballast_share_F', type: 'number' })
    fluorescent_light_with_classic_ballast_share_F;
    @meta({ name: 'Zářivky s klasickým předřadníkem', group: 'Osvětlení - doporučené', options: 'fluorescent_light_with_classic_ballast_share_R', type: 'number' })
    fluorescent_light_with_classic_ballast_share_R;
    @meta({ name: 'Výbojky metal-halogenidové', group: 'Osvětlení - výchozí', options: 'metal_halogen_discharge_lamp_share_I', type: 'number' })
    metal_halogen_discharge_lamp_share_I;
    @meta({ name: 'Výbojky metal-halogenidové', group: 'Osvětlení - konečné', options: 'metal_halogen_discharge_lamp_share_F', type: 'number' })
    metal_halogen_discharge_lamp_share_F;
    @meta({ name: 'Výbojky metal-halogenidové', group: 'Osvětlení - doporučené', options: 'metal_halogen_discharge_lamp_share_R', type: 'number' })
    metal_halogen_discharge_lamp_share_R;
    @meta({ name: 'Výbojky sodíkové', group: 'Osvětlení - výchozí', options: 'sodium_discharge_lamp_share_I', type: 'number' })
    sodium_discharge_lamp_share_I;
    @meta({ name: 'Výbojky sodíkové', group: 'Osvětlení - konečné', options: 'sodium_discharge_lamp_share_F', type: 'number' })
    sodium_discharge_lamp_share_F;
    @meta({ name: 'Výbojky sodíkové', group: 'Osvětlení - doporučené', options: 'sodium_discharge_lamp_share_R', type: 'number' })
    sodium_discharge_lamp_share_R;

    @meta({ name: 'Počátek [h]', group: 'Užívání', options: 'tus', type: 'number' })
    tus;
    @meta({ name: 'Konec [h]', group: 'Užívání', options: 'tue', type: 'number' })
    tue;
    @meta({ name: 'Počet dní', group: 'Užívání', options: 'ddu', type: 'number' })
    ddu;
    @meta({ name: 'Doba [h/den]', group: 'Užívání', options: 'dhu', type: 'number' })
    dhu;
    @meta({ name: 'Obložnost [m2/osoba]', group: 'Užívání', options: 'l_a', type: 'number' })
    l_a;

    @meta({ name: 'Provoz [°C]', group: 'Vytápění', options: 'thp', type: 'number' })
    thp;
    @meta({ name: 'Mimo provoz [°C]', group: 'Vytápění', options: 'tho', type: 'number' })
    tho;
    @meta({ name: 'Doba [h]', group: 'Vytápění', options: 'dhh', type: 'number' })
    dhh;

    @meta({ name: 'Má fan-coil', group: 'Vytápění - el. příkon', options: 'has_fan_coil', type: 'checkBox' })
    has_fan_coil;
    @meta({ name: 'Příkon fan-coil [W/m2]', group: 'Vytápění - el. příkon', options: 'fan_coil_share', type: 'number' })
    fan_coil_share;
    @meta({ name: 'Má VRF', group: 'Vytápění - el. příkon', options: 'has_vrf', type: 'checkBox' })
    has_vrf;
    @meta({ name: 'Příkon VRF [W/m2]', group: 'Vytápění - el. příkon', options: 'vrf_share', type: 'number' })
    vrf_share;
    @meta({ name: 'Má konvektory', group: 'Vytápění - el. příkon', options: 'has_heating_convectors', type: 'checkBox' })
    has_heating_convectors;
    @meta({ name: 'Příkon konvektorů [W/m2]', group: 'Vytápění - el. příkon', options: 'heating_convectors_share', type: 'number' })
    heating_convectors_share;

    @meta({ name: 'Plocha bazénu', group: 'Vlhkost', options: 'pool_area', type: 'number' })
    pool_area;
    @meta({ name: 'Požadovaná vlhkost', group: 'Vlhkost', options: 'requested_humidity', type: 'number' })
    requested_humidity;

    @meta({ name: 'Provoz [°C]', group: 'Chlazení', options: 'tcp', type: 'number' })
    tcp;
    @meta({ name: 'Mimo provoz [°C]', group: 'Chlazení', options: 'tco', type: 'number' })
    tco;
    @meta({ name: 'Doba [h]', group: 'Chlazení', options: 'dhc', type: 'number' })
    dhc;


    @meta({ name: 'Měrné zisky [W/m2]', group: 'Zisky z osob', options: 'qoc', type: 'number' })
    qoc;
    @meta({ name: 'Časový podíl', group: 'Zisky z osob', options: 'foc', type: 'number' })
    foc;
    @meta({ name: 'Měrné zisky [W/m2]', group: 'Zisky z vybavení', options: 'qap', type: 'number' })
    qap;
    @meta({ name: 'Časový podíl', group: 'Zisky z vybavení', options: 'fap', type: 'number' })
    fap;

    @meta({ name: 'Provozní doba - den [h]', group: 'Osvětlení - provoz', options: 'tda', type: 'enum' })
    tda;
    @meta({ name: 'Provozní doba - noc [h]', group: 'Osvětlení - provoz', options: 'tna', type: 'enum' })
    tna;
    @meta({ name: 'Osvětlenost [lx]', group: 'Osvětlení - provoz', options: 'ema', type: 'enum' })
    ema;
    @meta({ name: 'Plošné využití', group: 'Osvětlení - provoz', options: 'fla', type: 'enum' })
    fla;
    @meta({ name: 'Index místnosti', group: 'Osvětlení - provoz', options: 'kra', type: 'enum' })
    kra;

    constructor(element?: any, data?: any) {
        super(element, data);
        this.description = element.description;
        this.elevation = element.elevation;
        this.floorComposition = element.floorComposition; // => StructurePropertiesData
        this.ceilingComposition = element.ceilingComposition; // => StructurePropertiesData
        this.thermalRegime = element.thermalRegime;

        this.zone = element.zone;
        this.roomType = element.roomType;
        this.criticalRoom = element.criticalRoom;
        this.livingSpace = element.livingSpace;
        this.nonHeatedSpaceType = element.nonHeatedSpaceType;
        this.isolated = element.isolated;
        this.tempered = element.tempered;
        this.isGarage = element.isGarage;
        this.belowType = element.belowType;

        this.vzt = element.vzt;
        this.vzt2 = element.vzt2;
        this.mwa_I = element.mwa_I;
        this.mwa_F = element.mwa_F;
        this.xsw_I = element.xsw_I;
        this.xsw_F = element.xsw_F;
        this.xss_I = element.xss_I;
        this.xss_F = element.xss_F;
        this.vvl_I = element.vvl_I;
        this.vvl_F = element.vvl_F;
        this.vvs_I = element.vvs_I;
        this.vvs_F = element.vvs_F;
        this.ventilation_intensity_I = element.ventilation_intensity_I;
        this.ventilation_intensity_F = element.ventilation_intensity_F;
        this.dhv_I = element.dhv_I;
        this.dhv_F = element.dhv_F;
        this.tvs_I = element.tvs_I;
        this.tvs_F = element.tvs_F;
        this.tve_I = element.tve_I;
        this.tve_F = element.tve_F;

        this.lightControl = element.lightControl;
        this.light_sharing = element.light_sharing;
        this.permanentLightning = element.permanentLightning;
        this.light_bulb_share_I = element.light_bulb_share_I;
        this.light_bulb_share_F = element.light_bulb_share_F;
        this.light_bulb_share_R = element.light_bulb_share_R;
        this.halogen_light_bulb_share_I = element.halogen_light_bulb_share_I;
        this.halogen_light_bulb_share_F = element.halogen_light_bulb_share_F;
        this.halogen_light_bulb_share_R = element.halogen_light_bulb_share_R;
        this.led_light_bulb_share_I = element.led_light_bulb_share_I;
        this.led_light_bulb_share_F = element.led_light_bulb_share_F;
        this.led_light_bulb_share_R = element.led_light_bulb_share_R;
        this.compact_fluorescent_lamp_share_I = element.compact_fluorescent_lamp_share_I;
        this.compact_fluorescent_lamp_share_F = element.compact_fluorescent_lamp_share_F;
        this.compact_fluorescent_lamp_share_R = element.compact_fluorescent_lamp_share_R;
        this.fluorescent_light_with_el_ballast_share_I = element.fluorescent_light_with_el_ballast_share_I;
        this.fluorescent_light_with_el_ballast_share_F = element.fluorescent_light_with_el_ballast_share_F;
        this.fluorescent_light_with_el_ballast_share_R = element.fluorescent_light_with_el_ballast_share_R;
        this.fluorescent_light_without_el_ballast_share_I = element.fluorescent_light_without_el_ballast_share_I;
        this.fluorescent_light_without_el_ballast_share_F = element.fluorescent_light_without_el_ballast_share_F;
        this.fluorescent_light_without_el_ballast_share_R = element.fluorescent_light_without_el_ballast_share_R;
        this.fluorescent_light_with_classic_ballast_share_I = element.fluorescent_light_with_classic_ballast_share_I;
        this.fluorescent_light_with_classic_ballast_share_F = element.fluorescent_light_with_classic_ballast_share_F;
        this.fluorescent_light_with_classic_ballast_share_R = element.fluorescent_light_with_classic_ballast_share_R;
        this.metal_halogen_discharge_lamp_share_I = element.metal_halogen_discharge_lamp_share_I;
        this.metal_halogen_discharge_lamp_share_F = element.metal_halogen_discharge_lamp_share_F;
        this.metal_halogen_discharge_lamp_share_R = element.metal_halogen_discharge_lamp_share_R;
        this.sodium_discharge_lamp_share_I = element.sodium_discharge_lamp_share_I;
        this.sodium_discharge_lamp_share_F = element.sodium_discharge_lamp_share_F;
        this.sodium_discharge_lamp_share_R = element.sodium_discharge_lamp_share_R;
        this.tda = element.tda;
        this.tna = element.tna;
        this.ema = element.ema;
        this.fla = element.fla;
        this.kra = element.kra;

        this.tus = element.tus;
        this.tue = element.tue;
        this.ddu = element.ddu;
        this.dhu = element.dhu;
        this.l_a = element.l_a;

        this.thp = element.thp;
        this.tho = element.tho;
        this.dhh = element.dhh;
        this.has_fan_coil = element.has_fan_coil;
        this.fan_coil_share = element.fan_coil_share;
        this.has_vrf = element.has_vrf;
        this.vrf_share = element.vrf_share;
        this.has_heating_convectors = element.has_heating_convectors;
        this.heating_convectors_share = element.heating_convectors_share;

        this.pool_area = element.pool_area;
        this.requested_humidity = element.requested_humidity;

        this.tcp = element.tcp;
        this.tco = element.tco;
        this.dhc = element.dhc;

        this.qoc = element.qoc;
        this.foc = element.foc;
        this.qap = element.qap;
        this.fap = element.fap;

        this.setupEnum(THERMAL_REGIME_OPTIONS_REDUCED, "thermalRegime", element.thermalRegime);
        this.setupDropdown(data.structureProperties, "floorComposition", (element.floorComposition || {}).externalId, "detailedDescription", StructurePropertiesEditor);
        this.setupDropdown(data.structureProperties, "ceilingComposition", (element.ceilingComposition || {}).externalId, "detailedDescription", StructurePropertiesEditor);

        let extZones = data.zones.map(zone => {
            let zoneType = (element.state == StoreyEntityState.JUST_EXISTING ? zone.initialDefinition : zone.finalDefinition).zoneType;
            return { ...zone, extendedDescription: zone.description + " (" + zoneType + ")" }
        });
        this.setupDropdown(extZones, "zone", (element.zone || {}).externalId, "extendedDescription", ZoneEditorComponent);

        this.setupDropdown(data.ventilations, "vzt", (element.vzt || {}).externalId, "description", VentilationEditorComponent);
        this.setupDropdown(data.ventilations, "vzt2", (element.vzt2 || {}).externalId, "description", VentilationEditorComponent);
        this.setupEnum(NON_HEATED_SPACE_TYPE_OPTIONS, "nonHeatedSpaceType", element.nonHeatedSpaceType);
        this.setupEnum(THERMAL_REGIME_OPTIONS, "belowType", element.belowType);
        this.setupEnum(LIGHT_CONTROL_TYPES, "lightControl", element.lightControl);
        this.setupEnum(LIGHT_SHARING_TYPES, "light_sharing", element.light_sharing);
        if (element.zone) {
            let zone = data.zones.find(z => z.externalId == element.zone.externalId);
            if (zone) {
                let zoneType = (element.state == StoreyEntityState.JUST_EXISTING ? zone.initialDefinition : zone.finalDefinition).zoneType;
                this.setupEnum(FILTERED_ROOM_TYPES(zoneType), "roomType", element.roomType);
            }
        }

        this.hideProperty("zone", element.thermalRegime != ThermalRegime.HEATED);
        this.hideProperty("roomType", element.thermalRegime != ThermalRegime.HEATED);
        this.hideProperty("criticalRoom", element.thermalRegime != ThermalRegime.HEATED);
        this.hideProperty("livingSpace", element.thermalRegime != ThermalRegime.HEATED);
        this.hideProperty("nonHeatedSpaceType", element.thermalRegime != ThermalRegime.NON_HEATED);
        this.hideProperty("isolated", element.thermalRegime != ThermalRegime.NON_HEATED);
        this.hideProperty("tempered", element.thermalRegime != ThermalRegime.NON_HEATED);
        this.hideProperty("isGarage", element.thermalRegime != ThermalRegime.NON_HEATED);

        this.hideProperty("has_fan_coil", element.thermalRegime != ThermalRegime.HEATED);
        this.hideProperty("fan_coil_share", element.thermalRegime != ThermalRegime.HEATED || !element.has_fan_coil);
        this.hideProperty("has_vrf", element.thermalRegime != ThermalRegime.HEATED);
        this.hideProperty("vrf_share", element.thermalRegime != ThermalRegime.HEATED || !element.has_vrf);
        this.hideProperty("has_heating_convectors", element.thermalRegime != ThermalRegime.HEATED);
        this.hideProperty("heating_convectors_share", element.thermalRegime != ThermalRegime.HEATED || !element.has_heating_convectors);

        this.hideProperty("lightControl", element.thermalRegime != ThermalRegime.HEATED);
        this.hideProperty("pool_area", element.thermalRegime != ThermalRegime.HEATED);
        this.hideProperty("requested_humidity", element.thermalRegime != ThermalRegime.HEATED);

        this.hideProperty("tus", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("tue", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("ddu", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("dhu", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("l_a", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("thp", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("tho", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("dhh", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("tcp", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("tco", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("dhc", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("qoc", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("foc", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("qap", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("fap", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("tda", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("tna", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("ema", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("fla", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
        this.hideProperty("kra", element.thermalRegime != ThermalRegime.HEATED && !element.isGarage);
    }
}
