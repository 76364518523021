import { Storey } from './../models/storey.model';
import { WallLayer } from './../models/wall-layer.model';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { EntityType } from '../models/enums/entityType';
import { StoreyEntityState } from '../models/enums/StoreyEntityState';
import { MaterialLayer } from '../models/material-layer.model';
import { MaterialStrip } from '../models/material-strip.model';
import { Material } from '../models/material.model';
import { Project } from '../models/project.model';
import { StructurePropertiesType, StructureProperties, StructurePropertiesFeature } from '../models/structure-properties.model';
import { WallSegmentLayers } from '../models/wall-segment-layers.model';
import { WindowProperties } from '../models/window-properties.model';
import { DoorProperties } from './../models/door-properties.model';
import { ModelService } from './model.service';
import { StructureSet } from '../models/structure-set.model';

@Injectable()
export class DefaultDataService {
    public static materialExternalId = '6c27812a-c31c-11e9-b37e-525400d5e869';
    public static doorPropertiesExternalId = 'cb27e198-542e-4047-99ba-6c2b5c840735';
    public static windowPropertiesExternalId = '497c43e2-c8c4-11e9-ae95-525400d5e869';

    constructor(public model: ModelService) {
    }

    public getDefaultMaterial(): Material {
        const result = this.model.getEntityByExternalId<Material>(EntityType.Material, DefaultDataService.materialExternalId);
        return (result != null ? result : this.model.Materials[0]);
    }

    public getDefaultWindowProperties(): WindowProperties {
        const result = this.model.getEntityByExternalId<WindowProperties>(EntityType.WindowProperties, DefaultDataService.windowPropertiesExternalId);
        return (result != null ? result : this.model.WindowProperties[0]);
    }

    public getDefaultDoorProperties(): DoorProperties {
        const result = this.model.getEntityByExternalId<DoorProperties>(EntityType.DoorProperties, DefaultDataService.doorPropertiesExternalId);
        return (result != null ? result : this.model.DoorProperties[0]);
    }

    public getDefaultStructureProperties(structureType: StructurePropertiesType): StructureProperties {
        const requestedType = this.model.SelectedProject.structureProperties.find(sp => sp.structureType === structureType);
        return (requestedType ? requestedType : this.model.SelectedProject.structureProperties[0]);
    }

    public getDefaultWallSegmentLayers(): WallSegmentLayers {
        return this.model.SelectedProject.wallSegmentLayers[0];
    }

    public createStructureProperties(description: string, structurePropertiesType: StructurePropertiesType, project: Project): StructureProperties {
        const materialStrip = new MaterialStrip();
        materialStrip.material = this.getDefaultMaterial();
        materialStrip.project = project;

        const materialLayer = new MaterialLayer();
        materialLayer.strips = [materialStrip];
        materialLayer.project = project;

        const structureProperties = new StructureProperties();
        structureProperties.compositionInterior = [materialLayer];
        structureProperties.detailedDescription = description;
        structureProperties.structureType = structurePropertiesType;
        structureProperties.project = project;
        return structureProperties;
    }

    public createWallSegmentLayers(description: string, structureProperties: StructureProperties, project: Project): WallSegmentLayers {
        const wallLayer = new WallLayer();
        wallLayer.properties = structureProperties;

        const newWallSegmentLayers = new WallSegmentLayers();
        newWallSegmentLayers.description = description;
        newWallSegmentLayers.layers = [wallLayer];
        newWallSegmentLayers.project = project;
        newWallSegmentLayers.state = StoreyEntityState.EXISTING_AND_NEW;
        return newWallSegmentLayers;
    }

    public createStructureSet(roofProperties: StructureProperties, floorProperties: StructureProperties, wallProperties: StructureProperties, project: Project): StructureSet {
        const structureSet = new StructureSet();
        structureSet.project = project;
        structureSet.roofAboveHeatedSpace = roofProperties;
        structureSet.floorUnderUnheatedSpace = floorProperties;
        structureSet.innerFloorConstruction = floorProperties;
        structureSet.roofUnheatedSpace = roofProperties;
        structureSet.floorUnderTerrace = floorProperties;
        structureSet.outerWall = wallProperties;
        structureSet.innerWall = wallProperties;
        structureSet.wallAdjacentGround = wallProperties;
        structureSet.wallAdjacentUnheatedSpace = wallProperties;
        structureSet.outerWallUnheatedSpace = wallProperties;
        structureSet.wallAdjacentUnheatedSpaceSoil = wallProperties;
        structureSet.neighbourBuildingAdjacentWall = wallProperties;
        structureSet.wallUnheatedSpaceAdjacentNeighbourHeatedBuilding = wallProperties;
        structureSet.floorAboveTerrain = floorProperties;
        structureSet.floorAboveOutdoorSpace = floorProperties;
        structureSet.floorAboveUnheatedSpace = floorProperties;
        structureSet.floorAboveTerrainUnheatedSpace = floorProperties;
        structureSet.floorAboveOutdoorSpaceUnheatedSpace = floorProperties;

        return structureSet;
    }
}
