import { StructureKind } from "./enums/StructureKind";
import { EntityType } from "./enums/entityType";
import { StoreyEntity } from "./storey-entity.model";
import { StructureSet } from "./structure-set.model";
import { WallSegmentLayers } from "./wall-segment-layers.model";
import { WallSegment } from "./wall-segment.model";

export class DefaultWall extends StoreyEntity{
    entityType: EntityType = EntityType.DefaultWall;
    wallSegmentLayers: WallSegmentLayers;
    startHeight = 3000;
    endHeight = 3000;
    height = 1500;
    isBorder: boolean = false;
    structureKind: StructureKind = StructureKind.vnejsi_stena;
    structureSet: StructureSet = null;

    public returnWallSegment():WallSegment{
        const obj = new WallSegment;
        obj.entityType = EntityType.WallSegment;
        obj.wallSegmentLayers = this.wallSegmentLayers;
        obj.startHeight = this.startHeight;
        obj.endHeight = this.endHeight;
        obj.isBorder = this.isBorder;
        obj.structureKind = this.structureKind;
        obj.structureSet = this.structureSet;
    
        return obj;
    }
}