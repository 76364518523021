import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { EntityType } from '../../models/enums/entityType';
import { MaterialLayer } from '../../models/material-layer.model';
import { StructureProperties } from '../../models/structure-properties.model';
import { StructurePropertiesEditor } from '../components/structureProperties-editor/structureProperties-editor';
import { CatalogueListColumn } from '../readonly-list.component/readonly-list.component';
import { ModelService } from './../../services/model.service';
import { CommonTools } from './../../shared/helpers/common-tools';

@Component({
    selector: 'app-structure-properties-list',
    templateUrl: './structure-properties-list.component.html',
    styleUrls: ['./structure-properties-list.component.scss']
})

export class StructurePropertiesListComponent implements OnInit {

    dataSource: StructureProperties[] = [];

    constructor(
        private model: ModelService,
        private mbdModalService: MDBModalService) { }

    ngOnInit() {
        this.model.SelectedProject$.subscribe(project => this.dataSource = project.structureProperties);
    }

    defineColumns() {
        const columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = 'Popis';
        column.dataSourceColumnName = 'detailedDescription';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Počet vrstev v interiéru';
        column.dataSourceColumnName = 'compositionInterior';
        column.columnValueTranslator = (compositionInterior: MaterialLayer[]) => {
            return compositionInterior.length.toString();
        };
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Počet vrstev v exteriéru';
        column.dataSourceColumnName = 'compositionExterior';
        column.columnValueTranslator = (compositionExterior: MaterialLayer[]) => {
            return compositionExterior.length.toString();
        };
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Typ konstrukce';
        column.dataSourceColumnName = 'structureType';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Přirážka [W/m2.K]';
        column.dataSourceColumnName = 'deltaU';
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onCopyOne(externalId: string) {
        var properties: StructureProperties = this.model.getEntityByExternalId(EntityType.StructureProperties, externalId);
        var clone = Object.assign(new StructureProperties(), properties);
        clone.externalId = CommonTools.GetUUID();
        this.model.AddEntity(clone);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.StructureProperties, externalId);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    private openModal(externalId?: string) {
        this.mbdModalService.show(StructurePropertiesEditor, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: externalId,
                projectId: this.model.SelectedProject.externalId
            }
        });
    }
}