import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-json-schema-form',
    templateUrl: './json-schema-form.component.html',
    styleUrls: ['./json-schema-form.component.scss']
})
export class JsonSchemaFormComponent implements OnInit {

    jsonSchema = {
        "components": [
            {
                "label": "Page 1",
                "title": "Page 1",
                "theme": "primary",
                "mask": false,
                "tableView": true,
                "type": "panel",
                "key": "page1",
                "input": false,
                "components": [
                    {
                        "label": "Select",
                        "mask": false,
                        "type": "select",
                        "input": true,
                        "key": "select2",
                        "defaultValue": "",
                        "data": {
                            "values": {
                                "0": {
                                    "label": "1",
                                    "value": "1"
                                },
                                "1": {
                                    "label": "2",
                                    "value": "2"
                                },
                                "2": {
                                    "label": "3",
                                    "value": "3"
                                }
                            }
                        },
                        "valueProperty": "value",
                        "limit": null,
                        "refreshOn": null,
                        "url": "",
                        "resource": null,
                        "selectValues": "",
                        "disableLimit": false,
                        "searchField": "",
                        "clearOnRefresh": false,
                        "reference": false
                    }
                ]
            }
        ],
        "display": "form"
    };
    jsonSchema1 = {
        "title": "My Test Form",
        "components": [
            {
                "type": "textfield",
                "input": true,
                "tableView": true,
                "inputType": "text",
                "inputMask": "",
                "label": "First Name",
                "key": "firstName",
                "placeholder": "Enter your first name",
                "prefix": "",
                "suffix": "",
                "multiple": false,
                "defaultValue": "",
                "protected": false,
                "unique": false,
                "persistent": true,
                "validate": {
                    "required": true,
                    "minLength": 2,
                    "maxLength": 10,
                    "pattern": "",
                    "custom": "",
                    "customPrivate": false
                },
                "conditional": {
                    "show": "",
                    "when": null,
                    "eq": ""
                }
            },
            {
                "type": "textfield",
                "input": true,
                "tableView": true,
                "inputType": "text",
                "inputMask": "",
                "label": "Last Name",
                "key": "lastName",
                "placeholder": "Enter your last name",
                "prefix": "",
                "suffix": "",
                "multiple": false,
                "defaultValue": "",
                "protected": false,
                "unique": false,
                "persistent": true,
                "validate": {
                    "required": true,
                    "minLength": 2,
                    "maxLength": 10,
                    "pattern": "",
                    "custom": "",
                    "customPrivate": false
                },
                "conditional": {
                    "show": "",
                    "when": null,
                    "eq": ""
                }
            },
            {
                "input": true,
                "label": "Submit",
                "tableView": false,
                "key": "submit",
                "size": "md",
                "leftIcon": "",
                "rightIcon": "",
                "block": false,
                "action": "submit",
                "disableOnInvalid": true,
                "theme": "primary",
                "type": "button"
            }
        ]
    };
    jsonSchema2 = {
        "_id": "5b8c14e57f43cc40ba58e2bf",
        "type": "form",
        "tags": [],
        "owner": "553dbfc08d22d5cb1a7024f2",
        "components": [{
            "hideLabel": false,
            "clearOnHide": false,
            "conditional": { "eq": "", "when": null, "show": "" },
            "theme": "default",
            "key": "page1",
            "input": false,
            "components": [{
                "hideLabel": false,
                "type": "textfield",
                "conditional": { "eq": "", "when": null, "show": "" },
                "validate": {
                    "customPrivate": false,
                    "custom": "",
                    "pattern": "",
                    "maxLength": "",
                    "minLength": "",
                    "required": false
                },
                "persistent": true,
                "unique": false,
                "protected": false,
                "defaultValue": "",
                "multiple": true,
                "suffix": "",
                "prefix": "",
                "placeholder": "",
                "key": "textfieldonpage1",
                "label": "Textfield on page 1",
                "inputMask": "",
                "inputType": "text",
                "tableView": true,
                "input": true,
                "hidden": false,
                "clearOnHide": true,
                "autofocus": false,
                "spellcheck": true
            }, {
                "hideLabel": false,
                "conditional": { "eq": "", "when": null, "show": "" },
                "type": "number",
                "validate": {
                    "custom": "",
                    "multiple": "",
                    "integer": "",
                    "step": "any",
                    "max": "",
                    "min": "",
                    "required": false
                },
                "persistent": true,
                "protected": false,
                "defaultValue": 0,
                "suffix": "",
                "prefix": "",
                "placeholder": "",
                "key": "numberField",
                "label": "Number Field",
                "inputType": "number",
                "tableView": true,
                "input": true,
                "hidden": false,
                "clearOnHide": true,
                "autofocus": false
            }],
            "title": "First",
            "type": "panel",
            "tableView": false
        }, {
            "hideLabel": false,
            "tableView": false,
            "clearOnHide": false,
            "theme": "default",
            "key": "page2",
            "input": false,
            "components": [{
                "hideLabel": false,
                "clearOnHide": true,
                "hidden": false,
                "type": "textfield",
                "conditional": { "eq": "", "when": null, "show": "" },
                "validate": {
                    "customPrivate": false,
                    "custom": "",
                    "pattern": "",
                    "maxLength": "",
                    "minLength": "",
                    "required": false
                },
                "persistent": true,
                "unique": false,
                "protected": false,
                "defaultValue": "",
                "multiple": false,
                "suffix": "",
                "prefix": "",
                "placeholder": "",
                "key": "textfieldonPage2",
                "label": "Textfield on Page 2",
                "inputMask": "",
                "inputType": "text",
                "tableView": true,
                "input": true,
                "autofocus": false,
                "spellcheck": true
            }, {
                "input": true,
                "tableView": true,
                "label": "Customer",
                "key": "page2Customer",
                "placeholder": "Select a customer",
                "data": {
                    "values": [{ "value": "", "label": "" }],
                    "json": "",
                    "url": "https://examples.form.io/customer/submission",
                    "resource": "",
                    "custom": "",
                    "headers": [{ "value": "", "key": "" }]
                },
                "dataSrc": "url",
                "valueProperty": "data.email",
                "defaultValue": "",
                "refreshOn": "",
                "filter": "",
                "authenticate": false,
                "template": "<span>{{ item.data.firstName }} {{ item.data.lastName }}</span>",
                "multiple": false,
                "protected": false,
                "unique": false,
                "persistent": true,
                "hidden": false,
                "clearOnHide": true,
                "validate": { "required": false },
                "type": "select",
                "lazyLoad": true,
                "widget": "html5",
                "hideLabel": false,
                "labelPosition": "top",
                "tags": [],
                "conditional": { "show": "", "when": null, "eq": "" },
                "properties": {},
                "searchField": "data.email",
                "autofocus": false
            }, {
                "hideLabel": false,
                "clearOnHide": false,
                "conditional": { "eq": "", "when": null, "show": "" },
                "type": "fieldset",
                "components": [{
                    "hideLabel": false,
                    "clearOnHide": true,
                    "hidden": false,
                    "type": "textfield",
                    "conditional": { "eq": "", "when": null, "show": "" },
                    "validate": {
                        "customPrivate": false,
                        "custom": "",
                        "pattern": "",
                        "maxLength": "",
                        "minLength": "",
                        "required": false
                    },
                    "persistent": true,
                    "unique": false,
                    "protected": false,
                    "defaultValue": "",
                    "multiple": false,
                    "suffix": "",
                    "prefix": "",
                    "placeholder": "",
                    "key": "textfield",
                    "label": "Textfield",
                    "inputMask": "",
                    "inputType": "text",
                    "tableView": true,
                    "input": true,
                    "autofocus": false,
                    "spellcheck": true
                }],
                "legend": "FieldSet Label",
                "tableView": true,
                "input": false
            }],
            "title": "Page 2",
            "type": "panel"
        }, {
            "properties": { "": "" },
            "conditional": { "eq": "", "when": null, "show": "" },
            "tags": [],
            "hideLabel": false,
            "breadcrumb": "default",
            "type": "panel",
            "components": [{
                "properties": { "": "" },
                "conditional": { "eq": "", "when": null, "show": "" },
                "tags": [],
                "hideLabel": false,
                "type": "datagrid",
                "clearOnHide": true,
                "hidden": false,
                "persistent": true,
                "protected": false,
                "key": "panelDataGrid",
                "label": "Data Grid",
                "tableView": true,
                "components": [{
                    "properties": { "": "" },
                    "tags": [],
                    "labelPosition": "top",
                    "hideLabel": true,
                    "type": "textfield",
                    "conditional": { "eq": "", "when": null, "show": "" },
                    "validate": {
                        "customPrivate": false,
                        "custom": "",
                        "pattern": "",
                        "maxLength": "",
                        "minLength": "",
                        "required": false
                    },
                    "clearOnHide": true,
                    "hidden": false,
                    "persistent": true,
                    "unique": false,
                    "protected": false,
                    "defaultValue": "",
                    "multiple": false,
                    "suffix": "",
                    "prefix": "",
                    "placeholder": "",
                    "key": "panelDataGridA",
                    "label": "A",
                    "inputMask": "",
                    "inputType": "text",
                    "tableView": true,
                    "input": true,
                    "autofocus": false,
                    "spellcheck": true,
                    "inDataGrid": true
                }, {
                    "properties": { "": "" },
                    "tags": [],
                    "labelPosition": "top",
                    "hideLabel": true,
                    "type": "textfield",
                    "conditional": { "eq": "", "when": null, "show": "" },
                    "validate": {
                        "customPrivate": false,
                        "custom": "",
                        "pattern": "",
                        "maxLength": "",
                        "minLength": "",
                        "required": false
                    },
                    "clearOnHide": true,
                    "hidden": false,
                    "persistent": true,
                    "unique": false,
                    "protected": false,
                    "defaultValue": "",
                    "multiple": false,
                    "suffix": "",
                    "prefix": "",
                    "placeholder": "",
                    "key": "panelDataGridB",
                    "label": "B",
                    "inputMask": "",
                    "inputType": "text",
                    "tableView": true,
                    "input": true,
                    "autofocus": false,
                    "spellcheck": true,
                    "inDataGrid": true
                }, {
                    "properties": { "": "" },
                    "tags": [],
                    "labelPosition": "top",
                    "hideLabel": true,
                    "type": "textfield",
                    "conditional": { "eq": "", "when": null, "show": "" },
                    "validate": {
                        "customPrivate": false,
                        "custom": "",
                        "pattern": "",
                        "maxLength": "",
                        "minLength": "",
                        "required": false
                    },
                    "clearOnHide": true,
                    "hidden": false,
                    "persistent": true,
                    "unique": false,
                    "protected": false,
                    "defaultValue": "",
                    "multiple": false,
                    "suffix": "",
                    "prefix": "",
                    "placeholder": "",
                    "key": "panelDataGridC",
                    "label": "C",
                    "inputMask": "",
                    "inputType": "text",
                    "tableView": true,
                    "input": true,
                    "autofocus": false,
                    "spellcheck": true,
                    "inDataGrid": true
                }, {
                    "properties": { "": "" },
                    "tags": [],
                    "labelPosition": "top",
                    "hideLabel": true,
                    "type": "textfield",
                    "conditional": { "eq": "", "when": null, "show": "" },
                    "validate": {
                        "customPrivate": false,
                        "custom": "",
                        "pattern": "",
                        "maxLength": "",
                        "minLength": "",
                        "required": false
                    },
                    "clearOnHide": true,
                    "hidden": false,
                    "persistent": true,
                    "unique": false,
                    "protected": false,
                    "defaultValue": "",
                    "multiple": false,
                    "suffix": "",
                    "prefix": "",
                    "placeholder": "",
                    "key": "panelDataGridD",
                    "label": "D",
                    "inputMask": "",
                    "inputType": "text",
                    "tableView": true,
                    "input": true,
                    "autofocus": false,
                    "spellcheck": true,
                    "inDataGrid": true
                }],
                "tree": true,
                "input": true,
                "autofocus": false
            }, {
                "autofocus": false,
                "input": true,
                "tableView": true,
                "label": "HTML5 Select",
                "key": "panelHtml5Select",
                "placeholder": "",
                "data": {
                    "values": [{ "value": "orange", "label": "Orange" }, {
                        "value": "apple",
                        "label": "Apple"
                    }, { "value": "banana", "label": "Banana" }, {
                        "value": "strawberry",
                        "label": "Strawberry"
                    }, { "value": "kiwi", "label": "Kiwi" }], "json": "", "url": "", "resource": "", "custom": ""
                },
                "widget": "html5",
                "dataSrc": "values",
                "valueProperty": "",
                "defaultValue": "",
                "refreshOn": "",
                "filter": "",
                "authenticate": false,
                "template": "<span>{{ item.label }}</span>",
                "multiple": false,
                "protected": false,
                "unique": false,
                "persistent": true,
                "hidden": false,
                "clearOnHide": true,
                "validate": { "required": false },
                "type": "select",
                "labelPosition": "top",
                "tags": [],
                "conditional": { "show": "", "when": null, "eq": "" },
                "properties": {}
            }],
            "tableView": false,
            "theme": "default",
            "title": "Page 3",
            "input": false,
            "key": "panel",
            "clearOnHide": false
        }, {
            "hideLabel": false,
            "clearOnHide": false,
            "conditional": { "eq": "", "when": null, "show": "" },
            "theme": "default",
            "key": "page3",
            "input": false,
            "components": [{
                "hideLabel": false,
                "type": "textfield",
                "conditional": { "eq": "", "when": null, "show": "" },
                "validate": {
                    "customPrivate": false,
                    "custom": "",
                    "pattern": "",
                    "maxLength": "",
                    "minLength": "",
                    "required": false
                },
                "persistent": true,
                "unique": false,
                "protected": false,
                "defaultValue": "",
                "multiple": false,
                "suffix": "",
                "prefix": "",
                "placeholder": "",
                "key": "textfieldonPage3",
                "label": "Textfield on Page 3",
                "inputMask": "",
                "inputType": "text",
                "tableView": true,
                "input": true,
                "hidden": false,
                "clearOnHide": true,
                "autofocus": false,
                "spellcheck": true
            }, {
                "input": true,
                "tableView": true,
                "label": "Signature",
                "key": "signature",
                "placeholder": "",
                "footer": "Sign above",
                "width": "100%",
                "height": "150px",
                "penColor": "black",
                "backgroundColor": "rgb(245,245,235)",
                "minWidth": "0.5",
                "maxWidth": "2.5",
                "protected": false,
                "persistent": true,
                "hidden": false,
                "clearOnHide": true,
                "validate": { "required": false },
                "type": "signature",
                "hideLabel": true,
                "tags": [],
                "conditional": { "show": "", "when": null, "eq": "" },
                "properties": { "": "" },
                "lockKey": true
            }],
            "title": "Last",
            "type": "panel",
            "tableView": false
        }, {
            "hideLabel": false,
            "type": "button",
            "theme": "primary",
            "disableOnInvalid": true,
            "action": "submit",
            "block": false,
            "rightIcon": "",
            "leftIcon": "",
            "size": "md",
            "key": "submit",
            "tableView": false,
            "label": "Submit",
            "input": true,
            "autofocus": false
        }],
        "revisions": "",
        "_vid": 0,
        "title": "Wizard",
        "display": "wizard",
        "access": [{ "roles": [], "type": "create_own" }, { "roles": [], "type": "create_all" }, {
            "roles": [],
            "type": "read_own"
        }, {
            "roles": ["58b78b87f5609a0070f3f457", "58b78b87f5609a0070f3f458", "58b78b87f5609a0070f3f459"],
            "type": "read_all"
        }, { "roles": [], "type": "update_own" }, { "roles": [], "type": "update_all" }, {
            "roles": [],
            "type": "delete_own"
        }, { "roles": [], "type": "delete_all" }, { "roles": [], "type": "team_read" }, {
            "roles": [],
            "type": "team_write"
        }, { "roles": [], "type": "team_admin" }],
        "submissionAccess": [{
            "roles": ["58b78b87f5609a0070f3f459", "58b78b87f5609a0070f3f458"],
            "type": "create_own"
        }, { "roles": [], "type": "create_all" }, { "roles": [], "type": "read_own" }, {
            "roles": [],
            "type": "read_all"
        }, { "roles": [], "type": "update_own" }, { "roles": [], "type": "update_all" }, {
            "roles": [],
            "type": "delete_own"
        }, { "roles": [], "type": "delete_all" }, { "roles": [], "type": "team_read" }, {
            "roles": [],
            "type": "team_write"
        }, { "roles": [], "type": "team_admin" }],
        "settings": {},
        "name": "wizard",
        "path": "wizard",
        "project": "58b78b87f5609a0070f3f456",
        "created": "2018-09-02T16:50:45.678Z",
        "modified": "2018-09-02T16:51:59.815Z",
        "machineName": "fkdmhegmfwcwdca:wizard"
    };
    jsonSchema3 = {
        "components": [
            {
                "label": "Radio",
                "labelPosition": "left-left",
                "optionsLabelPosition": "left",
                "values": [
                    {
                        "label": "111",
                        "value": "111",
                        "shortcut": ""
                    },
                    {
                        "label": "222",
                        "value": "222",
                        "shortcut": ""
                    },
                    {
                        "label": "333",
                        "value": "333",
                        "shortcut": ""
                    }
                ],
                "inline": false,
                "mask": false,
                "tableView": true,
                "alwaysEnabled": false,
                "type": "radio",
                "input": true,
                "key": "radio2",
                "defaultValue": "",
                "encrypted": false
            },
            {
                "type": "button",
                "label": "Submit",
                "key": "submit",
                "disableOnInvalid": true,
                "theme": "primary",
                "input": true,
                "tableView": true
            }
        ]
    };

    jsonData;

    constructor() {
    }

    onSubmit(submission: any) {
        this.jsonData = submission;
    }

    ngOnInit() {
    }
}