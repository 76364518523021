import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        public auth: AuthenticationService,
        public router: Router
    ) { }

    canActivate(): boolean {
        if (!this.auth.getToken()) {
            this.router.navigate(['/login']);
            return false;
        } else {
            return true;
        }
    }
}
