import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbTableDirective, MdbTablePaginationComponent, MdbTableService } from 'angular-bootstrap-md';
import { BaseEntity } from '../../../models/base-entity.model';
import { CommonTools } from '../../../shared/helpers/common-tools';

export interface IButtonConfig {
    text: string, colName: string, onClick: CallableFunction, component: any
}
@Component({
    selector: 'app-editable-list',
    templateUrl: './editable-list.component.html',
    styleUrls: ['./editable-list.component.scss']
})
export class EditableListComponent implements OnInit {

    @ViewChild(MdbTableDirective) mdbTable: MdbTableDirective;
    @ViewChild(MdbTablePaginationComponent) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild('row') row: ElementRef;

    @Input('headings') headElements: string[];
    @Input('columns') columnElements: string[];
    @Input('comboboxesConfig') comboboxesConfig: any[] = [];
    @Input()
    set dataSource$(value: BaseEntity[]) {
        this._dataSource = value;
        this.tableService.setDataSource(this._dataSource);
        this.elements = this.tableService.getDataSource();
        this.previous = this.tableService.getDataSource();
        this.initPagination();
    }
    get dataSource$() {
        return this._dataSource;
    }
    @Input('showDefaultsButton') showDefaultsButton?: boolean = false;
    @Input('showAddButton') showAddButton: boolean = true;
    @Input('showCopyButton') showCopyButton: boolean = true;
    @Input('showDeleteButton') showDeleteButton: boolean = true;

    @Output() addOne = new EventEmitter<any>();
    @Output() updateOne = new EventEmitter<any>();
    @Output() copyOne = new EventEmitter<any>();
    @Output() deleteOne = new EventEmitter<any>();
    @Output() selectById = new EventEmitter<any>();
    @Output() editDefaults? = new EventEmitter<any>();

    editField = null;
    elements: BaseEntity[] = [];
    previous: BaseEntity[] = [];
    searchText = '';
    maxVisibleItems = 15;
    maxTableHeight = window.innerHeight - 220;
    firstItemIndex: any;
    lastItemIndex: any;

    @HostListener('input') oninput() {
        this.mdbTablePagination.searchText = this.searchText;
    }

    @HostListener('window:resize')

    private _dataSource: BaseEntity[];

    onResize() {
        this.maxTableHeight = window.innerHeight - 220;
    }
    constructor(
        private tableService: MdbTableService,
        private cdRef: ChangeDetectorRef,
        private router: Router,
        private translate: TranslateService) {
    }

    ngOnInit() {
        this.translate.addLangs(['en', 'fr', 'ur', 'es', 'it', 'fa', 'de']);
        this.translate.setDefaultLang('en');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr|ur|es|it|fa|de/) ? browserLang : 'en');
        this.translate.get(this.headElements).subscribe(translations => {
            this.headElements = this.headElements.map(el => translations[el]);
        });
    }

    initPagination() {
        this.mdbTablePagination['tableEl'] = this.tableService as any;

        this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
        this.firstItemIndex = this.mdbTablePagination.firstItemIndex;
        this.lastItemIndex = this.mdbTablePagination.lastItemIndex;

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();

        this.cdRef.detectChanges();
    }

    isNotCombo(colName: string) {
        return this.comboboxesConfig.findIndex(item => item.property === colName) === -1 ? true : false;
    }
    onNextPageClick(data: any) {
        this.firstItemIndex = data.first;
        this.lastItemIndex = data.last;
    }

    onPreviousPageClick(data: any) {
        this.firstItemIndex = data.first;
        this.lastItemIndex = data.last;
    }

    selectCatalogueItem(id: string) {
        this.selectById.emit(id);
    }

    addCatalogueItem() {
        const uuid = CommonTools.GetUUID();
        const item = {
            externalId: uuid
        };
        this.addOne.emit(item);
    }

    editedField(event: any) {
        this.editField = event.target.textContent.trim();
    }

    updateCatalogueItem(id: number, property: string, event: any) {
        const value = event.target ? event.target.textContent.trim() : event.value;
        if (this.editField !== value) {
            this.elements[id][property] = value;
            this.updateOne.emit(this.elements[id]);
        }
        this.editField = null;
    }

    editDefaultsItem(id: number, event: any){
        this.editDefaults.emit(this.elements[id].externalId);
    }

    copyCatalogueItem(id: number, event: any) {
        this.copyOne.emit(this.elements[id].externalId);
    }

    deleteCatalogueItem(id: number, event: any) {
        this.deleteOne.emit(this.elements[id].externalId);
    }

    searchItems() {
        const prev = this.tableService.getDataSource();

        if (!this.searchText) {
            this.tableService.setDataSource(this.previous);
            this.elements = this.tableService.getDataSource();
        }

        if (this.searchText) {
            this.elements = this.tableService.searchLocalDataBy(this.searchText);
            this.tableService.setDataSource(prev);
        }

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();

        this.tableService.searchDataObservable(this.searchText).subscribe((data: any) => {
            if (data.length === 0) {
                this.firstItemIndex = 0;
            }

            if (this.tableService.getDataSource().length !== data.length) {
                this.firstItemIndex = 1;
                this.lastItemIndex = this.maxVisibleItems;
            }

            this.mdbTablePagination.calculateFirstItemIndex();
            this.mdbTablePagination.calculateLastItemIndex();

        });
    }

    onNavigate(index: number, url: string) {
        this.selectCatalogueItem(this.elements[index]['externalId']);
        this.router.navigateByUrl(url);
    }
}
