export function IsClosed(points) {
    if (points && points.length > 0) {
        if (points[0] instanceof Vector2D) {
            return (points[0].subtract(points[points.length - 1]).length() < 1);
        } else {
            return points = [];
        }
    }
    return false;
}

export class Point2D {
    public x: number;
    public y: number;

    constructor(pt: { x: number, y: number }) {
        this.x = pt.x;
        this.y = pt.y;
    }

    public distance(pt: { x: number, y: number }) {
        return Math.sqrt(Math.pow(pt.x - this.x, 2) + Math.pow(pt.y - this.y, 2));
    }
    public perpendicularToLine = function (A: Point2D, B: Point2D): Point2D {
        var l2 = A.distance(B);
        if (l2 == 0) {
            return A;
        } else {
            var t = ((this.x - A.x) * (B.x - A.x) + (this.y - A.y) * (B.y - A.y)) / (Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
            if (t < 0) return A;
            if (t > 1) return B;
            return new Point2D({ x: A.x + t * (B.x - A.x), y: A.y + t * (B.y - A.y) });
        }
    }
    public move(other: { x: number, y: number }) {
        return new Vector2D(this.x + other.x, this.y + other.y);
    };
    public add(vector: Vector2D) {
        return new Point2D({ x: this.x + vector.x, y: this.y + vector.y });
    }
    public subtract(other: Point2D) {
        return new Vector2D(this.x - other.x, this.y - other.y);
    }
}

export class SelectionRectangle {
    public left: number;
    public right: number;
    public top: number;
    public bottom: number;
    public selectJustWhole: boolean;

    constructor(x1: number, y1: number, x2: number, y2: number, selectJustWhole: boolean) {
        this.left = Math.min(x1, x2);
        this.right = Math.max(x1, x2);
        this.top = Math.max(y1, y2);
        this.bottom = Math.min(y1, y2);
        this.selectJustWhole = selectJustWhole;
    }

    public containsPoint(pt: { x: number, y: number }) {
        return (this.left <= pt.x && pt.x <= this.right && this.bottom <= pt.y && pt.y <= this.top);
    }

    public intersectWithLine(pt1: { x: number, y: number }, pt2: { x: number, y: number }): Point2D[] {
        var t0 = 0, t1 = 1;
        var dx = pt2.x - pt1.x, dy = pt2.y - pt1.y;
        var p, q, r;

        for (var edge = 0; edge < 4; edge++) {   // Traverse through left, right, bottom, top edges.
            if (edge === 0) { p = -dx; q = -(this.left - pt1.x); }
            if (edge === 1) { p = dx; q = (this.right - pt1.x); }
            if (edge === 2) { p = -dy; q = -(this.bottom - pt1.y); }
            if (edge === 3) { p = dy; q = (this.top - pt1.y); }

            r = q / p;

            if (p === 0 && q < 0) return null;   // Don't draw line at all. (parallel line outside)

            if (p < 0) {
                if (r > t1) {
                    return null;     // Don't draw line at all.
                } else {
                    if (r > t0) t0 = r;     // Line is clipped!
                }
            } else if (p > 0) {
                if (r < t0) {
                    return null;      // Don't draw line at all.
                } else {
                    if (r < t1) t1 = r;     // Line is clipped!
                }
            }
        }

        return [new Point2D({ x: pt1.x + t0 * dx, y: pt1.y + t0 * dy }), new Point2D({ x: pt1.x + t1 * dx, y: pt1.y + t1 * dy })];
    }
}

export class Vector2D {

    constructor(public x: number, public y: number) {
    }

    public angle(): number {
        let angle = Math.abs(Math.atan(this.y / this.x) * 180 / Math.PI);
        if (this.x < 0) angle = 180 - angle;
        if (this.y < 0) angle = 360 - angle;
        return angle;
    }
    public add(other: Vector2D): Vector2D {
        return new Vector2D(this.x + other.x, this.y + other.y);
    }
    public subtract(other: Vector2D): Vector2D {
        return new Vector2D(this.x - other.x, this.y - other.y);
    }
    public length() {
        return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
    };
    public scale(scalar) {
        return new Vector2D(this.x * scalar, this.y * scalar);
    };
    public perpendicular() {
        return new Vector2D(-this.y, this.x);
    };
    public normalized() {
        const length = this.length();
        return new Vector2D(this.x / length, this.y / length);
    };
    public dot(other: Vector2D): number {
        return this.x * other.x + this.y * other.y;
    };
}


export class Line {
    constructor(public start: Point2D, public end: Point2D) {
    }


    public midpoint(): Point2D {
        return new Point2D({ x: (this.start.x + this.end.x) / 2, y: (this.start.y + this.end.y) / 2 });
    };
    public direction(): Vector2D {
        return this.end.subtract(this.start).normalized();
    };
    public normalVector(): Vector2D {
        const dir = this.direction();
        return new Vector2D(-dir.y, dir.x).normalized();
    };
    public offsetted(offset): Line {
        const pointOffset = this.normalVector().scale(offset);

        const pt1 = this.start.add(pointOffset);
        const pt2 = this.end.add(pointOffset);

        return new Line(pt1, pt2);
    }
}

function intersectionBetween(line1, line2) {
    const denom = (line1.startingPoint.x - line1.endingPoint.x) * (line2.startingPoint.y - line2.endingPoint.y) - (line1.startingPoint.y - line1.endingPoint.y) * (line2.startingPoint.x - line2.endingPoint.x);
    const isParallel = denom === 0;
    if (!isParallel) {
        const numeratorX = (line1.startingPoint.x * line1.endingPoint.y - line1.startingPoint.y * line1.endingPoint.x) * (line2.startingPoint.x - line2.endingPoint.x) - (line2.startingPoint.x * line2.endingPoint.y - line2.startingPoint.y * line2.endingPoint.x) * (line1.startingPoint.x - line1.endingPoint.x);
        const numeratorY = (line1.startingPoint.x * line1.endingPoint.y - line1.startingPoint.y * line1.endingPoint.x) * (line2.startingPoint.y - line2.endingPoint.y) - (line2.startingPoint.x * line2.endingPoint.y - line2.startingPoint.y * line2.endingPoint.x) * (line1.startingPoint.y - line1.endingPoint.y);
        return new Vector2D(numeratorX / denom, numeratorY / denom);
    }

    return false;
}

export function offsetPath(points, offsets) {
    const result = [];

    if (points && points.length > 0) {
        let previous_offsetted_line = null;
        let offsetted_line = null;
        const closedPolygon = IsClosed(points);

        // first segment
        if (closedPolygon) {
            previous_offsetted_line = new Line(points[0], points[1]).offsetted(offsets[0]);
            offsetted_line = new Line(points[points.length - 2], points[points.length - 1]).offsetted(offsets[points.length - 2]);
            result.push(intersectionBetween(previous_offsetted_line, offsetted_line));
        } else {
            previous_offsetted_line = new Line(points[0], points[1]).offsetted(offsets[0]);
            result.push(points[0].add(previous_offsetted_line.normalVector().scale(offsets[0])));
        }

        // inner segments
        for (let index = 1; index < points.length - 1; index++) {
            offsetted_line = new Line(points[index], points[index + 1]).offsetted(offsets[index]);
            result.push(intersectionBetween(previous_offsetted_line, offsetted_line));
            previous_offsetted_line = offsetted_line;
        }

        // last segment
        if (closedPolygon) {
            previous_offsetted_line = new Line(points[0], points[1]).offsetted(offsets[0]);
            offsetted_line = new Line(points[points.length - 2], points[points.length - 1]).offsetted(offsets[points.length - 2]);
            result.push(intersectionBetween(previous_offsetted_line, offsetted_line));
        } else {
            result.push(points[points.length - 1].add(previous_offsetted_line.normalVector().scale(offsets[points.length - 2])));
        }
    }
    return result;
}

