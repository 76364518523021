import { RoomTypes } from './enums/RoomType';
import { ZoneType } from './enums/ZoneType';

export class ZoneTemplate {
    building: string;
    zoneType: ZoneType;
    roomType: RoomTypes;
    ddu = 0;
    dhc = 0;
    dhh = 0;
    dhu = 0;
    dhv = 0;
    ema = 0;
    externalId: string;
    fap = 0;
    fla = 0;
    foc = 0;
    kra = 0;
    l_a = 0;
    mwa = 0;
    n_a = 0;
    qap = 0;
    qoc = 0;
    room: string;
    tco = 0;
    tcp = 0;
    tda = 0;
    temperature = 0;
    tho = 0;
    thp = 0;
    tna = 0;
    tue = 0;
    tus = 0;
    tve = 0;
    tvs = 0;
    vvl = 0;
    vvs = 0;
    xss = 0;
    xsw = 0;
}