import { DrawingSetup } from '../services/drawing-setup';
import { DoorProperties } from './door-properties.model';
import { EntityType } from './enums/entityType';
import { Opening } from './opening.model';
import { SegmentEntity } from './segment';

export class DoorSegment extends Opening {
    entityType: EntityType = EntityType.DoorSegment;
    propertiesExisting: DoorProperties;
    propertiesNew: DoorProperties;
    propertiesRecommended: DoorProperties;
    designation: string;
    changedFromType: string;
    changeReason: string;
    constructionDate: Date;

    // Konva configuration attributes
    id = '';
    stroke = '#353ef0';
    fill = 'red';
    strokeWidth = 3;
    draggable = false;

    sceneFunc = (context: any, shape: any) => {
        if (DrawingSetup.isShapeVisible(shape.attrs.state)) {
            SegmentEntity.drawSelectRect(context, shape, shape.attrs.wallSegment.start, shape.attrs.wallSegment.end);
            SegmentEntity.drawAxis(context, shape, shape.attrs.wallSegment.start, shape.attrs.wallSegment.end, null, 5);
            DoorSegment.drawDoor(context, shape);
        }
    };
    // a Konva.Canvas renderer is passed into the sceneFunc function
    static drawDoor(context: any, shape: any) {
        if (shape.attrs.wallSegment && shape.attrs.wallSegment.start && shape.attrs.wallSegment.end) {
            if (DrawingSetup.isShapeVisible(shape.attrs.state)) {
                const { x: startX, y: startY } = shape.attrs.wallSegment.start;
                const { x: endX, y: endY } = shape.attrs.wallSegment.end;
                const [x, y] = [endX - startX, endY - startY];
                const radius = Math.sqrt(x * x + y * y);
                const startAngle = Math.atan2(y, x);
                const endAngle = startAngle + 90 * Math.PI / 180;
                const [doorX, doorY] = [radius * Math.cos(endAngle) + startX, radius * Math.sin(endAngle) + startY];
                shape.strokeWidth(3 / shape.parent.scale().x)

                context.beginPath();
                context.moveTo(shape.attrs.wallSegment.start.x, shape.attrs.wallSegment.start.y);
                context.lineTo(shape.attrs.wallSegment.end.x, shape.attrs.wallSegment.end.y);
                context.arc(shape.attrs.wallSegment.start.x, shape.attrs.wallSegment.start.y, radius, startAngle, endAngle, false);
                context.closePath();
                context.strokeShape(shape);

                context.beginPath();
                context.moveTo(shape.attrs.wallSegment.start.x, shape.attrs.wallSegment.start.y);
                context.lineTo(doorX, doorY);
                context.closePath();
                context.strokeShape(shape);
            }
        }
    }
}
