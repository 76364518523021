import { Component } from '@angular/core';

@Component({
    selector: 'app-cad-side-panel',
    templateUrl: './cad-side-panel.component.html',
    styleUrls: ['./cad-side-panel.component.scss']
})
export class CadSidePanelComponent {

    constructor() { }

}
