import { ValidatedInputComponent } from './validated-input/validated-input.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MinValueDirective } from './validators/min-value.directive';
import { MaxValueDirective } from './validators/max-value.directive';

@NgModule({
  declarations: [
    ValidatedInputComponent,
    MinValueDirective,
    MaxValueDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    ValidatedInputComponent,
  ]
})
export class ValidationModule { }
