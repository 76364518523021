import { SpaceProperties } from './../../models/space-properties.model';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MDBModalService, MdbTableDirective, MdbTablePaginationComponent, MdbTableService } from 'angular-bootstrap-md';
import * as frLocale from 'date-fns/locale/en';
import * as FileSaver from 'file-saver';
import { DatepickerOptions } from 'ng2-datepicker';
import { environment } from '../../../environments/environment';
import { Address } from '../../models/address.model';
import { Building } from '../../models/building.model';
import { Company } from '../../models/company.model';
import { CurrentUser } from '../../models/current-user.model';
import { Defaults } from '../../models/defaults.model';
import { DoorProperties } from '../../models/door-properties.model';
import { DoorSegment } from '../../models/door-segment.model';
import { EntityType } from '../../models/enums/entityType';
import { Point } from '../../models/point.model';
import { Project, ProjectStatus, PROJECT_INTENTION_OPTIONS, PROJECT_REGION_OPTIONS, PROJECT_STATUS_OPTIONS, SoilType } from '../../models/project.model';
import { Storey } from '../../models/storey.model';
import { StructurePropertiesType } from '../../models/structure-properties.model';
import { Role } from '../../models/user.model';
import { WallSegment } from '../../models/wall-segment.model';
import { WindowProperties } from '../../models/window-properties.model';
import { WindowSegment } from '../../models/window-segment.model';
import { RestService } from '../../services/rest.service';
import { Point2D } from '../../shared/helpers/geometry';
import { AddressEditorComponent } from '../components/address-editor/address-editor.component';
import { CompanyEditorComponent } from '../components/company-editor/company-editor.component';
import { DefaultsEditorComponent } from '../components/defaults-editor/defaults-editor.component';
import { AuthenticationService } from './../../services/authentication.service';
import { DefaultDataService } from './../../services/defaultdata.service';
import { ModelService } from './../../services/model.service';
import { DefaultWall } from '../../models/default-wall';
import { DefaultDoor } from '../../models/default-door';
import { DefaultWindow } from '../../models/default-window';
import { DefaultSpaceProperties } from '../../models/default-space-properties.model';

@Component({
    selector: 'app-projects-list',
    templateUrl: './projects-list.component.html',
    styleUrls: ['./projects-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectsListComponent implements OnInit, AfterViewInit {
    roles = Role;
    baseUrl = environment.apiEndpoint;
    firstLoad = true;

    @ViewChild(MdbTableDirective) mdbTable: MdbTableDirective;
    @ViewChild(MdbTablePaginationComponent) mdbTablePagination: MdbTablePaginationComponent;
    @ViewChild('row') row: ElementRef;

    mainElementsHeadings: string[] = ['Název', 'Popis', 'Vypracoval', 'Kontroloval', 'Schválil', 'Status'];
    mainElementsFields: string[] = ['title', 'description', 'elaboratedBy', 'checkedBy', 'approvedBy', 'projectStatus'];
    detailElementsHeadings: string[] = ['Podtitul', 'Zahájeno', 'Ukončeno', 'Záměr', 'Vodivost zeminy', 'Region', '', '', '', ''];
    detailElementsFields: string[] = ['subTitle', 'startDate', 'endDate', 'intention', 'thermalConductionSoil', 'region', 'soilType', 'Editovat adresu', 'Editovat zákazníka', '', '', 'Defaulty'];
    detailElementsData: any[] = [
        { type: 'string' },
        { type: 'datepicker1' },
        { type: 'datepicker2' },
        {
            type: 'intention'
        },
        { type: 'string' },
        { type: 'region' },
        { type: 'soilType' },
        {
            type: 'button',
            modal: AddressEditorComponent,
            property: 'address'
        },
        {
            type: 'button',
            modal: CompanyEditorComponent,
            property: 'customer'
        },
        { type: 'buildings' },
        { type: 'export' },
        {
            type: 'button',
            modal: DefaultsEditorComponent,
            property: 'defaults'
        }
    ];
    editField = null;

    elements: any = [];
    previous: any = [];
    searchText = '';

    maxVisibleItems = 50;
    maxTableHeight = window.innerHeight - 220;
    firstItemIndex: any;
    lastItemIndex: any;

    currentUser: CurrentUser = null;

    ProjectStatus = ProjectStatus;
    PROJECT_INTENTION_OPTIONS = PROJECT_INTENTION_OPTIONS;
    PROJECT_STATUS_OPTIONS = PROJECT_STATUS_OPTIONS;
    PROJECT_REGION_OPTIONS = PROJECT_REGION_OPTIONS;
    datePicker1Options: DatepickerOptions = {
        minYear: 2000,
        maxYear: 2030,
        displayFormat: 'DD-MM-YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
        locale: frLocale,
        minDate: null, // Minimal selectable date
        maxDate: null,  // Maximal selectable date
        addClass: 'form-control main-fields', // Optional, value to pass on to [ngClass] on the input field
        fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
        useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
    };
    datePicker2Options: DatepickerOptions = {
        minYear: 2000,
        maxYear: 2030,
        displayFormat: 'DD-MM-YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
        locale: frLocale,
        minDate: null, // Minimal selectable date
        maxDate: null,  // Maximal selectable date
        addClass: 'form-control main-fields', // Optional, value to pass on to [ngClass] on the input field
        fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
        useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
    };
    soilTypeOptions = [
        { value: SoilType.CLAY, label: 'Clay or silt', selected: false },
        { value: SoilType.SAND, label: 'Sand or gravel', selected: false },
        { value: SoilType.ROCK, label: 'Homogenous rock', selected: false },
    ];
    working = false;
    deleting = false;

    @HostListener('input') oninput() {
        this.mdbTablePagination.searchText = this.searchText;
    }

    @HostListener('window:resize')
    onResize() {
        this.maxTableHeight = window.innerHeight - 220;
    }

    constructor(
        public model: ModelService,
        private tableService: MdbTableService,
        private cdRef: ChangeDetectorRef,
        private rest: RestService,
        private router: Router,
        private authService: AuthenticationService,
        private translate: TranslateService,
        private defaultDataService: DefaultDataService,
        private mbdModalService: MDBModalService) {
    }

    disabledField(): boolean {
        return !this.currentUser || this.currentUser.role !== this.roles.ADMINISTRATOR;
    }

    isProjectVisibleForCurrentUser(project: Project, user: CurrentUser): boolean {
        const userElaborated = project.elaboratedBy && project.elaboratedBy.externalId === user.externalId;
        const userApproved = project.approvedBy && project.approvedBy.externalId === user.externalId;
        const userChecked = project.checkedBy && project.checkedBy.externalId === user.externalId;
        return userElaborated || userApproved || userChecked;
    }
    ngOnInit() {
        this.currentUser = this.authService.getUser();
        this.model.Projects$.subscribe(projects => {
            if (projects) {
                this.tableService.setDataSource(projects);
                this.elements = this.tableService.getDataSource();
                this.previous = this.tableService.getDataSource();
                this.initPagination();
            }
        });
        this.translate.addLangs(['en', 'fr', 'ur', 'es', 'it', 'fa', 'de']);
        this.translate.setDefaultLang('en');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr|ur|es|it|fa|de/) ? browserLang : 'en');

        this.translate.get(this.mainElementsHeadings.concat(this.detailElementsHeadings)).subscribe(translations => {
            this.mainElementsHeadings = this.mainElementsHeadings.map(el => translations[el]);
            this.detailElementsHeadings = this.detailElementsHeadings.map(el => translations[el]);
        });
    }

    initPagination() {
        if (this.firstLoad) {
            this.mdbTablePagination['tableEl'] = this.tableService as any;
            this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

            this.firstItemIndex = this.mdbTablePagination.firstItemIndex;
            this.lastItemIndex = this.mdbTablePagination.lastItemIndex;

            this.mdbTablePagination.calculateFirstItemIndex();
            this.mdbTablePagination.calculateLastItemIndex();
            this.firstLoad = false;
        }
        if (this.firstItemIndex > this.elements.length && this.elements.length > 0) {
            this.firstItemIndex -= this.maxVisibleItems;
            this.lastItemIndex = this.firstItemIndex + this.maxVisibleItems;
            this.mdbTablePagination.activePageNumber--;
            this.mdbTablePagination.calculateFirstItemIndex();
            this.mdbTablePagination.calculateLastItemIndex();
        }
        this.cdRef.detectChanges();
    }

    onToggle(index) {
        this.elements[index]['visible'] = !this.elements[index]['visible'];
    }

    setActiveProject(externalId: string) {
        this.model.SelectProjectByExternalId(externalId);
    }

    ngAfterViewInit() {

    }

    onNextPageClick(data: any) {
        this.firstItemIndex = data.first;
        this.lastItemIndex = data.last;
    }

    onPreviousPageClick(data: any) {
        this.firstItemIndex = data.first;
        this.lastItemIndex = data.last;
    }

    onSelectUser(index) {
        return index = 1 ? true : false;
    }

    addProject() {
        this.working = true;
        const user = this.authService.getUser();

        const newAddress = new Address();

        const newCompany = new Company();
        newCompany.address = newAddress;

        const newProject = new Project();
        newProject.address = newAddress;
        newProject.customer = newCompany;
        newProject.approvedBy = user;
        newProject.elaboratedBy = user;
        newProject.checkedBy = user;

        const newBuilding = new Building();
        newBuilding.project = newProject;
        newBuilding.name = 'Budova001';

        const newStorey = new Storey();
        newStorey.building = newBuilding;
        newStorey.project = newProject;
        newStorey.description = 'Podlaží001';

        const floorComposition = this.defaultDataService.createStructureProperties('SP001', StructurePropertiesType.FLOOR, newProject);
        const roofComposition = this.defaultDataService.createStructureProperties('SP002', StructurePropertiesType.ROOF, newProject);
        const wallComposition = this.defaultDataService.createStructureProperties('SP003', StructurePropertiesType.WALL, newProject);
        const wallSegmentLayers = this.defaultDataService.createWallSegmentLayers('WSL001', wallComposition, newProject);

        const newStructureSet = this.defaultDataService.createStructureSet(roofComposition, floorComposition, wallComposition, newProject);
        newStructureSet.name = 'SS001';

        /*const zeroPoint = new Point();
        zeroPoint.x = 0;
        zeroPoint.y = 0;
        zeroPoint.storey = newStorey;*/

        const defaultWall = new DefaultWall();
        /*newWallSegment.start = zeroPoint;
        newWallSegment.end = zeroPoint;*/
        defaultWall.wallSegmentLayers = wallSegmentLayers;
        defaultWall.storey = newStorey;

        const defaultDoor = new DefaultDoor();
        defaultDoor.storey = newStorey;

        const defaultWindow = new DefaultWindow();
        defaultWindow.storey = newStorey;

        const newDoorProperties = new DoorProperties();
        const newWindowProperties = new WindowProperties();

        const newSpaceProperties = new DefaultSpaceProperties();
        newSpaceProperties.floorComposition_F = floorComposition;
        newSpaceProperties.floorComposition_I = floorComposition;

        const newDefaults = new Defaults();
        newDefaults.project = newProject;
        newDefaults.structureSet = newStructureSet;
        newDefaults.defaultDoor = defaultDoor;
        newDefaults.defaultDoor.propertiesExisting = newDoorProperties;
        newDefaults.defaultWindow = defaultWindow;
        newDefaults.defaultWindow.propertiesExisting = newWindowProperties;
        newDefaults.defaultSpaceProperties = newSpaceProperties;


        this.model['selectedProject'] = newProject;     // hack to avoid features bound to Project change
        this.model['selectedBuilding'] = newBuilding;     // hack to avoid features bound to Building change
        this.model['selectedStorey'] = newStorey;
        this.model.AddEntities([
            [newAddress],
            [newCompany],
            [newProject],
            [newBuilding],
            [newStorey],
            [floorComposition, roofComposition, wallComposition],
            [newDoorProperties],
            [newWindowProperties],
            [wallSegmentLayers],
            [newStructureSet],
            [newSpaceProperties],
            //[defaultWall],
            [defaultDoor],
            [defaultWindow],
            [newDefaults]
        ]).then(_ => {
            newProject.defaults = newDefaults;
            this.model.UpdateEntity(newProject);
            this.model.Projects$.next(this.model.Projects);
            this.mdbTablePagination.calculateLastItemIndex();
            this.mdbTablePagination.allItemsLength += 1;
            this.working = false;
        });
    }

    editedField(event: any) {
        this.editField = ((event.target || {}).textContent || (event.target || {}).value || event.value || '').trim();
    }

    updateProject(index: number, property: string, event: any, isSelect = false) {
        const value = isSelect && event.externalId ? { externalId: event.externalId } : isSelect && event.value ? event.value : ((event.target || {}).textContent || (event.target || {}).value || event.value || '').trim();
        this.elements[index][property] = value;
        if (this.elements[index] !== this.model.SelectedProject) { // toto je treba udelat, protoze neaktivni projekty nejsou kompletne nactene
            delete this.elements[index].buildings;
            delete this.elements[index].shieldings;
            delete this.elements[index].structureProperties;
            delete this.elements[index].ventilations;
            delete this.elements[index].wallSegmentLayers;
            delete this.elements[index].zoneDefinitions;
            delete this.elements[index].zones;
        }
        this.model.UpdateEntity(this.elements[index]).then( updateSuccessfully => {
            this.model.Projects$.next(this.model.Projects);
        });
        this.editField = null;
    }

    updateProjectFromSelect(index: number, property: string, event: any) {
        this.elements[index][property] = event.value;
        if (this.elements[index] !== this.model.SelectedProject) { this.elements[index].buildings = null; }      // toto je treba udelat, protoze neaktivni projekty nejsou kompletne nactene
        this.model.UpdateEntity(this.elements[index]);
    }

    deleteProject(index: number) {
        if (confirm('Opravdu chcete smazat projekt \'' + this.elements[index].description + '\' ?')) {
            this.deleting = true;
            this.model.RemoveEntity(this.elements[index]).then(deleteSuccessfull => {
                this.deleting = false;
                location.reload();
            });
        }
    }

    onGenerateOutput(id) {
        this.rest.getFile('export', id).subscribe(resp => {
            FileSaver.saveAs(resp['body'], 'Export.xlsx');
        });
    }

    searchProjects() {
        const prev = this.tableService.getDataSource();

        if (!this.searchText) {
            this.tableService.setDataSource(this.previous);
            this.elements = this.tableService.getDataSource();
        }

        if (this.searchText) {
            this.elements = this.tableService.searchLocalDataBy(this.searchText);
            this.tableService.setDataSource(prev);
        }

        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();

        this.tableService.searchDataObservable(this.searchText).subscribe((data: any) => {
            if (data.length === 0) {
                this.firstItemIndex = 0;
            }

            if (this.tableService.getDataSource().length !== data.length) {
                this.firstItemIndex = 1;
                this.lastItemIndex = this.maxVisibleItems;
            }

            this.mdbTablePagination.calculateFirstItemIndex();
            this.mdbTablePagination.calculateLastItemIndex();
        });
    }

    openModal(component, id: string, projectId: string, addressId?: string) {
        this.mbdModalService.show(component, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: id,
                addressExternalId: addressId,
                ownerId: projectId,
                ownerType: EntityType.Project,
            },
        });
    }

    onNavigateToBuildings(index: number) {
        this.setActiveProject(this.elements[index]['externalId']);
        this.router.navigate(['projects/buildings']);
    }

    onDatepicker1Options(datepickerValue) {
        const options = this.datePicker1Options;
        if (datepickerValue) {
            options.maxDate = datepickerValue;
            return options;
        }
        return options;
    }

    onDatepicker2Options(datepickerValue) {
        const options = this.datePicker2Options;
        if (datepickerValue) {
            options.minDate = datepickerValue;
            return options;
        }
        return options;
    }
}
