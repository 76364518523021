import { meta } from 'ngx-property-grid';
import { EntityType } from '../../../../models/enums/entityType';
import { ProjectIntention } from '../../../../models/project.model';
import { DoorPropertiesModalComponent } from '../../../doors-properties-list/door-properties-modal/door-properties-modal.component';
import { EntityWithStatePanelProperties } from './base-entities-for-panel-properties.model';
import { CHANGEREASON_OPTIONS } from '../../../../models/enums/changeReason';

export class DoorSegmentPanelProperties extends EntityWithStatePanelProperties {
    type = EntityType.DoorSegment;

    @meta({ name: 'Výška dveří [mm]', group: 'Rozměry', options: 'height', valueConvert: parseInt, type: 'number' })
    height;

    @meta({ name: 'Typové označení', group: 'Vlastnosti', options: 'designation', type: 'text' })
    designation;

    @meta({ name: 'Typ dveří stávající', group: 'Vlastnosti', options: 'propertiesExisting', type: 'dropdown' })
    propertiesExisting;

    @meta({ name: 'Datum výroby', group: 'Vlastnosti', options: 'constructionDate', type: 'text' })
    constructionDate;

    @meta({ name: 'Typ dveří nový', group: 'Vlastnosti', options: 'propertiesNew', type: 'dropdown' })
    propertiesNew;

    @meta({ name: 'Změněno z typu', group: 'Vlastnosti', options: 'changedFromType', type: 'text' })
    changedFromType;

    @meta({ name: 'Důvod změny', group: 'Vlastnosti', options: 'changeReason', type: 'enum' })
    changeReason;

    @meta({ name: 'Typ dveří doporučený', group: 'Vlastnosti', options: 'propertiesRecommended', type: 'dropdown' })
    propertiesRecommended;

    constructor(element?: any, data?: any) {
        super(element, data);

        if (element) {
            this.height = element.height;
            this.designation = element.designation;
            this.constructionDate = element.constructionDate;
            this.changedFromType = element.changedFromType;
            this.setupDropdown(data.doorProperties$, "propertiesExisting", element.propertiesExisting.externalId, "name", DoorPropertiesModalComponent);
            this.setupDropdown(data.doorProperties$, "propertiesNew", (element.propertiesNew ? element.propertiesNew.externalId : null), "name", DoorPropertiesModalComponent);
            this.setupDropdown(data.doorProperties$, "propertiesRecommended", (element.propertiesRecommended ? element.propertiesRecommended.externalId : null), "name", DoorPropertiesModalComponent);
            this.setupEnum(CHANGEREASON_OPTIONS, "changeReason", element.changeReason);

            this.hideProperty("constructionDate", data.selectedProject.intention == ProjectIntention.NEW);
            this.hideProperty("propertiesNew", data.selectedProject.intention == ProjectIntention.NEW);
        }
    }
}
