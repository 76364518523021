import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[minValue]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinValueDirective, multi: true }]
})
export class MinValueDirective implements Validator {
    @Input('min') min: number;

    validate(control: FormControl): ValidationErrors | null {
        const invalid = control.value < this.min;
        return invalid ? { 'lowValue': { value: control.value } } : null;
    }
}
