import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared';

const routes: Routes = [
    { path: 'login', loadChildren: './components/login/login.module#LoginModule' },
    { path: 'signup', loadChildren: './components/signup/signup.module#SignupModule' },
    { path: 'forgotten', loadChildren: './components/forgotten/forgotten.module#ForgottenModule' },
    { path: 'send', loadChildren: './components/send/send.module#SendModule' },
    { path: 'error', loadChildren: './components/server-error/server-error.module#ServerErrorModule' },
    { path: 'access-denied', loadChildren: './components/access-denied/access-denied.module#AccessDeniedModule' },
    { path: '', loadChildren: './layout/layout.module#LayoutModule', canActivate: [AuthGuard] },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
