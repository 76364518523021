import * as uuid from 'uuid4';
import { BorderSegment } from './border-segment.model';
import { BorderType } from './enums/borderType';
import { EntityType } from './enums/entityType';
import { StoreyEntityState } from './enums/StoreyEntityState';
import { StoreyEntity } from './storey-entity.model';

export abstract class BaseEntity {

    constructor() {
        this.externalId = uuid();
    }

    static readonly selectionColor = '#45d96d';
    externalId: string;
    abstract entityType: EntityType;
    isSelected = false;

    static getShapeColor(entity: StoreyEntity, defaultColor: string = null): string {
        if (entity.isSelected) {
            return this.selectionColor;
        } else {
            switch (entity.state) {
                case StoreyEntityState.EXISTING_AND_NEW: {
                    switch (entity.entityType) {
                        case EntityType.WallSegment: return (defaultColor || 'blue');
                        case EntityType.WindowSegment: return 'blue';
                        case EntityType.DoorSegment: return 'blue';
                        case EntityType.RoofProperties: return '#FF6600';
                        case EntityType.Border: {
                            switch ((<BorderSegment>entity).borderType) {
                                case BorderType.FLOOR_CHANGE: return 'brown';
                                default: return 'lightblue';
                            }
                        }
                        default: return 'black';
                    }
                }
                case StoreyEntityState.JUST_EXISTING: return '#FFC338';
                case StoreyEntityState.JUST_NEW: return '#FF0000';
                default: throw new Error('Unknown StoreyEntityState \'' + entity.state + '\'.');
            }
        }
    }
}
