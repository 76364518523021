import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StoreyEntityState } from '../models/enums/StoreyEntityState';

@Injectable()
export class DrawingSetup {
    private static selectedStateToDraw: StoreyEntityState = StoreyEntityState.EXISTING_AND_NEW;
    static set SelectedStateToDraw(value: StoreyEntityState) {
        DrawingSetup.selectedStateToDraw = value;
        DrawingSetup.SelectedStateToDraw$.next(value);
    }
    static get SelectedStateToDraw() {
        return DrawingSetup.selectedStateToDraw;
    }

    public static SelectedStateToDraw$: Subject<StoreyEntityState> = new Subject<StoreyEntityState>();

    public static isShapeVisible(state: StoreyEntityState): boolean {
        switch (this.selectedStateToDraw) {
            case StoreyEntityState.EXISTING_AND_NEW: return true;
            case StoreyEntityState.JUST_EXISTING: return (state === StoreyEntityState.EXISTING_AND_NEW || state === StoreyEntityState.JUST_EXISTING);
            case StoreyEntityState.JUST_NEW: return (state === StoreyEntityState.EXISTING_AND_NEW || state === StoreyEntityState.JUST_NEW);
            default: return true;
        }
    }
}
