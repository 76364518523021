/* Angular */
import { Injectable } from '@angular/core';

/* 3rd parties */
import { Observable, Subject } from 'rxjs';

/* Components */
import { NotificationComponent } from '../layout/notification/notification.component';
import { Toast } from '../layout/toast/toast';

/* Services */
import { ToasterService } from '../services/toaster.service';

@Injectable()
export class NotificationService {
    key = '';
    keyDetails = '';
    type = 'success';
    params: any;

    trigger: boolean;
    subject: Subject<boolean> = new Subject<boolean>();

    constructor(public toasterService: ToasterService) {
        this.setTrigger(false);
    }

    setTrigger(trigger: boolean): void {
        if (!(typeof trigger === 'boolean')) {
            throw Error('Argument Error : Method setTrigger() - First parameter must be an instance of Boolean');
        }

        this.trigger = trigger;
        this.subject.next(trigger);
    }

    getTrigger(): Observable<boolean> {
        return this.subject.asObservable();
    }

    getBody() {
        const params = {
            'component': NotificationComponent,
            'params': {
                'type': this.type,
                'params': this.params,
                'key': this.key,
                'keyDetails': this.keyDetails
            }
        };

        return params;
    }

    showNotification(type: string, key: string, keyDetails?: string, params?: {}) {
        if (!(typeof type === 'string')) {
            throw Error('Argument Error : Method showNotification() - First parameter must be an instance of String');
        }
        if (!(typeof key === 'string')) {
            throw Error('Argument Error : Method showNotification() - Second parameter must be an instance of String');
        }

        this.key = key;
        this.keyDetails = keyDetails;
        this.type = type;
        this.params = params;

        this.setTrigger(true);
    }

    show(toasterService: ToasterService) {
        const params: Toast = {
            type: this.type,
            body: this.getBody()
        };

        toasterService.pop(params);

        this.setTrigger(false);
    }
}
