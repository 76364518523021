import { meta } from 'ngx-property-grid';
import { EntityType } from '../../../../models/enums/entityType';
import { WindowShieldingEditorComponent } from '../../window-shielding-editor/window-shielding-editor.component';
import { CHANGEREASON_OPTIONS } from './../../../../models/enums/changeReason';
import { ProjectIntention } from './../../../../models/project.model';
import { COVERAGE_TYPE_OPTIONS } from './../../../../models/window-properties.model';
import { WindowPropertiesModalComponent } from './../../../windows-properties-list/window-properties-modal/window-properties-modal.component';
import { EntityWithStatePanelProperties } from './base-entities-for-panel-properties.model';

export class WindowSegmentPanelProperties extends EntityWithStatePanelProperties {
    type = EntityType.WindowSegment;

    @meta({ name: 'Výška okna [mm]', group: 'Rozměry', options: 'height', valueConvert: parseInt, type: 'number' })
    height;

    @meta({ name: 'Typové označení', group: 'Vlastnosti', options: 'designation', type: 'text' })
    designation;

    @meta({ name: 'Typ okna stávající', group: 'Vlastnosti', options: 'propertiesExisting', type: 'dropdown' })
    propertiesExisting;

    @meta({ name: 'Datum výroby', group: 'Vlastnosti', options: 'constructionDate', type: 'text' })
    constructionDate;

    @meta({ name: 'Typ okna nový', group: 'Vlastnosti', options: 'propertiesNew', type: 'dropdown' })
    propertiesNew;

    @meta({ name: 'Změněno z typu', group: 'Vlastnosti', options: 'changedFromType', type: 'text' })
    changedFromType;

    @meta({ name: 'Důvod změny', group: 'Vlastnosti', options: 'changeReason', type: 'enum' })
    changeReason;

    @meta({ name: 'Typ okna doporučený', group: 'Vlastnosti', options: 'propertiesRecommended', type: 'dropdown' })
    propertiesRecommended;

    @meta({ name: 'Výška parapetu [mm]', group: 'Rozměry', options: 'parapetHeight', valueConvert: parseInt, type: 'number' })
    parapetHeight;

    @meta({ name: 'Zapuštění [mm]', group: 'Rozměry', options: 'embedding', valueConvert: parseInt, type: 'number' })
    embedding;

    @meta({ name: 'Markýzy a žebra stávající', group: 'Markýzy a žebra', options: 'shieldingExisting', type: 'dropdown' })
    shieldingExisting;

    @meta({ name: 'Markýzy a žebra stávající', group: 'Markýzy a žebra', options: 'shieldingNew', type: 'dropdown' })
    shieldingNew;

    @meta({ name: 'Vzdálenost překážky 1 [m]', group: 'Překážka', options: 'obstacleDistance1', type: 'number' })
    obstacleDistance1;

    @meta({ name: 'Výška překážky 1 [m]', group: 'Překážka', options: 'obstacleHeight1', type: 'number' })
    obstacleHeight1;

    @meta({ name: 'Vzdálenost překážky 2 [m]', group: 'Překážka', options: 'obstacleDistance2', type: 'number' })
    obstacleDistance2;

    @meta({ name: 'Výška překážky 2 [m]', group: 'Překážka', options: 'obstacleHeight2', type: 'number' })
    obstacleHeight2;

    @meta({ name: 'Vzdálenost překážky 3 [m]', group: 'Překážka', options: 'obstacleDistance3', type: 'number' })
    obstacleDistance3;

    @meta({ name: 'Výška překážky 3 [m]', group: 'Překážka', options: 'obstacleHeight3', type: 'number' })
    obstacleHeight3;

    @meta({ name: 'Vzdálenost překážky 4 [m]', group: 'Překážka', options: 'obstacleDistance4', type: 'number' })
    obstacleDistance4;

    @meta({ name: 'Výška překážky 4 [m]', group: 'Překážka', options: 'obstacleHeight4', type: 'number' })
    obstacleHeight4;

    @meta({ name: 'Stínící technika stávající', group: 'Stínící technika', options: 'coverageExisting', type: 'enum' })
    coverageExisting;

    @meta({ name: 'Stínící technika nová', group: 'Stínící technika', options: 'coverageNew', type: 'enum' })
    coverageNew;

    @meta({ name: 'Stínící technika doporučená', group: 'Stínící technika', options: 'coverageRecommended', type: 'enum' })
    coverageRecommended;

    constructor(element?: any, data?: any) {
        super(element, data);

        if (element) {
            this.height = element.height;
            this.designation = element.designation;
            this.propertiesExisting = element.propertiesExisting;
            this.constructionDate = element.constructionDate;
            this.propertiesNew = element.propertiesNew;
            this.changedFromType = element.changedFromType;
            this.changeReason = element.changeReason;
            this.propertiesRecommended = element.propertiesRecommended;
            this.parapetHeight = element.parapetHeight;
            this.embedding = element.embedding;
            this.shieldingNew = element.shieldingNew;
            this.shieldingExisting = element.shieldingExisting;
            this.obstacleDistance1 = element.obstacleDistance1;
            this.obstacleHeight1 = element.obstacleHeight1;
            this.obstacleDistance2 = element.obstacleDistance2;
            this.obstacleHeight2 = element.obstacleHeight2;
            this.obstacleDistance3 = element.obstacleDistance3;
            this.obstacleHeight3 = element.obstacleHeight3;
            this.obstacleDistance4 = element.obstacleDistance4;
            this.obstacleHeight4 = element.obstacleHeight4;
            this.coverageExisting = element.coverageExisting;
            this.coverageNew = element.coverageNew;
            this.coverageRecommended = element.coverageRecommended;

            this.setupDropdown(data.windowProperties$, "propertiesExisting", element.propertiesExisting.externalId, "name", WindowPropertiesModalComponent);
            this.setupDropdown(data.windowProperties$, "propertiesNew", (element.propertiesNew ? element.propertiesNew.externalId : null), "name", WindowPropertiesModalComponent);
            this.setupDropdown(data.windowProperties$, "propertiesRecommended", (element.propertiesRecommended ? element.propertiesRecommended.externalId : null), "name", WindowPropertiesModalComponent);
            this.setupDropdown(data.windowShielding$, "shieldingExisting", (element.shieldingExisting ? element.shieldingExisting.externalId : null), "externalId", WindowShieldingEditorComponent);
            this.setupDropdown(data.windowShielding$, "shieldingNew", (element.shieldingNew ? element.shieldingNew.externalId : null), "externalId", WindowShieldingEditorComponent);
            this.setupEnum(CHANGEREASON_OPTIONS, "changeReason", element.changeReason);
            this.setupEnum(COVERAGE_TYPE_OPTIONS, "coverageExisting", element.coverageExisting);
            this.setupEnum(COVERAGE_TYPE_OPTIONS, "coverageNew", element.coverageNew);
            this.setupEnum(COVERAGE_TYPE_OPTIONS, "coverageRecommended", element.coverageRecommended);

            this.hideProperty("constructionDate", data.selectedProject.intention == ProjectIntention.NEW);
            this.hideProperty("propertiesNew", data.selectedProject.intention == ProjectIntention.NEW);
        }
    }
}
