import { EntityType } from './enums/entityType';
import { Material } from './material.model';
import { ProjectEntity } from './project-entity.model';

export class MaterialStrip extends ProjectEntity {
    entityType: EntityType = EntityType.MaterialStrip;
    material: Material = null;
    widthRatio: number;

    get widthRatioPercentage() {
        return this.widthRatio * 100.0;
    }
    set widthRatioPercentage(value: number) {
        this.widthRatio = value / 100;
    }

}
