import { AfterViewInit, Component } from '@angular/core';
import { CadEditor } from './cadEditor';

@Component({
    selector: 'cadEditor',
    templateUrl: './CadEditor.component.html',
    styleUrls: ['./CadEditor.component.scss'],
})
export class CadEditorComponent implements AfterViewInit {
    public activeInfoPanel: string = "";

    constructor(public cadEditor: CadEditor) {}

    ngAfterViewInit() {
        this.cadEditor.Init(window.innerWidth - 235, window.innerHeight - 110, 'cadEditorContainer');
    }

    handleInfoPanelChange = (panelName: string) => this.activeInfoPanel === panelName ? this.activeInfoPanel = "" : this.activeInfoPanel = panelName;
}
