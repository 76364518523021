import { DrawingSetup } from '../services/drawing-setup';
import { CommonTools } from '../shared/helpers/common-tools';
import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { StoreyEntityState } from './enums/StoreyEntityState';
import { LocationEntity } from './location-entity.model';

export class SoilHeight extends LocationEntity {
    entityType: EntityType = EntityType.SoilHeight;
    soilHeight_M: number = 0;

    sceneFunc = (context: any, shape: any) => {
        if (shape.attrs.location && DrawingSetup.isShapeVisible(shape.attrs.state)) {
            const zoomedStrokeWidth = 1 / shape.parent.scale().x;
            CommonTools.RenderText(context, shape.attrs.soilHeight_M.toFixed(3), { x: shape.attrs.location.x, y: shape.attrs.location.y - (10 / shape.parent.scale().y) }, 20 / shape.parent.scale().x, 'black', 'bottom', 'left');
            if (DrawingSetup.SelectedStateToDraw == StoreyEntityState.JUST_EXISTING || DrawingSetup.SelectedStateToDraw == StoreyEntityState.EXISTING_AND_NEW) {
                SoilHeight.drawOutline(context, shape, zoomedStrokeWidth, 12);
            }
            if (DrawingSetup.SelectedStateToDraw == StoreyEntityState.JUST_NEW || DrawingSetup.SelectedStateToDraw == StoreyEntityState.EXISTING_AND_NEW) {
                SoilHeight.drawOutline(context, shape, zoomedStrokeWidth, 15);
            }
        }
    };
    static drawOutline(context: any, shape: any, zoomedStrokeWidth: number, size: number) {
        const center = shape.attrs.location;
        shape.stroke(BaseEntity.getShapeColor(<SoilHeight>shape.attrs));
        shape.strokeWidth(zoomedStrokeWidth);
        context.beginPath();
        context.moveTo(center.x, center.y - size / shape.parent.scale().y);
        context.lineTo(center.x + size / shape.parent.scale().x, center.y + size / 2 / shape.parent.scale().y);
        context.lineTo(center.x - size / shape.parent.scale().x, center.y + size / 2 / shape.parent.scale().y);
        context.closePath();
        context.fillStrokeShape(shape);
    }
}
