import { BaseEntity } from './base-entity.model';
import { Address } from './address.model';
import { EntityType } from './enums/entityType';

export class Company extends BaseEntity {
    entityType = EntityType.Company;
    name: string = "";
    address: Address;
    phone: string = "";
    email: string = "";
    web: string = "";
    ico: string = "";
    dic: string = "";
}
