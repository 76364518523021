import { EntityType } from './enums/entityType';
import { StructureKind } from './enums/StructureKind';
import { ProjectEntity } from './project-entity.model';
import { StructureProperties } from './structure-properties.model';

export class StructureSet extends ProjectEntity {
    entityType: EntityType = EntityType.StructureSet;
    name: string;
    description?: string;

    roofAboveHeatedSpace: StructureProperties;
    floorUnderUnheatedSpace: StructureProperties;
    innerFloorConstruction: StructureProperties;
    roofUnheatedSpace: StructureProperties;
    floorUnderTerrace: StructureProperties;
    outerWall: StructureProperties;
    innerWall: StructureProperties;
    wallAdjacentGround: StructureProperties;
    wallAdjacentUnheatedSpace: StructureProperties;
    outerWallUnheatedSpace: StructureProperties;
    wallAdjacentUnheatedSpaceSoil: StructureProperties;
    neighbourBuildingAdjacentWall: StructureProperties;
    wallUnheatedSpaceAdjacentNeighbourHeatedBuilding: StructureProperties;
    floorAboveTerrain: StructureProperties;
    floorAboveOutdoorSpace: StructureProperties;
    floorAboveUnheatedSpace: StructureProperties;
    floorAboveTerrainUnheatedSpace: StructureProperties;
    floorAboveOutdoorSpaceUnheatedSpace: StructureProperties;

    public getStructureProperties(kind: StructureKind): StructureProperties {
        switch (kind) {
            case StructureKind.strecha_nad_vytapenym_prostorem:
                return this.roofAboveHeatedSpace;
            case StructureKind.strop_pod_nevytapenym_prostorem:
                return this.floorUnderUnheatedSpace;
            case StructureKind.strop_pod_sousedni_zonou:
            case StructureKind.strop_mezi_zonami:
            case StructureKind.podlaha_nad_sousedni_zonou:
            case StructureKind.vnitrni_stropni_konstrukce:
                return this.innerFloorConstruction;
            case StructureKind.strecha_nevytapeneho_prostoru:
                return this.roofUnheatedSpace;
            case StructureKind.strop_pod_terasou:
                return this.floorUnderTerrace;
            case StructureKind.vnejsi_stena:
                return this.outerWall;
            case StructureKind.stena_mezi_zonami:
            case StructureKind.stena_prilehla_k_sousedni_zone:
            case StructureKind.vnitrni_pricka:
                return this.innerWall;
            case StructureKind.stena_prilehla_k_zemine:
                return this.wallAdjacentGround;
            case StructureKind.stena_prilehla_k_nevytapenemu_prizemi:
            case StructureKind.stena_prilehla_k_nevytapenemu_suterenu:
            case StructureKind.stena_prilehla_k_nevytapenemu_prostoru:
                return this.wallAdjacentUnheatedSpace;
            case StructureKind.vnejsi_stena_nevytapeneho_prizemi:
            case StructureKind.vnejsi_stena_nevytapeneho_suterenu:
            case StructureKind.vnejsi_stena_nevytapeneho_prostoru:
                return this.outerWallUnheatedSpace;
            case StructureKind.stena_prilehla_k_zemine_nevytapeneho_prizemi:
            case StructureKind.stena_prilehla_k_zemine_nevytapeneho_suterenu:
            case StructureKind.stena_prilehla_k_zemine_nevytapeneho_prostoru:
                return this.wallAdjacentUnheatedSpaceSoil;
            case StructureKind.stena_prilehla_k_sousedni_budove:
                return this.neighbourBuildingAdjacentWall;
            case StructureKind.stena_nevytapeneho_prostoru_prilehla_k_sousedni_vytapene_budove:
                return this.wallUnheatedSpaceAdjacentNeighbourHeatedBuilding;
            case StructureKind.podlaha_nad_terenem:
                return this.floorAboveTerrain;
            case StructureKind.podlaha_nad_venkovnim_prostorem:
                return this.floorAboveOutdoorSpace;
            case StructureKind.podlaha_nad_nevytapenym_prizemim:
            case StructureKind.podlaha_nad_nevytapenym_suterenem:
            case StructureKind.podlaha_nad_nevytapenym_prostorem:
                return this.floorAboveUnheatedSpace;
            case StructureKind.podlaha_nad_terenem_nevytapeneho_prizemi:
            case StructureKind.podlaha_nad_terenem_nevytapeneho_suterenu:
            case StructureKind.podlaha_nad_terenem_nevytapeneho_prostoru:
                return this.floorAboveTerrainUnheatedSpace;
            case StructureKind.podlaha_nad_venkovnim_prostorem_nevytapeneho_prizemi:
            case StructureKind.podlaha_nad_venkovnim_prostorem_nevytapeneho_suterenu:
            case StructureKind.podlaha_nad_venkovnim_prostorem_nevytapeneho_prostoru:
                return this.floorAboveOutdoorSpaceUnheatedSpace;
            default:
                throw new Error('Neznámá hodnota StructureKind');
        }
    }
}
