import { Injectable, Injector } from '@angular/core';
import { ModelService } from './model.service';
import { UndoRedoAction } from './../models/enums/undoredo';

@Injectable({
    providedIn: 'root',
})
export class UndoRedoService {
    currentPositionInArray = 0;
    actionsArray = [];
    public processingUndoRedoAction = false;
    private model: ModelService;

    constructor(injector: Injector) {
        setTimeout(() => this.model = injector.get(ModelService));
    }

    public Undo() {
        if (this.currentPositionInArray > 0) {
            this.processingUndoRedoAction = true;
            switch (this.actionsArray[this.currentPositionInArray - 1].action) {
                case UndoRedoAction.ADD: {
                    this.actionsArray[this.currentPositionInArray - 1].entities.forEach(entity => {
                        entity.forEach(subentity => {
                            if (subentity.entityType !== 'Point') {
                                this.model.RemoveEntities(entity);
                            }
                        });
                    });
                }
                case UndoRedoAction.REMOVE: {
                    this.actionsArray[this.currentPositionInArray - 1].entities.forEach(entity => {
                        this.actionsArray[this.currentPositionInArray - 1].entities.forEach(entity => {
                            if (entity.entityType !== 'Point') {
                                this.model.AddEntities([[entity.start, entity.end], [entity]]);
                            }
                        });
                    });
                }
            }
            this.processingUndoRedoAction = false;
            this.currentPositionInArray--;
            console.log(this.currentPositionInArray, this.actionsArray);
        } else {
            console.log('No Undo Point!');
        }
    }
    public Redo() {
        if (this.currentPositionInArray !== this.actionsArray.length) {
            this.processingUndoRedoAction = true;
            switch (this.actionsArray[this.currentPositionInArray - 1].action) {
                case UndoRedoAction.ADD: {
                    this.actionsArray[this.currentPositionInArray - 1].entities.forEach(entity => {
                        this.actionsArray[this.currentPositionInArray - 1].entities.forEach(entity => {
                            if (entity.entityType !== 'Point') {
                                this.model.AddEntities([[entity.start, entity.end], [entity]]);
                            }
                        });
                    });
                }
                case UndoRedoAction.REMOVE: {
                    this.actionsArray[this.currentPositionInArray].entities.forEach(entity => {
                        entity.forEach(subentity => {
                            if (subentity.entityType !== 'Point') {
                                this.model.RemoveEntities(entity);
                            }
                        });
                    });
                }
            }
            this.processingUndoRedoAction = false;
            this.currentPositionInArray++;
        } else {
            console.log('No Redo Point!');
        }
    }

    public trackUndoRedo(entityArray: any, type: UndoRedoAction) {
        this.actionsArray = this.actionsArray.slice(0, this.currentPositionInArray); // cut array to position index
        this.actionsArray.push({
            action: type,
            entities: entityArray
        });
        this.currentPositionInArray++;
    }

}
