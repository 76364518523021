import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ICustomDynamicComponent } from 'ngx-property-grid';

export interface IMultiselect {
    propName?: string;
    selectedId: string;
    selectedValues?: Array<any>;
    options: { name: string, id: string, object: any }[];
}
export interface IOption { value: any; label: any; selected: boolean; icon: string; }

@Component({
    selector: 'app-multiselect-property',
    templateUrl: './multiselect-property.component.html',
    styleUrls: ['./multiselect-property.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MultiselectPropertyComponent implements ICustomDynamicComponent<IMultiselect> {
    @Input() value: IMultiselect;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    onChange(value: any) {
        var selectedValue= value.reduce((a, b) => a + b, 0);
        this.valueChange.emit(selectedValue);
    }
}
