import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HostBinding } from '@angular/core';
import { ICustomDynamicComponent } from 'ngx-property-grid';

@Component({
    selector: 'app-toggle-property',
    templateUrl: './toggle-property.component.html',
    styleUrls: ['./toggle-property.component.scss']
})
export class TogglePropertyComponent implements ICustomDynamicComponent<boolean> {
    @Input() value: boolean;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() colorClass: string = 'toggle-primary';

    onChange(data: any) {
        this.value = data.target.checked;
        this.valueChange.emit(this.value);
    }
}
