import { Component, Inject, Input, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { MaterialStrip } from '../../../models/material-strip.model';
import { CommonTools } from '../../../shared/helpers/common-tools';
import { MaterialSelectFormComponent } from '../material-select-form/material-select-form.component';

@Component({
    selector: 'app-material-layer-strips',
    templateUrl: './material-layer-strips.component.html',
    styleUrls: ['./material-layer-strips.component.scss']
})
export class MaterialLayerStripsComponent implements OnInit {
    @Input() uuid;
    layerId: string;
    strips: any;
    materialOptions: any;

    constructor(
        private mbdModalService: MDBModalService
    ) {
    }

    ngOnInit() {
        this.mapArray(this.strips);
    }

    onOK() {
        this.mbdModalService.hide(1);
    }

    onCancel() {
        this.mbdModalService.hide(1);
    }

    mapArray(arr, unmap = false) {
        for (const item of arr) {
            item.strips.forEach(element => {
                if (!unmap) {
                    element['widthRatioPercentage'] = element.widthRatio * 100;
                } else {
                    element.widthRatio = element['widthRatioPercentage'] / 100;
                }
            });
        }
    }

    openModal(id: string) {
        const modalRef = this.mbdModalService.show(MaterialSelectFormComponent, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: { id }
        });
    }

    addMaterialLayerStrip(material?, widthRatioPercentage?) {
        this.strips.push({
            externalId: CommonTools.GetUUID(),
            material: (material ? material : this.materialOptions[0]),
            widthRatioPercentage: widthRatioPercentage ? widthRatioPercentage : 100,
            widthRatio: widthRatioPercentage ? widthRatioPercentage / 100 : 1
        });
    }

    deleteMaterialLayerStrip(id) {
        if (id > -1 && id < this.strips.length) {
            this.strips.splice(id, 1);
        }
    }

    updateMaterialLayerStrip(layerStrip: MaterialStrip, val?: string) {
        if (val) {
            layerStrip.widthRatio = parseInt(val);
        }
    }
}
