import { ModelService } from './../../../services/model.service';
import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { RecuperationTypeOptions, Ventilation, VENTILATION_CONTROL_OPTIONS } from '../../../models/ventilation.model';
import { RPM_REGULATION_OPTIONS, VENTILATION_TYPE_OPTIONS, SOIL_HEAT_CHANGED_OPTIONS } from './../../../models/ventilation.model';
import { EntityType } from '../../../models/enums/entityType';

@Component({
    selector: 'app-ventilation-editor',
    templateUrl: './ventilation-editor.component.html',
    styleUrls: ['./ventilation-editor.component.scss']
})

export class VentilationEditorComponent implements OnInit {

    externalId: string;
    isNewVentilation: boolean;

    ventilation: Ventilation = <Ventilation>{};
    ventilationControlOptions = VENTILATION_CONTROL_OPTIONS;
    ventilatorTypeOptions = VENTILATION_TYPE_OPTIONS;
    rpmRegulationOptions = RPM_REGULATION_OPTIONS;
    soilHeatChangerOptions = SOIL_HEAT_CHANGED_OPTIONS;
    recuperationTypeOptions = RecuperationTypeOptions;

    constructor(
        private model: ModelService,
        public mdbModalService: MDBModalService,
    ) { }

    ngOnInit() {
        this.initData(this.externalId);
    }

    private initData(externalId?: string) {
        if (externalId) {
            this.ventilation = this.model.getEntityByExternalId(EntityType.Ventilation, externalId);
            this.isNewVentilation = false;
        } else {
            this.ventilation = new Ventilation();
            this.ventilation.description = this.model.generateName(this.ventilation);
            this.isNewVentilation = true;
        }
    }

    onSave() {
        this.saveData();
        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService['loaders'].length);
    }

    private saveData() {
        if (this.isNewVentilation) {
            this.model.AddEntity(this.ventilation);
        } else {
            this.model.UpdateEntity(this.ventilation);
        }
    }
}
