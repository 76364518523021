import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from '../../../models/current-user.model';
import { StoreyEntityState, STOREY_ENTITY_STATE_OPTIONS } from '../../../models/enums/StoreyEntityState';
import { Project } from '../../../models/project.model';
import { DrawingSetup } from '../../../services/drawing-setup';
import { AuthenticationService } from './../../../services/authentication.service';
import { ModelService } from './../../../services/model.service';
import { CadEditor } from './../../CadEditor/cadEditor';
import { Version } from '../../../models/version';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})

export class HeaderComponent {
    projects = [];
    buildings = [];
    storeys = [];
    public drawnStoreyEntityState: StoreyEntityState = StoreyEntityState.EXISTING_AND_NEW;
    drawnStoreyEntityStateOptions = STOREY_ENTITY_STATE_OPTIONS;
    user: CurrentUser;
    version: Version;

    constructor(
        public model: ModelService,
        public cadEditor: CadEditor,
        private authService: AuthenticationService,
        private translate: TranslateService,
    ) {
        let thisComponent = this;

        model.Projects$.subscribe({
            next(projects) {
                if(projects != null)
                    thisComponent.projects = [...projects]
                else 
                    thisComponent.projects = projects
            },
            error(err) {console.error('Observable emitted an error: ' + err); }        
        });

        model.SelectedProject$.subscribe(project => thisComponent.buildings = project != null ? [...project.buildings] : []);
        model.SelectedBuilding$.subscribe(building => thisComponent.storeys = building != null ? [...building.storeys] : []);
        model.Version$.subscribe(result => this.version = result );
        this.user = authService.getUser();

        this.translate.addLangs(['en', 'fr', 'ur', 'es', 'it', 'fa', 'de', 'zh-CHS']);
        this.translate.setDefaultLang('en');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr|ur|es|it|fa|de|zh-CHS/) ? browserLang : 'en');
    }

    isProjectVisibleForCurrentUser(project: Project, user: CurrentUser): boolean {
        /* FIX
        const userElaborated = project.elaboratedBy && project.elaboratedBy.externalId == user.externalId;
        const userApproved = project.approvedBy && project.approvedBy.externalId == user.externalId;
        const userChecked = project.checkedBy && project.checkedBy.externalId == user.externalId;
        return userElaborated || userApproved || userChecked;
        */
        return true;
    }

    onLogout() {
        this.authService.logout();
    }

    changeLang(language: string) {
        // TODO: use NgRx here
        this.translate.use(language);
    }

    setProject(externalId) {
        this.model.loadProject(externalId);
    }

    setBuilding(externalId) {
        this.model.SelectBuildingByExternalId(externalId);
    }

    setStorey(externalId) {
        this.model.SelectStoreyByExternalId(externalId);
    }

    setDrawnStoreyEntityState(drawnStoreyEntityState) {
        this.drawnStoreyEntityState = drawnStoreyEntityState;
        DrawingSetup.SelectedStateToDraw = drawnStoreyEntityState;
    }

    setStoreyBacklight(storey) {
        storey.backLighted = !storey.backLighted;
        this.model.BacklightedStoreys = this.model.Storeys.filter(s => s.backLighted == true);
    }
}
