import { Component, OnInit } from "@angular/core";
import { MDBModalService } from "angular-bootstrap-md";
import { EntityType } from '../../models/enums/entityType';
import { CatalogueListColumn } from "../readonly-list.component/readonly-list.component";
import { ModelService } from "../../services/model.service";
import { CommonTools } from "../../shared/helpers/common-tools";
import { RoofDefinitionModalComponent } from "./roof-definition-modal/roof-definition-modal.component";
import { RoofDefinition } from "../../models/roof-definition.model";

@Component({
    selector: "app-roof-definition-list",
    templateUrl: "./roof-definition-list.component.html",
    styleUrls: ["./roof-definition-list.component.scss"],
})
export class RoofDefinitionListComponent implements OnInit {
    dataSource: RoofDefinition[] = [];

    constructor(private model: ModelService, private mbdModalService: MDBModalService) { }

    ngOnInit() {
        this.model.SelectedProject$.subscribe((project) => {
            this.dataSource = project.roofDefinition ? project.roofDefinition : [];
        });
    }

    defineColumns() {
        const columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = "Název";
        column.dataSourceColumnName = "name";
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = "Popis";
        column.dataSourceColumnName = "description";
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onCopyOne(externalId: string) {
        let roofDefinition: RoofDefinition = this.model.getEntityByExternalId(EntityType.RoofDefinition, externalId);
        const clone = Object.assign(new RoofDefinition(), roofDefinition);
        clone.externalId = CommonTools.GetUUID();
        this.model.AddEntity(clone);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(data: any) {
        this.model.RemoveEntity(data);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    private openModal(externalId?: string) {
        this.mbdModalService.show(RoofDefinitionModalComponent, {
            containerClass: "modal fade",
            class: "modal-dialog modal-dialog-scrollable modal-xl",
            animated: true,
            data: {
                externalId: externalId,
                projectId: this.model.SelectedProject.externalId,
            },
        });
    }
}
function RoofDefinitionModal(RoofDefinitionModal: any, arg1: { containerClass: string; class: string; animated: boolean; data: { externalId: string; projectId: string; }; }) {
    throw new Error("Function not implemented.");
}

