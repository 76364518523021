export enum StoreyEntityState {
    EXISTING_AND_NEW = "EXISTING_AND_NEW",
    JUST_EXISTING = "JUST_EXISTING",
    JUST_NEW = "JUST_NEW",
    RECOMMENDED = "RECOMMENDED"
}

export const STOREY_ENTITY_STATE_OPTIONS = [
    { value: StoreyEntityState.EXISTING_AND_NEW, label: 'Stávající + nový stav', selected: true, externalId: 0 },
    { value: StoreyEntityState.JUST_EXISTING, label: 'Stávající', selected: false, externalId: 1 },
    { value: StoreyEntityState.JUST_NEW, label: 'Nový', selected: false, externalId: 2 },
    //{ value: StoreyEntityState.RECOMMENDED, label: 'Doporučený', selected: false, externalId: 3 },
];