import { UserSettingsService } from '../../services/user-settings-service.service';
import Konva from 'konva';
import { Storey } from '../../models/storey.model';
import { ModelService } from '../../services/model.service';
import { AuxLayer } from './auxLayer';
import { MainLayer } from './mainLayer';
import { Subject } from 'rxjs';
import { PointInput, OsnapMode } from '../../models/pointInput';
import { Point2D } from '../../shared/helpers/geometry';
import { Layer } from 'konva/types/Layer';
import { HighlightLayer } from './highlightLayer';
import { StoreyEntity } from '../../models/storey-entity.model';
import { SegmentEntity } from '../../models/segment';
import { LocationEntity } from '../../models/location-entity.model';

export class CadStage extends Konva.Stage {
    public mainLayer: MainLayer;
    public backlightLayer: MainLayer;
    public highlightLayer: HighlightLayer;
    public auxLayer: AuxLayer;
    public set OsnapActive(value: boolean) {
        this.auxLayer.OsnapActive = value;
    }
    public get OsnapActive() {
        return this.auxLayer.OsnapActive;
    }
    public PointInput: Subject<PointInput> = new Subject();

    private zoom: number = 1;
    private lastMiddleButtonClick: any = null;

    constructor(
        config: any,
        private model: ModelService,
        private userSettingsService: UserSettingsService) {
        super(config);

        this.highlightLayer = new HighlightLayer();
        this.add(this.highlightLayer);

        this.mainLayer = new MainLayer(model);
        this.add(this.mainLayer);

        this.backlightLayer = new MainLayer(model);
        this.backlightLayer.opacity(0.05);
        this.add(this.backlightLayer);

        this.auxLayer = new AuxLayer();
        this.add(this.auxLayer);

        this.addEventListener('mousedown', this.stageMouseDown.bind(this));
        this.addEventListener('mousemove', this.stageMouseMove.bind(this));
        this.addEventListener('wheel', this.stageMouseWheel.bind(this));
    }

    public backlightStorey(storeys: Storey[]) {
        this.backlightLayer.clearEntities();
        storeys.forEach(storey => this.backlightLayer.importStorey(storey));
        this.batchDraw();
    }

    public ZoomAll() {
        this.Zoom = 0.05;
    }
    public ZoomIn() {
        this.Zoom = this.zoom * 1.1;
    }
    public ZoomOut() {
        this.Zoom = this.zoom * 0.9;
    }
    public ZoomToEntity(entity: StoreyEntity) {
        var left, right, top, bottom: number = 0;
        if (entity instanceof SegmentEntity) {
            left = Math.min(entity.start.x, entity.end.x);
            right = Math.max(entity.start.x, entity.end.x);
            top = Math.min(entity.start.y, entity.end.y);
            bottom = Math.max(entity.start.y, entity.end.y);
        } else if (entity instanceof LocationEntity) {
            left = entity.location.x - 1000;
            right = entity.location.x + 1000;
            top = entity.location.y - 1000;
            bottom = entity.location.y + 1000;
        }

        this.Zoom = 0.8 * Math.min(this.width() / (right - left), this.height() / (bottom - top));
        var dx = -(((left + right) / 2) * this.zoom - this.width() / 2) - this.children[0].x();
        var dy = -(((top + bottom) / 2) * this.zoom - this.height() / 2) - this.children[0].y();
        this.Pan(dx, dy);
    }
    public set Zoom(value: number) {
        var initRect = this.getClientRect({ skipTransform: false });
        this.zoom = value;
        for (var i = 0; i < this.children.length; i++) {
            this.children[i].scale({ x: value, y: value });
            this.children[i].batchDraw();
        };

        var center = this.getPointerPosition(); // will be defined, if user is zooming with mouse in the screen
        if (center) {
            var finalRect = this.getClientRect({ skipTransform: false });
            var dx = (finalRect.width - initRect.width) * (center.x - initRect.x) / initRect.width;
            var dy = (finalRect.height - initRect.height) * (center.y - initRect.y) / initRect.height;
            if (dx && dy) {
                this.Pan(-dx, -dy);
            }
        }
    }
    public get Zoom() {
        return this.zoom;
    }

    public getPosition(): { x: number, y: number } {
        return this.getAbsCoord(this.getPointerPosition());
    }
    public getAbsCoord(point: { x: number, y: number }): { x: number, y: number } {
        return {
            x: (point.x - this.mainLayer.x()) / this.zoom,
            y: (point.y - this.mainLayer.y()) / this.zoom,
        };
    }

    private stageMouseMove(event: MouseEvent): void {
        switch (event.buttons) {
            case 0: {
                var shape = this.mainLayer.getIntersection(this.getPointerPosition(), 'Shape');
                if (shape == null) shape = this.backlightLayer.getIntersection(this.getPointerPosition(), 'Shape');

                if (shape == null) {
                    this.PointInput.next(this.getStagePointInput(event));
                } else {
                    this.PointInput.next(this.getEntityPointInput(shape, false, event));
                }
                break;
            }
            case 4: {   // middle
                var pos = this.getPointerPosition();
                this.Pan(pos.x - this.lastMiddleButtonClick.x, pos.y - this.lastMiddleButtonClick.y);
                this.lastMiddleButtonClick = pos;
                break;
            }
        }
    }
    private stageMouseDown(event: MouseEvent): void {
        switch (event.buttons) {
            case 1: {
                var shape = this.mainLayer.getIntersection(this.getPointerPosition(), 'Shape');
                if (shape == null) {
                    shape = this.backlightLayer.getIntersection(this.getPointerPosition(), 'Shape');
                    if (shape == null) {
                        this.PointInput.next(this.getStagePointInput(event));
                    } else {
                        this.PointInput.next(this.getEntityPointInput(shape, false, event));
                    }
                } else {
                    this.PointInput.next(this.getEntityPointInput(shape, true, event));
                }
                break;
            }
            case 4: {       // middle
                this.lastMiddleButtonClick = this.getPointerPosition();
                break;
            }
        }
    }
    private stageMouseWheel(event: any): void {
        if (event.wheelDelta > 0) {
            this.ZoomIn();
        } else {
            this.ZoomOut();
        }
    }
    private Pan(dx: number, dy: number) {
        for (var i = 0; i < this.children.length; i++) {
            this.children[i].move({ x: dx, y: dy });
        };
        this.batchDraw();
    }

    private getStagePointInput(event: MouseEvent) {
        var pos = this.getPosition();
        var x = Math.round(pos.x / this.userSettingsService.UserSettings.SnapGrid_X) * this.userSettingsService.UserSettings.SnapGrid_X;
        var y = Math.round(pos.y / this.userSettingsService.UserSettings.SnapGrid_Y) * this.userSettingsService.UserSettings.SnapGrid_Y;
        return new PointInput(x, y, OsnapMode.NEW, null, null, event.buttons == 1, event.ctrlKey, event.shiftKey);
    }

    private getEntityPointInput(shape: any, isActiveLayer: boolean, event: MouseEvent) {
        var pos = this.getPosition();
        if ((shape.attrs.start && shape.attrs.end) || shape.attrs.wallSegment) {    // SegmentEntity or Opening
            var curr = new Point2D(pos);
            var start = shape.attrs.start ? shape.attrs.start : shape.attrs.wallSegment.start;
            var start2D = new Point2D(start);
            var end = shape.attrs.end ? shape.attrs.end : shape.attrs.wallSegment.end
            var end2D = new Point2D(end);
            var segmentLength = start2D.distance(end2D);
            if (curr.distance(start2D) < segmentLength / 20) {
                return new PointInput(start2D.x, start2D.y, OsnapMode.EXISTING_POINT, shape.attrs, isActiveLayer ? start : null, event.buttons == 1, event.ctrlKey, event.shiftKey);
            }
            if (curr.distance(end2D) < segmentLength / 20) {
                return new PointInput(end2D.x, end2D.y, OsnapMode.EXISTING_POINT, shape.attrs, isActiveLayer ? end : null, event.buttons == 1, event.ctrlKey, event.shiftKey);
            }
            var mid = new Point2D({ x: (start2D.x + end2D.x) / 2, y: (start2D.y + end2D.y) / 2 });
            if (curr.distance(mid) < segmentLength / 20) {
                return new PointInput(mid.x, mid.y, OsnapMode.MID_POINT, shape.attrs, null, event.buttons == 1, event.ctrlKey, event.shiftKey);
            }
            var nearest = curr.perpendicularToLine(start2D, end2D);
            return new PointInput(nearest.x, nearest.y, OsnapMode.NEAREST_ON_LINE, shape.attrs, null, event.buttons == 1, event.ctrlKey, event.shiftKey);
        }
        if (shape.attrs.x && shape.attrs.y) {   // Point
            return new PointInput(shape.attrs.x, shape.attrs.y, OsnapMode.EXISTING_POINT, shape.attrs, isActiveLayer ? shape.attrs : null, event.buttons == 1, event.ctrlKey, event.shiftKey);
        }
        if (shape.attrs.location) {     // LocationEntity
            return new PointInput(shape.attrs.location.x, shape.attrs.location.y, OsnapMode.EXISTING_POINT, shape.attrs, isActiveLayer ? shape.attrs.location : null, event.buttons == 1, event.ctrlKey, event.shiftKey);
        }
        if (shape.attrs.edgePolygon) {      // RoofProperties
            return new PointInput(pos.x, pos.y, OsnapMode.NEW, shape.attrs, isActiveLayer ? shape.attrs.location : null, event.buttons == 1, event.ctrlKey, event.shiftKey);
        }
        return this.getStagePointInput(event);
    }
}