import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { EntityType } from '../../../models/enums/entityType';
import { StructurePropertiesType } from '../../../models/structure-properties.model';
import { WallLayer } from '../../../models/wall-layer.model';
import { RestService } from '../../../services/rest.service';
import { StructurePropertiesEditor } from '../structureProperties-editor/structureProperties-editor';
import { WallSegmentLayers } from './../../../models/wall-segment-layers.model';
import { BackendService } from './../../../services/backend.service';
import { DefaultDataService } from './../../../services/defaultdata.service';
import { ModelService } from './../../../services/model.service';

@Component({
    selector: 'app-wallsegmentlayers-editor',
    templateUrl: './wallsegmentlayers-editor.html',
    styleUrls: ['./wallsegmentlayers-editor.scss']
})
export class WallSegmentLayersEditor implements OnInit {
    externalId: string;
    isNewWallSegmentLayers: boolean;
    public wallSegmentLayers: WallSegmentLayers = null;
    loading: boolean;

    constructor(
        public model: ModelService,
        public backendService: BackendService,
        public defaultDataService: DefaultDataService,
        public rest: RestService,
        private mdbModalService: MDBModalService) {
        this.loading = true;
    }


    ngOnInit() {
        if (this.externalId) {
            this.wallSegmentLayers = this.model.getEntityByExternalId<WallSegmentLayers>(EntityType.WallSegmentLayers, this.externalId);
            this.isNewWallSegmentLayers = false;
        } else {
            this.wallSegmentLayers = new WallSegmentLayers();
            this.wallSegmentLayers.description = this.model.generateName(this.wallSegmentLayers);
            this.isNewWallSegmentLayers = true;
        }
    }

    onSelectState(layer) {
        layer.state = 0;
        layer.stateList.forEach(element => {
            layer.state += element;
        });
        layer.state = layer.state === 0 ? null : layer.state;
    }

    addLayer() {
        var newLayer = new WallLayer();
        newLayer.properties = this.defaultDataService.getDefaultStructureProperties(StructurePropertiesType.WALL);
        this.wallSegmentLayers.layers.push(newLayer);
    }

    deleteLayer(layerIndex: number) {
        this.wallSegmentLayers.layers.splice(layerIndex, 1);
    }

    onSave() {
        if (this.isNewWallSegmentLayers) {
            this.model.AddEntity(this.wallSegmentLayers);
        } else {
            this.model.UpdateEntity(this.wallSegmentLayers);
        }

        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onLayerEdit(propertiesId: string) {
        this.mdbModalService.show(StructurePropertiesEditor, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: propertiesId
            }
        });
    }
}
