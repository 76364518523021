import { CommonTools } from '../../../shared/helpers/common-tools';
import { DefaultDataService } from '../../../services/defaultdata.service';
import { Component, OnInit } from "@angular/core";
import { MDBModalService } from "angular-bootstrap-md";
import { EntityType } from "../../../models/enums/entityType";
import { StructureProperties } from "../../../models/structure-properties.model";
import { ModelService } from "../../../services/model.service";
import { RoofDefinition } from '../../../models/roof-definition.model';
import { THERMAL_REGIME_OPTIONS } from '../../../models/enums/Space-properties-enums.model';

@Component({
    selector: "app-roof-definition-modal",
    templateUrl: "./roof-definition-modal.component.html",
    styleUrls: ["./roof-definition-modal.component.scss"],
})
export class RoofDefinitionModalComponent implements OnInit {
    public externalId: string;

    public roofDefinition: RoofDefinition = null;
    public structureProperties: StructureProperties[] = [];
    THERMAL_REGIME_OPTIONS = THERMAL_REGIME_OPTIONS;

    private isNewWindow: boolean;

    constructor(private model: ModelService, private mdbModalService: MDBModalService) {
        this.model.SelectedProject$.subscribe((project) => {
            if (project) this.structureProperties = project.structureProperties;
        });
    }

    ngOnInit() {
        if (this.externalId) {
            this.roofDefinition = this.model.getEntityByExternalId<RoofDefinition>(EntityType.RoofDefinition, this.externalId);
        }
        if (this.roofDefinition) {
            this.isNewWindow = false;
        } else {
            this.roofDefinition = new RoofDefinition();
            this.roofDefinition.name = this.model.generateName(this.roofDefinition);
            this.roofDefinition.externalId = this.externalId ? this.externalId : CommonTools.GetUUID();
            this.isNewWindow = true;
        }
    }

    onSave() {
        if (this.isNewWindow) {
            this.model.AddEntity(this.roofDefinition);
        } else {
            this.model.UpdateEntity(this.roofDefinition);
        }

        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }
}
