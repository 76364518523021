import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';

export class Address extends BaseEntity {
    entityType: EntityType = EntityType.Address;
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    country: string = "";
    zipCode: string = "";

}
