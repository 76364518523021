import { EntityType } from './enums/entityType';
import { StoreyEntityState } from './enums/StoreyEntityState';
import { ProjectEntity } from './project-entity.model';
import { WallLayer } from './wall-layer.model';

export class WallSegmentLayers extends ProjectEntity {
    entityType: EntityType = EntityType.WallSegmentLayers
    description?: string = "";
    layers: WallLayer[] = [];
    state: StoreyEntityState.EXISTING_AND_NEW;
}
