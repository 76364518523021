import { meta } from 'ngx-property-grid';
import { EntityType } from '../../../../models/enums/entityType';
import { FloorDefinitionModalComponent } from '../../../floor-definition-list/floor-definition-modal/floor-definition-modal.component';
import { LocationEntityPanelProperties } from './base-entities-for-panel-properties.model';

export class FloorPropertiesPanelProperties extends LocationEntityPanelProperties {
    type = EntityType.FloorProperties;

    @meta({
        name: 'výška podlahy', group: 'Vlastnosti', options: 'floorHeight', type: 'number'
    })
    floorHeight;
    @meta({
        name: 'Definice podlahy', group: 'Definice', options: 'floorDefinition', type: 'dropdown'
    })
    floorDefinition;

    constructor(element?: any, data?: any) {
        super(element, data);
        this.floorHeight = element.floorHeight;
        this.setupDropdown(data.floorDefinition$, "floorDefinition", element.floorDefinition.externalId, "name", FloorDefinitionModalComponent);
    }
}
