import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICustomDynamicComponent } from 'ngx-property-grid';

@Component({
    selector: 'app-checkbox-property',
    templateUrl: './checkbox-property.component.html',
    styleUrls: ['./checkbox-property.component.scss']
})
export class CheckboxPropertyComponent implements ICustomDynamicComponent<boolean> {
    @Input() value: boolean;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    onChange(data: any) {
        this.value = !this.value;
        this.valueChange.emit(this.value);
    }
}