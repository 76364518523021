import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { EntityType } from '../../models/enums/entityType';
import { DefaultDataService } from '../../services/defaultdata.service';
import { CommonTools } from '../../shared/helpers/common-tools';
import { WallSegmentLayersEditor as WallSegmentLayersEditor } from '../components/wallsegmentlayers-editor/wallsegmentlayers-editor';
import { CatalogueListColumn } from '../readonly-list.component/readonly-list.component';
import { WallSegmentLayers } from './../../models/wall-segment-layers.model';
import { ModelService } from './../../services/model.service';

@Component({
    selector: 'app-wall-segment-layers-list',
    templateUrl: './wall-segment-layers-list.component.html',
    styleUrls: ['./wall-segment-layers-list.component.scss']
})
export class WallSegmentLayersListComponent implements OnInit {
    dataSource: WallSegmentLayers[];
    selected: WallSegmentLayers;
    selectedLayer;

    constructor(
        private model: ModelService,
        public defaultDataService: DefaultDataService,
        private mbdModalService: MDBModalService
    ) { }

    ngOnInit() {
        this.model.SelectedProject$.subscribe(project => this.dataSource = project.wallSegmentLayers);
        this.selected = this.defaultDataService.getDefaultWallSegmentLayers();
    }

    defineColumns(): CatalogueListColumn[] {
        let columns: CatalogueListColumn[] = [];

        let column = new CatalogueListColumn();
        column.heading = 'Popis';
        column.dataSourceColumnName = 'description';
        columns.push(column);

        column = new CatalogueListColumn();
        column.heading = 'Počet vrstev';
        column.dataSourceColumnName = 'numberOfLayers';
        columns.push(column);

        return columns;
    }

    onAddOne() {
        this.openModal();
    }

    onCopyOne(externalId: string) {
        var wsl: WallSegmentLayers = this.model.SelectedProject.wallSegmentLayers.find(m => m.externalId == externalId);
        var clone = Object.assign(new WallSegmentLayers(), wsl);
        clone.externalId = CommonTools.GetUUID();
        this.model.AddEntity(clone);
    }

    onUpdateOne(data: any) {
        this.model.UpdateEntity(data);
    }

    onDeleteOne(externalId: any) {
        this.model.RemoveEntityByExternalId(EntityType.WallSegmentLayers, externalId);
    }

    onDetail(externalId: string) {
        this.openModal(externalId);
    }

    onSelect(item: any) {
        // this.wallSegmentLayersFacade.selectById(item['externalId']);
    }

    private openModal(externalId?: string) {
        const modalRef = this.mbdModalService.show(WallSegmentLayersEditor, {
            containerClass: 'modal fade',
            class: 'modal-dialog modal-dialog-scrollable modal-xl',
            animated: true,
            data: {
                externalId: externalId
            }
        });
    }
}
