/* Angular */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

/* 3rd parties */
import { TranslateModule } from '@ngx-translate/core';

/* Components */
import { NotificationComponent } from './notification.component';

@NgModule({
    imports: [
        BrowserModule,
        TranslateModule
    ],
    declarations: [
        NotificationComponent
    ],
    exports: [
        NotificationComponent
    ],
    entryComponents: [NotificationComponent]
})

export class NotificationModule {}
