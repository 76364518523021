import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { AuxLine } from '../models/auxline.model';
import { RestService } from '../services/rest.service';

const API_GET = 'auxline/list';
const API_ADD = 'auxline';
const API_UPDATE = 'auxline';
const API_DELETE = 'auxline';

@Injectable()
export class AuxLineService {

  constructor(private rest: RestService) { }

  public getAll(storeyId: any): Observable<AuxLine[]> {
    let filter = {};
    if (storeyId) {
      filter = { storey: { externalId: storeyId } };
    }
    return this.rest.post<any>(API_GET, filter).pipe(
      map((lines) => lines.content)
    );
  }

  public get(id: any): Observable<AuxLine> {
    return this.rest.get<AuxLine>(API_ADD, id);
  }

  public create(auxline: AuxLine): Observable<AuxLine> {
    return this.rest.post<AuxLine>(API_ADD, auxline);
  }

  public update(auxline: AuxLine): Observable<any> {
    return this.rest.put<AuxLine>(API_UPDATE, auxline.externalId, auxline);
  }

  delete(id: number): Observable<AuxLine> {
    return this.rest.delete<AuxLine>(API_DELETE, id);
  }
}
