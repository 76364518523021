import { Component, OnInit } from '@angular/core';
import { MDBModalService } from 'angular-bootstrap-md';
import { Role } from '../../../models/user.model';
import { COVERAGE_TYPE_OPTIONS, WindowDefaultGenerator, WindowProperties, WindowPropertyVisibility, WINDOW_AGE_OPTIONS, WINDOW_DISTANCE_FRAME_OPTIONS, WINDOW_GLAZING_INFILL_OPTIONS, WINDOW_GLAZING_OPTIONS, WINDOW_MATERIAL, WINDOW_TYPE_OPTIONS } from '../../../models/window-properties.model';
import { AuthenticationService } from './../../../services/authentication.service';
import { ModelService } from './../../../services/model.service';


/**
 * This component represents form listing all defaultWindow properties. It can load the defaultWindow properties from BE and save them.
 */
@Component({
    selector: 'app-window-properties-modal',
    templateUrl: './window-properties-modal.component.html',
    styleUrls: ['./window-properties-modal.component.scss']
})
export class WindowPropertiesModalComponent implements OnInit {

    // @ViewChild('propertiesModal') detailModal: ModalDirective;

    /** externalId: string | undefined - id of properties element
     *  Could be set in parent component using mbdModalService.show(ModalComponent, {data: {externalId}})
     */
    externalId: string;

    properties: WindowProperties = null;

    windowTypeOptions = WINDOW_TYPE_OPTIONS;
    windowGlazingOptions = WINDOW_GLAZING_OPTIONS;
    windowDistanceFrameOptions = WINDOW_DISTANCE_FRAME_OPTIONS;
    windowAgeOptions = WINDOW_AGE_OPTIONS;
    windowGlazingInfillOptions = WINDOW_GLAZING_INFILL_OPTIONS;
    windowMaterialOptions = WINDOW_MATERIAL;
    coverageTypeOptions = COVERAGE_TYPE_OPTIONS;

    windowDefaultsGenerator = new WindowDefaultGenerator();
    windowPropertyVisibility = new WindowPropertyVisibility();

    heightOpenableRadioValue = true;
    heightImmovableRadioValue = true;
    enterUwRadioValue = false;
    enterPsigRadioValue = true;

    private isNewWindow: boolean;
    private currentUser = null;

    constructor(
        private model: ModelService,
        private authService: AuthenticationService,
        private mdbModalService: MDBModalService,
    ) {
    }

    ngOnInit() {
        this.currentUser = this.authService.getUser();

        if (this.externalId) {
            this.properties = this.model.WindowProperties.find(item => item.externalId == this.externalId);
        }
        if (this.properties) {
            this.isNewWindow = false;
        } else {
            this.properties = new WindowProperties();
            this.properties.externalId = this.externalId;
            this.isNewWindow = true;
        }
        this.initForm();
    }

    onSave() {
        this.initBeforeSave();

        if (this.isNewWindow) {
            this.model.AddEntity(this.properties);
        } else {
            this.model.UpdateEntity(this.properties);
        }

        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onCancel() {
        this.mdbModalService.hide(this.mdbModalService["loaders"].length);
    }

    onChangeMaterial(event: any) {
        let parameters = this.windowDefaultsGenerator.getDefaultParameters(this.properties);
        let uw = Number(parameters[0]); if (uw > 0) this.properties.uw = uw;
        let g = Number(parameters[1]); if (g > 0) this.properties.g = g;
        let uf = Number(parameters[3]); if (uf > 0) this.properties.uf = uf;
        let ug = Number(parameters[4]); if (ug > 0) this.properties.ug = ug;

        let psi_g = this.windowDefaultsGenerator.getDefaultPsig(this.properties); if (psi_g > 0) this.properties.psi_g = psi_g;
    }

    public isPropertyVisible(propertyName: string): boolean {
        var isVisible = this.windowPropertyVisibility.isPropertyVisible(this.properties, propertyName);

        if (propertyName == "confirmed") {
            isVisible = isVisible && this.currentUser && this.currentUser.role == Role.ADMINISTRATOR;
        }
        return isVisible;
    }

    private initForm() {
        this.heightOpenableRadioValue = true;
        const heightOpenableDimensions = [
            this.properties.heightOpenableTop,
            this.properties.heightOpenableBottom,
            this.properties.heightOpenableRight,
            this.properties.heightOpenableLeft
        ];
        if (!heightOpenableDimensions.every(key =>
            key === null || this.properties.heightOpenableAverage != null ||
            key === this.properties.heightOpenableAverage)) {
            this.heightOpenableRadioValue = false;
        }

        this.heightImmovableRadioValue = true;
        const heightImmovableDimensions = [
            this.properties.heightImmovableTop,
            this.properties.heightImmovableBottom,
            this.properties.heightImmovableRight,
            this.properties.heightImmovableLeft
        ];
        if (!heightImmovableDimensions.every(key =>
            key === null || this.properties.heightOpenableAverage != null ||
            key === this.properties.heightImmovableAverage)) {
            this.heightImmovableRadioValue = false;
        }

        this.enterUwRadioValue = false;
        if (this.properties.uw != null && this.properties.ug == null && this.properties.uf == null) {
            this.enterUwRadioValue = true;
        }

        this.enterPsigRadioValue = true;
    }

    private initBeforeSave() {
        if (this.heightOpenableRadioValue) {
            this.properties.heightOpenableTop = this.properties.heightOpenableAverage;
            this.properties.heightOpenableBottom = this.properties.heightOpenableAverage;
            this.properties.heightOpenableLeft = this.properties.heightOpenableAverage;
            this.properties.heightOpenableRight = this.properties.heightOpenableAverage;
        } else {
            this.properties.heightOpenableAverage = null;
        }

        if (this.heightImmovableRadioValue) {
            this.properties.heightImmovableTop = this.properties.heightImmovableAverage;
            this.properties.heightImmovableBottom = this.properties.heightImmovableAverage;
            this.properties.heightImmovableLeft = this.properties.heightImmovableAverage;
            this.properties.heightImmovableRight = this.properties.heightImmovableAverage;
        } else {
            this.properties.heightImmovableAverage = null;
        }

        if (this.enterUwRadioValue) {
            this.properties.ug = null;
            this.properties.uf = null;
        } else {
            this.properties.uw = null;
        }

        if (!this.enterPsigRadioValue) {
            this.properties.psi_g = null;
        }
    }
}

