import { BaseEntity } from './base-entity.model';
import { EntityType } from './enums/entityType';
import { ZoneType } from './enums/ZoneType';
import { SpaceProperties } from './space-properties.model';
import { Zone } from './zone.model';

export enum ZoneDefinitionType {
    Initial = 'Initial',
    Final = 'Final',
    Alternative = 'Alternative',
}

export const ZONE_DEFINIITION_TYPE_OPTIONS = [
    { value: ZoneDefinitionType.Initial, label: 'Výchozí' },
    {
        value: ZoneDefinitionType.Final, label: 'Konečný',
    },
    { value: ZoneDefinitionType.Alternative, label: 'Alternativní' },
];

export enum HeatingSource { H1 = "H1", H2 = "H2", H3 = "H3", H4 = "H4", H5 = "H5", H6 = "H6", H7 = "H7", H8 = "H8" }
export const HeatingSourceOptions = [
    { value: HeatingSource.H1, label: 'H1' },
    { value: HeatingSource.H2, label: 'H2' },
    { value: HeatingSource.H3, label: 'H3' },
    { value: HeatingSource.H4, label: 'H4' },
    { value: HeatingSource.H5, label: 'H5' },
    { value: HeatingSource.H6, label: 'H6' },
    { value: HeatingSource.H7, label: 'H7' },
    { value: HeatingSource.H8, label: 'H8' },
];

export enum CoolingSource { C1 = "C1", C2 = "C2", C3 = "C3", C4 = "C4", C5 = "C5", C6 = "C6", C7 = "C7", C8 = "C8" }
export const CoolingSourceOptions = [
    { value: CoolingSource.C1, label: 'C1' },
    { value: CoolingSource.C2, label: 'C2' },
    { value: CoolingSource.C3, label: 'C3' },
    { value: CoolingSource.C4, label: 'C4' },
    { value: CoolingSource.C5, label: 'C5' },
    { value: CoolingSource.C6, label: 'C6' },
    { value: CoolingSource.C7, label: 'C7' },
    { value: CoolingSource.C8, label: 'C8' },
];

export enum WaterHeatingSource { W1 = "W1", W2 = "W2", W3 = "W3", W4 = "W4", W5 = "W5", W6 = "W6", W7 = "W7", W8 = "W8" }
export const WaterHeatingSourceOptions = [
    { value: WaterHeatingSource.W1, label: 'W1' },
    { value: WaterHeatingSource.W2, label: 'W2' },
    { value: WaterHeatingSource.W3, label: 'W3' },
    { value: WaterHeatingSource.W4, label: 'W4' },
    { value: WaterHeatingSource.W5, label: 'W5' },
    { value: WaterHeatingSource.W6, label: 'W6' },
    { value: WaterHeatingSource.W7, label: 'W7' },
    { value: WaterHeatingSource.W8, label: 'W8' },
];

export enum ClimaType {
    Heating = 'Heating',
    Heating_Cooling = 'Heating_Cooling',
    Heating_Ventilation = 'Heating_Ventilation',
    Heating_Ventilation_Cooling = 'Heating_Ventilation_Cooling',
}

export const CLIMA_TYPE_OPTIONS = [
    { value: ClimaType.Heating, label: 'Vytápění' },
    { value: ClimaType.Heating_Cooling, label: 'Vytápění a chlazení' },
    { value: ClimaType.Heating_Ventilation, label: 'Vytápění a větrání' },
    { value: ClimaType.Heating_Ventilation_Cooling, label: 'Vytápění, větrání a chlazení' },
];

export enum ShieldingLevel {
    NoShielding = 'NoShielding',
    ModerateShielding = 'ModerateShielding',
    SignificatngShielding = 'SignificatngShielding',
}

export const SHIELDING_LEVEL_OPTIONS = [
    { value: ShieldingLevel.NoShielding, label: 'Bez stínění' },
    { value: ShieldingLevel.ModerateShielding, label: 'Částečné stínění' },
    { value: ShieldingLevel.SignificatngShielding, label: 'Významné stínění' },
];

export enum HeatingMediumType {
    electrocity = 'electrocity',
    gas = 'gas',
    wood = 'wood',
    coal = 'coal',
}

export const HEATING_MEDIUM_TYPE_OPTIONS = [
    { value: HeatingMediumType.electrocity, label: 'Elektřina' },
    { value: HeatingMediumType.gas, label: 'Plyn' },
    { value: HeatingMediumType.wood, label: 'Dřevo' },
    { value: HeatingMediumType.coal, label: 'Uhlí' },
];

export enum FlatSize {
    flat1_0 = "flat1_0",
    flat1_KK = "flat1_KK",
    flat1_1 = "flat1_1",
    flat2_0 = "flat2_0",
    flat2_KK = "flat2_KK",
    flat2_1 = "flat2_1",
    flat3_KK = "flat3_KK",
    flat3_1 = "flat3_1",
    flat4_KK = "flat4_KK",
    flat4_1 = "flat4_1",
    flat5_KK = "flat5_KK",
    flat5_1 = "flat5_1",
    flat6_KK = "flat6_KK",
    flat6_1 = "flat6_1",
    flat6_2 = "flat6_2",
    flat7_1 = "flat7_1",
    flat7_2 = "flat7_2",
    other = "other",
}

export const FLAT_SIZE_OPTIONS = [
    { value: FlatSize.flat1_0, label: '1+0' },
    { value: FlatSize.flat1_KK, label: '1+KK' },
    { value: FlatSize.flat1_1, label: '1+1' },
    { value: FlatSize.flat2_0, label: '2+0' },
    { value: FlatSize.flat2_KK, label: '2+KK' },
    { value: FlatSize.flat2_1, label: '2+1' },
    { value: FlatSize.flat3_KK, label: '3+KK' },
    { value: FlatSize.flat3_1, label: '3+1' },
    { value: FlatSize.flat4_KK, label: '4+KK' },
    { value: FlatSize.flat4_1, label: '4+1' },
    { value: FlatSize.flat5_KK, label: '5+KK' },
    { value: FlatSize.flat5_1, label: '5+1' },
    { value: FlatSize.flat6_KK, label: '6+KK' },
    { value: FlatSize.flat6_1, label: '6+1' },
    { value: FlatSize.flat6_2, label: '6+2' },
    { value: FlatSize.flat7_1, label: '7+1' },
    { value: FlatSize.flat7_2, label: '7+2' },
    { value: FlatSize.other, label: 'jiný' },
];
export class ZoneDefinition extends BaseEntity {
    entityType: EntityType.ZoneDefinition;
    aciProc1: Zone = null;
    aciProc2: Zone = null;
    climaType: ClimaType = ClimaType.Heating;
    cooling: number = 0;
    coolingSource: CoolingSource = CoolingSource.C1;
    createdDate: Date;
    defaultSpaceProperties: SpaceProperties;
    description: string = "Definice zóny"
    dshift: number = 5;
    dthai: number = 0.6;
    dthp: number = 0;
    duem: number = 0.02;
    eerciProc1: number = 2.0;
    eerciProc2: number = 3.0;
    ehiProc1: number = 100;
    ehiProc2: number = 10;
    exportId: String
    exposedMoreThanOneFace: boolean
    externalId: string;
    heating: number = 0;
    heatingSource: HeatingSource = HeatingSource.H1;
    mhiProc1: HeatingMediumType = HeatingMediumType.electrocity;
    mhiProc2: HeatingMediumType = HeatingMediumType.gas;
    modifiedDate: Date
    mthpz: number = 0;
    n50: number = 1;
    nbed: number = 0;
    nflat10: number = 0;
    nflat1: number = 0;
    nflat2: number = 0;
    nflat3: number = 0;
    nflat4: number = 0;
    nflat5: number = 0;
    nflat6: number = 0;
    nflat7: number = 0;
    nflat8: number = 0;
    nflat9: number = 0;
    nshift: number = 3;
    pciProc1: number = 2.2;
    pciProc2: number = 5;
    phiProc1: number = 1.5;
    phiProc2: number = 20;
    project: { externalId: string };
    qwndfd: number = 28.8;
    sflat10: FlatSize = FlatSize.other;
    sflat1: FlatSize = FlatSize.flat1_KK;
    sflat2: FlatSize = FlatSize.flat2_KK;
    sflat3: FlatSize = FlatSize.flat2_0;
    sflat4: FlatSize = FlatSize.flat3_KK;
    sflat5: FlatSize = FlatSize.flat3_1;
    sflat6: FlatSize = FlatSize.flat3_KK;
    sflat7: FlatSize = FlatSize.flat4_1;
    sflat8: FlatSize = FlatSize.flat4_KK;
    sflat9: FlatSize = FlatSize.flat5_1;
    shielding: ShieldingLevel = ShieldingLevel.ModerateShielding;
    state: ZoneDefinitionType = ZoneDefinitionType.Initial
    waterHeating: number = 0;
    waterHeatingSource: WaterHeatingSource = WaterHeatingSource.W1;
    zone: Zone = null;
    zoneType: ZoneType = ZoneType.FamilyHouse;
}