import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as uuid from 'uuid4';
import { environment } from '../../environments/environment';

@Injectable()
export class RestService {

    externalId = '';
    baseUrl = environment.apiEndpoint;
    headers_object = new HttpHeaders();

    constructor(private http$: HttpClient) {
        this.baseUrl = this.baseUrl.replace(/\\$/, '');
        this.createHeader(this.headers_object);
    }

    createHeader(header: any) {
        header.append('Content-Type', 'application/json; charset=utf-8');
        header.append('Accept', 'application/json; charset=utf-8');
    }

    getAuthorization() {
        return this.headers_object.get('Authorization');
    }

    setExternalId(value): void {
        this.externalId = value;
    }

    getExternalId() {
        return this.externalId;
    }

    getUrl(method) {
        let result = this.baseUrl;
        if (method && method.length > 0) {
            if (method.charAt(0) !== '/') {
                result += '/';
            }
            result += 'api/v1/';
            result += method;
        } else {
            result += '/api/v1/';
        }
        return result;
    }

    getUrlId(method, externalId) {
        return this.getUrl(method) + '/' + encodeURIComponent(externalId);
    }

    getHostame() {
        return this.baseUrl;
    }

    getHeader() {
        return this.headers_object;
    }

    validateUUID(data: string): boolean {
        // common pattern - this should be used because externalid can be also generated by database without uuid4 angular library
        const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
        return uuidPattern.test(data);
    }

    post<T = any>(method: string, body: any = {}) {
        return this.http$.post<T>(this.getUrl(method), body, { headers: this.getHeader() });
    }

    getAll<T = any>(method: string) {
        return this.http$.get<T>(this.getUrl(method), { headers: this.getHeader() });
    }

    get<T = any>(method: string, id: any) {
        return this.http$.get<T>(this.getUrl(method) + '/' + id, { headers: this.getHeader() });
    }

    getVersion<T = any>(){
        return this.http$.get<T>(this.getUrl("version"), { headers: this.getHeader()});
    }

    getFile<T = any>(method: string, id: any) {
        return this.http$
            .get<T>(this.getUrl(method) + '/' + id, {
                headers: this.getHeader(),
                responseType: 'blob' as 'json',
                observe: 'response'
            });
    }

    put<T = any>(method: string, id: any, body: any = {}) {
        return this.http$.put<T>(this.getUrl(method) + '/' + id, body, { headers: this.getHeader() });
    }

    delete<T = any>(method: string, id: any) {
        return this.http$.delete<T>(this.getUrl(method) + '/' + id, { headers: this.getHeader() });
    }
}
